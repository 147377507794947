import React, {useState, useEffect} from "react";
import axios from "axios";
import {
    Form,
    notification,
    HyperLink,
    fixed2Digit,
    InputBox,
    Card,
    Modal,
    Button,
} from "../components/Elements/appUtils";

import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../components/_utils/formUtils";
import {
    States,
    PaymentTypes,
    IndianStates,
} from "../components/_utils/appUtils";
import PageHeader from "../components/Elements/pageHeader";
import _ from "lodash";
import ItemListComponent from "./sales/ItemListComponent";
import { generateSaleFxn, fetchUser } from "./sales/actions";
import {getItemLocalstorage} from "../components/_utils/_utils";
import ChooseCustomer from "./customer/chooseCustomer";
import {useNavigate} from "react-router-dom";
import { fetchBank } from "./customer/actions";
import moment from "moment";
import GenerateSaleItemComponent from "./generateSaleItemComponent";




function GenerateSale(props) {
    const [formData, setFormData] = useState([
        {bankId: "", paymentType: "", amount: "", transactionDetails: ""},
    ]);
    const [showDeleteButtons, setShowDeleteButtons] = useState([false]);
    // const [isModal2Open, setIsModal2Open] = useState(false);
    const [requestedMedModal, setRequestedMedModal] = useState(false);

    const handleInputChange = (index, field, value) => {
        const newData = [...formData];
        newData[index][field] = value;
        setFormData(newData);
        setShowDeleteButtons((prevButtons) =>
            prevButtons?.map((btn, idx) => (idx === index ? true : btn))
        );
    };

    const handleDelete = (index) => {
        const newPayments = [...formData];
        newPayments.splice(index, 1);
        setFormData(newPayments);
        setShowDeleteButtons((prevButtons) =>
            prevButtons?.filter((_, idx) => idx !== index)
        );
    };

    const handleAddField = () => {
        // const sumAmount = formData.reduce(
        //     (sum, item) => sum + parseFloat(item.amount),
        // );
        const amountsArray = formData.map(data => data.amount);
        const totalAmount = amountsArray.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
        
        console.log("Total Amount:", totalAmount);
        if (totalAmount > getFieldValue("paidAmount")) {
            notification.warning({
                message: "Amount Cannot Be Greater Than Receiving Amount",
            });
            return;
        }
      
        if (totalAmount == getFieldValue("paidAmount")) {
            notification.warning({
                message: "Enough Amount Added, Cannot Add More Payment Method",
            });
            return;
        }
        const lastData = formData[formData.length - 1];
        if (!lastData.bankId) {
            notification.warning({message: "Please Choose Bank"});
            return;
        }
        if (!lastData.paymentType) {
            notification.warning({message: "Please Choose Payment Type"});
            return;
        }
        if (!lastData.amount) {
            notification.warning({message: "Please Enter Amount"});
            return;
        }

        setFormData([
            ...formData,
            {bankId: "", paymentType: "", amount: "", transactionDetails: ""},
        ]);

        setShowDeleteButtons([...showDeleteButtons, true]);
    };

    const companyStateCode = getItemLocalstorage("user")["stateCode"];

    const {getFieldValue} = props.form;
    const navigate = useNavigate();
    let dispatch = useDispatch();
    let [customerId, setCustomerId] = useState("");
    let [visitNumber, setVisitNumber] = useState();
    let [visitData, setVisitData] = useState({});
    let [clientId, setClientId] = useState("");
    const paidToUserId = getFieldValue("paidToUserId");
    const [banks, setBanks] = useState([]);
    const [saleCategory, setSaleCategory] = useState([]);
    const [ProductReport, setProductReport] = useState([]);

    const updateProductReport = (newProductReport) => {
        setProductReport(newProductReport);
    };

    const [customerState, setCustomerState] = useState("");
    const [requestId, setRequestId] = useState(null);
    const [visitId, setVisitId] = useState("");
    const [customerName, setCustomerName] = useState("");

    //credit note useStates
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [creditNoteData, setCreditNoteData] = useState([]);
    const [CreditNoteBalance, setCreditNoteBalance] = useState();
    const [creditNote_id, setCreditNote_id] = useState();
    const [showcreditNoteButton, setCreditNoteButton] = useState(false);

    useEffect(() => {
        let x = new URLSearchParams(window.location.search);
        
        setClientId(x.get("clientId"));
     
    }, []);


    useEffect(() => {
        const apiRequest123 = (params = {}) => {
            return new Promise(async (resolve) => {
                params._id = clientId;
                const data = await fetchUser({...params});
                console.log(data, "checkdata")
                // setCustomerName(data.data[0].name);
              
            });
        };
        if (clientId) {
            apiRequest123();
            setCustomerId(clientId);
        }
    }, [clientId]);


    console.log(customerId, "customerState")
  

    

    let netAmount = 0;
    let quantity = 0;
    let balance = 0;

    const calNetAmount = () => {
        if (ProductReport.length == 0) {
            props.form.setFieldsValue({
                totalAmount: 0,
                balance: 0,
                quantity: 0,
                creditNoteBalance: 0,
                remainingAmount: 0,
            });
        }
        ProductReport.forEach((y) => {
            let paidAmount = getFieldValue("paidAmount");
            paidAmount = paidAmount ? fixed2Digit(paidAmount) : 0;
            netAmount += Number(y.netAmount);
            quantity += Number(y.quantity);
            balance = paidAmount ? netAmount - paidAmount : netAmount;

            if (CreditNoteBalance) {
                balance = CreditNoteBalance ? balance - CreditNoteBalance : balance;
            }

            if (balance < 0) {
                balance = "Not valid Paid Amount";

                props.form.setFieldsValue({
                    totalAmount: netAmount ? fixed2Digit(netAmount) : 0,
                    quantity: quantity,
                    balance: 0,
                });
            } else {
                props.form.setFieldsValue({
                    totalAmount: netAmount.toFixed(2),
                    quantity: quantity,
                    balance: balance ? balance.toFixed(2) : 0,
                });
            }
        });
    };

    useEffect(() => {
        if (CreditNoteBalance) {
            const finalBalance = getFieldValue("balance") - CreditNoteBalance;
            const remainingAmount = getFieldValue("totalAmount") - CreditNoteBalance;
            props.form.setFieldsValue({
                balance: finalBalance,
                remainingAmount: remainingAmount,
            });
        }
    }, [CreditNoteBalance]);

    const fetchBanks = async () => {
        const {data} = await fetchBank({
            type: "bank",
            results: 100,
            count: 100,
        });
        setBanks(data);
    };

   

    

  

    useEffect(() => {
        props.form.setFieldsValue({
            paidAmount: 0,
            paymentType: "None",
            salesDate: moment(),
            creditNoteBalance: 0,
            remainingAmount: 0,
        });
        
        fetchBanks();
      
    }, []);

    useEffect(() => {
       
        if(customerState)
        {
            props.form.setFieldsValue({
               
                stateOfSupply: customerState,
               
            });
        }
     
    }, [customerState]);


    useEffect(() => {
        calNetAmount();
    }, [ProductReport, getFieldValue("paidAmount")]);

    const handleSubmit = async (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let findStateCode = _.find(States, (item) => {
                    return item.name === valData.state;
                });
                if (findStateCode) {
                    valData.stateCode = findStateCode.stateCode;
                }
                valData.productReports = ProductReport;
                valData.branchId = customerId;
                valData.paidToUserId = paidToUserId;
                // valData.requestId = requestId;
                valData.paidToUser = formData;
              
                console.log(valData, "valData");

                const sumFormDataAmount = formData.reduce(
                    (sum, item) => sum + parseFloat(item.amount) || 0,
                    0
                );

                if (ProductReport.length == 0) {
                    notification.error({
                        message: "Please Add Products",
                    });
                    return;
                }

                const totalAmount = valData.totalAmount;
                const paidAmount = valData.paidAmount;
                if (paidAmount < 0) {
                    notification.error({
                      message: "Paid Amount cannot be negative",
                    });
                    return;
                  }
                if (parseFloat(paidAmount) > parseFloat(totalAmount)) {
                    notification.error({
                        message: "Recieving amount cannot be Greater than total amount",
                    });
                    return null;
                }

                if (valData.creditNoteBalance) {
                    if (parseFloat(paidAmount) > parseFloat(valData.remainingAmount)) {
                        notification.error({
                            message:
                                "Recieving amount cannot be Greater than Remaining amount",
                        });
                        return null;
                    }
                }

                if (!valData.totalAmount) {
                    notification.warning({message: "Please Add Some Items"});
                    return;
                }

               
                if (!valData.stateOfSupply) {
                    notification.warning({message: "Please Add State of Supply"});
                    return;
                }
                // if (!valData.customerId) {
                //     notification.warning({message: "Please Choose Client"});
                //     return;
                // }

                console.log(
                    parseFloat(valData.paidAmount) < sumFormDataAmount,
                    "totalAmount"
                );

                if (parseFloat(valData.paidAmount) < sumFormDataAmount) {
                    notification.error({
                        message: "Paid amount cannot be Greater than total amount",
                    });
                    return;
                } else if (parseFloat(valData.paidAmount) > sumFormDataAmount) {
                    notification.error({
                        message: "Paid amount cannot be less than total amount",
                    });
                    return;
                }
                let x = await dispatch(generateSaleFxn(valData));

                if (x && x.success) {
                    notification.success({message: x.message});
                    setProductReport([]);
                    props.form.setFieldsValue({});
                    setTimeout(() => {
                        navigate("/sale");
                    }, 1000);
                } else {
                    notification.error({message: x.message});
                }
                props.form.setFieldsValue({
                    stateOfSupply: "",
                    invoiceNumber: "",
                    salesDate: "",
                    totalAmount: "",
                    paidAmount: "",
                    balance: "",
                    paidToUserId: "",
                    paymentType: "",
                    paymentDetails: "",
                });
                setCustomerId("");
            }
        });
    };

    let inputTypes = {
        upperFields: [
            {
                key: "salesDate",
                label: "Date of Sale",
                type: "date",
                span: "col-md-3",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        salesDate: x,
                    });
                },
            },
         
            {
                key: "stateOfSupply",
                label: "State of Supply",
                span: "col-md-3",
                placeholder: "State of supply",
                type: "select",
                options: IndianStates,
                showSearch: true,
                keyAccessor: (x) => x.stateCode,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        stateOfSupply: x,
                    });
                },
            },
        ],

        lowerFields: [
            {
                key: "totalAmount",
                label: "Total Amount *",
                span: "col-md-2",
                placeholder: "Total Amount",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        totalAmount: netAmount,
                    });
                },
                disabled: true,
            },
            {
                key: "creditNoteBalance",
                label: "Credit Note  *",
                span: "col-md-2",
                placeholder: "Credit Note Balance",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        creditNoteBalance: CreditNoteBalance,
                    });
                },
                hidden: creditNoteData.length == 0,
                disabled: true,
            },
            {
                key: "remainingAmount",
                type: "number",
                label: "Remaining Amt",
                span: "col-md-2",
                placeholder: "Remaining Amount",
                hidden: creditNoteData.length == 0,
                disabled: true,
            },
            {
                key: "paidAmount",
                type: "number",
                label: "Received Amount",
                span: "col-md-2",
                placeholder: "Received Amount",
            },
            {
                key: "balance",
                label: "Balance *",
                span: "col-md-2",
                placeholder: "Balance",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        balance: balance,
                    });
                },
                disabled: true,
            },
        ],
    };
console.log(clientId, "clientId")
    const groupType = "SUNDRY DEBTORS";

    return (
        <PageHeader title={"Add Sale"}>
            <div className="card">
                <div className="card-body">
                    <div className={"row"}>
                        {clientId ? (
                            <div className={"row"}>
                                <div className={"col-md-3 mb-3"}>
                                    <InputBox title={"Client"}>
                                        <input
                                            style={{height: "45px", color: "black"}}
                                            disabled
                                            className={"form-control"}
                                            type="text"
                                            // value={customerName}
                                        />
                                    </InputBox>
                                </div>
                            </div>
                        ) : (
                            <div className={"col-md-3 mb-3"}>
                                <ChooseCustomer
                                    groupType={groupType}
                                    customerId={customerId}
                                    callback={(clientData) => {
                                        if (clientData && clientData._id) {
                                            setCustomerId(clientData._id);
                                            setCustomerState(clientData.
                                                
state

                                                )
                                        }
                                    }}
                                />
                            </div>
                        )}

                      
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <div className="form-elements-wrapper">
                            <div className={"row"}>
                                {inputTypes.upperFields.map((item, key) => {
                                    return !item.hidden ? (
                                        <div
                                            className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    ) : null;
                                })}
                            </div>
                            <div className={"row"} key={getFieldValue("stateOfSupply")}>
                                <div className={"col-md-12 mt-4"}>
                                    <GenerateSaleItemComponent
                                        // isModal2Open={isModal2Open}
                                        // setIsModal2Open={setIsModal2Open}
                                        // requestedMedModal={requestedMedModal}
                                        // setRequestedMedModal={setRequestedMedModal}
                                        visitData={visitData}
                                        requestId={requestId}
                                        visitId={visitId}
                                        ProductReport={ProductReport}
                                        customerId={customerId}
                                        updateProductReport={updateProductReport}
                                        stateOfSupply={getFieldValue("stateOfSupply")}
                                        companyState={companyStateCode}
                                    />
                                </div>
                            </div>
                           

                            <div className={"row"}>
                                {inputTypes.lowerFields.map((item, key) => {
                                    return !item.hidden ? (
                                        <div
                                            className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    ) : null;
                                })}
                            </div>

                            {getFieldValue("paidAmount") ? (
                                <Card title={"Payments"} size={"small"}>
                                    <div className="d-flex align-items-end">
                                        <div>
                                            {formData?.map((data, index) => (
                                                <div key={index} className={"item-flex mt-2"}>
                                                    <InputBox title={"Banks"}>
                                                        <select
                                                            style={{height: "45px"}}
                                                            className="form-control"
                                                            value={data.bank}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "bankId",
                                                                    e.target.value
                                                                )
                                                            }
                                                            required="true"
                                                        >
                                                            <option value="">Select Bank</option>
                                                            {banks?.map((bank) => (
                                                                <option key={bank._id} value={bank._id}>
                                                                    {bank.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </InputBox>

                                                    <InputBox title={"Payment Type"}>
                                                        <select
                                                            style={{
                                                                height: "45px",
                                                            }}
                                                            className="form-control"
                                                            value={data.paymentType}
                                                            required
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "paymentType",
                                                                    e.target.value
                                                                )
                                                            }
                                                        >
                                                            <option value="">Select Payment Type</option>
                                                            {PaymentTypes?.map((PaymentTypes) => (
                                                                <option>{PaymentTypes.name}</option>
                                                            ))}
                                                        </select>
                                                    </InputBox>

                                                    <InputBox title={"Amount"}>
                                                        <input
                                                            style={{
                                                                height: "45px",
                                                            }}
                                                            className={"form-control"}
                                                            type="number"
                                                            value={data.amount}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "amount",
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder="Amount"
                                                        />
                                                    </InputBox>

                                                    <InputBox title={"Transaction Details"}>
                                                        <input
                                                            style={{
                                                                height: "45px",
                                                            }}
                                                            className={"form-control"}
                                                            value={data.transactionDetails}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "transactionDetails",
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder="Transaction Details"
                                                        />
                                                    </InputBox>
                                                    <InputBox className="deleteBtn">
                                                        <div key={index} className={"item-flex mt-3"}>
                                                            {index > 0 &&
                                                            showDeleteButtons[index] && ( // Condition added to not show delete button for zero index
                                                                <a
                                                                    className="empty_btn"
                                                                    onClick={() => handleDelete(index)}
                                                                >
                                                                    <i className="fa fa-trash-alt"></i>
                                                                </a>
                                                            )}
                                                        </div>
                                                    </InputBox>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            <InputBox className={"pb-2"}>
                                                <a
                                                    className="addBtn  btn-link"
                                                    onClick={handleAddField}
                                                >
                                                    Add More
                                                </a>
                                            </InputBox>
                                        </div>
                                    </div>
                                </Card>
                            ) : null}
                        </div>

                        <button type="submit" className="btn btn-gradient-info me-2">
                            Submit
                        </button>
                        <HyperLink className=" ms-4 btn btn-danger" link={"/sale"}>
                            Cancel
                        </HyperLink>
                    </Form>
                </div>
            </div>
        </PageHeader>
    );
}

export default Form.create()(GenerateSale);
