import React, { useEffect, useRef, useState } from "react";
import { Modal, Table } from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { fetchVisits, checkrecords, fetchIdPrescription } from "./actions";
import moment from "moment";
import PrescriptionModal from "../patients/components/prescriptionModal";
import { useNavigate } from "react-router-dom";
import { getItemLocalstorage } from "../../components/_utils/_utils";
import { fetchVisitAllDetailsFxn } from "../patients/components/action";
import { SiConvertio } from "react-icons/si";

import log from "async";
const Visits = (props) => {
  const userType = getItemLocalstorage("user")["userType"];
  const userId = getItemLocalstorage("user")["_id"];
  let tableRef = useRef();
  const navigate = useNavigate();
  const [patientidrecord, setPatientidrecord] = useState("");
  const [patientNameRecord, setPatientNameRecord] = useState("");
  const [filterState, setFilterState] = useState(`initFilter`);
  const [visitId, setVisitId] = useState("");
  const [visiNumber, setVisitNumber] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [prescripedProduct, setPrescribedProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visitData, setVisitData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [searchPatientId, setSearchPatientId] = useState("")
  const [searchPatientName, setSearchPatientName] = useState("")
  const [searchDate, setSearchDate] = useState("")
  const [searchTime, setSearchTime] = useState("")
  let [patientObj, setPatientObj] = useState({
    visible: false,
    patient: {},
  });

  const apiRequest = (params={}) => {
    return new Promise(async (resolve) => {
      try {
        if (userType == "doctor"){
          params = {
            doctorId: userId,
          };
        }
        const data = await fetchVisits({ ...params } );
        const currentDate = moment().format('YYYY-MM-DD');
        const filteredData = data.data.filter((item) =>
          moment(item.date).format('YYYY-MM-DD') == currentDate
        );
        const responseData = { data: filteredData };
        setVisitData(responseData.data)
        setFilteredData(responseData.data)
        resolve(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
        resolve([]);
      }
    });
};

  const apiRequest2 = async () => {
    return new Promise(async (resolve) => {
      try {
        let params = {
          visitId: visitId,
        };
        const data = await fetchVisitAllDetailsFxn({ ...params });
        setPrescribedProducts(data?.prescriptionDetails?.products);
        resolve(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const ConvertSale = (id,patientId,visitNumber) => {
    setTimeout(() => {
      navigate(`/add-sales?visitID=${id}&clientId=${patientId}&visitNumber=${visitNumber}`);
    }, 1000);
  };

  const events = {
    showPatientModal: (patient) => {
      setPatientObj({
        patient,
        visible: true,
      });
    },
    hidePatientModal: () => {
      setPatientObj({
        patient: {},
        visible: false,
      });
    },
  };

  useEffect(() => {
    apiRequest();
  }, []);

  useEffect(() => {
    apiRequest2();
  }, [visitId]);

  const columns = [
    {
      title: "Sr. No",
      width:100,
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "UhId",
      dataIndex: "patientId",
      key: "patientId",
      width:80,
      render: (item) => {
        return<>{item?.uhid}</>
      }
    },
    {
      title: "Visit Number",
      dataIndex: "visitNumber",
      width:80,
      key: "visitNumber",
      render: (text, record) => (
        <span>{record.visitNumber} </span>
      ),
    },

    {
      title: "Patient Name",
      dataIndex: "patientId",
      key: "patientId",
      width:80,
      render: (item) => {
        return <>{item && item.name ? item.name : ""}</>;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width:80,
      render: (text, record) => (
        <span>{moment(record.date).format("DD/MM/YYYY")} </span>
      ),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width:80,
      render: (text, record) => (
        <span>{moment(record.date).format("hh:mm A")}</span>
      ),
    },
    {
      title: "Actions",
      width:150,
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <>
          {userType === "doctor" && (
            <>
              {record.statusApprovedByDoctor === false ? (
                <>
                  <a
                    className={"empty_btn"}
                    onClick={() => {
                      events.showPatientModal(record);
                      setPatientidrecord(record.patientId._id);
                      setPatientNameRecord(record.patientId.name);
                      setVisitId(record._id);
                      setVisitNumber(record?.visitNumber);
                      setDoctorId(record?.doctorId?._id);
                    }}
                  >
                    <i className={"fa fa-plus"}/>
                  </a>
                </>
              ) : (
                <>
                  <a
                    href={`/prescription-detail?visitId=${record._id}`}
                    target="_blank"
                    className="m-2"
                  >
                    <img src={"./printer-50.png"} className="print" />
                  </a>
                  <a
                    className="m-2"
                    onClick={() => {
                      navigate(`/edit-prescription?_id=${record._id}`);
                    }}
                  >
                    <img src={"./edit.svg"} />
                  </a>
                </>
              )}
            </>
          )}

          {userType === "reception" && record.statusApprovedByDoctor === true ? (
            <>
              <SiConvertio
                  className={'fs-3'}
                  onClick={() => {
                    ConvertSale(record._id,record.patientId._id,record.visitNumber);
                  }}
              />

              <a
              href={`/prescription-detail?visitId=${record._id}`}
               target="_blank"
              className="m-2"
              >
              <img src={"./printer-50.png"} className="print" />
            </a>
              </>
          ) : null}
        </>
      ),
    },
    {
      title: "Prescriptions",
      width:150,
      dataIndex: "description",
      key: "description",
      hidden: userType == 'doctor' || userType == 'branch' || userType == 'company' ,
      render: (text, record) => (

          <>
            {(userType === "reception" || userType === "headBranch") && (
                <div className="d-flex row">
                  {record.prescriptionId && record.prescriptionId.test && record.prescriptionId.test.length !== 0 && (
                      <span>Test Added ✅</span>
                  )}
                  {record.prescriptionId && record.prescriptionId.services && record.prescriptionId.services.length !== 0 && (
                      <span>Service Added ✅</span>
                  )}
                  {record.prescriptionId && record.prescriptionId.products && record.prescriptionId.products.length !== 0 && (
                      <span>Prescription Added ✅</span>
                  )}
                </div>
            )}
          </>
      ),
    },
  ];

  const filterPatient = () => {
    setShowFilter(true);

    let filteredPatient = [];

    if (!searchPatientId && !searchPatientName && !searchDate && !searchTime) {
      setVisitData([]);
      return;
    }

    filteredPatient = visitData.filter((item) => {
      const nameMatch = searchPatientName && item.patientId?.name.toLowerCase().includes(searchPatientName.toLowerCase());

      const idMatch = searchPatientId && item.patientId?.uhid.toLowerCase().includes(searchPatientId.toLowerCase());

      if ((searchPatientName && nameMatch) || (searchPatientId && idMatch)) {
        return true;
      }

      return false;
    });

    setVisitData(filteredPatient);
  };

  const clearPatientFilters = () => {
    setSearchPatientId("")
    setSearchPatientName("")
    setSearchDate("")
    setSearchTime("")
    setShowFilter(false)
    setVisitData(filteredData)
    if(tableRef.current.reload) {
      tableRef.current.reload()
    }
  }

  const reloadPage = () =>{
    tableRef.current.reload()
  };

  return (
    <PageHeader
        title={"Visits"}
        extraLink={[
          {
            name: "Add Patients",
            link: "/add-patient",
          },
        ]}
    >
      <div className="card">
        <div className="card-body">
        <div className="mb-3 row">
        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
        <label htmlFor="searchPatientId" className="form-label" />
        <input
          type="text"
          className="form-control search-filters"
          id="searchPatientId"
          placeholder="Search by PatientId"
          value={searchPatientId}
          onChange={(e) => setSearchPatientId(e.target.value)}
        />
      </div>
        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>

          <label htmlFor="searchPatientName" className="form-label" />
          <input
            type="text"
            className="form-control search-filters"
            id="searchPatientName"
            name="name"
            placeholder="Search by PatientName"
            value={searchPatientName}
            onChange={(e) => setSearchPatientName(e.target.value)}
          />
        </div>
      </div>
      <div className="mb-3 search-buttons justify-content-between">
        <div className={'d-flex'}>
          <div className="search-wrap pe-2">
            <button className="btn btn-info" onClick={filterPatient}>
              Search
            </button>
          </div>
          <div className="search-wrap">
            <button className="btn btn-secondary" onClick={clearPatientFilters}>
              Clear
            </button>
          </div>
        </div>
        <div>
          <div className="search-wrap">
            <i
                style={{fontSize:'30px',alignSelf:'self-end'}}
                onClick={reloadPage}>
              🔄️
            </i>
          </div>
        </div>

      </div>
      {showFilter ? (
        <Table
          dataSource={visitData}
          columns={columns}
          ref={tableRef}
          key={`table-${showFilter ? 'filtered' : 'original'}`}
        />
      ) : (
        <Table
          apiRequest={apiRequest}
          columns={columns}
          ref={tableRef}
        />
      )}
        </div>
      </div>

      {patientObj.visible ? (
        <PrescriptionModal
          {...patientObj}
          onClose={events.hidePatientModal}
          patientidrecord={patientidrecord}
          patientNameRecord={patientNameRecord}
          visitId={visitId}
          doctorId={doctorId}
          visiNumber={visiNumber}
        />
      ) : null}

      {isModalOpen && (
        <Modal
          title={"Medicines"}
          width={"50%"}
          visible={isModalOpen == true}
          onClose={() => setIsModalOpen(false)}
        >
          <table class="table table-bordered mt-4">
            <thead>
              <td>Sr no.</td>
              <td>Product Name</td>
              <td>Dosage</td>
              <td>Frequency/Routine</td>
              <td>Remarks</td>
              <td>When to Taken</td>
            </thead>
            {prescripedProduct ? (
              <tbody>
                {prescripedProduct.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.productName}</td>
                    <td>{item.dosage}</td>
                    <td>{item.frequency}</td>
                    <td>{item.remarks}</td>
                    <td>{item.whentotake}</td>
                  </tr>
                ))}
              </tbody>
            ) : null}
          </table>
        </Modal>
      )}
    </PageHeader>
  );
};

export default Visits;
