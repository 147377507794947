import {
    addProductUrl,
    deleteProductUrl,
    getBrandUrl,
    getCategoryUrl,
    getProductDataUrl,
    getProductDataUrl2,
    getStockUrl,
    getServiceUrl,
    getPatientServiceDataUrl
} from "../api";
import {customAxios as axios, getToken} from "../../../request";
import {hideLoader, showLoader} from "../../../actions/loader";
import {notification} from "../../../components/Elements/appUtils";
import { singleProductUrl,updateProductUrl ,updateServiceUrl} from "../api";
export const fetchProduct = async () => {
    let config = {
        // params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getStockUrl(), config);
    return data;
};

export const fetchService = async (valData) => {
    let config = {
        params: {...valData, type: "Service"},
        ...await getToken()
    }
    const {data} = await axios.get(getProductDataUrl(), config);
    return data;
};

export const fetchOnlyServiceFxn = async (valData) => {
    let config = {
        params: {...valData, type: "Service"},
        ...await getToken()
    }
    const {data} = await axios.get(getServiceUrl(), config);
    return data;
};

export const getServiceforReceptionFxn = async (valData) => {
    let config = {
        params: {...valData, type: "Service"},
        ...await getToken()
    }
    const {data} = await axios.get(getPatientServiceDataUrl(), config);
    return data;
};


// export const fetchProduct2 = async (valData) => {
//     let config = {
//         params: {...valData},
//         ...await getToken()
//     }
//     const {data} = await axios.get(getProductDataUrl2(), config);
//     return data;
// };

export const FetchBrand = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getBrandUrl(), config);
    return data;
};


export const getCategory = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getCategoryUrl(), config);
    return data;
};

export const addProductFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addProductUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};
export const singleProductFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    
    let { data } = await axios.get(singleProductUrl(id),getToken ());
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    }
  
    return data;
  };
  export const updateProductFxn = (valData) => async (dispatch) => {
   
    dispatch(showLoader());
    let {data} = await axios.post(updateProductUrl(), valData,getToken() );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };
  export const updateServiceFxn = (valData) => async (dispatch) => {
   
    dispatch(showLoader());
    let {data} = await axios.post(updateServiceUrl(), valData,getToken() );
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };

  export const deleteProductFxn = (id) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.delete(deleteProductUrl(id), getToken());
    dispatch(hideLoader())
    if (data.success) {
        notification.success({
            message: data.message || "Success",
        });
    } else {
        notification.error({
            message: data.message || "Error",
        });
    }
    return data;
};