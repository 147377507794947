import {
  getSaleUrl,
  getCustomerDataUrl,
  getbatchRecordByProductIdUrl,
  invoiceUpdateUrl,
  addTransferUrl,
  addTransferSaleUrl,
  addTransferPurchaseUrl,
  getTransferUrl,
  generateSale
} from "../api";
import { getProductReport } from "../../purchase/api";
import { customAxios as axios, getToken } from "../../../request";
import { hideLoader, showLoader } from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
import { getSaleByHeadBranch } from "../api";
import { generateSaleUrl } from "../../sales/api";
export const fetchCustomer = async (valData) => {
  let config = {
    params: { ...valData },
    ...getToken()
  };
  const { data } = await axios.get(getCustomerDataUrl(), config);
  return data;
};

export const fetchBatchByProductId = async (productId) => {
  let config = {
    params: { productId },
  };
  const { data } = await axios.get(getbatchRecordByProductIdUrl(), config);
  return data;
};

export const fetchTransfer = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  const { data } = await axios.get(getTransferUrl(), config);
  return data;
};

export const fetchProductReportFxn = async (valData) => {
  let config = {
      params: {...valData},
      ...getToken(),
  }
  console.log(config,"thsi si confic");
  const {data} = await axios.get(getProductReport(),config);
  return data;
};

export const invoiceUpdateFxn = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  const { data } = await axios.get(invoiceUpdateUrl(), config);
  return data;
};

export const addTransferSaleFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  const { data } = await axios.post(addTransferSaleUrl(), valData, getToken());
  dispatch(hideLoader());
  if (!data.error) {
    // notification.success(data.message || "Success")
  } else {
    notification.error(data.message || "Error");
  }
  return data;
};

export const addTransferPurchaseFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  const { data } = await axios.post(addTransferPurchaseUrl(), valData, getToken());
  dispatch(hideLoader());
  if (!data.error) {
    // notification.success(data.message || "Success")
  } else {
    notification.error(data.message || "Error");
  }
  return data;
};

// export const fetchROI = async (valData) => {

//   const { data } = await axios.get(getSaleByHead());
//   return data;
// };
export const fetchROI = (valData) => async (dispatch) => {
  dispatch(showLoader());
  const { data } = await axios.get(getSaleByHeadBranch(), valData);
  dispatch(hideLoader());
  if (!data.error) {
  } else {
    notification.error(data.message || "Error");
  }
  return data;
};
export const generateSales = (valData) => async (dispatch) => {
  dispatch(showLoader());
  const { data } = await axios.post(generateSale(), {}, getToken());
 
  dispatch(hideLoader());
  if (!data.error) {
    notification.success(data.message || "Success")
  } else {
    notification.error(data.message || "Error");
  }
  return data;
};