import React, { useEffect, useState } from "react"
import { InputBox,Tooltip } from "../../components/Elements/appUtils";
import _ from "lodash"
import Select from "rc-select";
import { fetchSymptom } from "./action";

import "../../components/Elements/Select/index.css"
import "rc-select/assets/index.less"
import AddCustomerDrawer from "../customer/components/AddCustomerDrawer";
import AddSymptomDrawer from "./addSymptomDrawer";


const { Option } = Select

const ChooseSymptom = (props) => {
    let {
        callback,
        symptomId, 
    } = props;
    
    let [clientId, setClientId] = useState(undefined)
    let [arrData, setArrData] = useState([])
    let [visible, setVisible] = useState(false)
    const events = {
        loadDefaultClient: async () => {
            if (symptomId) {
                let params = {
                    // results: 20,
                    // count: 20,
                    _id: symptomId,
                    regExFilters: ['symptomName', ],
                    select: ['symptomName']
                }
                let { data } = await fetchSymptom(params);
                setArrData(data)
            } else {
                setArrData([])
            }
        },
        fetchClient: async (symptomName) => {
            if (symptomName) {
                let params = {
                  
                    name:  symptomName,
              
                 
                   regExFilters: ['symptomName', ],
                    select: ['symptomName']
                }
                let { data } = await fetchSymptom(params);
               
                setArrData(data)
            } else {
                setArrData([])
            }
        },
        handleChange: (clientId) => {
            let item = _.find(arrData, (value) => {
                return value._id == clientId
            })
            setClientId(clientId)
            callback(item)
        },
       
    }
    useEffect(() => {
        events.loadDefaultClient()
    }, [symptomId])

    return (
        <>
            <InputBox title={'Choose Symptom'}>
                <div className={'rowFlex'}>
                    <Select showSearch={true}
                        onSearch={events.fetchClient}
                        filterOption={false}
                        autoClearSearchValue={true}
                        allowClear={true}
                        value={clientId}
                        showArrow={true}
                        onChange={events.handleChange}
                        placeholder="Choose Symptom"
                        className={`rc-select rc-select1`}
                        choiceTransitionName="rc-select-selection__choice-zoom"
                        style={{ width: "100%" }}
                        dropdownRender={menu => {
                            return (
                                <div key={arrData}>
                                    {menu}
                                
                                </div>
                            )
                        }}
                        >

                        {arrData && arrData.length ? arrData.map((item, index) => {
                            return (
                                <Option key={item._id} value={item._id}>{item.symptomName}</Option>
                            )
                        }) : null}
                    </Select>
                    <Tooltip title={'Add Customer'}>
                        <a className={'plus_btn '} onClick={() => {
                            setVisible(true)
                        }}>
                            <i className="mdi mdi-plus menu-icon"></i>
                        </a>
                    </Tooltip>
                </div>
            </InputBox>

            {visible ? <AddSymptomDrawer
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }} /> : null}
        </>
    )
}
export default ChooseSymptom
