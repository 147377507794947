import {apiUrl} from "../../../setting";



export const addLeadUrl = () => {
    return `${apiUrl}/routes/lead/createLead`;
};
export const getAllLeadUrl = () => {
    return `${apiUrl}/routes/lead/leadList`;
};
export const addFollowUrl = () => {
    return `${apiUrl}/routes/followUp/addFollowUp`;
};
export const addFollowListUrl = () => {
    return `${apiUrl}/routes/followUp/followUpList`;
};
export const singleLeadUrl = (id) => {
    return apiUrl + "/routes/lead/getSingleLead/" + id;
};
export const updateLeadUrl = () => {
    return `${apiUrl}/routes/lead/updateLead`;
  };
