import React, { useEffect, useState } from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../../components/Elements/appUtils";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../../components/_utils/formUtils";
import {
  States,
  gstTypeList,
  getStateByCountry,
} from "../../../components/_utils/appUtils";
import { addCustomerFxn } from "../actions";
import _ from "lodash";
import { fetchGroup } from "../../customerGroup/actions";
import { useNavigate } from "react-router-dom";

const AddBankComponent = (props) => {
  const navigate = useNavigate();

  const [groups, setGroups] = useState([]);
  const { getFieldValue } = props.form;
  let dispatch = useDispatch();

  useEffect(() => {
    props.form.setFieldsValue({
      customerGroupName: "BANK ACCOUNT",
    });
  }, []);

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        let findStateCode = _.find(States, (item) => {
          return item.name === valData.state;
        });
        if (findStateCode) {
          valData.stateCode = findStateCode.stateCode;
        }

       
        if (!valData.customerGroupName) {
          notification.warning({ message: "Please Select Group" });
          return;
        }

        valData.type = "bank";
        let x = await dispatch(addCustomerFxn(valData));
        if (x && x.success) {
          setTimeout(() => {
            navigate("/banks");
        }, 1000);
          props.form.setFieldsValue({
            name: "",
            // openingBalance: "",
            customerGroupName: "BANK ACCOUNT",
            bankName: "",
            accountNo: "",
            ifsc: "",
            details: "",
            address: "",
          });
        }
      }
    });
  };

  const groupName = getFieldValue("customerGroupName");
  console.log(groupName, "group name");

  const events = {
    fetchGroups: async () => {
      let params = {
        // results: 100,
        // count: 100,
        type: "BANK",
      };
      const data = await fetchGroup({ ...params });
      setGroups(data.data);
    },

    // getState: (countryName) => {
    //     let resp = getStateByCountry(countryName)
    //     setStateList(resp)
    // },
    // getShippingState: (countryName) => {
    //     let resp = getStateByCountry(countryName)
    //     setShippingStateList(resp)
    // }
  };

  useEffect(() => {
    events.fetchGroups();
  }, []);

  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name",
        span: "col-md-6",
        placeholder: "Name",
        required: true,
      },
      {
        key: "customerGroupName",
        label: "Select Group",
        span: "col-md-6",
        placeholder: "Select Group",
        type: "select",
        options: groups,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            customerGroupName: x,
          });
        },
      },
      // {
      //   key: "openingBalance",
      //   label: "Opening Balance",
      //   span: "col-md-6",
      //   placeholder: "Opening Balance",
      //   required: false,
      //   hidden: groupName === "CASH IN HAND",

      // },

     

      {
        key: "bankName",
        label: "Bank Name",
        span: "col-md-6",
        placeholder: "Bank Name",
        hidden: groupName === "CASH IN HAND",
        required: true,
      },
      {
        key: "accountNo",
        label: "Account No",
        span: "col-md-6",
        placeholder: "Account No",
        required: true,
        hidden: groupName === "CASH IN HAND",
      },

      {
        key: "ifsc",
        label: "IFSC Code",
        span: "col-md-6",
        placeholder: "IFSC Code",
        hidden: groupName === "CASH IN HAND",
        required: true,
      },

      {
        key: "details",
        label: "Details",
        span: "col-md-12",
        type: "textarea",
        placeholder: "Details..",
      },
      {
        key: "address",
        label: "Address",
        span: "col-md-12",
        type: "textarea",
        placeholder: "Bank Address",
        hidden: groupName === "CASH IN HAND",
      },
    ],
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-elements-wrapper">
        <div className={"row"}>
          {inputTypes.fields.map((item, key) => {
            return !item.hidden ? (
              <div
                className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                key={key}
              >
                <GetEachFormFields {...props.form} item={item} />
              </div>
            ) : null;
          })}
        </div>
      </div>

      <button type="submit" className="btn btn-gradient-info me-2">
        Submit
      </button>
      <HyperLink className=" ms-4 btn btn-danger" link={"/banks"}>
        Cancel
      </HyperLink>
    </Form>
  );
};

export default Form.create()(AddBankComponent);
