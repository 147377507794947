

import { getToken } from "../../../../request";
import axios from "axios";
import { hideLoader, showLoader } from "../../../../actions/loader";
import notification from "../../../../components/Elements/Notification";
import { createPrescreptionUrl, getTakenDataUrl, getFrequencyDataUrl,getVisitAllRecordsUrl,updatePrescriptionUrl, updatetakenUrl, singletakenUrl, singleFrequencyUrl, updateFrequencyUrl } from "../api";
export const addPrescreptionFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(createPrescreptionUrl(), valData, getToken());
    dispatch(hideLoader())

    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};

export const updatePrescriptionFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updatePrescriptionUrl(), valData);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };
export const fetchTakenDetails = async (valData) => {
    let config = {
        ...await getToken()
    }
    const {data} = await axios.get(getTakenDataUrl(), config);
    return data;
};

export const fetchFrequencyDetails = async (valData) => {
    let config = {
        ...await getToken()
    }
    const {data} = await axios.get(getFrequencyDataUrl(), config);
    return data;
};

export const fetchVisitAllDetailsFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getVisitAllRecordsUrl(), config);
    return data;
};

export const singleTakenFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    
    let { data } = await axios.get(singletakenUrl(id));
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    }
  
    return data;
  };
  export const updateTakenFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updatetakenUrl(), valData ,getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };
  export const singleFrequencyFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    
    let { data } = await axios.get(singleFrequencyUrl(id));
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    }
  
    return data;
  };
  export const updateFrequencyFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateFrequencyUrl(), valData ,getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };
