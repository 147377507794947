import React, { useState, useRef } from "react";
import { customAxios as axios, getToken } from "../../request";
import {
  InputBox,
  Form,
  Table,
  notification,
} from "../../components/Elements/appUtils";
import { addCategoryUrl } from "./api";
import { fetchCategory } from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import { useNavigate } from "react-router-dom";
function AddCategory() {
  let tableRef = useRef();
  const [formData, setFormData] = useState({
    categoryName: "",
  });
const navigate = useNavigate();
  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Send the data to the backend using Axios
    const response = await axios.post(addCategoryUrl(), formData, getToken());
    if (response.data.success) {
      notification.success({
        message: response.data.message || "Success",
      });
      setFormData({ categoryName: "" });
      tableRef.current.reload();
    } else {
      notification.error({ message: response.data.message });
    }
  };

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await fetchCategory({ ...params });
        resolve(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const columns = [
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 190,
      render: (val, record) => {
        return (
            <>
                <a onClick={() => {
                  navigate(`/edit-category?_id=${record._id}`)
                }}>
                  <img src={"./edit.svg"}/>

                </a> 
              

            </>
        );
      }
  }
  ];

  return (
    <PageHeader title={"Categories"}>
      <Form>
        <div class="card">
          <div class="card-body">
            <div class="form-elements-wrapper">
              <div class="row">
                <div class="col-lg-6">
                  <InputBox title={"Category Name"}>
                    <input
                      type="text"
                      className={"form-control"}
                      placeholder="Enter Category"
                      value={formData.categoryName}
                      onChange={(e) => handleChange(e, "categoryName")}
                      required
                    />
                  </InputBox>
                </div>
              </div>
            </div>

            <button onClick={handleSubmit} className="btn btn-success">
              SUBMIT
            </button>
          </div>
        </div>
      </Form>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
            </div>
          </div>
        </div>
      </div>
    </PageHeader>
  );
}

export default AddCategory;
