import React, {Suspense} from 'react';
import Header from "./header";
import Footer from "./footer";
import SideBar from "./asidebar";
import {Navigate, Outlet} from "react-router-dom";
import PrescriptionDetail from '../../pages/doctor/prescriptionDetail';
import PrintCreditNote from '../../pages/creditNote/check2';
// import PrintSale from '../../pages/sales/printSale';

// const Scripts = React.lazy(() => import('./scripts.js'))

const isLoggedIn = localStorage.getItem("token") ? true : false;

export default function CreditNoteLayout(props) {
    return isLoggedIn ? (
        <>
            <div className="container-scroller">

                <div className="container-fluid slip">


                    {/* <div className="main-panel  mt-4"> */}

                    <PrintCreditNote/>



                    {/* </div> */}
                </div>
            </div>

        </>) : (
        <Navigate replace to="/login"/>
    )
}
