import { InputBox, Modal } from "./Elements/appUtils";
import { useState, useEffect } from "react";
import moment from "moment";

const TransactionComp = (props) => {
  const { customerDetails, Report, visible, onClose } = props;
  console.log(Report,"report")
  const [balances, setBalances] = useState({});
  useEffect(() => {
    if (Report) {
      updateBalances();
    }
  }, [Report]);

  const updateBalances = () => {
    let currentBalance = 0;
    const newBalances = {};

    Report.forEach((item, index) => {
      const key = item.invoiceNumber;
      if (item.transactionType === "Credit") {
        currentBalance += item.netAmount;
      } else if (item.transactionType === "Debit") {
        currentBalance -= item.netAmount;
      }
      newBalances[key] = currentBalance;
    });
    setBalances(newBalances);
  };

  return (
    <>
      <Modal
        title={"Transaction"}
        width={"80%"}
        visible={visible}
        onClose={onClose}
      >
        <div className={"mb50 bordered"}>
          {customerDetails && (
            <div className="d-inline-block border border-2">
              <div className="item-flex">
                <h5 className="m-3 d-block">
                  Name:{" "}
                  <span className="font-weight-normal ms-2">
                    {customerDetails.customerName}
                  </span>
                </h5>
                <h5 className="m-3 d-block">
                  State:{" "}
                  <span className="font-weight-normal ms-2">
                    {customerDetails.customerState}
                  </span>
                </h5>
                <h5 className="m-3 d-block">
                  Number:{" "}
                  <span className="font-weight-normal ms-2">
                    {customerDetails.customerNumber}
                  </span>
                </h5>
                <h5 className="m-3 d-block">
                  Date:{" "}
                  <span className="font-weight-normal ms-2">
                    {moment(customerDetails.purchaseDate).format("DD-MMM-YYYY")}
                  </span>
                </h5>
              </div>
            </div>
          )}
          {Report && Report.length ? (
            <div
              className="table-wrapper mt-4"
              style={{
                maxHeight: "800px",
                overflowX: "auto",
                overflowY: "auto",
              }}
            >
              <table className="table table-bordered mt-4">
                <thead>
                  <tr>
                    <td>Sr no.</td>
                    <td>Date</td>
                    <td>Details</td>
                    <td>Credit</td>
                    <td>Debit</td>
                    {/* <td>Balance</td> */}
                  </tr>
                </thead>
                <tbody>
                  {Report.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.date && (moment(item.date).format("DD/MM/YYYY"))}</td>
                      <td>
                        {item.type} - {item.invoiceNumber}
                      </td>
                      <td
                          style={{ color: '#03C03C', padding: '5px', fontWeight: 'bold' }}
                      >
                        {item.transactionType === "Debit" && item.netAmount}
                      </td>
                      <td
                          style={{  color: '#FF0000', padding: '5px', fontWeight: 'bold' }}
                      >
                        {item.transactionType === "Credit" && item.netAmount}
                      </td>
                      {/* <td>
                        {item.transactionType === "Credit" &&
                          debitbalances[item.invoiceNumber]}
                        {item.transactionType === "Debit" &&
                          balances[item.invoiceNumber]}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

export default TransactionComp;
