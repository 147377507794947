import React from "react";
import PageHeader from "../../components/Elements/pageHeader";
import AddBankComponent from "./components/addBankComponent";
import AddClientComponent from "./components/addClientComponent";

const AddClient = (props) => {
    return (
        <PageHeader title={'Add Other Head'}>
            <div className="card">
                <div className="card-body">
                    <AddClientComponent/>
                </div>
            </div>
        </PageHeader>
    );
}

export default AddClient;
