import React, {useEffect, useRef, useState} from "react";
import {calculation, Card, Form, InputBox, Modal, notification, Tabs,} from "../../../components/Elements/appUtils";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {useCollapse} from "react-collapsed";
import {fetchOnlyServiceFxn} from "../../products/actions";
import moment from "moment";
import ServiceModal from "./serviceModal";
import {addPrescreptionFxn, fetchFrequencyDetails, fetchTakenDetails, fetchVisitAllDetailsFxn} from "./action";
import {findPrescriptionByVisitNumberUrl} from "./api";
import _ from "lodash";
import {getToken} from "../../../request";
import axios from "axios";
import {getStockUrl} from "../../products/api";
import TableMain from "../../../components/Elements/Table";
import {useDispatch} from "react-redux";
import {findPrescriptionIdUrl} from "../../doctor/api";
import {dosage} from "../../../components/_utils/_utils";
import {arraySorter, complain, diagnosis, testReport} from "../../../components/_utils/appUtils";

let {TabPane} = Tabs;
let initState = {
    productId: "",
    productName: "",
    dosage: "",
    whentotake: "",
    routine: "",
    duration: "",
    remarks: "",
};
const PrescriptionModal = (props) => {
    let tableRef = useRef();
    let {
        statesAreEqual,
        patientidrecord,
        patientNameRecord,
        visitId,
        doctorId,
    } = props;
    let secondValue = {
        serviceId: "",
        name: "",
    };
    let followValues = {
        days: "30",
        nextday: "",
        weekday: "",
        remark: "",
    };

    const dispatch = useDispatch();
    const [stock, setStock] = useState([]);
    const [takenDetails, SetTakenDetails] = useState([]);
    const [frequencyDetails, SetFrequencyDetails] = useState([]);
    const [medicineDetails, setMedicineDetails] = useState([]);
    const [stockAll, setStockAll] = useState([]);
    const [serviceStock, setServiceStock] = useState([]);
    const [stockServiceAll, setStockServiceAll] = useState([]);
    const [allFollowUp, setFollowUpAll] = useState([]);
    const [allPrescription, setAllPrescription] = useState([]);
    const [visitIds, setVisitIds] = useState("");
    let {visible, onClose, patient} = props;
    let {patientId} = patient;
    let [items, setItems] = useState([]);
    const [advice, setAdvice] = useState("");
    let initState = {
        productId: "",
        productName: "",
        dosage: dosage?.length > 0 ? dosage[0].option : "",
        whentotake: "",
        duration: "30",
        frequency:
            frequencyDetails?.length > 0 ? frequencyDetails[0].frequency : "",
        remarks: "",
    };
    let [state, setState] = useState(initState);
    let events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data,
                };
            });
        },
        addItem: () => {
            // if (!state.productId) {
            //   notification.warning({ message: "Choose product" });
            //   return;
            // }
            if (!state.dosage) {
                notification.warning({message: "Choose dosage"});
                return;
            }
            if (!state.whentotake) {
                notification.warning({message: "Choose when to takes"});
                return;
            }
            if (!state.duration) {
                notification.warning({message: "Enter duration"});
                return;
            }

            let findItem = _.find(items, (item) => {
                return item.productId == state.productId;
            });
            if (findItem) {
                notification.warning({message: "Product already added."});
                return;
            } else {
                let itemClone = _.clone(items);
                itemClone.push(state);
                setItems(itemClone);
                setState(initState);
            }
        },
    };

    const fetchStock = async () => {
        try {
            const response = await axios.get(getStockUrl(), getToken());
            const filteredStock = response.data.data.filter(
                (item) => item.productId.type === "Product"
            );

            const sortedArray = arraySorter(filteredStock);
            setStock(sortedArray);
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };
    useEffect(() => {
        fetchStock();
        apiRequest();
        fetchTaken();
        fetchFrequency();
        apiRequest2();
        prescriptionByVisitNumber();
    }, []);

    const apiRequest = async (params) => {
        try {
            const data = await fetchOnlyServiceFxn({...params});
            setServiceStock(data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleExpansion = async (index, id) => {
        setExpandedIndex(expandedIndex === index ? null : index);
        const response = await axios.get(findPrescriptionIdUrl(id), getToken());
        setMedicineDetails(response.data.visitDoc);
    };

    let [productState, setProductState] = useState(initState);
    let [serviceState, setServiceState] = useState(secondValue);

    let [followState, setFollowState] = useState(followValues);
    const onSubmitMedicine = () => {
        if (!productState.productId) {
            notification.warning({message: "Please Choose Medicine"});
            return;
        }

        const updatedProductReport = {...productState};
        const updatedStockAll = [...stockAll, updatedProductReport];
        setStockAll(updatedStockAll);

        setProductState(initState);
    };

    const onSubmitServices = () => {
        if (!serviceState.serviceId) {
            notification.warning({message: "Please Choose Service"});
            return;
        }
        const updatedServiceReport = {...serviceState};

        const updatedStockServiceAll = [...stockServiceAll, updatedServiceReport];

        setStockServiceAll(updatedStockServiceAll);

        setServiceState(secondValue);
    };

    const prescriptionByVisitNumber = () => {
        return new Promise(async (resolve) => {
            try {
                const response = await axios.get(
                    findPrescriptionByVisitNumberUrl(visitIds),
                    getToken()
                );
                resolve(response);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const handleChange1 = (e, fieldName) => {
        const {value} = e.target;

        let obj = {};
        if (fieldName === "productId") {
            const selectedProduct = stock.find(
                (prod) => prod.productId?._id === value
            );
            obj = {
                productName: selectedProduct.productId.name,
                hsnCode: selectedProduct.hsnCode,
                dosage: selectedProduct.productId.dosage || initState.dosage,
                whentotake: selectedProduct.productId.takenDetails || "After meal",
                frequency: selectedProduct.productId.frequencyDetails || initState.frequency,
                remarks: selectedProduct.remarks,
            };
        }
        setProductState({
            ...productState,
            [fieldName]: value,
            ...obj,
        });
    };

    const getColumns = () => {
        let columnArr = [];
        _.each(columns, (item) => {
            if (!item.show) {
                columnArr.push(item);
            }
        });

        return columnArr;
    };

    const handleDelete = (index) => {
        reCalculateAmount();
        const updatedProductReport = [...stockAll];
        updatedProductReport.splice(index, 1);
        setStockAll(updatedProductReport);
    };

    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Name",
            dataIndex: "productName",
            key: "productName",
        },
        {
            title: "Dosage",
            dataIndex: "dosage",
            key: "dosage",
        },
        {
            title: "When to take",
            dataIndex: "whentotake",
            key: "whentotake",
        },
        {
            title: "Duration",
            dataIndex: "duration",
            key: "duration",
        },
        {
            title: "Frequency.",
            dataIndex: "frequency",
            key: "frequency",
        },

        {
            title: "Action",
            key: "delete",
            render: (text, record, index) => (
                <a className="nav-link" onClick={() => handleDelete(index)}>
                    <i className="mdi mdi-delete"/>
                </a>
            ),
        },
    ];

    const column = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Service Name",
            dataIndex: "name",
            key: "name",
        },
    ];

    const fetchTaken = (params) => {
        return new Promise(async (resolve) => {
            const data = await fetchTakenDetails({...params});
            SetTakenDetails(data.data);
        });
    };

    const fetchFrequency = (params) => {
        return new Promise(async (resolve) => {
            const data = await fetchFrequencyDetails({...params});
            SetFrequencyDetails(data.data);
        });
    };

    const handleAdviceChange = (e) => {
        const {value} = e.target;
        setAdvice(value);
    };
    const handleSubmit = async (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {
            if (!err) {
                // if (stockAll.length === 0) {
                //     notification.warning({message: "Please select a product"});
                //     return;
                // }
                valData.products = stockAll;
                valData.advice = advice;
                valData.days = allFollowUp.days;
                valData.services = stockServiceAll;
                valData.days = followState.days;
                valData.nextday = followState.nextday;
                valData.weekday = followState.weekday;
                valData.remark = followState.remark;
                valData.patientId = patientidrecord;
                valData.patientName = patientNameRecord;
                valData.visitId = visitId;
                valData.doctorId = doctorId;

                // const formData = new FormData();
                // //appending all the form Data here..
                // Object.keys(valData).forEach((key) => {
                //     formData.append(key, valData[key]);

                // });
                // //appending multiple uploading Files array
                // finalUploadData.forEach(file => {
                //     formData.append("uploadTestData", file);
                // });

                let x = await dispatch(addPrescreptionFxn(valData));
                if (x && x.success) {
                    setAdvice("");
                    setStockAll([]);
                    setStockServiceAll([]);
                    setFollowState(followValues);
                    onClose();
                    window.location.reload();
                }
            }
        });
    };
    const handleChange2 = (e, fieldName) => {
        const {value} = e.target;

        let object = {};
        if (fieldName == "serviceId") {
            const selectedProduct = serviceStock.find((prod) => prod._id === value);
            object = {
                name: selectedProduct.name,
            };
        }
        setServiceState({
            ...serviceState,
            [fieldName]: value,
            ...object,
        });
    };

    const handleChange3 = (e, fieldName) => {
        const {value} = e.target;
        let nextday = followState.nextday;
        let weekday = followState.weekday;

        if (fieldName === "days") {
            const days = parseInt(value);

            if (!isNaN(days)) {
                const currentDate = new Date();
                const nextFollowupDate = new Date(
                    currentDate.setDate(currentDate.getDate() + days)
                );
                const day = nextFollowupDate.getDate();
                const month = nextFollowupDate.getMonth() + 1;
                const year = nextFollowupDate.getFullYear();

                nextday = `${day}/${month}/${year}`;
                const dayNames = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                ];
                const dayOfWeek = nextFollowupDate.getDay();
                weekday = dayNames[dayOfWeek];
            }
        }


        setFollowState({
            ...followState,
            [fieldName]: value,
            nextday: nextday,
            weekday: weekday,
        });
    };

    useEffect(() => {
        // Calculate nextday and weekday based on the default value of 30 days
        const days = parseInt(followState.days);

        if (!isNaN(days)) {
            const currentDate = new Date();
            const nextFollowupDate = new Date(
                currentDate.setDate(currentDate.getDate() + days)
            );
            const day = nextFollowupDate.getDate();
            const month = nextFollowupDate.getMonth() + 1;
            const year = nextFollowupDate.getFullYear();

            const nextday = `${day}/${month}/${year}`;

            const dayNames = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
            ];
            const dayOfWeek = nextFollowupDate.getDay();
            const weekday = dayNames[dayOfWeek];

            setFollowState({
                ...followState,
                nextday: nextday,
                weekday: weekday,
            });
        }
    }, []);

    const reCalculateAmount = () => {
        calculation({...productState, statesAreEqual}).then((resp) => {
            setProductState({
                ...productState,
                ...resp,
            });
        });
    };
    useEffect(() => {
        reCalculateAmount();
    }, [
        productState.productId,
        productState.dosage,
        productState.duration,
        productState.routine,
        productState.remarks,
    ]);
    const apiRequest2 = async () => {
        return new Promise(async (resolve) => {
            try {
                let params = {
                    visitId: visitId,
                    patientId: patientidrecord,
                };

                const data = await fetchVisitAllDetailsFxn({...params});
                setAllPrescription(data);

                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };
    let inputTypes = {
        fields: [
            {
                key: "complaints",
                placeholder: "Please Choose Complaint",
                type: "select",
                showStar: true,
                span: "col-md-6",
                label: "Complaints",
                options: complain,
                multiple: true,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        complaints: x,
                    });
                },
                itemStyle: {
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",  // Adjust padding here
                    gap: '13px'
                },
                labelStyle: {
                    marginRight: "10px",
                    width: "150px",
                },
                inputStyle: {
                    flex: "1",
                    height: "20px",  // Reduce height here
                    padding: "1px",  // Adjust padding inside input
                },
            },
            {
                key: "familyHistory",
                label: "Family History",
                placeholder: "Enter Family History",
                type: "text",
                span: "col-md-6",
                itemStyle: {
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",  // Adjust padding here
                    gap: '13px'
                },
                labelStyle: {
                    marginRight: "10px",
                    width: "150px",
                },
                inputStyle: {
                    flex: "1",
                    height: "20px",  // Reduce height here
                    padding: "1px",  // Adjust padding inside input
                },
            },
            {
                key: "pastHistory",
                label: "Past History",
                placeholder: "Enter Past History",
                type: "text",
                span: "col-md-6",
                itemStyle: {
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",  // Adjust padding here
                    gap: '13px',
                },
                labelStyle: {
                    marginRight: "10px",
                    width: "150px",
                },
                inputStyle: {
                    height: "20px",  // Reduce height here
                    padding: "1px",  // Adjust padding inside input
                },


        },
            {

                key: "test",
                placeholder: "Please Choose Test",
                type: "select",
                showStar: true,
                span: "col-md-6",
                label: "Investigation",
                options: testReport,
                multiple: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        test: x,
                    });
                },
                itemStyle: {
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",  // Adjust padding here
                    gap: '13px'
                },
                labelStyle: {
                    marginRight: "10px",
                    width: "150px",
                },
                inputStyle: {
                    flex: "1",
                    height: "20px",  // Reduce height here
                    padding: "1px",  // Adjust padding inside input
                },
            },

            {
                key: "otherDisease",
                label: "Other Disease",
                placeholder: "Enter Other Disease",
                type: "text",
                span: "col-md-6",
                itemStyle: {
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",  // Adjust padding here
                    gap: '13px'
                },
                labelStyle: {
                    marginRight: "10px",
                    width: "150px",
                },
                inputStyle: {
                    flex: "1",
                    height: "20px",  // Reduce height here
                    padding: "1px",  // Adjust padding inside input
                },
            },
            {
                key: "otherTest",
                label: "Other Investigation",
                placeholder: "Other Investigation",
                type: "text",
                span: "col-md-6",
                itemStyle: {
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",  // Adjust padding here
                    gap: '13px'
                },
                labelStyle: {
                    marginRight: "10px",
                    width: "150px",
                },
                inputStyle: {
                    flex: "1",
                    height: "20px",  // Reduce height here
                    padding: "1px",  // Adjust padding inside input
                },
            },
            {
                key: "diagnosis",
                placeholder: "Please Choose Diagnosis",
                type: "select",
                showStar: true,
                span: "col-md-6 mb-3",
                label: "Diagnosis",
                options: diagnosis,
                showSearch: true,
                multiple: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        diagnosis: x,
                    });
                },
                itemStyle: {
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",  // Adjust padding here
                    gap: '13px',
                },
                labelStyle: {
                    marginRight: "10px",
                    width: "150px",
                },
                inputStyle: {
                    height: "20px",  // Reduce height here
                    padding: "1px",  // Adjust padding inside input
                },
            },

        ]

    };

    return (
        <>
            <Modal
                visible={visible}
                onClose={onClose}
                title={`Patient - ${patientId && patientId.name ? patientId.name : ""}`}
                width={"95%"}
            >
                <div>
                    <Form onSubmit={handleSubmit}>

                        <div className={"row"}>
                            <div className={"col-md-3"}>
                                <Card title={"Patient Details"} padding={0}>
                                    <div>
                                        <div className="row"
                                             style={{marginLeft: 0, width: '100%', borderBottom: '1px solid #e6e9ed'}}>
                                            <div className="col-md-4"
                                                 style={{padding: '9px', borderRight: '1px solid #e6e9ed'}}>Name
                                            </div>
                                            <div className="col-md-8" style={{padding: '9px'}}>
                                                {patientId && patientId.name ? patientId.name : ""}
                                            </div>
                                        </div>
                                        <div className="row"
                                             style={{marginLeft: 0, width: '100%', borderBottom: '1px solid #e6e9ed'}}>
                                            <div className="col-md-4"
                                                 style={{padding: '9px', borderRight: '1px solid #e6e9ed'}}>Mobile
                                            </div>
                                            <div className="col-md-8" style={{padding: '9px'}}>
                                                {patientId && patientId.mobile ? patientId.mobile : ""}
                                            </div>
                                        </div>
                                        <div className="row"
                                             style={{marginLeft: 0, width: '100%', borderBottom: '1px solid #e6e9ed'}}>
                                            <div className="col-md-4"
                                                 style={{padding: '9px', borderRight: '1px solid #e6e9ed'}}>Email
                                            </div>
                                            <div className="col-md-8" style={{padding: '9px'}}>
                                                {patientId && patientId.email ? patientId.email : ""}
                                            </div>
                                        </div>
                                        <div className="row"
                                             style={{marginLeft: 0, width: '100%', borderBottom: '1px solid #e6e9ed'}}>
                                            <div className="col-md-4"
                                                 style={{padding: '9px', borderRight: '1px solid #e6e9ed'}}>UHID
                                            </div>
                                            <div className="col-md-8" style={{padding: '9px'}}>
                                                {patientId && patientId.uhid ? patientId.uhid : ""}
                                            </div>
                                        </div>
                                        <div className="row"
                                             style={{marginLeft: 0, width: '100%', borderBottom: '1px solid #e6e9ed'}}>
                                            <div className="col-md-4"
                                                 style={{padding: '9px', borderRight: '1px solid #e6e9ed'}}>State
                                            </div>
                                            <div className="col-md-8" style={{padding: '9px'}}>
                                                {patientId && patientId.state ? patientId.state : ""}
                                            </div>
                                        </div>
                                        <div className="row"
                                             style={{marginLeft: 0, width: '100%', borderBottom: '1px solid #e6e9ed'}}>
                                            <div className="col-md-4"
                                                 style={{padding: '9px', borderRight: '1px solid #e6e9ed'}}>City
                                            </div>
                                            <div className="col-md-8" style={{padding: '9px'}}>
                                                {patientId && patientId.city ? patientId.city : ""}
                                            </div>
                                        </div>
                                        <div className="row"
                                             style={{marginLeft: 0, width: '100%', borderBottom: '1px solid #e6e9ed'}}>
                                            <div className="col-md-4"
                                                 style={{padding: '9px', borderRight: '1px solid #e6e9ed'}}>Pincode
                                            </div>
                                            <div className="col-md-8" style={{padding: '9px'}}>
                                                {patientId && patientId.pincode ? patientId.pincode : ""}
                                            </div>
                                        </div>
                                        <div className="row"
                                             style={{marginLeft: 0, width: '100%', borderBottom: '1px solid #e6e9ed'}}>
                                            <div className="col-md-4"
                                                 style={{padding: '9px', borderRight: '1px solid #e6e9ed'}}>Address
                                            </div>
                                            <div className="col-md-8" style={{padding: '9px'}}>
                                                {patientId && patientId.address ? patientId.address : ""}
                                            </div>
                                        </div>
                                    </div>
                                </Card>


                            </div>
                            <div className={"col-md-9"}>

                                <Tabs defaultActiveKey="1" onChange={() => {
                                }}>

                                    <TabPane tab={"Add Prescription"} key="1" width={150}>
                                        <div style={{
                                            textAlign: 'right',
                                            position: 'absolute',
                                            top: '0px',
                                            zIndex: '20',
                                            right: '0px',
                                            width: '50%'
                                        }}>
                                            <button
                                                type="submit"
                                                className="btn btn-gradient-info mb-2"
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                        <div className={"row"}>
                                            <form>
                                                <div className={"row abc"}>
                                                    {inputTypes.fields.map((item, key) => {
                                                        return (
                                                            <div
                                                                className={`${item.span ? item.span : "col-md-12 "} shrinker `}
                                                                key={key}
                                                            >
                                                                <GetEachFormFields {...props.form}
                                                                                   item={item}
                                                                                   layout={'horizontal'}
                                                                                   itemStyle={item.itemStyle}          // Passing itemStyle
                                                                                   labelStyle={item.labelStyle}        // Passing labelStyle
                                                                                   inputStyle={item.inputStyle}        // Passing inputStyle
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <Card title={"Select Medicine"} size={"small"}>
                                                    <div className={"item-flex flex-wrap"}>
                                                        <InputBox title={"Medicine"} className={"flex2"}>
                                                            <div className="d-flex align-items-center">
                                                                <select
                                                                    className="form-control medwidth"
                                                                    value={productState.productId}
                                                                    onChange={(e) =>
                                                                        handleChange1(e, "productId")
                                                                    }
                                                                    required
                                                                >
                                                                    <option value="">Select Medicine</option>
                                                                    {stock.map((stock) => (
                                                                        <option
                                                                            key={stock.productId._id}
                                                                            value={stock.productId._id}
                                                                        >
                                                                            {stock.productId.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </InputBox>

                                                        <InputBox title={"Dosage"}>
                                                            <select
                                                                className={"form-control medwidth"}
                                                                placeholder={"Dosage"}
                                                                value={productState.dosage}
                                                                onChange={(e) => handleChange1(e, "dosage")}
                                                                defaultValue={dosage[0].option}
                                                            >
                                                                <option value="">Select</option>
                                                                {dosage.map((dosage) => (
                                                                    <option
                                                                        key={dosage.option}
                                                                        value={dosage.option}
                                                                    >
                                                                        {dosage.option}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </InputBox>

                                                        <InputBox title={"When to Taken"}>
                                                            <select
                                                                className="form-control medwidth"
                                                                value={productState.whentotake || "After Meal"}
                                                                onChange={(e) => handleChange1(e, "whentotake")}
                                                                required
                                                            >
                                                                <option value="">Select</option>
                                                                {takenDetails.map((taken) => (
                                                                    <option
                                                                        key={taken._id}
                                                                        value={taken.whentotake}
                                                                    >
                                                                        {taken.whentotake}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </InputBox>

                                                        <InputBox title={"Duration"}>
                                                            <select
                                                                className={"form-control medwidth"}
                                                                placeholder={"Duration"}
                                                                value={productState.duration}
                                                                onChange={(e) => handleChange1(e, "duration")}
                                                                defaultValue="1"
                                                            >
                                                                {Array.from(
                                                                    {length: 60},
                                                                    (_, index) => index + 1
                                                                ).map((duration) => (
                                                                    <option key={duration} value={duration}>
                                                                        {duration} Days
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </InputBox>
                                                        <InputBox title={"Routine/Freq"}>
                                                            <select
                                                                className="form-control medwidth"
                                                                value={productState.frequency}
                                                                onChange={(e) => handleChange1(e, "frequency")}
                                                                required
                                                            >
                                                                <option value="">Select</option>
                                                                {frequencyDetails.map((item) => (
                                                                    <option key={item._id} value={item.frequency}>
                                                                        {item.frequency}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </InputBox>

                                                        <InputBox title={""}>
                                                            <div className={"alignCenter mt-3"}>
                                                                <a
                                                                    className={"empty_btn"}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        onSubmitMedicine();
                                                                    }}
                                                                >
                                                                    <i className={"fa fa-plus"}/>
                                                                </a>
                                                                &nbsp;&nbsp;
                                                            </div>
                                                        </InputBox>
                                                    </div>
                                                </Card>
                                            </form>
                                            <div key={stockAll.length} className="mt-4 mb-4">
                                                <h4 className="m-2">Prescription</h4>
                                                <TableMain
                                                    dataSource={stockAll}
                                                    columns={getColumns()}
                                                />
                                            </div>

                                            <InputBox title={"Advice / Observation"} className="mb-3">
                                                <input
                                                    placeholder={"advice"}
                                                    className={"form-control"}
                                                    value={advice}
                                                    onChange={handleAdviceChange}
                                                    style={{height: "60px"}}
                                                />
                                            </InputBox>
                                            <form>
                                                <Card title={"Select Service"} size={"small"}>
                                                    <div className={"item-flex"}>
                                                        <InputBox>
                                                            <div className={"alignCenter"}>
                                                                <select
                                                                    className="form-control medwidth"
                                                                    onChange={(e) =>
                                                                        handleChange2(e, "serviceId")
                                                                    }
                                                                    value={serviceState.serviceId}
                                                                    required
                                                                >
                                                                    <option value="">Select Service</option>
                                                                    {serviceStock?.map((item) => (
                                                                        <option key={item._id} value={item._id}>
                                                                            {item.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <InputBox title={""}>
                                                                    <div className={"alignCenter ms-2"}>
                                                                        <a
                                                                            className={"empty_btn"}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                onSubmitServices();
                                                                            }}
                                                                        >
                                                                            <i className={"fa fa-plus"}/>
                                                                        </a>
                                                                        &nbsp;&nbsp;
                                                                    </div>
                                                                </InputBox>
                                                            </div>
                                                        </InputBox>
                                                    </div>
                                                </Card>
                                            </form>
                                            <ServiceModal
                                                stockServiceAll={stockServiceAll}
                                                serviceState={serviceState}
                                                setServiceState={setServiceState}
                                                setStockServiceAll={setStockServiceAll}
                                            />

                                            <form>
                                                <Card title={"Followup Visit"} size={"small"}>
                                                    <div className={"item-flex"}>
                                                        <InputBox title={"Days"}>
                                                            <input
                                                                placeholder={"Days"}
                                                                className={"form-control"}
                                                                value={followState.days}
                                                                onChange={(e) => handleChange3(e, "days")}
                                                            />
                                                        </InputBox>

                                                        <InputBox title={"Next Followup"}>
                                                            <input
                                                                placeholder={"Next Followup"}
                                                                className={"form-control"}
                                                                onChange={(e) => handleChange3(e, "nextday")}
                                                                value={followState.nextday}
                                                                // onChange={(e) => handleChange1(e, "frequency")}
                                                            />
                                                        </InputBox>

                                                        <InputBox title={"Week Day"}>
                                                            <input
                                                                placeholder={"Week Day"}
                                                                className={"form-control"}
                                                                onChange={(e) => handleChange3(e, "weekday")}
                                                                value={followState.weekday}
                                                                // onChange={(e) => handleChange1(e, "frequency")}
                                                            />
                                                        </InputBox>
                                                    </div>
                                                    <InputBox title={"Remarks"} className="mt-4">
                                                        <div className={"alignCenter"}>
                                                            <input
                                                                placeholder={"Remarks"}
                                                                className={"form-control"}
                                                                onChange={(e) => handleChange3(e, "remark")}
                                                                value={followState.remark}
                                                            />
                                                        </div>
                                                    </InputBox>
                                                </Card>
                                            </form>

                                            <button
                                                type="submit"
                                                className="btn btn-gradient-primary me-2"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </TabPane>

                                    <TabPane tab={"Visit Details"} key="2">
                                        {allPrescription?.visitRecords?.map((item, index) => (
                                            <div key={index}>
                                                <div
                                                    className="faq-question"
                                                    style={{
                                                        border: "1px solid #ccc",
                                                        padding: "10px",
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <div className="row">
                                                            <div
                                                                onClick={() => toggleExpansion(index, item._id)}
                                                                style={{cursor: "pointer"}}
                                                            >
                                                                <h4 style={{margin: 0}}>
                                                                    Visit Number: {item.visitNumber}
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;Date:{" "}
                                                                    {moment(item.date).format("DD/MM/YYYY")}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div
                                                            onClick={() => toggleExpansion(index)}
                                                            style={{cursor: "pointer"}}
                                                        >
                                                            {expandedIndex === index ? "▲" : "▼"}
                                                        </div>
                                                    </div>
                                                </div>
                                                {expandedIndex === index && (
                                                    <div className="faq-answer">
                                                        <p className="mb-0 p-0">Medicines Advised</p>
                                                        <table class="table table-bordered mt-2">
                                                            <thead>
                                                            <tr>
                                                                <th>
                                                                    <b>Sr No.</b>
                                                                </th>
                                                                <th>
                                                                    <b>Drug Name</b>
                                                                </th>
                                                                <th>
                                                                    <b>Dosage</b>
                                                                </th>
                                                                <th>
                                                                    <b>When to taken</b>
                                                                </th>
                                                                <th>
                                                                    <b>Frequency</b>
                                                                </th>
                                                                <th>
                                                                    <b>Duration</b>
                                                                </th>

                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {medicineDetails?.products?.length ? (
                                                                medicineDetails?.products?.map(
                                                                    (item, index) => (
                                                                        <tr>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item.productName}</td>
                                                                            <td>{item.dosage}</td>
                                                                            <td>{item.whentotake}</td>
                                                                            <td>{item.frequency}</td>
                                                                            <td>{item.duration}</td>

                                                                        </tr>
                                                                    )
                                                                )
                                                            ) : (
                                                                <tr>
                                                                    <td
                                                                        colSpan="9"
                                                                        style={{textAlign: "center"}}
                                                                    >
                                                                        No records found
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            </tbody>
                                                        </table>
                                                        <div className="next-step mt-1 mb-2">
                                                            <p className=" mt-1">
                                                                <b>Services</b>
                                                            </p>
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                <tr>
                                                                    <th>
                                                                        <b>Service</b>
                                                                    </th>
                                                                    <th>
                                                                        <b>Service Name</b>
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {medicineDetails?.services?.length ? (
                                                                    medicineDetails.services?.map(
                                                                        (item, index) => (
                                                                            <tr>
                                                                                <td>{index + 1}</td>
                                                                                <td>{item.name}</td>
                                                                            </tr>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <tr>
                                                                        <td
                                                                            colSpan="9"
                                                                            style={{textAlign: "center"}}
                                                                        >
                                                                            No records found
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    );
};
export default Form.create()(PrescriptionModal);
