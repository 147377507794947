import React from "react";
import { Form, HyperLink } from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { addTypeUserFxn } from "./actions";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { UsersType } from "../../components/_utils/appUtils";
const PurchaseUser = (props) => {
  const navigate = useNavigate();

  const { getFieldValue } = props.form;
  let dispatch = useDispatch();

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        valData.showPassword = getFieldValue("password");
        if (valData.typeOfUser == "purchaseUser") {
          valData.userType = "purchaseUser";
        } else {
          valData.userType = "transferUser";
        }
        let x = await dispatch(addTypeUserFxn(valData));
        if (x && x.success) {
          props.form.setFieldsValue({
            name: "",
            email: "",
            mobile: "",
            password: "",
            address: "",
            typeOfUser: "",
          });
        }
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "typeOfUser",
        label: "Type of User",
        span: "col-md-6",
        type: "select",
        options: UsersType,
        keyAccessor: (x) => x.key,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            typeOfUser: x,
          });
        },
      },
      {
        key: "name",
        label: "Name",
        span: "col-md-6",
        placeholder: "Name",
        required: true,
      },
      {
        key: "email",
        label: "Email",
        type: "email",
        span: "col-md-6",
        placeholder: "Email",
        required: true,
      },
      {
        key: "password",
        label: "Password",
        type: "password",
        span: "col-md-6",
        placeholder: "Password",
        required: true,
        minLength: 6,
      },
      {
        key: "mobile",
        label: "Mobile No",
        span: "col-md-6",
        placeholder: "Mobile No",
        required: true,
      },
      {
        key: "address",
        label: "Address",
        span: "col-md-12",
        placeholder: "Address",
        required: false,
        type: "textarea",
      },
    ],
  };

  return (
    <>
      <PageHeader title={"Add User"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary me-2">
                Submit
              </button>
              <HyperLink className=" ms-4 btn btn-danger" link={"/reception"}>
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(PurchaseUser);
