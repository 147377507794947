import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Table,
  Tooltip,
  notification,
  Popconfirm,
} from "../../components/Elements/appUtils";
import { fetchTransfer, fetchProductReportFxn } from "./actions";
import { deleteTransferReport } from "./api";
import PageHeader from "../../components/Elements/pageHeader";
import moment from "moment";
import { getToken } from "../../request";
import axios from "axios";
import { getItemLocalstorage } from "../../components/_utils/_utils";
import { DefaultTablePagination } from "../../components/Elements/appUtils";
import SearchCustomer from "../customer/chooseCustomer/searchCustomer";
function Transfer() {
  let tableRef = useRef();
  const userType = getItemLocalstorage("user")["userType"];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transferId, setTransferId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const checkUserType = getItemLocalstorage("user")["userType"];
  const [showFilter, setShowFilter] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [customerId, setCustomerId] = useState([]);
  let [dates, setDates] = useState({
    fromDate: "",
    toDate: "",
  });

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        if (dates.fromDate && dates.toDate) {
          const fromDateStartOfDay = new Date(dates.fromDate);
          fromDateStartOfDay.setHours(0, 0, 0, 0);
          // Set toDate to end of the day
          const toDateEndOfDay = new Date(dates.toDate);
          toDateEndOfDay.setHours(23, 59, 59, 999);
          params.transferDate = {
            $gte: new Date(fromDateStartOfDay),
            $lte: new Date(toDateEndOfDay),
          };
        }
        if (customerId) {
          params.customerId = customerId
        }
        const data = await fetchTransfer({ ...params });
        resolve(data);
        setFilteredData(data.data);
        setCustomerData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };
  const fetchProductReport = () => {
    return new Promise(async (resolve) => {
      try {
        const datas = await fetchProductReportFxn({
          transferId: transferId,
          results:100
        });
        resolve(datas);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  useEffect(() => {
    apiRequest();
  }, []);

  useEffect(() => {
    fetchProductReport(transferId);
  }, [transferId]);

  const deleteTransfer = async (data) => {
    try {
      let config = {
        ...(await getToken()),
      };
      const response = await axios.post(deleteTransferReport(), data, config);
      console.log(response);
      if (response) {
        tableRef.current.reload();
        notification.success({ message: response.data.message });
        return;
      } else {
        notification.error({ message: response.data.message });
      }
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  const handleFilter = async () => {
    if (tableRef.current) {
      tableRef.current.reload();
    }
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Vendor",
      dataIndex: "customerId",
      key: "customerId",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: "Transfer Date",
      dataIndex: "transferDate",
      key: "transferDate",
      render: (text) => <span>{moment(text).format("DD/MMM/YY")}</span>,
    },
    {
      title: "State",
      dataIndex: "customerId",
      key: "customerId",
      render: (text) => <span>{text?.state}</span>,
    },
    {
      title: "Total Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Actions",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <>
          <Tooltip title={"View"}>
            <a
              className={"empty_btn"}
              onClick={() => {
                setIsModalOpen(true);
                setTransferId(record._id);
              }}
            >
              <i className={"fa fa-eye far"}></i>
            </a>
          </Tooltip>
          {userType == "company" ? (
            <Tooltip title={"Delete"}>
              <Popconfirm
                title={"Are your sure, you want to delete Transfer?"}
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  deleteTransfer(record);
                }}
              >
                <img src={"./close.svg"} />
              </Popconfirm>
            </Tooltip>
          ) : null}
        </>
      ),
    },
  ];
  const columns1 = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Manufacturing Date",
      dataIndex: "manufacturingDate",
      key: "manufacturingDate",
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
    },
    {
      title: "Batch",
      dataIndex: "batchNo",
      key: "batchNo",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
  ];

  return (
    <PageHeader
      title={"Transfer"}
      extraLink={
        checkUserType == "headBranch" || checkUserType == "branch"
          ? []
          : [
              {
                name: "Add Transfer",
                link: "/add-transfer",
              },
            ]
      }
    >
      <div className="card">
        <div className="card-body">
          <div className="mb-3 row">
            <div className={"col-md-2 pe-0"} style={{marginTop: "13px"}}>
              <SearchCustomer
                  type={["branch", "headBranch"]}
                  customerId={customerId}
                  callback={(clientData) => {
                    if (clientData && clientData._id) {
                      setCustomerId(clientData._id);
                    }
                  }}
              />
            </div>

            <div className="col-md-2">
              <label htmlFor="searchCategory" className="form-label" />
              <input
                  type="date"
                  className="form-control search-filters"
                  value={dates.fromDate}
                  onChange={(e) =>
                      setDates({ ...dates, fromDate: e.target.value })
                  }
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="searchCategory" className="form-label" />
              <input
                  type="date"
                  className="form-control search-filters"
                  value={dates.toDate}
                  onChange={(e) => setDates({ ...dates, toDate: e.target.value })}
              />
            </div>
            <div className="col-md-2 search-buttons">
              <div className="search-wrap pe-2">
                <button className="btn btn-info" onClick={handleFilter}>
                  Search
                </button>
              </div>
            </div>

          </div>


        {showFilter ? (
          <Table
          dataSource={customerData}
          columns={columns}
          ref={tableRef}
          key={`table-${showFilter ? 'filtered' : 'original'}`}
        />

        ) : (
          <Table
          apiRequest={apiRequest}
          columns={columns}
          ref={tableRef}
          pagination={{ ...DefaultTablePagination(), defaultPageSize: 50 }}
        />
        )}
        </div>
      </div>
      {isModalOpen && (
        <Modal
          title={"Products"}
          width={"70%"}
          visible={isModalOpen === true}
          onClose={() => setIsModalOpen(false)}
        >
          <Table
            apiRequest={fetchProductReport}
            columns={columns1}
            ref={tableRef}
            pagination={{ ...DefaultTablePagination(), defaultPageSize: 100 }}
          />
        </Modal>
      )}
    </PageHeader>
  );
}

export default Transfer;
