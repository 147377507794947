import {
  getCategoryDataUrl,
  getSaleCategoryDataUrl,
  getVisitNumberURl,
  singleCategoryUrl,
  updateCategoryUrl,
  singleSaleCategoryUrl,
  updateSaleCategoryUrl
} from "../api";
import { customAxios as axios, getToken } from "../../../request";
import { showLoader,hideLoader } from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
export const fetchCategory = async (valData) => {
  let config = {
    params: { ...valData },
  };
  const { data } = await axios.get(getCategoryDataUrl(), getToken(), config);
  return data;
};

export const fetchSaleCategory = async (valData) => {
  let config = {
    params: { ...valData },
  };
  const { data } = await axios.get(
    getSaleCategoryDataUrl(),
    getToken(),
    config
  );
  return data;
};

export const fetchVisitNumber = async (valData) => {
  let config = {
    params: { visitNumber: valData },
    ...(await getToken()),
  };
  const { data } = await axios.get(getVisitNumberURl(), config);
  return data;
};

export const singleCategoryFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
  
  let { data } = await axios.get(singleCategoryUrl(id));
  console.log(data);
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};

export const updateCategoryFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let {data} = await axios.post(updateCategoryUrl(), valData);
  dispatch(hideLoader());
  if (data.error) {
      notification.error({
          message: data.message || "Error",
      });
  } else {
      notification.success({
          message: data.message || "Success",
      });
  }
  return data;
};

export const singleSaleCategoryFxn = (id) => async (dispatch) => {
  dispatch(showLoader());
  
  let { data } = await axios.get(singleSaleCategoryUrl(id));
  console.log(data);
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};

export const updateSaleCategoryFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let {data} = await axios.post(updateSaleCategoryUrl(), valData);
  dispatch(hideLoader());
  if (data.error) {
      notification.error({
          message: data.message || "Error",
      });
  } else {
      notification.success({
          message: data.message || "Success",
      });
  }
  return data;
};