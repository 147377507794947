import { addPaymentInUrl,addPaymentOutUrl,getPaymentInUrl ,getPaymentOutUrl} from "../api";
  import { customAxios as axios, getToken } from "../../../request";
  import { hideLoader, showLoader } from "../../../actions/loader";
  import notification from "../../../components/Elements/Notification";
  
  
  
  export const addPaymentInFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    const { data } = await axios.post(addPaymentInUrl(), valData, getToken());
    dispatch(hideLoader());
    if (!data.error) {
    } else {
      notification.error(data.message || "Error");
    }
    return data;
  };
  export const addPaymentOutFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    const { data } = await axios.post(addPaymentOutUrl(), valData, getToken());
    dispatch(hideLoader());
    if (!data.error) {
    } else {
      notification.error(data.message || "Error");
    }
    return data;
  };
  export const fetchPaymentIn = async (valData) => {
    let config = {
      params: { ...valData },
    };
    const { data } = await axios.get(getPaymentInUrl(), getToken(), config);
    return data;
  };
  export const fetchPaymentOut = async (valData) => {
    let config = {
      params: { ...valData },
    };
    const { data } = await axios.get(getPaymentOutUrl(), getToken(), config);
    return data;
  };