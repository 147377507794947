import React from "react";
import AddSymptom from "./addSymptom";
import { Modal } from "../../components/Elements/appUtils";

const AddSymptomDrawer = (props) => {
  let { visible, onClose } = props;


  return (
    <Modal
      width={"75%"}
      visible={visible}
      onClose={onClose}
      title={"Add Symptom"}
    >
    <AddSymptom onClose={onClose} />
    </Modal>
    
  );
};
export default AddSymptomDrawer;
