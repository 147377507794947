import {
  getSaleUrl,
  addSaleUrl,
  editSaleUrl,
  getCustomerDataUrl,
  getbatchRecordByProductIdUrl,
  invoiceUpdateUrl,
  getUserDataUrl,
  generateSaleUrl,
  getSingleUser, getSaleCsvUrl
} from "../api";
import { customAxios as axios, getToken } from "../../../request";
import { hideLoader, showLoader } from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";

export const fetchCustomer = async (valData) => {
  let config = {
    params: { ...valData },
    ...getToken(),
  };
  const { data } = await axios.get(getCustomerDataUrl(),  config);

  return data;
};
export const fetchUser = async (valData) => {
  let config = {
    params: { ...valData },
    ...getToken(),
  };
  const { data } = await axios.get(getUserDataUrl(),  config);

  return data;
};

export const fetchBatchByProductId = async (productId) => {
  let config = {
    params: { productId },
  };
  const { data } = await axios.get(getbatchRecordByProductIdUrl(), config);
  return data;
};

export const fetchSale = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  const { data } = await axios.get(getSaleUrl(), config);
  return data;
};
export const fetchSaleForCsv = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
    responseType: 'blob',  // Set the response type to blob
  };
  const { data } = await axios.get(getSaleCsvUrl(), config);
  return data;
};


export const invoiceUpdateFxn = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  const { data } = await axios.get(invoiceUpdateUrl(), config);
  return data;
};

export const addSaleFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  const { data } = await axios.post(addSaleUrl(), valData, getToken());
  dispatch(hideLoader());
  if (!data.error) {
  } else {
    notification.error(data.message || "Error");
  }
  return data;
};

export const generateSaleFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  const { data } = await axios.post(generateSaleUrl(), valData, getToken());
  dispatch(hideLoader());
  if (!data.error) {
  } else {
    notification.error(data.message || "Error");
  }
  return data;
};

export const editSaleFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  const { data } = await axios.post(editSaleUrl(), valData, getToken());
  dispatch(hideLoader());
  if (!data.error) {
  } else {
    notification.error(data.message || "Error");
  }
  return data;
};

export const fetchSingleUser = async (valData) => {
  let config = {
    params: { ...valData },
    ...getToken(),
  };
  const { data } = await axios.get(getSingleUser(),  config);
  return data.data;
};

