import {apiUrl} from "../../../setting";


export const addPurchaseUserUrl = () => {
    return `${apiUrl}/routes/user/createUser`;
};
export const getUsersUrl = () => {
    return `${apiUrl}/routes/user/getUsers`;
};
export const singleUserUrl = (id) => {
    return apiUrl + "/routes/user/getSingleUser/" + id;
};
export const updateUserUrl = () => {
    return `${apiUrl}/routes/user/updateUserList`;
  };