import {apiUrl} from "../../../setting";

export const getCompanyDataUrl = () => {
    return `${apiUrl}/routes/company/getCompanyData`;
};

export const getPrescriptionURL = () => {
    return `${apiUrl}/routes/prescription/getPrescription`;
};

export const getCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/customerList`;
};

export const AddDoctorUrl = () => {
    return `${apiUrl}/routes/user/createUser`;
};

export const getDoctorsUrl = () => {
    return `${apiUrl}/routes/user/getDoctors`;
};

export const getVisitsUrl = () => {
    return `${apiUrl}/routes/visit/getVisits`;
};
export const getSlipUrl = () => {
    return `${apiUrl}/routes/customer/getAllSlip`;
};

export const PrescriptionDetailUrl = (id) => {
    return apiUrl + "/routes/prescription/visitRecords/" + id;
};
// export const singlePrescriptionUrl = (id) => {
//     return apiUrl + "/routes/prescription/getSinglePrescription/" + id;
// };
export const findPrescriptionIdUrl = (id) => {
    return apiUrl + "/routes/prescription/findPrescriptionId/" + id;
    
};
export const singleUserUrl = (id) => {
    return apiUrl + "/routes/user/getSingleUser/" + id;
};
export const updateUserUrl = () => {
    return `${apiUrl}/routes/user/updateUser`;
};

export const testUploadUrl = () => {
    return `${apiUrl}/routes/prescription/testUpload`;
};
