import React, { useEffect , useState} from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import {
  States,
  branchType,
  gstTypeList,
} from "../../components/_utils/appUtils";
import { Countries } from "../../components/_utils/appUtils";
import { getStateByCountry } from "../../components/_utils/appUtils";
import { addBranchFxn } from "./actions";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { groupNameObj } from "../../components/_utils/_utils";
import {fetchGroup} from "../customerGroup/actions"
const BranchForm = (props) => {
  const navigate = useNavigate();
  const { getFieldValue } = props.form;
  let dispatch = useDispatch();
  const [groups, setGroups] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [shippingStateList, setShippingStateList] = useState([]);
  useEffect(() => {
    props.form.setFieldsValue({
        customerGroupName: groupNameObj.sundryDebtors,
        gstType: "Unregistered",
        country: "India",
        discount: 5,
        shippingCountry: "India",
    });
    let resp = getStateByCountry("India");
      setStateList(resp);
      setShippingStateList(resp);
  }, []);
  useEffect(() => {
    props.form.setFieldsValue({
      typeOfBranch: "branch",
    });
  }, []);
  const events = {
    fetchGroups: async () => {
      let params = {
        // results: 100,
        // count: 100,
        type: "CLIENT",
      };
      const data = await fetchGroup({ ...params });
      setGroups(data.data);
    },
    getState: (countryName) => {
      let resp = getStateByCountry(countryName);
      setStateList(resp);
    },
    getShippingState: (countryName) => {
      let resp = getStateByCountry(countryName);
      setShippingStateList(resp);
    },
  };


  const handleSubmit = (e) => {
    const { form } = props; 
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        let findStateCode = _.find(States, (item) => {
          return item.name === valData.state;
        });
        if (findStateCode) {
          valData.stateCode = findStateCode.stateCode;
        }
        if (valData.typeOfBranch == "headBranch") {
        valData.type = "headBranch";
        }else{
          valData.type = "branch";
        }
        valData.customerGroupName = groupNameObj.sundryDebtors;

        if (!valData.name) {
          notification.warning({ message: "Fill Branch Name" });
          return;
        }

        if (!valData.customerGroupName) {
          notification.warning({ message: "Fill Contact Person Name" });
          return;
        }
        if (!valData.state) {
          notification.warning({ message: "plese select state" });
          return;
        }
        if (!valData.gstType) {
          notification.warning({ message: "select gst type" });
          return;
        }
        valData.balance = 0
        if (!valData.discount) {
          valData.discount = 5;
        }

        let x = await dispatch(addBranchFxn(valData));
        if (x && x.success) {
          setTimeout(() => {
            navigate("/branch");
          }, 1000);
          props.form.setFieldsValue({
            name: "",
           
            email: "",
            mobile: "",
            state: "",
            city: "",
            pincode: "",
            address: "",
            royalty: "",
            discount: "",
            password: "",
            gstType: "",
            gstIn: "",
          });
        }
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "typeOfBranch",
        label: "Type of Branch",
        span: "col-md-3",
        type: "select",
        options: branchType,
        keyAccessor: (x) => x.key,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            typeOfBranch: x,
          });
        },
      },
      {
        key: "name",
        label: "Branch Name",
        span: "col-md-3",
        placeholder: "Branch Name",
        required: true,
      },
      // {
      //   key: "contactName",
      //   label: "Contact Person Name",
      //   span: "col-md-6",
      //   placeholder: "Contact Person Name",
      //   required: true,
      // },
      {
        key: "email",
        label: "Email",
        type: "email",
        span: "col-md-6",
        placeholder: "Email",
        required: true,
      },
      {
        key: "password",
        label: "Password",
        type: "password",
        span: "col-md-6",
        placeholder: "Password",
        required: true,
        minLength: 6,
      },
      {
        key: "mobile",
        label: "Mobile No",
        span: "col-md-6",
        placeholder: "Mobile No",
        required: true,
      },
      {
        key: "country",
        label: "Country *",
        span: "col-md-6",
        placeholder: "Country",
        type: "select",
        options: Countries,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            country: x,
          });
          events.getState(x);
        },
      },
      {
        key: "state",
        label: "State",
        span: "col-md-6",
        placeholder: "State",
        type: "select",
        options: States,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            state: x,
          });
        },
      },

      {
        key: "city",
        label: "City",
        span: "col-md-3",
        placeholder: "City",
        required: true,
      },
      {
        key: "pincode",
        label: "Pincode",
        span: "col-md-3",
        placeholder: "Pincode",
        required: true,
      },
      {
        key: "discount",
        label: "Default Discount",
        span: "col-md-3",
        type: "number",
        placeholder: "Default Discount",
        required: true,
   
      },
      {
        key: "royalty",
        label: "Royalty %",
        span: "col-md-3",
        type: "number",
        placeholder: "Royalty %",
        required: true,
      },
      {
        key: "gstType",
        label: "GST Type",
        span: "col-md-6",
        placeholder: "GST Type",
        type: "select",
        options: gstTypeList,
        showSearch: true,
        keyAccessor: (x) => x.value,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          console.log(x);
          props.form.setFieldsValue({
            gstType: x,
          });
        },
      },
      {
        key: "gstIn",
        label: "GSTIN",
        span: "col-md-6",
        placeholder: "GSTIN",
        hidden: getFieldValue("gstType") !== "Registered",
        required: getFieldValue("gstType") === "Registered",
      },
      {
        key: "address",
        label: "Address",
        span: "col-md-12",
        placeholder: "Address",
        required: false,
        type: "textarea",
      },
    ],
  };

  return (
    <>
      <PageHeader title={"Add Branch"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary">
                Submit
              </button>
              <HyperLink className=" ms-4 btn btn-danger" link={"/branch"}>
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(BranchForm);
