import { apiUrl } from "../../../setting";

export const addUpdatedServicePriceUrl = () => {
    return `${apiUrl}/routes/product/addServicePriceUpdate`;
};

export const getUpdatedServicePriceListUrl = () => {
    return `${apiUrl}/routes/product/getAllUpdateServicePrice`;
};

export const deleteUpdatedservicePrice = (id) => {
    return apiUrl + "/routes/product/deleteServicePriceUpdate/" + id;
};

