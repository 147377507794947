import { apiUrl } from "../../../setting";
// import { getItemLocalstorage } from "../../../components/_utils/_utils";


// const companyId = getItemLocalstorage("user")["companyId"];


export const getCategoryDataUrl = () => {
    return `${apiUrl}/routes/category/getCategoryDataByCompanyId`;
};
export const getSaleCategoryDataUrl = () => {
    return `${apiUrl}/routes/saleCategory/getSaleCategory`;
};

export const deleteSaleCategoryUrl = (id) => {
    return apiUrl + "/routes/saleCategory/deleteSaleCategory/" + id;
};

export const getVisitNumberURl = () => {
    return `${apiUrl}/routes/prescription/fetchPrescriptionByVisitNumber`;
};
export const addsaleCategoryUrl = () => {
    return `${apiUrl}/routes/saleCategory/createSaleCateory`;
};
export const addCategoryUrl = () => {
    return `${apiUrl}/routes/category/createCategory`;
};
export const singleCategoryUrl = (id) => {
    return apiUrl + "/routes/category/getSingleCategory/" + id;
};
export const updateCategoryUrl = () => {
    return `${apiUrl}/routes/category/updateCategory`;
  };
  export const singleSaleCategoryUrl = (id) => {
    return apiUrl + "/routes/saleCategory/getSingleSaleCategory/" + id;
};
export const updateSaleCategoryUrl = () => {
    return `${apiUrl}/routes/saleCategory/updateSaleCategory`;
  };

