import React, { useEffect, useRef, useState } from "react";
import { Table, notification } from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { fetchReception } from "./actions";
import { useNavigate } from "react-router-dom";

const ReceptionList = (props) => {
  let tableRef = useRef();
  const navigate = useNavigate();
  const [receptionData, setReceptionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchMobile, setSearchMobile] = useState("");

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      const data = await fetchReception({ ...params });
      setReceptionData(data.data);
      setFilteredData(data.data);
      resolve(data);
    });
  };

  useEffect(() => {
    apiRequest();
  }, []);

  const filterReception = () => {
    setShowFilter(true);

    if (!searchName && !searchEmail && !searchMobile) {
      // setReceptionData([]);
      notification.error({ message: "please enter name" });
      setShowFilter(false)
      return
    } else {
      const filteredReception = receptionData.filter((reception) =>
        reception.name.toLowerCase().includes(searchName.toLowerCase()) &&
        reception.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
        reception.mobile.toLowerCase().includes(searchMobile.toLowerCase())
      );
      setReceptionData(filteredReception);
    }
  };

  const clearReceptionFilters = () => {
    setSearchName("");
    setSearchEmail("");
    setSearchMobile("");
    setShowFilter(false);
    setReceptionData(filteredData);
    if(tableRef.current.reload) {
      tableRef.current.reload()
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Number",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 190,
      render: (val, record) => {
        return (
          <>
            <a onClick={() => {
              navigate(`/edit-reception?_id=${record._id}`)
            }}>
              <img src={"./edit.svg"} alt="Edit"/>
            </a>
          </>
        );
      }
    }
  ];

  return (
    <PageHeader
      title={"Reception"}
      extraLink={[
        {
          name: "Add Reception",
          link: "/add-reception",
        },
      ]}
    >
      <div className="card">
        <div className="card-body">
          <div className="mb-3 row">
            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
              <label htmlFor="searchName" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchName"
                placeholder="Search by Name"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </div>

            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
              <label htmlFor="searchEmail" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchEmail"
                placeholder="Search by Email"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
              />
            </div>

            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
              <label htmlFor="searchMobile" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchMobile"
                placeholder="Search by Mobile"
                value={searchMobile}
                onChange={(e) => setSearchMobile(e.target.value)}
              />
            </div>
          </div>

          <div className="mb-3 search-buttons">
            <div className="search-wrap pe-2">
              <button className="btn btn-info" onClick={filterReception}>
                Search
              </button>
            </div>
            <div className="search-wrap">
              <button className="btn btn-secondary" onClick={clearReceptionFilters}>
                Clear
              </button>
            </div>
          </div>

          {showFilter ? (
            <Table
              dataSource={receptionData}
              columns={columns}
              ref={tableRef}
              key={`table-${showFilter ? 'filtered' : 'original'}`}
            />
          ) : (
            <Table
              apiRequest={apiRequest}
              columns={columns}
              ref={tableRef}
            />
          )}
        </div>
      </div>
    </PageHeader>
  );
};

export default ReceptionList;
