import { apiUrl } from "../../../setting";


export const addPaymentInUrl = () => {
    return `${apiUrl}/routes/payment/paymentIn`;
};

export const addPaymentOutUrl = () => {
    return `${apiUrl}/routes/payment/paymentOut`;
};
export const getPaymentInUrl = () => {
    return `${apiUrl}/routes/payment/getPaymentIn`;
};
export const getPaymentOutUrl = () => {
    return `${apiUrl}/routes/payment/getPaymentOut`;
};

export const deletePaymentIn = () => {
    return `${apiUrl}/routes/payment/deletePaymentIn`;
};
export const deletePaymentOut = () => {
    return `${apiUrl}/routes/payment/deletePaymentOut`;
};