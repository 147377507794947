import React, { useState, useRef } from "react";
import { customAxios as axios, getToken } from "../../request";
import {
  InputBox,
  Form,
  Table,
  notification,
  Tooltip,
  Popconfirm
} from "../../components/Elements/appUtils";
import { useNavigate } from "react-router-dom";
import { fetchSymptom } from "./action";
import { getSymptomDataUrl, addSymptomUrl, deleteSymptomUrl } from "./api";
import { deleteSymptomFxn } from "./action";
import PageHeader from "../../components/Elements/pageHeader";

function AddSymptom(props) {

  const navigate = useNavigate();
  
 let {onClose}=props;
 console.log(onClose, "onClose");
  let tableRef = useRef();
  const [formData, setFormData] = useState({
    symptomName: "",
  });
  let [symptomId, setSymptomId] = useState("");

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleS = async (e) => {
    e.preventDefault();
    formData.symptomId = symptomId;

    const response = await axios.post(addSymptomUrl(), formData, getToken());
    if (response.data.success) {
      
      notification.success({
        message: response.data.message || "Success",
      });
      setFormData({ symptomName: "" });
      tableRef.current.reload();
    
    } else {
      console.log(response.data.message,"brand data");
      notification.error({message:response.data.message});
    }
  };

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      const data = await fetchSymptom({...params});
      console.log(data, "sddsfds")
      resolve(data)
    });
  };

  const deleteSymptom = async(id) => {
    console.log(id, "idddddddd")
    
    try {
      let config = {
        ...await getToken()
      }
      const response = await axios.delete(deleteSymptomUrl(id), config);
      console.log(response);
      if (response) {
            tableRef.current.reload()
            notification.success({message: response.data.message})
          return;
          }
          else{
            notification.error({message: response.data.message})
          }

    } catch (error) {
      console.error("Error getting the data:", error);
    }
  }
  const columns = [
    {
      title: "Symptom Name",
      dataIndex: "symptomName",
      key: "symptomName",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 190,
      render: (val, record) => {
        return (
            <>
                <a onClick={() => {
                  navigate(`/edit-symptom?_id=${record._id}`)
                }}>
                  <img src={"./edit.svg"}/>

                </a> 
                <Tooltip title={'Delete'}> 


                <Popconfirm
                    title={"Are your sure, you want to delete Symptom?"}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                      deleteSymptom(record._id);
                    }}>
                     <img src={"./close.svg"}/>
                </Popconfirm>
                </Tooltip> 


            </>
        );
      }
  }
  ];

  // useEffect(() => {
  //   // Fetch data when the component mounts
  //   fetchBrand();
  // }, []);

  return (
    <PageHeader>
      <Form>
        <div class="card">
          <div class="card-body">
            <div class="form-elements-wrapper">
              <div class="row">
                <div class="col-lg-6">
                  <InputBox title={"Symptom name"}>
                    <input
                      type="text"
                      className={"form-control"}
                      placeholder="Enter Symptom Name"
                      value={formData.symptomName}
                      onChange={(e) => handleChange(e, "symptomName")}
                      name="symptomName"
                      required
                    />
                  </InputBox>
                </div>
              </div>
            </div>
            <button onClick={handleS} className="btn btn-success">
              SUBMIT DETAILS
            </button>
          </div>
        </div>
      </Form>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
            </div>
          </div>
        </div>
      </div>
    </PageHeader>
  );
}

export default AddSymptom;
