import React, {useState, useEffect} from "react";
import {
    Form,
    notification,
    HyperLink,
    fixed2Digit,
    InputBox,
    Card,
} from "../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {
    States,
    PaymentTypes,
    IndianStates,
} from "../../components/_utils/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import _ from "lodash";
import {addCreditNoteFxn, addDebitNoteFxn, fetchCompany, fetchCustomer} from "./actions";
import {getItemLocalstorage} from "../../components/_utils/_utils";
import ChooseCustomer from "../customer/chooseCustomer";
import {useNavigate} from "react-router-dom";
import {fetchBank} from "../customer/actions";
import moment from "moment";
import {fetchSaleCategory, fetchVisitNumber} from "../category/actions";
import {fetchVisitAllDetailsFxn} from "../patients/components/action";
import SaleDetailModal from "../patients/components/saleDetailModal";
import addDebitNote from "./addDebitNote";
import ItemListComponent from "../creditNote/ItemListComponent";

function AddDebitNote(props) {
    const [formData, setFormData] = useState([
        {bankId: "", paymentType: "", amount: "", transactionDetails: ""},
    ]);
    const [showDeleteButtons, setShowDeleteButtons] = useState([false]);
    const [saleCategory, setSaleCategory] = useState([]);


    const handleInputChange = (index, field, value) => {
        const newData = [...formData];
        newData[index][field] = value;
        setFormData(newData);
        setShowDeleteButtons((prevButtons) =>
            prevButtons?.map((btn, idx) => (idx === index ? true : btn))
        );
    };
    const fetchSaleFrom = async () => {
        const {data} = await fetchSaleCategory({});
        setSaleCategory(data);
    };
    const handleDelete = (index) => {
        const newPayments = [...formData];
        newPayments.splice(index, 1);
        setFormData(newPayments);
        setShowDeleteButtons((prevButtons) =>
            prevButtons?.filter((_, idx) => idx !== index)
        );
    };

    const handleAddField = () => {
        const sumAmount = formData.reduce(
            (sum, item) => sum + parseFloat(item.amount) || 0,
            0
        );
        if (sumAmount > getFieldValue("paidAmount")) {
            notification.warning({
                message: "Amount Cannot Be Greater Than Returning Amount",
            });
            return;
        }
        if (sumAmount == getFieldValue("paidAmount")) {
            notification.warning({
                message: "Enough Amount Added, Cannot Add More Payment Method",
            });
            return;
        }

        const lastData = formData[formData.length - 1];
        if (!lastData.bankId) {
            notification.warning({message: "Please Choose Bank"});
            return;
        }
        if (!lastData.paymentType) {
            notification.warning({message: "Please Choose Payment Type"});
            return;
        }
        if (!lastData.amount) {
            notification.warning({message: "Please Enter Amount"});
            return;
        }

        setFormData([
            ...formData,
            {bankId: "", paymentType: "", amount: "", transactionDetails: ""},
        ]);

        setShowDeleteButtons([...showDeleteButtons, true]);
    };

    const companyStateCode = getItemLocalstorage("user")["stateCode"];

    const {getFieldValue} = props.form;
    const navigate = useNavigate();
    let dispatch = useDispatch();
    let [customerId, setCustomerId] = useState("");
    const [isSaleModalOpen, setSaleIsModalOpen] = useState(false);
    let [visitNumber, setVisitNumber] = useState();
    let [visitData, setVisitData] = useState({});
    let [clientId, setClientId] = useState("");
    const paidToUserId = getFieldValue("paidToUserId");
    const [banks, setBanks] = useState([]);
    const [ProductReport, setProductReport] = useState([]);
    const updateProductReport = (newProductReport) => {
        setProductReport(newProductReport);
    };
    const [requestId, setRequestId] = useState(null);
    const [visitId, setVisitId] = useState("");
    const [customerName, setCustomerName] = useState("");

    useEffect(() => {
        const apiRequest123 = (params = {}) => {
            return new Promise(async (resolve) => {
                params._id = clientId;
                const data = await fetchCustomer({...params});
                setCustomerName(data.data[0].name);
            });
        };
        if (clientId) {
            apiRequest123();
            setCustomerId(clientId);
        }
    }, [clientId]);

    useEffect(() => {
        let x = new URLSearchParams(window.location.search);
        if (x.get("id")) {
        }
        setRequestId(x.get("id"));
        setClientId(x.get("clientId"));
        setVisitNumber(x.get("visitNumber"));


    }, []);
    useEffect(() => {
        let x = new URLSearchParams(window.location.search);
        if (x.get("_id")) {

        }
    }, []);

    let netAmount = 0;
    let quantity = 0;
    let balance = 0;

    const calNetAmount = () => {
        if (ProductReport.length == 0) {
            props.form.setFieldsValue({
                totalAmount: 0,
                balance: 0,
                quantity: 0,
            });
        }
        ProductReport.forEach((y) => {
            let paidAmount = getFieldValue("paidAmount");
            paidAmount = paidAmount ? fixed2Digit(paidAmount) : 0;
            netAmount += Number(y.netAmount);
            quantity += Number(y.quantity);
            balance = paidAmount ? netAmount - paidAmount : netAmount;

            if (balance < 0) {
                balance = "Not valid Paid Amount";

                props.form.setFieldsValue({
                    totalAmount: netAmount ? fixed2Digit(netAmount) : 0,
                    quantity: quantity,
                    balance: 0,
                });
            } else {
                props.form.setFieldsValue({
                    totalAmount: netAmount.toFixed(2),
                    quantity: quantity,
                    balance: balance ? balance.toFixed(2) : 0,
                });
            }
        });
    };

    const fetchBanks = async () => {
        const {data} = await fetchBank({
            type: "bank",
            results: 100,
            count: 100,
        });
        setBanks(data);
    };

    const fetchVisitByVisitNumber = async (visitNumber) => {
        const data = await fetchVisitNumber(visitNumber);
        if (data) {
            setVisitData(data.products);
        }
    };

    useEffect(() => {
        fetchVisitByVisitNumber();
    }, [visitNumber]);

    const apiRequest2 = async () => {
        return new Promise(async (resolve) => {
            try {
                let params = {
                    visitId: visitId,
                };
                const data = await fetchVisitAllDetailsFxn({...params});
                setVisitData(data.prescriptionDetails.products);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    useEffect(() => {
        apiRequest2();
    }, [visitId]);

    const addCompanyName = () => {
        return new Promise(async (resolve) => {


            const data = await fetchCompany();
            // console.log(data,'dtaa');
            // setCustomerName(data.name);
            // setCustomerId(data._id);
        });
    };

    useEffect(() => {
        addCompanyName();
    }, []);

    useEffect(() => {
        props.form.setFieldsValue({
            paidAmount: 0,
            paymentType: "None",
            debitNoteDate: moment(),
        });
        fetchBanks();
        fetchSaleFrom();
    }, []);

    useEffect(() => {
        calNetAmount();
    }, [ProductReport, getFieldValue("paidAmount")]);

    const handleSubmit = async (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {
            if (!err) {
                let findStateCode = _.find(States, (item) => {
                    return item.name === valData.state;
                });
                if (findStateCode) {
                    valData.stateCode = findStateCode.stateCode;
                }
                valData.productReports = ProductReport;
                valData.customerId = customerId;
                valData.paidToUserId = paidToUserId;
                valData.requestId = requestId;
                valData.paidToUser = formData;
                valData.saleBalance = valData.balance

                const sumFormDataAmount = formData.reduce(
                    (sum, item) => sum + parseFloat(item.amount) || 0,
                    0
                );

                if (ProductReport.length == 0) {
                    notification.error({
                        message: "Please Add Products",
                    });
                    return;
                }

                const totalAmount = valData.totalAmount;
                const paidAmount = valData.paidAmount;
                if (parseFloat(paidAmount) > parseFloat(totalAmount)) {
                    notification.error({
                        message: "paid amount cannot be Greater than total amount",
                    });
                    return null;
                }

                if (!valData.totalAmount) {
                    notification.warning({message: "Please Add Some Items"});
                    return;
                }

                if (!valData.stateOfSupply) {
                    notification.warning({message: "Please Add State of Supply"});
                    return;
                }
                if (!valData.customerId) {
                    notification.warning({message: "Please Choose Client"});
                    return;
                }
                if (parseFloat(valData.paidAmount) < sumFormDataAmount) {
                    notification.error({
                        message: "Paid amount cannot be Greater than total amount",
                    });
                    return;
                } else if (parseFloat(valData.paidAmount) > sumFormDataAmount) {
                    notification.error({
                        message: "Paid amount cannot be less than total amount",
                    });
                    return;
                }
                if (!valData.saleCategory) {
                    notification.warning({message: "Please Add Sale Category"});
                    return;
                }
                let x = await dispatch(addDebitNoteFxn(valData));

                if (x && x.success) {
                    notification.success({message: x.message});
                    setProductReport([]);
                    props.form.setFieldsValue({});
                    setTimeout(() => {
                        navigate("/debitNote");
                    }, 1000);
                } else {
                    notification.error({message: x.message});
                }
                props.form.setFieldsValue({
                    stateOfSupply: "",
                    totalAmount: "",
                    paidAmount: "",
                    balance: "",
                    paymentType: "",
                    paymentDetails: "",
                });
                setCustomerId("");
            }
        });
    };

    let inputTypes = {
        upperFields: [

            {
                key: "debitNoteDate",
                label: "Date of Debit Note",
                type: "date",
                span: "col-md-3",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        debitNoteDate: x,
                    });
                },
            },
            {
                key: "stateOfSupply",
                label: "State of Supply",
                span: "col-md-3",
                placeholder: "State of supply",
                type: "select",
                options: IndianStates,
                showSearch: true,
                keyAccessor: (x) => x.stateCode,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        stateOfSupply: x,
                    });
                },
            },
            {
                key: "otherState",
                label: "Other State",
                span: "col-md-3",
                placeholder: "Other State",
                type: "text",
                hidden: getFieldValue('stateOfSupply') !== -1
            },
            {
                key: "orderId",
                label: "Order ID",
                span: "col-md-3",
                placeholder: "Type here..",

            },

            {
                key: "saleCategory",
                label: "Sale Category*",
                span: "col-md-3",
                placeholder: "Choose One",
                type: "select",
                options: saleCategory,
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        saleCategory: x,
                    });
                },
            },
        ],

        lowerFields: [
            {
                key: "totalAmount",
                label: "Total Amount *",
                span: "col-md-3",
                placeholder: "Total Amount",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        totalAmount: netAmount,
                    });
                },
                disabled: true,
            },
            /* {
                 key: "paidAmount",
                 type: "number",
                 label: "Return Amount",
                 span: "col-md-3",
                 placeholder: "Return Amount",
             },*/
            {
                key: "balance",
                label: "Balance *",
                span: "col-md-3",
                placeholder: "Balance",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        balance: balance,
                    });
                },
                disabled: true,
            },
        ],
    };

    const groupType = "SUNDRY DEBTORS";

    return (
        <PageHeader title={"Add Debit Note"}>
            <div className="card">
                <div className="card-body">
                    <div className={"row"}>
                        {clientId ? (
                            <div className={"row"}>
                                <div className={"col-md-3 mb-3"}>
                                    <InputBox title={"Client"}>
                                        <input
                                            style={{height: "45px", color: "black"}}
                                            disabled
                                            className={"form-control"}
                                            type="text"
                                            value={customerName}
                                        />
                                    </InputBox>
                                </div>
                            </div>
                        ) : (
                            <div className={"col-md-3 mb-3"}>
                                <ChooseCustomer
                                    groupType={groupType}
                                    customerId={customerId}
                                    callback={(clientData) => {
                                        if (clientData && clientData._id) {
                                            setCustomerId(clientData._id);
                                        }
                                    }}
                                />
                            </div>
                        )}
                        <div className="col-1 sale-view ms-2">
                            {customerId && (
                                <>
                                    <button
                                        className="btn btn-info btn-sm"
                                        style={{height: "35px"}}
                                        onClick={() => {
                                            setSaleIsModalOpen(true);
                                        }}
                                    >
                                        Prev.Rec.
                                    </button>
                                    {isSaleModalOpen ? (
                                        <SaleDetailModal
                                            visible={isSaleModalOpen}
                                            onClose={() => {
                                                setSaleIsModalOpen(false);
                                            }}
                                            customerId={customerId}
                                            // customerRecord={customerRecord}
                                        />
                                    ) : null}
                                </>
                            )}
                        </div>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <div className="form-elements-wrapper">
                            <div className={"row"}>
                                {inputTypes.upperFields.map((item, key) => {
                                    return !item.hidden ? (
                                        <div
                                            className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    ) : null;
                                })}
                            </div>
                            <div className={"row"} key={getFieldValue("stateOfSupply")}>
                                <div className={"col-md-12 mt-4"}>
                                    <ItemListComponent
                                        ProductReport={ProductReport}
                                        customerId={customerId}
                                        updateProductReport={updateProductReport}
                                        stateOfSupply={getFieldValue("stateOfSupply")}
                                        companyState={companyStateCode}
                                    />
                                </div>
                            </div>
                            <div className={"row"}>
                                {inputTypes.lowerFields.map((item, key) => {
                                    return !item.hidden ? (
                                        <div
                                            className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    ) : null;
                                })}
                            </div>

                            {getFieldValue("paidAmount") ? (
                                <Card title={"Payments"} size={"small"}>
                                    <div className="d-flex align-items-end">
                                        <div>
                                            {formData?.map((data, index) => (
                                                <div key={index} className={"item-flex mt-2"}>
                                                    <InputBox title={"Banks"}>
                                                        <select
                                                            style={{height: "45px"}}
                                                            className="form-control"
                                                            value={data.bank}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "bankId",
                                                                    e.target.value
                                                                )
                                                            }
                                                            required="true"
                                                        >
                                                            <option value="">Select Bank</option>
                                                            {banks?.map((bank) => (
                                                                <option key={bank._id} value={bank._id}>
                                                                    {bank.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </InputBox>

                                                    <InputBox title={"Payment Type"}>
                                                        <select
                                                            style={{
                                                                height: "45px",
                                                            }}
                                                            className="form-control"
                                                            value={data.paymentType}
                                                            required
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "paymentType",
                                                                    e.target.value
                                                                )
                                                            }
                                                        >
                                                            <option value="">Select Payment Type</option>
                                                            {PaymentTypes?.map((PaymentTypes) => (
                                                                <option>{PaymentTypes.name}</option>
                                                            ))}
                                                        </select>
                                                    </InputBox>

                                                    <InputBox title={"Amount"}>
                                                        <input
                                                            style={{
                                                                height: "45px",
                                                            }}
                                                            className={"form-control"}
                                                            type="number"
                                                            value={data.amount}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "amount",
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder="Amount"
                                                        />
                                                    </InputBox>

                                                    <InputBox title={"Transaction Details"}>
                                                        <input
                                                            style={{
                                                                height: "45px",
                                                            }}
                                                            className={"form-control"}
                                                            value={data.transactionDetails}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "transactionDetails",
                                                                    e.target.value
                                                                )
                                                            }
                                                            placeholder="Transaction Details"
                                                        />
                                                    </InputBox>
                                                    <InputBox className="deleteBtn">
                                                        <div key={index} className={"item-flex mt-3"}>
                                                            {index > 0 &&
                                                            showDeleteButtons[index] && ( // Condition added to not show delete button for zero index
                                                                <a
                                                                    className="empty_btn"
                                                                    onClick={() => handleDelete(index)}
                                                                >
                                                                    <i className="fa fa-trash-alt"></i>
                                                                </a>
                                                            )}
                                                        </div>
                                                    </InputBox>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            <InputBox className={"pb-2"}>
                                                <a
                                                    className="addBtn  btn-link"
                                                    onClick={handleAddField}
                                                >
                                                    Add More
                                                </a>
                                            </InputBox>
                                        </div>
                                    </div>
                                </Card>
                            ) : null}
                        </div>

                        <button type="submit" className="btn btn-gradient-info me-2">
                            Submit
                        </button>
                        <HyperLink className=" ms-4 btn btn-danger" link={"/debitNote"}>
                            Cancel
                        </HyperLink>
                    </Form>
                </div>
            </div>
        </PageHeader>
    );
}

export default Form.create()(AddDebitNote);
