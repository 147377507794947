import React from "react";
import PageHeader from "../../components/Elements/pageHeader";
import AddBankComponent from "./components/addBankComponent";

const AddBank = (props) => {
    return (
        <PageHeader title={'Add Bank'}>
            <div className="card">
                <div className="card-body">
                    <AddBankComponent/>
                </div>
            </div>
        </PageHeader>
    );
}

export default AddBank;
