import React, { useEffect, useState } from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { singleCategoryFxn, updateCategoryFxn } from "./actions";

const EditCategory = (props) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [categoryId, setCategoryId] = useState("");
  useEffect(() => {
    checkcategoryId();
  }, []);

  let id;

  const checkcategoryId = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    id = searchParams.get("_id");
    if (id) {
      getSinglecategory(id);
      setCategoryId(id);
    }
  };
  const getSinglecategory = async (id) => {
    let { data, success } = await dispatch(singleCategoryFxn(id));
    console.log(data, "valdata");
    if (success) {
      setTimeout(() => {
        props.form.setFieldsValue({
          categoryName: data.categoryName,
        });
      }, 300);
    }
  };

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      if (!err) {
        valData._id = categoryId;

        let x = await dispatch(updateCategoryFxn(valData));
        if (x && x.success) {
          console.log(x, "valdata");
          navigate("/category");
        }
      } else {
        if (err.name) {
          notification.warning({
            message: `Please enter Category Name`,
          });
          return;
        }

        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        title: "Category Name",
        dataIndex: "categoryName",
        key: "categoryName",
      },
    ],
  };

  return (
    <>
      <PageHeader title={"Edit Category"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary me-2">
                Submit
              </button>
              <HyperLink className=" ms-4 btn btn-danger" link={"/category"}>
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(EditCategory);
