import React, {useState} from "react";
import {useDispatch} from "react-redux";
import axios from "axios";
import {Navigate, useNavigate} from "react-router-dom";
import notification from "../../components/Elements/Notification";
import {apiUrl} from "../../setting";

function Login() {
    const navigate = useNavigate();

    let dispatch = useDispatch();
    const [email, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const isLoggedIn = localStorage.getItem("token") ? true : false;

    const loginUser = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${apiUrl}/auth/login`, {
                email,
                password,
            });
            const {token, success, user} = response.data;
            if (success) {
                localStorage.setItem("token", token);
                localStorage.setItem("user", JSON.stringify(user));
                dispatch({
                    type: "SET_CURRENT_USER",
                    user: user,
                });
                setTimeout(() => {
                    window.location.href = "/dashboard";
                }, 300);
            } else {
                notification.error({
                    message: "Invalid login" || "Invalid login",
                });
            }
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <>
            <h4>Hello! let's get started</h4>
            <h6 className="font-weight-light">Sign in to continue.</h6>

            <form className="pt-3">
                <div className="form-group">
                    <input type="email" className="form-control form-control-lg" id="exampleInputEmail1" required
                           onChange={(e) => setUsername(e.target.value)}
                           value={email}
                           placeholder="Username"/>
                </div>
                <div className="form-group">
                    <input type="password" className="form-control form-control-lg" id="exampleInputPassword1"
                           onChange={(e) => setPassword(e.target.value)}
                           value={password}
                           placeholder="Password"/>
                </div>
                <div className="mt-3">
                    <button className="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn"
                            onClick={loginUser}>SIGN IN
                    </button>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check">
                    </div>
                    <a className="auth-link text-black" onClick={()=>{window.location.href = "/forgot-password"}}>Forgot password?</a>
                </div>
                {/*   <div className="text-center mt-4 font-weight-light"> Don't have an account? <a href="register.html"
                                                                                         className="text-primary">Create</a>
          </div>*/}
            </form>

        </>
    )
}

export default Login;
