import { apiUrl } from "../../../../setting";

export const createPrescreptionUrl = () => {
    return `${apiUrl}/routes/prescription/createProductPrescription`;
};

export const addTakenUrl = () => {
    return `${apiUrl}/routes/taken/createDiet`;
};
export const getTakenDataUrl = () => {
    return `${apiUrl}/routes/taken/getDietDataByCompanyId`;
};
export const addFrequencyUrl = () => {
    return `${apiUrl}/routes/frequency/createFrequency`;
};
export const getFrequencyDataUrl = () => {
    return `${apiUrl}/routes/frequency/getFrequencyDataByCompanyId`;
};
export const getVisitAllRecordsUrl = () => {
    return `${apiUrl}/routes/prescription/fetchVisitAllDetails`;
};
export const updatePrescriptionUrl = () => {
    return `${apiUrl}/routes/prescription/updatePrescription`;
  };
  export const findPrescriptionByVisitNumberUrl = () => {
    return `${apiUrl}/routes/prescription/findprescriptionByVisitNumber`;
    
};
export const singletakenUrl = (id) => {
    return apiUrl + "/routes/taken/getSingleTaken/" + id;
};
export const updatetakenUrl = () => {
    return `${apiUrl}/routes/taken/updateTaken`;
  };
  export const singleFrequencyUrl = (id) => {
    return apiUrl + "/routes/frequency/getSingleFrequency/" + id;
};
export const updateFrequencyUrl = () => {
    return `${apiUrl}/routes/frequency/updateFrequency`;
  };
  export const getSaleByCustomerId = () => {
    return `${apiUrl}/routes/purchase/getProductReportByCustomerId`;
};

