import React from "react";

import { Modal } from "../../components/Elements/appUtils";
import AddProduct from "../products/addProduct";

const AddProductDrawer = (props) => {
  let { visible, onClose,onSubmit } = props;


  return (
    <Modal
      width={"75%"}
      visible={visible}
      onClose={onClose}
      title={"Add Product"}
    >
    <AddProduct onClose={onClose} onSubmit={onSubmit}/>
    </Modal>
    
  );
};
export default AddProductDrawer;
