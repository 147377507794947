import React, {
  useState,
  useEffect,
  // useRef
} from "react";
import axios from "axios";
import {
  Form,
  notification,
  HyperLink,
  fixed2Digit,
  Card,
  InputBox,
} from "../../components/Elements/appUtils";
import moment from "moment";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { States, PaymentTypes } from "../../components/_utils/appUtils";
import { getBank, getCustomerDataUrl } from "./api";
import { getToken } from "../../request";
import PageHeader from "../../components/Elements/pageHeader";
import _ from "lodash";
import ItemListComponent from "./ItemListComponent";
import { addPurchaseFxn } from "./actions";
import { getItemLocalstorage } from "../../components/_utils/_utils";
import { getGroupDataByCustomerIdUrl } from "../customerGroup/api";
import ChooseCustomer from "../customer/chooseCustomer";
import { useNavigate } from "react-router-dom";
import { fetchBank } from "../customer/actions";

function Purchase(props) {
  const companyStateCode = getItemLocalstorage("user")["stateCode"];
  const { getFieldValue } = props.form;
  let [stateCode, setStateCode] = useState("");
  let [customerId, setCustomerId] = useState("");
  const [isPaymentVisible, setIsPaymentVisible] = useState(true);
  // useEffect(() => {

  //   return () => {
  //     window.location.reload()
  //   }
  // }, [customerId])
  useEffect(() => {
    setIsPaymentVisible(getFieldValue("paidAmount") !== 0);
  }, [getFieldValue("paidAmount")]);
  const [formData, setFormData] = useState([
    { bankId: "", paymentType: "", amount: "", transactionDetails: "" },
  ]);
  let [productId, setProductId] = useState("");
  //   let [paidToUserId, setPaidToUserId] = useState("");
  const [customer, setCustomer] = useState([]);
  const [banks, setBanks] = useState([]);
  const [groupName, setGroupName] = useState(null);
  // const [statesAreEqual, setStatesAreEqual] = useState(false);
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const paidToUserId = getFieldValue("paidToUserId");
  const [ProductReport, setProductReport] = useState([]);

  const updateProductReport = (newProductReport) => {
    setProductReport(newProductReport);
  };

  let netAmount = 0;
  let quantity = 0;
  let balance = 0;

  const calNetAmount = () => {
    //   if (ProductReport.length == 0) {
    //     props.form.setFieldsValue({
    //         totalAmount: 0,
    //         balance: 0,
    //         quantity: 0,
    //     });
    // }
    ProductReport.forEach((y) => {
      let paidAmount = getFieldValue("paidAmount");
      paidAmount = paidAmount ? fixed2Digit(paidAmount) : 0;
      netAmount += Number(y.netAmount);
      quantity += Number(y.quantity);
      balance = paidAmount ? netAmount - paidAmount : netAmount;

      if (balance < 0) {
        balance = "Not valid Paid Amount";

        props.form.setFieldsValue({
          totalAmount: netAmount ? fixed2Digit(netAmount) : 0,
          quantity: quantity,
          balance: 0,
        });
      } else {
        props.form.setFieldsValue({
          totalAmount: netAmount.toFixed(2),
          quantity: quantity,
          balance: balance ? balance.toFixed(2) : 0,
        });
      }
    });
  };

  const fetchBanks = async () => {
    const { data } = await fetchBank({
      type: "bank",
      results: 100,
      count: 100,
    });
    setBanks(data);
  };
  const handleInputChange = (index, field, value) => {
    const newData = [...formData];
    newData[index][field] = value;
    setFormData(newData);
  };
  const fetchCustomer1 = async () => {
    try {
      const response = await axios.get(getCustomerDataUrl(), {
        params: {
          results: 10000,
        },
        ...(await getToken()),
      });
      setCustomer(response.data.data);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  const fetchGroupName = async () => {
    try {
      const response = await axios.get(getGroupDataByCustomerIdUrl(), {
        params: { customerId: paidToUserId },
      });
      setGroupName(response.data.data.name);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    props.form.setFieldsValue({
      paidAmount: 0,
      paymentType: "None",
      purchaseDate: moment(),
    });
    fetchCustomer1();
    fetchBanks();
  }, [customerId]);

  useEffect(() => {
    fetchGroupName();
  }, [paidToUserId]);

  useEffect(() => {
    if (ProductReport) {
      calNetAmount();
    }
  }, [ProductReport, getFieldValue("paidAmount")]);

  const handleSubmit = async (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      if (!err) {
        let findStateCode = _.find(States, (item) => {
          return item.name === valData.state;
        });
        if (findStateCode) {
          valData.stateCode = findStateCode.stateCode;
        }
        valData.productReports = ProductReport;
        valData.customerId = customerId;
        valData.productId = productId;
        valData.productId = productId;
        valData.hsnCode = productId;
        valData.paidToUserId = paidToUserId;
        valData.bankId = paidToUserId;
        
        if (valData.paidAmount && isNaN(valData.paidAmount)) {
          notification.warning({
            message: "Please Enter Paid Amount in Numbers",
          });
          return;
        }

        //paid amount check
        const totalAmount = valData.totalAmount;
        const paidAmount = valData.paidAmount;
        if (paidAmount < 0) {
          notification.error({
            message: "Paid Amount cannot be negative",
          });
          return;
        }
        // if (parseFloat(paidAmount) > parseFloat(totalAmount)) {
        //   notification.error({
        //     message: "Paid Amount Cannot Be Greater Than Total Amount",
        //   });
        //   return null;
        // }
        if (valData.quantity == 0) {
          notification.error({
            message: "No item To Purchase",
          });
          return null;
        }

        if (!valData.invoiceNumber) {
          notification.warning({ message: "Please Enter Invoice Nummber" });
          return;
        }
        if (!valData.totalAmount) {
          notification.warning({ message: "Please Add Some Items" });
          return;
        }

        if (!valData.paidToUserId && valData.paidAmount != 0) {
          notification.warning({ message: "Please select BANK" });
          return;
        }

        let x = await dispatch(addPurchaseFxn(valData));
        if (x && x.success) {
          setProductReport([]);
          props.form.setFieldsValue({});
          setTimeout(() => {
            navigate("/purchase");
          }, 1000);
          props.form.setFieldsValue({
            invoiceNumber: "",
            purchaseDate: "",
            totalAmount: "",
            paidAmount: "",
            balance: "",
            paidToUserId: "",
            paymentType: "",
            paymentDetails: "",
          });
          setCustomerId("");
        }
      }
    });
  };

  //selected customers state
  useEffect(() => {
    if (customerId) {
      let c = customer.find((i) => i._id == customerId);
      if (c) {
        setStateCode(c.stateCode);
      }
    }
  }, [customerId]);

  let inputTypes = {
    upperFields: [
      {
        key: "invoiceNumber",
        label: "Vendor Invoice Number*",
        span: "col-md-3",
        placeholder: "Vendor Invoice Number",
      },
      {
        key: "purchaseDate",
        label: "Date of Receiving product",
        type: "date",
        span: "col-md-3",
        onChange: (x) => {
          props.form.setFieldsValue({
            purchaseDate: x,
          });
        },
      },
      {
        key: "vendorInvoiceDate",
        label: "Vendor Invoice Date",
        type: "date",
        span: "col-md-3",
        onChange: (x) => {
          props.form.setFieldsValue({
            vendorInvoiceDate: x,
          });
        },
      },
    ],

    lowerFields: [
      {
        key: "totalAmount",
        label: "Total Amount *",
        span: "col-md-4",
        placeholder: "Total Amount",
        onChange: (x) => {
          props.form.setFieldsValue({
            totalAmount: netAmount,
          });
        },
        disabled: true,
      },
      {
        key: "paidAmount",
        label: "Paid Amount",
        span: "col-md-4",
        placeholder: "Paid Amount",
        
      },
      {
        key: "balance",
        label: "Balance *",
        span: "col-md-4",
        placeholder: "Balance",
        onChange: (x) => {
          props.form.setFieldsValue({
            balance: balance,
          });
        },
        disabled: true,
      },
      {
        key: "paidToUserId",
        label: "Select Bank *",
        span: "col-md-4",
        placeholder: "Bank",
        type: "select",
        options: banks,
        showSearch: true,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            paidToUserId: x,
          });
        },
        hidden: getFieldValue("paidAmount") == 0,
      },
      {
        key: "paymentType",
        label: "Payment type",
        span: "col-md-4",
        type: "select",
        placeholder: "None",
        options: PaymentTypes,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            paymentType: x,
          });
        },
        required: getFieldValue("paidToUserId"),
        hidden:
          !isPaymentVisible ||
          groupName === "CASH IN HAND" ||
          getFieldValue("paidAmount") == 0,
      },
      {
        key: "paymentDetails",
        label: "Payment Details (Optional)",
        span: "col-md-4",
        placeholder: "Payment Details",
        hidden: !isPaymentVisible || getFieldValue("paidAmount") == 0,
        // hidden: groupName == "CASH IN HAND",
      },
    ],
  };
  return (
    <PageHeader title={"Add Purchase"}>
      <div className="card">
        <div className="card-body">
          <div className={"col-md-3 mb-3 "}>
            <ChooseCustomer
              gstType="Registered"
              type="customer"
              customerId={customerId}
              callback={(clientData) => {
                if (clientData && clientData._id) {
                  setCustomerId(clientData._id);
                }
              }}
            />
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="forhandleSubmitm-elements-wrapper">
              <div className={"row"}>
                {inputTypes.upperFields.map((item, key) => {
                  return !item.hidden ? (
                    <div
                      className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                      key={key}
                    >
                      <GetEachFormFields {...props.form} item={item} />
                    </div>
                  ) : null;
                })}
              </div>
              <div className={"row"}>
                <div className={"col-md-12 mt-4"}>
                  <ItemListComponent
                    ProductReport={ProductReport}
                    updateProductReport={updateProductReport}
                    productId={productId}
                    customerId={customerId}
                    stateOfSupply={stateCode}
                    companyState={companyStateCode}
                  />
                </div>
              </div>
              <div className={"row"}>
                {inputTypes.lowerFields.map((item, key) => {
                  return !item.hidden ? (
                    <div
                      className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                      key={key}
                    >
                      <GetEachFormFields {...props.form} item={item} />
                    </div>
                  ) : null;
                })}

                {/* <div className="d-flex align-items-end">
                    <div>
                      {formData?.map((data, index) => (
                        <div key={index} className={"item-flex mt-2"}>
                          <InputBox title={"Banks"}>
                            <select
                              style={{ height: "45px" }}
                              className="form-control"
                              value={data.bank}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "bankId",
                                  e.target.value
                                )
                              }
                              required="true"
                            >
                              <option value="">Select Bank</option>
                              {banks?.map((bank) => (
                                <option key={bank._id} value={bank._id}>
                                  {bank.name}
                                </option>
                              ))}
                            </select>
                          </InputBox>

                          <InputBox title={"Payment Type"}>
                            <select
                              style={{
                                height: "45px",
                              }}
                              className="form-control"
                              value={data.paymentType}
                              required
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "paymentType",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Payment Type</option>
                              {PaymentTypes?.map((PaymentTypes) => (
                                <option>{PaymentTypes.name}</option>
                              ))}
                            </select>
                          </InputBox>

                          <InputBox title={"Amount"}>
                            <input
                              style={{
                                height: "45px",
                              }}
                              className={"form-control"}
                              type="number"
                              value={data.amount}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "amount",
                                  e.target.value
                                )
                              }
                              placeholder="Amount"
                            />
                          </InputBox>

                          <InputBox title={"Transaction Details"}>
                            <input
                              style={{
                                height: "45px",
                              }}
                              className={"form-control"}
                              value={data.transactionDetails}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "transactionDetails",
                                  e.target.value
                                )
                              }
                              placeholder="Transaction Details"
                            />
                          </InputBox>
                         
                        </div>
                      ))}
                    </div>
                    <div>
                      
                    </div>
                  </div> */}
              </div>
            </div>

            <button type="submit" className="btn btn-info me-2">
              Submit
            </button>
            <HyperLink className=" ms-4 btn btn-danger" link={"/purchase"}>
              Cancel
            </HyperLink>
          </Form>
        </div>
      </div>
    </PageHeader>
  );
}

export default Form.create()(Purchase);
