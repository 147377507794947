import React, { useState, useRef, useEffect } from "react";
import { customAxios as axios, getToken } from "../../request";
import {
  InputBox,
  Card,
  Form,
  notification,
} from "../../components/Elements/appUtils";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  dailyReportFxn,
  fetchAllEmp,
  singleExpenseEmployeeFxn,
} from "./action";
import ChooseCustomer from "../customer/chooseCustomer";
import PageHeader from "../../components/Elements/pageHeader";
import { useNavigate } from "react-router-dom";
import { singleEmployeeFxn } from "./action";
import { fetchBank } from "../customer/actions";
import { PaymentTypes } from "../../components/_utils/appUtils";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { getGroupDataByCustomerIdUrl } from "../customerGroup/api";
import { addSalaryFxn } from "./action";
function EmployeeProfile(props) {
  const { getFieldValue } = props.form;
  const dispatch = useDispatch();
  const location = useLocation();
  const { employeeId } = location.state || {};
  console.log(employeeId, "employeeId");
  let tableRef = useRef();
  const [profile, setProfile] = useState("");
  const [dailyReports, setDailyReports] = useState("");
  const [expenseDetail, setExpenseDetail] = useState([]);
  const [showDeleteButtons, setShowDeleteButtons] = useState([false]);
  const [allList, setAllList] = useState([]);

  let [empName, setEmpName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getSingleEmployee();
    getExpenseEmployee();
    dailyReport();
  }, []);

  console.log(expenseDetail, "expenseDetail")

  const apiRequest1 = (params) => {
    return new Promise(async (resolve) => {
      const data = await fetchAllEmp({ ...params });
      const employeeData = data.data.filter((item) => item.type == "employee");

      setAllList(employeeData);
      setCustomerId(employeeData._id);
      console.log(employeeData._id, "alllis");

      resolve({ data: employeeData });
    });
  };

  useEffect(() => {
    apiRequest1();
  }, []);
  const getSingleEmployee = async () => {
    let { data, success } = await dispatch(singleEmployeeFxn(employeeId));

    if (success) {
      console.log(data, "expensecategoryrecord");
      setProfile(data);
      setEmpName(data.name);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDelete = (index) => {
    const newPayments = [...formData];
    newPayments.splice(index, 1);
    setFormData(newPayments);
    setShowDeleteButtons((prevButtons) =>
      prevButtons?.filter((_, idx) => idx !== index)
    );
  };

  const getExpenseEmployee = async () => {
    let data = await dispatch(singleExpenseEmployeeFxn(employeeId));
    setExpenseDetail(data);
  };

  const dailyReport = async () => {
    try {
      let data = await dispatch(dailyReportFxn(employeeId));
      setDailyReports(data);

      console.log(data, "aaaaaaaaaaaa");
    } catch (error) {
      console.log(error);
    }
  };

  const manageTime = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
    return formattedDate;
  };

  const fetchDataForSelectedMonth = async (selectedMonth) => {
    try {
      let data = await dispatch(dailyReportFxn(employeeId));
      setDailyReports(data);

      const selectedMonthData = data?.report?.filter(
        (item) => moment(item.checkIn).format("YYYY-MM") === selectedMonth
      );
      setFilteredCheckInOut(selectedMonthData);
    } catch (error) {
      console.log(error);
    }
  };
  const [formData, setFormData] = useState({
    bankId: "",
    paymentType: "",
    amount: "",
  });
  const [selectedDate, setSelectedDate] = useState("");
  const [details, setDetails] = useState("");
  const [filteredCheckInOut, setFilteredCheckInOut] = useState([]);
  const [currentMonthData, setCurrentMonthData] = useState([]);
  const [banks, setBanks] = useState([]);
  let [customerId, setCustomerId] = useState("");
  let [bankId, setBankId] = useState("");
  let [handleDate, setHandleDate] = useState("");
  let [paymentType, setPaymentType] = useState("");
  let [amount, setAmount] = useState("");
  const paidToUserId = getFieldValue("paidToUserId");
  const [groupName, setGroupName] = useState(null);
  const [isPaymentVisible, setIsPaymentVisible] = useState(true);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    fetchDataForSelectedMonth(date);
  };

  const fetchBanks = async () => {
    const { data } = await fetchBank({
      type: "bank",
      results: 100,
      count: 100,
    });
    setBanks(data);
  };
  useEffect(() => {
    props.form.setFieldsValue({
      paidAmount: 0,
      paymentType: "None",
      salesDate: moment(),
      creditNoteBalance: 0,
      remainingAmount: 0,
    });
    fetchBanks();
  }, []);

  const getCurrentMonth = () => {
    return moment().format("YYYY-MM");
  };

  useEffect(() => {
    const currentMonth = getCurrentMonth();
    setSelectedDate(currentMonth);

    fetchDataForSelectedMonth(currentMonth).then(() => {
      setCurrentMonthData(filteredCheckInOut);
    });
  }, []);
  const fetchGroupName = async () => {
    try {
      const response = await axios.get(getGroupDataByCustomerIdUrl(), {
        params: { customerId: paidToUserId },
      });

      setGroupName(response.data.data.name);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    fetchGroupName();
  }, [paidToUserId]);

  console.log(groupName, "response.data.data.name");
  let inputTypes = {
    lowerFields: [
      {
        key: "paidToUserId",
        label: "Select Bank *",
        span: "col-md-3",
        placeholder: "Bank",
        type: "select",
        options: banks,
        showSearch: true,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            paidToUserId: x,
          });
        },
      },
      {
        key: "paymentType",
        label: "Payment type",
        span: "col-md-3",
        type: "select",
        placeholder: "None",
        options: PaymentTypes,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            paymentType: x,
          });
        },
        required: getFieldValue("paidToUserId"),
        hidden: groupName == "CASH IN HAND",
      },
      {
        key: "amount",
        label: "Amount",
        span: "col-md-3",
        type: "Number",
        placeholder: "Amount",
      },
      {
        key: "paymentDetails",
        label: "Details",
        span: "col-md-3",
        type: "text",
        placeholder: "Details",
      },
    ],
  };

  const handleSubmit = async (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
     

        valData.customerId = customerId;
        valData.bankId = paidToUserId;
        valData.date = handleDate;
        const totalAmount = valData.amount;

        if (!valData.customerId) {
          notification.warning({ message: "Please Choose Employee" });
          return;
        }
        if (!valData.date) {
          notification.warning({ message: "Please Enter Date" });
          return;
        }
      

        let x = await dispatch(addSalaryFxn(valData));
        if (x && x.success) {
          notification.success({ message: x.message });
          props.form.setFieldsValue({
            amount: "",
            paidToUserId: "",
            paymentType: "",
            paymentDetails: "",
          });
          setCustomerId("");
          setHandleDate("");
        } else {
          notification.error({ message: x.message });
        }
      }
    });
  };

  return (
    <PageHeader title={"Employee"}>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div className="information-box card-box border-grey mb-4">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="Profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Profile"
                      type="button"
                      role="tab"
                      aria-controls="Profile"
                      aria-selected="true"
                    >
                      Profile
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Education-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Education"
                      type="button"
                      role="tab"
                      aria-controls="Education"
                      aria-selected="false"
                    >
                      Attendence
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Documents-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Documents"
                      type="button"
                      role="tab"
                      aria-controls="Documents"
                      aria-selected="false"
                    >
                      Transactions
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="Profile"
                    role="tabpanel"
                    aria-labelledby="Profile-tab"
                  >
                    {" "}
                    <div className="detail-box">
                      <ul>
                        <li>
                          <span>Name:</span>
                          {profile.name}
                        </li>
                        <li>
                          <span>Mobile Number:</span> {profile.mobile}
                        </li>
                        <li>
                          <span>Email:</span>
                          {profile.name}
                        </li>
                        <li>
                          <span>Date Of Birth:</span>{" "}
                          {moment(profile.dateOfBirth).format("DD/MM/YYYY")}
                        </li>
                        <li>
                          <span>Address:</span> {profile.address}
                        </li>
                        <li>
                          <span>Joining Date:</span>{" "}
                          {moment(profile.joiningDate).format("DD/MM/YYYY")}
                        </li>
                        <li>
                          <span>Designation:</span> {profile.designation}
                        </li>
                        <li>
                          <span>Emergency Contact:</span>{" "}
                          {profile.emergencyNumber}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="Education"
                    role="tabpanel"
                    aria-labelledby="Education-tab"
                  >
                    <div className="detail-box">
                      <div className="label">
                        <label className="m-2">Select Date </label>
                        <input
                          type="month"
                          label="Select Date"
                          value={selectedDate}
                          className="form-control"
                          style={{ width: "180px", height: "25px" }}
                          onChange={(e) => handleDateChange(e.target.value)}
                        />
                      </div>
                      {filteredCheckInOut?.map((item, index) => (
                        <ul key={index}>
                          <li>
                            <span>CheckIn:</span>{" "}
                            {item?.checkIn ? manageTime(item?.checkIn) : ""}
                          </li>
                          <li>
                            <span>CheckOut:</span>{" "}
                            {item?.checkOut ? manageTime(item?.checkOut) : ""}
                          </li>
                        </ul>
                      ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Documents"
                    role="tabpanel"
                    aria-labelledby="Documents-tab"
                  >
                    <div className="detail-box">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Sr No.</th>
                            <th scope="col">Date</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Type</th>
                            <th scope="col">Payment Detail</th>
                          </tr>
                        </thead>
                        <tbody>
                          {expenseDetail?.expenseData?.map((item, index) => {
                            const correspondingTransaction =
                              expenseDetail?.transactionData?.[index];

                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {moment(
                                    correspondingTransaction?.date
                                  ).format("DD/MM/YYYY")}
                                </td>
                                <td>{correspondingTransaction?.netAmount}</td>
                                <td>{correspondingTransaction?.transactionType}</td>
                                <td>
                                  {correspondingTransaction?.paymentDetails}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="Salary"
                    role="tabpanel"
                    aria-labelledby="Salary-tab"
                  >
                    <Form onSubmit={handleSubmit}>
                      <div className={"row"}>
                        <div className={"col-md-3 mb-3"}>
                          <InputBox title={"Employee"}>
                            <select
                              style={{ height: "45px" }}
                              className="form-control"
                              value={customerId}
                              onChange={(e) => setCustomerId(e.target.value)}
                              required="true"
                            >
                              <option value="">Select Employee</option>
                              {allList.map((bank) => (
                                <option key={bank._id} value={bank._id}>
                                  {bank.name}
                                </option>
                              ))}
                            </select>
                          </InputBox>
                        </div>
                        <div className={"col-md-3 mb-3"}>
                          <InputBox title={"Select Date"}>
                            <input
                              type="month"
                              label="Select Date"
                              value={handleDate}
                              className="form-control"
                              style={{ height: "45px" }}
                              onChange={(e) => setHandleDate(e.target.value)}
                            />
                          </InputBox>
                        </div>
                      </div>
                      <div className={"row"}>
                        {inputTypes.lowerFields.map((item, key) => {
                          return !item.hidden ? (
                            <div
                              className={`pb-3 ${
                                item.span ? item.span : "col-md-6"
                              }`}
                              key={key}
                            >
                              <GetEachFormFields {...props.form} item={item} />
                            </div>
                          ) : null;
                        })}
                      </div>
                      <button type="submit" className="btn btn-danger mt-4">
                        Submit
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageHeader>
  );
}
export default Form.create()(EmployeeProfile);
