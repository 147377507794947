import React, {Component} from 'react'

class InputComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        let {title = '', className = '', extra, labelCls = '', children} = this.props;
        return (
            <div className={`row ${className}`}>
                <div className={'labelRow col-md-12'}>
                    {title ? <React.Fragment>
                        <label className={`form-label ${labelCls}`}>{title} : </label>
                        {extra ? <div className={'extra'}>{extra}</div> : ''}
                    </React.Fragment> : ''}
                </div>
                <div className={'col-md-12'}>
                    <div className={`innerBox`}>
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}

export default InputComponent

