import React from "react"
import {Navigate, Outlet} from "react-router-dom";

const image2 = require('../../components/assets/images/logo.png')

const isLoggedIn = localStorage.getItem("token") ? true : false;

const Layout = (props) => {
    return isLoggedIn ? (
        <Navigate replace to="/dashboard"/>
    ) : (
        <>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-center auth">
                        <div className="row flex-grow">
                            <div className="col-lg-4 mx-auto">
                                <div className="auth-form-light text-left p-5">
                                    <div className="brand-logo">
                                        <img src={image2}/>
                                    </div>
                                    <Outlet/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Layout
