import React, {useState, useEffect} from "react";
import {
    Modal,
    Form, HyperLink, notification,
} from "../../components/Elements/appUtils";
import {showLoader, hideLoader} from "../../actions/loader";
import {getToken} from "../../request";
import axios from "axios";
import {useDispatch} from "react-redux";
import {getSinglePatientURl} from "./api";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {
    Countries,
    gender, getCityByState,
    getStateByCountry,
} from "../../components/_utils/appUtils";
import {leadSourceOption} from "../../components/_utils/_utils";
import moment from "moment";
import {updatePatientsFxn} from "./actions";

const EditPatientModal = (props) => {
    let {
        patientId,
        visible,
        onClose,
    } = props;
    const {getFieldValue} = props.form;
    const dispatch = useDispatch();
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const fetchPatientData = async () => {
        try {
            let config = {
                params: {patientId},
                ...(await getToken()),
            };
            dispatch(showLoader());
            if (patientId) {
                const response = await axios.get(getSinglePatientURl(), config);
                if (response.data.data) {
                    props.form.setFieldsValue({
                        name: response.data.data && response.data.data.name,
                        email: response.data.data && response.data.data.email,
                        mobile: response.data.data && response.data.data.mobile,
                        address: response.data.data && response.data.data.address,
                        gender: response.data.data && response.data.data.gender,
                        age: response.data.data && response.data.data.age,
                        country: response.data.data && response.data.data.country,
                        state: response.data.data && response.data.data.state,
                        city: response.data.data && response.data.data.city,
                        pincode: response.data.data && response.data.data.pincode,
                        sourceMedium: response.data.data && response.data.data.sourceMedium,
                        dateOfBirth: response.data.data && moment(response.data.data.dateOfBirth),
                    });
                    dispatch(hideLoader());
                }
            } else {
                dispatch(hideLoader());
            }
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };


    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData._id = patientId
                let x = await dispatch(updatePatientsFxn(valData));
                if (x && x.success) {
                    console.log(x, "valdata")
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };


    useEffect(() => {
        if (visible) {
            fetchPatientData();
        }
    }, [visible && patientId]);

    const events = {
        getState: (countryName) => {
            let resp = getStateByCountry(countryName);
            setStateList(resp);
        },
        getCity: (stateName) => {
            let resp = getCityByState(stateName);
            setCityList(resp);
        },
    };

    let inputTypes = {
        upperFields: [
            {
                key: "mobile",
                type: "String",
                label: "Mobile No",
                span: "col-md-3",
                placeholder: "Mobile No",
            },
            {
                key: "name",
                label: "Name",
                span: "col-md-3",
                placeholder: "Type here..",
                required: true
            },
            {
                key: "gender",
                label: "Gender",
                span: "col-md-3",
                type: "select",
                options: gender,
                showSearch: true,
                keyAccessor: (x) => x.value,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        gender: x,
                    });
                },
            },
            {
                key: "dateOfBirth",
                label: "Date of Birth",
                showStar: true,
                placeholder: "Date of Birth",
                type: "date",
                span: "col-md-3",
                onChange: (x) => {
                    const dob = new Date(x);
                    const today = new Date();

                    if (dob > today) {
                        notification.warning({
                            message: "Please Select Valid Date Of Birth",
                        });
                    } else {
                        const age = Math.floor(
                            (today - dob) / (365.25 * 24 * 60 * 60 * 1000)
                        );

                        props.form.setFieldsValue({
                            age: age,
                            dateOfBirth: x,
                        });
                    }
                },
            },
            {
                key: "age",
                label: "Age(In Years)",
                span: "col-md-3",
                placeholder: "Enter your Age",
            },
            {
                key: "country",
                label: "Country *",
                span: "col-md-3",
                placeholder: "Country",
                type: "select",
                options: Countries,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        country: x,
                        state: "",
                    });
                    events.getState(x);
                },
            },
            {
                key: "state",
                label: "State",
                span: "col-md-3",
                placeholder: "State",
                type: "select",
                options: stateList,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        state: x,
                        city: "",
                    });
                    events.getCity(x);
                },
            },
            {
                key: "city",
                label: "City",
                span: "col-md-3",
                placeholder: "City",
                type: "select",
                options: [...cityList, {name: 'other'}],
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        city: x,
                    });
                },
                hidden: getFieldValue("country") !== "India",
            },

            {
                key: "notFoundCity",
                label: "Other",
                span: "col-md-3",
                placeholder: "Other city",
                type: "text",
                hidden: getFieldValue('city') !== 'other'
            },
            {
                key: "city",
                label: "City",
                span: "col-md-3",
                placeholder: "City",
                hidden: getFieldValue("country") === "India",
            },
            {
                key: "pincode",
                label: "Pincode",
                span: "col-md-3",
                placeholder: "Pincode",
            },
            {
                key: "email",
                label: "Email",
                span: "col-md-3",
                placeholder: "Type here.. ",
            },
            {
                key: "sourceMedium",
                label: "Source Medium",
                span: "col-md-3",
                placeholder: "Please Choose",
                type: "select",
                options: leadSourceOption,
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        sourceMedium: x,
                    });
                },
            },
            {
                key: "address",
                label: "Address ",
                span: "col-md-6",
                type: "textarea",
                placeholder: "Address",
            },
        ],
    };


    return (
        <>
            <Modal
                title={
                    <div className={'d-flex justify-content-between'}>
                        <div>Edit Patient</div>
                    </div>
                }
                visible={visible}
                onClose={onClose}
                width={"70%"}
            >
                <Form onSubmit={handleSubmit}>
                    <div className="form-elements-wrapper">
                        <div className={"row"}>
                            {inputTypes.upperFields.map((item, key) => {
                                return !item.hidden ? (
                                    <div
                                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                        key={key}
                                    >
                                        <GetEachFormFields {...props.form} item={item}/>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </div>
                    <div className={'mt-2'}>
                        <button type="submit" className="btn btn-gradient-info me-2 mt">
                            Submit
                        </button>
                    </div>
                </Form>

            </Modal>
        </>
    );
};
export default Form.create()(EditPatientModal);
