import {customAxios as axios, getToken} from "../../../request";
import { showLoader, hideLoader } from "../../../actions/loader";
import {getUpdatedServicePriceListUrl} from "../apis";


export const getUpdatedServicePriceCheckList = async (valData) => {
    showLoader()
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getUpdatedServicePriceListUrl(), config);
    hideLoader()
    return data;
};