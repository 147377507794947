import React, { useState, useRef } from "react";
import { InputBox, Form, Table } from "../../components/Elements/appUtils";
import { addGroupFxn, fetchGroup } from "./actions/index";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
// import { groupType } from "../../components/_utils/appUtils";

function AddGroup() {
  let tableRef = useRef();
  let dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: ""
  });

  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleS = async (e) => {
    e.preventDefault();
    const { success } = await dispatch(addGroupFxn(formData));
    if (success) {
      setFormData({ name: "" });
      tableRef.current.reload();
    }
  };

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await fetchGroup({ ...params });
        resolve(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const columns = [
    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
    },
  ];

  return (
    <PageHeader title={"Customer Groups"}>
      <Form>
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div className="col-lg-12 row">
                <div className="col-3">
                  <InputBox title={"Group name"}>
                    <input
                      type="text"
                      className={"form-control"}
                      placeholder="Type here..."
                      value={formData.name}
                      onChange={(e) => handleChange(e, "name")}
                      required
                    />
                    {errorMessage && (
                      <div className="error-message">{errorMessage}</div>
                    )}
                  </InputBox>
                </div>
                {/* <div className="col-3">
                  <InputBox title={"Type"}>
                    <select
                      style={{ height: "45px" }}
                      className="form-control"
                      value={formData.type}
                      onChange={(e) => handleChange(e, "type")}
                      required
                    >
                      <option value="">Select</option>
                      {groupType.map((type) => (
                        <option  key={type.type} value={type.type}>
                          {type.type}
                        </option>
                      ))}
                    </select>
                  </InputBox>
                </div> */}
              </div>
            </div>

            <button
              onClick={handleS}
              className="btn btn-gradient-success me-2 mt-2"
            >
              SUBMIT DETAILS
            </button>
          </div>
        </div>
      </Form>
      <div className="card mt-2">
        <div className="card-body">
          <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
        </div>
      </div>
    </PageHeader>
  );
}

export default AddGroup;
