import React, { useState, useRef, useEffect } from "react";
import { customAxios as axios, getToken } from "../../request";
import {
  InputBox,
  Form,
  Table,
  notification,
  Tooltip,
  Popconfirm,
} from "../../components/Elements/appUtils";
import AddFollowup from "./addFollowup";
import { checkInUrl, checkOutUrl } from "./api";
import moment from "moment";
import { fetchAllLeadEmp } from "./action";
import PageHeader from "../../components/Elements/pageHeader";
import { useNavigate } from "react-router-dom";
import FollowUp from "./followUp";
import { DefaultTablePagination } from "../../components/Elements/appUtils";
import {fetchSaleCategory} from "../category/actions";
function LeadList() {
  let tableRef = useRef();
  const [allList, setAllList] = useState([]);
  const [filteredLead, setFilteredLead] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchMobile, setSearchMobile] = useState("");
  const [searchState, setSearchState] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [saleCategoryId, setSaleCategoryId] = useState("");

  let [dates, setDates] = useState({
    fromDate: "",
    toDate: "",
  });
  let [followupState, setFollowUpState] = useState({
    visible: false,
    lead: {},
  });
  let [followupList, setFollowUpList] = useState({
    visible: false,
    lead: {},
  });
  const navigate = useNavigate();
  // const apiRequest = (params) => {
  //   return new Promise(async (resolve) => {
  //     const data = await fetchAllLeadEmp({...params});
  //       setAllList(data.data)
  //       setFilteredLead(data.data)
  //       console.log(data.data, "alllis")

  //     resolve(data)
  //   });
  // };
  const [saleCategory, setSaleCategory] = useState([]);
  const fetchSaleFrom = async () => {
    const {data} = await fetchSaleCategory({});
    setSaleCategory(data);
  };
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        if (searchName) {
          params.name = searchName;
        }

        if (searchMobile) {
          params.mobile = searchMobile;
        }
        if (searchState) {
          params.state = searchState;
        }
        if (searchCity) {
          params.city = searchCity;
        }
        if (searchStatus) {
          params.status = searchStatus;
        }
        if(saleCategoryId)
          params.userName=saleCategoryId;

        if (dates.fromDate && dates.toDate) {
          const fromDateStartOfDay = new Date(dates.fromDate);
          fromDateStartOfDay.setHours(0, 0, 0, 0);
  
          const toDateEndOfDay = new Date(dates.toDate);
          toDateEndOfDay.setHours(23, 59, 59, 999);
    
          params.date = {
            $gte: new Date(fromDateStartOfDay),
            $lte: new Date(toDateEndOfDay),
          };
        }

        const data = await fetchAllLeadEmp({
          ...params,
          regExFilters: ["name", "mobile", "state", "city"],
        });

        resolve(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  console.log(allList, "alllist");

  useEffect(()=>{
    fetchSaleFrom();
  },[])
  const events = {
    showFollowUp: (lead) => {
      setFollowUpState({
        visible: true,
        lead: lead,
      });
    },
    hideFollowUp: () => {
      setFollowUpState({
        visible: false,
        lead: {},
      });
      events.reloadTable();
    },

    showFollowUpList: (lead) => {
      setFollowUpList({
        visible: true,
        lead: lead,
      });
    },
    hideFollowUpList: () => {
      setFollowUpList({
        visible: false,
        lead: {},
      });
      events.reloadTable();
    },

    reloadTable: () => {
      tableRef.current.reload();
    },
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },

    {
      title: "Lead Source",
      dataIndex: "leadSource",
      key: "leadSource",
    },
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      render: (text, record) => (
          <>
            {text && text.name ? text.name : null}
          </>
      ),
    },
    {
      title: "Lead Date",
      dataIndex: "date",
      key: "date",
      render: (text, record, index) => <span>{moment(record.date).format("DD-MMM-YY")}</span>
      ,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render : (v,text) => {
        if(text.status==='follow up')
        return <div style={{color:'orange'}}>{text.status}</div>
        else if (text.status==='pending')
          return <div style={{color:'red'}}>{text.status}</div>
        else
          return <div style={{color:'green'}}>{text.status}</div>
      }
    },
    {
      title: "Follow ups",
      dataIndex: "followUps",
      key: "followUps",
      render: (v, item) => {
        return (
          <>
            <a
              className={"btn btn-link btn-xs mr-1"}
              onClick={() => events.showFollowUp(item)}
            >
              Add
            </a>
            <a
              className={"btn btn-link btn-xs mr-1"}
              onClick={() => events.showFollowUpList(item)}
            >
              View
            </a>
            <a
              onClick={() => {
                navigate(`/edit-lead?_id=${item._id}`);
              }}
            >
              <img src={"./edit.svg"} />
            </a>
            <a
                className={"btn btn-link btn-xs mr-1"}
                onClick={() => {
                  navigate(`/add-patient?_id=${item._id}&&name=${item.name}&&state=${item.state}&&mobile=${item.mobile}`);
                }}
                style={{fontSize:"20px"}}
            >
             +
            </a>
          </>
        );
      },
    },
  ];

  const handleFilter = async () => {
    if (tableRef.current) {
      tableRef.current.reload();
    }
  };
  const handleClear = () => {

    setSearchStatus("");setSearchCity("");setSearchState("");setSearchMobile("");setSearchName("");
    setSaleCategoryId("");
    setDates({
      fromDate: "",
      toDate: "",
    })
    if(tableRef.current.reload) {
      tableRef.current.reload()
    }

  };

  return (
    <PageHeader
      title={"Lead List"}
      extraLink={[
        {
          name: "Add Lead",
          link: "/add-lead",
        },
      ]}
    >
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div className="mb-3 row">
                <div
                  className="col-md-2 mb-2 pe-0"
                  style={{ paddingLeft: "7px" }}
                >
                  <label htmlFor="searchName" className="form-label" />

                  <input
                    type="text"
                    className="form-control search-filters"
                    id="searchName"
                    placeholder="Search by Name"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                  />
                </div>
                <div
                  className="col-md-2 mb-2 pe-0"
                  style={{ paddingLeft: "7px" }}
                >
                  <label htmlFor="searchMobile" className="form-label" />
                  <input
                    type="text"
                    className="form-control search-filters"
                    id="searchMobile"
                    placeholder="Search by Mobile"
                    value={searchMobile}
                    onChange={(e) => setSearchMobile(e.target.value)}
                  />
                </div>

                <div
                  className="col-md-2 mb-2 pe-0"
                  style={{ paddingLeft: "7px" }}
                >
                  <label htmlFor="searchState" className="form-label" />
                  <input
                    type="text"
                    className="form-control search-filters"
                    id="searchState"
                    placeholder="Search by State"
                    value={searchState}
                    onChange={(e) => setSearchState(e.target.value)}
                  />
                </div>
                <div
                    className="col-md-2 mb-2 pe-0"
                  style={{ paddingLeft: "7px" }}
                >
                  <label htmlFor="searchCity" className="form-label" />
                  <input
                    type="text"
                    className="form-control search-filters"
                    id="searchCity"
                    placeholder="Search by City"
                    value={searchCity}
                    onChange={(e) => setSearchCity(e.target.value)}
                  />
                </div>
                <div     className="col-md-2 pe-0" style={{marginTop: "1.5%"}}>
             <select
               className="form-control search-filters"
                placeholder="Search by Type"
                style={{ height: "45px" }}
                onChange={(e) => setSearchStatus(e.target.value)}
              >
                <option value="">Select Type</option>
                <option value="pending">Pending</option>
                <option value="success">Success</option>
          
              </select>
              </div>
              
                <div     className="col-md-2 mb-2 pe-0">
              <label htmlFor="searchCategory" className="form-label" />
              <input
                type="date"
                className="form-control search-filters"
                name="name"
                value={dates.fromDate}
                onChange={(e) =>
                  setDates({ ...dates, fromDate: e.target.value })
                }
              />
            </div>
            <div     className="col-md-2 mb-2 pe-0">
              <label htmlFor="searchCategory" className="form-label" />
              <input
                type="date"
                className="form-control search-filters"
                name="name"
                value={dates.toDate}
                onChange={(e) => setDates({ ...dates, toDate: e.target.value })}
              />
            </div>
                <div className="col-md-2 mb-2 pe-0">
                  <InputBox>
                    <select
                        className="form-control search-filters"
                        onChange={({ target: { value } }) => {setSaleCategoryId(value); console.log(value)}}
                        value={saleCategoryId}
                        required
                        style={{height:"46px" , textAlign:"left" , paddingLeft:"21px" , marginTop:"10px" ,fontSize:'14px'}}
                    >
                      <option value="" disabled selected>
                        Select User Name
                      </option>
                      {saleCategory.map((category) => (
                          <option key={category._id} value={category._id}>
                            {category.name}
                          </option>
                      ))}
                    </select>
                  </InputBox>
                </div>


              </div>
              <div className="mb-3 search-buttons">
                <div className="search-wrap">
                  <button className="btn btn-info" onClick={handleFilter}>
                    Search
                  </button>
                </div>
                <div className="search-wrap">
                  <button className="btn btn-secondary" onClick={handleClear}>
                    Clear
                  </button>
                </div>
              </div>
              {/* {showFilter ? (
              <Table
              dataSource={allList}
              columns={columns}
              ref={tableRef}
              key={`table-${showFilter ? 'filtered' : 'original'}`}
            />
            
            ) : (
              <Table
              apiRequest={apiRequest}
              columns={columns}
              ref={tableRef}
              pagination={DefaultTablePagination()}
            />
            )} */}
              <Table
                apiRequest={apiRequest}
                columns={columns}
                ref={tableRef}
                pagination={DefaultTablePagination()}
              />
              {followupState.visible ? (
                <AddFollowup
                  onClose={events.hideFollowUp}
                  onSubmit={() => {
                    events.hideFollowUp();
                    events.reloadTable();
                  }}
                  visible={followupState.visible}
                  lead={followupState.lead}
                />
              ) : null}
              {followupList.visible ? (
                <FollowUp
                  onClose={events.hideFollowUpList}
                  onSubmit={() => {
                    events.hideFollowUpList();
                    events.reloadTable();
                  }}
                  visible={followupList.visible}
                  lead={followupList.lead}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </PageHeader>
  );
}

export default LeadList;
