import {
    getSaleUrl,
    addSaleUrl,
    editSaleUrl,
    getCustomerDataUrl,
    getbatchRecordByProductIdUrl,
    invoiceUpdateUrl,
    addCreditNoteUrl,
    getCreditNoteUrl, addDebitNoteUrl, getDebitNoteUrl, getUpdateStatusUrl, editDebitUrl, getCompanyDataUrl,
} from "../api";
import { customAxios as axios, getToken } from "../../../request";
import { hideLoader, showLoader } from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";

import { addStockRequestUrl, approveRequestURl,getPurchaseUrl,fetchRequestedProductReport } from "../api";

export const fetchCustomer = async (valData) => {
    let config = {
        params: { ...valData },
        ...getToken(),
    };
    const { data } = await axios.get(getCustomerDataUrl(),  config);

    return data;
};

export const fetchBatchByProductId = async (productId) => {
    let config = {
        params: { productId },
    };
    const { data } = await axios.get(getbatchRecordByProductIdUrl(), config);
    return data;
};

export const fetchCreditNote = async (valData) => {
    let config = {
        params: { ...valData },
        ...(await getToken()),
    };
    const { data } = await axios.get(getCreditNoteUrl(), config);
    return data;
};
export const fetchDebitNote = async (valData) => {
    let config = {
        params: { ...valData },
        ...(await getToken()),
    };
    const { data } = await axios.get(getDebitNoteUrl(), config);
    return data;
};
export const updateDebitStatus = async (valData) => {
    const {data}  = await axios.post(getUpdateStatusUrl(), valData, getToken());
    return data;
};

export const fetchCreditNoteByCustomerId = async (valData) => {
    let config = {
        params: { customerId:valData },
        ...(await getToken()),
    };
    const { data } = await axios.get(getCreditNoteUrl(), config);
    return data;
};

export const invoiceUpdateFxn = async (valData) => {
    let config = {
        params: { ...valData },
        ...(await getToken()),
    };
    const { data } = await axios.get(invoiceUpdateUrl(), config);
    return data;
};

export const addDebitNoteFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    const { data } = await axios.post(addDebitNoteUrl(), valData, getToken());
    dispatch(hideLoader());
    if (!data.error) {
    } else {
        notification.error(data.message || "Error");
    }
    return data;
};
export const editDebitNoteFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    const { data } = await axios.post(editDebitUrl(), valData, getToken());
    dispatch(hideLoader());
    if (!data.error) {
    } else {
        notification.error(data.message || "Error");
    }
    return data;
};

export const editSaleFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    const { data } = await axios.post(editSaleUrl(), valData, getToken());
    dispatch(hideLoader());
    if (!data.error) {
    } else {
        notification.error(data.message || "Error");
    }
    return data;
};


export const fetchRequestedProductReportFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...getToken(),
    }
    console.log(config,"thsi si confic");
    const {data} = await axios.get(fetchRequestedProductReport(),config);
    return data;
};

export const fetchPurchase = async (valData) => {
    let config = {
        params: {...valData}
    }
    const {data} = await axios.get(getPurchaseUrl(),getToken(),config);
    return data;
};

export const approveReqFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    }
    // console.log(config,"thisis config");
    const {data} = await axios.post(approveRequestURl(),config);
    return data;
};

export const addStockRequestFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addStockRequestUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        // notification.success(data.message || "Success")
    } else {
        notification.error(data.message || "Error")
    }
    return data;
};

export const stockRequest = async (valData) => {
    let config = {
        params: {...valData}
    }
    const {data} = await axios.get(getPurchaseUrl(),getToken(),config);
    return data;
};

export const fetchCompany = async () => {
    const { data } = await axios.get(getCompanyDataUrl(),getToken() );
    return data;
};