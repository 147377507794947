import React, {useEffect, useState} from "react";
import {
    Form,
    HyperLink,
    notification,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {singleProductFxn, updateProductFxn} from "./actions";
import {useNavigate} from "react-router-dom";
import {types, UnitCodeArr, gstRate} from "../../setting";
import {FetchBrand, getCategory} from "./actions";
import {dosage} from "../../components/_utils/_utils";
import {fetchFrequencyDetails, fetchTakenDetails} from "../patients/components/action";

const EditProduct = (props) => {
    const {getFieldValue, setFieldsValue} = props.form;
    let dispatch = useDispatch();

    const [brands, setBrands] = useState([]);
    const [category, setCategory] = useState([]);
    const [takenDetails, SetTakenDetails] = useState([]);
    const [frequencyDetails, SetFrequencyDetails] = useState([]);


    const fetchBrand = (params) => {
        return new Promise(async (resolve) => {
            try {
                const data = await FetchBrand({...params});
                resolve(data);
                setBrands(data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const fetchCategory = (params) => {
        return new Promise(async (resolve) => {
            try {
                const data = await getCategory({...params});
                resolve(data);
                setCategory(data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };


    const fetchTaken = (params) => {
        return new Promise(async (resolve) => {
            const data = await fetchTakenDetails({...params});
            SetTakenDetails(data.data);
        });
    };


    const fetchFrequency = (params) => {
        return new Promise(async (resolve) => {
            const data = await fetchFrequencyDetails({...params});
            SetFrequencyDetails(data.data);
        });
    };


    useEffect(() => {
        props.form.setFieldsValue({
            type: "Product",
        });
        props.form.setFieldsValue({
            gst: 18,
        });

        fetchBrand();
        fetchCategory();
        fetchTaken();
        fetchFrequency()
    }, []);

    const [productId, setProductId] = useState("");
    useEffect(() => {
        checkProductId();
    }, []);

    const checkProductId = async () => {
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("_id");
        if (id) {
            getSingleProduct(id);
            setProductId(id);
        }
    };
    const getSingleProduct = async (id) => {
        let {data, success} = await dispatch(singleProductFxn(id));
        console.log(data, "valdata")
        if (success) {
            setTimeout(() => {
                props.form.setFieldsValue({
                    type: data.type,
                    description: data.description,
                    mrp: data.mrp,
                    name: data.name,
                    stock: data.stock,
                    hsnCode: data.hsnCode,
                    code: data.code,
                    gst: data.gst,
                    packing: data.packing,
                    weight: data.weight,
                    unitCode: data.unitCode,
                    brandId: data.brandId,
                    categoryId: data.categoryId,
                    dosage: data.dosage,
                    frequencyDetails: data.frequencyDetails,
                    takenDetails: data.takenDetails
                });


            }, 300);


        }
    };
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {

            if (!err) {
                if (!valData.type) {
                    notification.warning({message: "choose Type"});
                    return;
                }
                if (!valData.name) {
                    notification.warning({message: "Enter Name"});
                    return;
                }
                if (!valData.brandId && getFieldValue("type") === "Product") {
                    notification.warning({message: "Choose Brand"});
                    return;
                }
                if (!valData.categoryId && getFieldValue("type") === "Product") {
                    notification.warning({message: "Choose Category"});
                    return;
                }
                if (!valData.code) {
                    notification.warning({message: "Enter Code"});
                    return;
                }
                if (!valData.hsnCode) {
                    notification.warning({message: "Enter Hsn Code"});
                    return;
                }
                if (!valData.unitCode && getFieldValue("type") === "Product") {
                    notification.warning({message: "Choose Unit"});
                    return;
                }
                if (!valData.packing && getFieldValue("type") === "Product") {
                    notification.warning({message: "Enter Packing Details"});
                    return;
                }
                if (!valData.gst) {
                    notification.warning({message: "Choose GST"});
                    return;
                }

                valData._id = productId

                let x = await dispatch(updateProductFxn(valData));
                if (x && x.success) {
                    console.log(x, "valdata")
                }
            }
        });
    };


    let inputTypes = {
        fields: [
            {
                key: "type",
                label: "Type *",
                span: "col-md-4",
                placeholder: "Type",
                type: "select",
                options: types,
                showSearch: true,
                keyAccessor: (x) => x.type,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        type: x,
                    });
                },
                disabled: true,

            },

            {
                key: "name",
                label: "Name *",
                span: "col-md-4",
                placeholder: "Name",
            },
            {
                key: "brandId",
                label: "Select Brand",
                span: "col-md-4",
                placeholder: "Brands",
                type: "select",
                options: brands,
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.brand_Name
                }`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        brandId: x,
                    });
                },
                hidden: getFieldValue("type") === "Service",
            },
            {
                key: "categoryId",
                label: "Select Category *",
                span: "col-md-4",
                placeholder: "Categories",
                type: "select",
                options: category,
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.categoryName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        categoryId: x,
                    });
                },
                hidden: getFieldValue("type") === "Service",
            },
            {
                key: "code",
                label: "Code *",
                type: "text",
                span: "col-md-4",
                placeholder: "Product Code",
            },
            {
                key: "hsnCode",
                label: "HSN Code *",
                type: "text",
                span: "col-md-4",
                placeholder: "HSN Code",
            },
            {
                key: "price",
                label: "Price *",
                type: "number",
                span: "col-md-4",
                placeholder: "Price",
                hidden: getFieldValue("type") === "Product",
            },
            {
                key: "unitCode",
                label: "Unit *",
                span: "col-md-4",
                placeholder: "Unit Code",
                type: "select",
                options: UnitCodeArr,
                showSearch: true,
                keyAccessor: (x) => x.code,
                valueAccessor: (x) => `${x.name} (${x.code})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        unitCode: x,
                    });
                },
                hidden: getFieldValue("type") === "Service",
            },

            {
                key: "weight",
                label: "Weight",
                span: "col-md-4",
                placeholder: "Weight",
                hidden: getFieldValue("type") === "Service",
            },

            {
                key: "packing",
                label: "Packing *",
                span: "col-md-4",
                placeholder: "Packing",
                hidden: getFieldValue("type") === "Service",
                // required: true,
            },

            {
                key: "gst",
                label: "GST Rate *",
                span: "col-md-4",
                placeholder: "GST Rate",
                type: "select",
                options: gstRate,
                showSearch: true,
                keyAccessor: (x) => x.code,
                valueAccessor: (x) => `${x.number}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        gst: x,
                    });
                },
                // required: true,
            },

            {
                key: "dosage",
                label: "Dosage",
                span: "col-md-4",
                type: "select",
                options: dosage,
                showSearch: true,
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        dosage: x,
                    });
                },
            },

            {
                key: "takenDetails",
                label: "When To Take",
                span: "col-md-4",
                type: "select",
                options: takenDetails,
                showSearch: true,
                keyAccessor: (x) => x.whentotake,
                valueAccessor: (x) => `${x.whentotake}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        takenDetails: x,
                    });
                },
            },

            {
                key: "frequencyDetails",
                label: "Routine/Freq",
                span: "col-md-4",
                type: "select",
                options: frequencyDetails,
                showSearch: true,
                keyAccessor: (x) => x.frequency,
                valueAccessor: (x) => `${x.frequency}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        frequencyDetails: x,
                    });
                },
            },

            {
                key: "description",
                label: "Description",
                span: "col-md-8",
                placeholder: "Description",
                type: "textarea",
            },
        ],
    };

    return (
        <>
            <PageHeader title={"Edit Product"}>
                <div className="card">
                    <div className="card-body">
                        <Form onSubmit={handleSubmit}>
                            <div className="form-elements-wrapper">
                                <div className={"row"}>
                                    {inputTypes.fields.map((item, key) => {
                                        return !item.hidden ? (
                                            <div
                                                className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                                key={key}
                                            >
                                                <GetEachFormFields {...props.form} item={item}/>
                                            </div>
                                        ) : null;
                                    })}
                                </div>
                            </div>

                            <button type="submit" className="btn btn-gradient-primary">
                                Submit
                            </button>
                            <HyperLink
                                className=" ms-4 btn btn-danger"
                                link={"/product-list"}
                            >
                                Cancel
                            </HyperLink>
                        </Form>
                    </div>
                </div>
            </PageHeader>
        </>
    );
};

export default Form.create()(EditProduct);
