import React, { useEffect, useState } from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { States, gstTypeList } from "../../components/_utils/appUtils";
import { addCompanyFxn } from "../customer/actions";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { singleSymptomFxn, updateSymptomFxn } from "./actions";
import { singleBrandFxn, updateBrandFxn } from "./actions";

const EditBrand = (props) => {
    let {onClose} = props;
  const { getFieldValue,setFieldsValue } = props.form;
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [brandId, setBrandId] = useState("");
  useEffect(() => {
    checkBrandId();
  }, []);

  let id;

  const checkBrandId = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    id = searchParams.get("_id");
    if (id) {
        getSingleBrand(id);
        setBrandId(id);
    }
  };
  const getSingleBrand = async (id) => {
    let { data, success } = await dispatch(singleBrandFxn(id));
    console.log(data, "valdata")
    if (success) {
      setTimeout(() => {
        props.form.setFieldsValue({
            brand_Name: data.brand_Name,
        });
       
         
     
      }, 300);
      
      
    }
  };
 

    const handleSubmit = (e) => {
      const {form} = props;
      e.preventDefault();

      form.validateFields(async (err, valData) => {
          if (!err) {

              valData._id = brandId

              let x = await dispatch(updateBrandFxn(valData));
              if (x && x.success) {
                  console.log(x, "valdata")
                  navigate('/brand')
              }
          } else {
              if (err.name) {
                  notification.warning({
                      message: `Please enter Brand Name`,
                  });
                  return;
              }

            

              notification.warning({
                  message: "Fill All Required Fields",
              });
          }
      });
  };

  let inputTypes = {
    fields: [
        {
            title: "Brand Name",
            dataIndex: "brand_Name",
            key: "brand_Name",
          },
     
     
    ],
  };

  return (
    <>
      <PageHeader title={"Edit Brand"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary me-2">
                Submit
              </button>
              <HyperLink
                className=" ms-4 btn btn-danger"
                link={"/brand"}
              >
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(EditBrand);
