import React, {useState, useRef, useEffect} from "react";
import {customAxios as axios, getToken} from "../../request";
import {InputBox, Form, Table, notification, Tooltip, Popconfirm,} from "../../components/Elements/appUtils";
import {getUpdatedServicePriceCheckList} from "./actions/index";
import PageHeader from "../../components/Elements/pageHeader";
import {addUpdatedServicePriceUrl, deleteUpdatedservicePrice} from "./apis";
import {getPatientServiceDataUrl} from "../products/api";

function AddUpdatedServicePriceAndList() {
    let tableRef = useRef();
    const [formData, setFormData] = useState({
        productId: '',
        price: "",
    });
    const [service, setService] = useState([]);


    const handleSave = async (e) => {
        e.preventDefault();
        if (!formData.productId) {
            notification.warning({
                message: `Please Choose Service`,
            });
            return;
        } if (!formData.price) {
            notification.warning({
                message: `Please Enter Updated Price`,
            });
            return;
        }
        const response = await axios.post(addUpdatedServicePriceUrl(), formData, getToken());
        if (response.data.success) {
            notification.success({
                message: response.data.message || "Success",
            });
            setFormData({
                price: "",
                productId: ''
            });
            tableRef.current.reload();
        } else {
            console.log(response.data.message, "brand data");
            notification.error({message: response.data.message});
        }
    };

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            const data = await getUpdatedServicePriceCheckList({...params});
            console.log(data)
            resolve(data)
        });
    };

    const fetchService = async () => {
        try {
            const response = await axios.get(getPatientServiceDataUrl(), getToken());
            setService(response.data.data);
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    useEffect(() => {
        fetchService()
    }, []);

    const deleteSaleCateogry = async (id) => {
        try {
            const response = await axios.delete(deleteUpdatedservicePrice(id));
            if (response.data.error) {
                notification.error({
                    message: response.data.message,
                });
            } else {
                tableRef.current.reload();
                notification.success({
                    message: response.data.message,
                });
            }
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "productId",
            key: "productId",
            render: (text) => <span>{text?.name}</span>,
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 190,
            render: (val, record) => {
                return (
                    <>
                        <Tooltip title={"Delete"} className="ml-4">
                            <Popconfirm
                                title={"Are your sure, you want to delete Updated Service Price?"}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    deleteSaleCateogry(record._id);
                                }}
                            >
                                <img
                                    className="ms-2"
                                    style={{ height: "30px", width: "30px" }}
                                    src={"./close.svg"}
                                 alt={'no image'}/>
                            </Popconfirm>
                        </Tooltip>
                    </>
                );
            },
        },
    ];


    return (
        <PageHeader title={"Update Service Price"}>
            <Form>
                <div>
                    <div className="card-body">
                        <div className="form-elements-wrapper">
                            <div className="row">
                                <div className="col-lg-4">
                                    <InputBox title={"Services *"} className={"flex2"}>
                                        <select
                                            style={{height: "45px"}}
                                            className="form-control"
                                            value={formData.productId}
                                            onChange={(e) => setFormData({...formData, productId: e.target.value})}
                                            required
                                        >
                                            <option value="">Select Services</option>
                                            {service.map((service) => (
                                                <option key={service._id} value={service._id}>
                                                    {service.name}
                                                </option>
                                            ))}
                                        </select>
                                    </InputBox>
                                </div>

                                <div className="col-lg-4">
                                <InputBox title={"Updated Price *"}>
                                        <input
                                            className={"form-control"}
                                            type="number"
                                            placeholder="Price"
                                            value={formData.price}
                                            onChange={(e) => {
                                                setFormData({...formData, price: e.target.value})
                                            }
                                            }
                                        />
                                    </InputBox>
                                </div>
                            </div>
                        </div>
                        <button onClick={handleSave} className="btn btn-success mb-2">
                            SUBMIT
                        </button>
                    </div>
                </div>
            </Form>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
                        </div>
                    </div>
                </div>
            </div>
        </PageHeader>
    );
}

export default AddUpdatedServicePriceAndList;
