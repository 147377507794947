import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Table,
  Tooltip,
  notification,
  Popconfirm,
  DefaultTablePagination,
} from "../../components/Elements/appUtils";
import _ from "lodash";
import { fetchPurchase } from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import moment from "moment";
import { deletePurchaseReport, getProductReport } from "./api";
import { deletePurchaseFxn } from "./actions";
import { getToken } from "../../request";
import axios from "axios";
import { getItemLocalstorage } from "../../components/_utils/_utils";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../actions/loader";

const ProductReportList = (props) => {
  let { customerDetails, Report, visible, onClose } = props;

  console.log(customerDetails, "customerDetails");
  return (
    <>
      <Modal
        title={"Product List"}
        width={"80%"}
        visible={visible}
        onClose={onClose}
      >
        <div className={"mb50 bordered"}>
          {customerDetails ? (
            <div className="d-inline-block border border-2">
              <div className="item-flex">
                <h5 className="m-3 d-block">
                  Name:{" "}
                  <span className="font-weight-normal ms-2">
                    {customerDetails.customerName}
                  </span>
                </h5>
                <h5 className="m-3 d-block">
                  State:{" "}
                  <span className="font-weight-normal ms-2">
                    {customerDetails.customerState}
                  </span>
                </h5>
                <h5 className="m-3 d-block">
                  Invoice No:{" "}
                  <span className="font-weight-normal ms-2">
                    {customerDetails.customerInvoice}
                  </span>
                </h5>
                <h5 className="m-3 d-block">
                  Number:{" "}
                  <span className="font-weight-normal ms-2">
                    {customerDetails.customerNumber}
                  </span>
                </h5>
                <h5 className="m-3 d-block">
                  Date:{" "}
                  <span className="font-weight-normal ms-2">
                    {moment(customerDetails.purchaseDate).format("DD-MMM-YYYY")}
                  </span>
                </h5>
                {customerDetails.vendorInvoiceDate && (
                  <h5 className="m-3 d-block">
                    Vendor Invoice Date:{" "}
                    <span className="font-weight-normal ms-2">
                      {moment(customerDetails.vendorInvoiceDate).format(
                        "DD-MMM-YYYY"
                      )}
                    </span>
                  </h5>
                )}
              </div>
            </div>
          ) : null}
          {Report && Report.length ? (
            <div
              className="table-wrapper mt-4"
              style={{
                maxHeight: "200px",
                overflowX: "auto",
                overflowY: "auto",
              }}
            >
              <table className="table table-bordered mt-4">
                <thead>
                  <tr>
                    <td>Sr no.</td>
                    <td>Product Name</td>
                    <td>Report Type</td>
                    <td>Mfg Date</td>
                    <td>Exp Date</td>
                    <td>Batch</td>
                    <td>Qty.</td>
                    <td>Pur Price</td>
                    <td>Sale Price</td>
                    <td>Dis Amt.</td>
                    <td>Taxable Amt.</td>
                    {customerDetails.cgstAmount !== 0 &&
                    customerDetails.sgstAmount !== 0 ? (
                      <>
                        <td>CGST Amt.</td>
                        <td>SGST Amt.</td>
                      </>
                    ) : customerDetails.igstAmount !== 0 ? (
                      <td>IGST Amt.</td>
                    ) : (
                      <>
                        <td>CGST Amt.</td>
                        <td>SGST Amt.</td>
                      </>
                    )}
                    <td>Net Amt.</td>
                  </tr>
                </thead>
                <tbody>
                  {Report &&
                    Report.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.productName}</td>
                        <td>{item.reportType}</td>
                        <td>{item.manufacturingDate}</td>
                        <td>{item.expiryDate}</td>
                        <td>{item.batchNo}</td>
                        <td>{item.quantity}</td>
                        {/* <td>Rs. {item.salePrice}</td> */}
                        <td>Rs. {item.price}</td>
                        <td>Rs. {item.salePrice}</td>
                        <td>
                          Rs. {item.discountAmount} ({item.discount}%)
                        </td>
                        <td>Rs. {item.taxableAmount}</td>
                        {customerDetails.igstAmount === 0 ? (
                          <>
                            <td>
                              Rs. {item.cgstAmount} ({item.gst / 2}%)
                            </td>
                            <td>
                              Rs. {item.sgstAmount} ({item.gst / 2}%)
                            </td>
                          </>
                        ) : customerDetails.cgstAmount === 0 && customerDetails.sgstAmount === 0 ? (
                          <td>
                            Rs. {item.igstAmount} ({item.gst}%)
                          </td>
                        ) : (
                          <>
                            <td>
                              Rs. {item.cgstAmount} ({item.gst / 2}%)
                            </td>
                            <td>
                              Rs. {item.sgstAmount} ({item.gst / 2}%)
                            </td>
                          </>
                        )}

                        <td>Rs. {item.netAmount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>

        <div className="Total-section mt-4">
          <div className="row">
            <div className="col-sm-7 mt-1">
              <h6 style={{ fontSize: "13px" }}>Payment Details:</h6>
              <div
                  style={{
                    borderBottom: "1px solid #000",
                    marginTop: "7px",
                    width: "300px",
                  }}
              />
              <div
                style={{
                  borderBottom: "1px solid #000",
                  marginTop: "10px",
                  width: "300px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ fontSize: "13px" }}>PAYMODE</span>
                <span style={{ fontSize: "13px" }}>AMOUNT</span>
              </div>
              <div>
                <div
                  style={{
                    marginTop: "10px",
                    width: "300px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: "13px" }}>
                    {customerDetails.paymentType}
                  </span>
                  <span style={{ fontSize: "13px" }}>
                    {customerDetails.netAmount}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-5 border-design">
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-7">
                      <div style={{ textAlign: "right" }}>
                        <p style={{ fontSize: "13px" }}>
                          <b>Taxable Amt :</b>
                        </p>
                        {customerDetails.cgstAmount !== 0 &&
                        customerDetails.sgstAmount !== 0 ? (
                          <>
                            <p style={{ fontSize: "13px" }}>
                              <b>CGST Total :</b>{" "}
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <b>SGST Total :</b>{" "}
                            </p>
                          </>
                        ) : (
                          <p style={{ fontSize: "13px" }}>
                            <b>IGST Total : </b>
                          </p>
                        )}
                        <p style={{ fontSize: "13px" }}>
                          <b>Total Amt :</b>
                        </p>
                        <div
                          style={{
                            borderBottom: "1px solid #000",
                            marginTop: "7px",
                            width: "185%",
                            marginLeft: "10px",
                            textAlign: "right",
                          }}
                        />
                        <p style={{ fontSize: "13px" }}>
                          <b>Paid Amt :</b>
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div style={{ textAlign: "right" }}>
                        <p style={{ fontSize: "13px" }}>
                          {" "}
                          ₹{" "}
                          {parseFloat(customerDetails.taxableAmount).toFixed(2)}
                        </p>
                        {customerDetails.cgstAmount !== 0 &&
                        customerDetails.sgstAmount !== 0 ? (
                          <>
                            <p style={{ fontSize: "13px" }}>
                              {" "}
                              ₹{" "}
                              {parseFloat(customerDetails.cgstAmount).toFixed(
                                2
                              )}
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              {" "}
                              ₹{" "}
                              {parseFloat(customerDetails.sgstAmount).toFixed(
                                2
                              )}
                            </p>
                          </>
                        ) : (
                          <p style={{ fontSize: "13px" }}>
                            {" "}
                            ₹{" "}
                            {parseFloat(customerDetails.igstAmount).toFixed(2)}
                          </p>
                        )}
                        <p style={{ fontSize: "13px" }}>
                          ₹ {parseFloat(customerDetails.gstAmount).toFixed(2)}
                        </p>
                        <p style={{ fontSize: "13px" }}>
                          ₹ {customerDetails.netAmount}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

function Purchase() {
  const userType = getItemLocalstorage("user")["userType"];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purchaseId, setPurchaseId] = useState(null);
  console.log(purchaseId, "thsi is purchase id");
  const [Report, setReport] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({
    customerName: "",
    customerState: "",
    customerInvoice: "",
    customerNumber: "",
    purchaseDate: "",
    taxableAmount: "",
    sgstAmount: "",
    cgstAmount: "",
    igstAmount: "",
    gstAmount: "",
    totalAmount: "",
    paidAmount: "",
    netAmount: "",
  });
  const [searchVendor, setSearchVendor] = useState("");
  const [searchInvoiceNo, setSearchInvoiceNo] = useState("");
  const [searchPurchaseDate, setSearchPurchaseDate] = useState("");
  const [searchState, setSearchState] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  let tableRef = useRef();
  let dispatch = useDispatch();

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await fetchPurchase({
          ...params,
        });
        setCustomerData(data.data);
        setFilteredData(data.data);
        resolve(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const fetchcustomerdata = () => {
    const allrecordofcustomer = customerData.filter(
      (item) => item._id == purchaseId
    );

    allrecordofcustomer.map((i) => {
      console.log(i, "cafpjpj");
      setCustomerDetails({
        customerName: i.customerId?.name,
        customerState: i.customerId?.state,
        customerNumber: i.customerId?.mobile,
        customerInvoice: i.invoiceNumber,
        purchaseDate: i.purchaseDate,
        vendorInvoiceDate: i.vendorInvoiceDate,
        taxableAmount: i.taxableAmount,
        cgstAmount: i.cgstAmount,
        sgstAmount: i.sgstAmount,
        igstAmount: i.igstAmount,
        netAmount: i.netAmount,
        totalAmount: i.totalAmount,
        gstAmount: i.gstAmount,
        paymentType: i.paymentType,
      });
      return;
    });
  };

  const fetchProductReport = async () => {
    try {
      let config = {
        params: { purchaseId },
        ...(await getToken()),
      };
      dispatch(showLoader());
      const response = await axios.get(getProductReport(), config);
      setReport(response.data.data);
      dispatch(hideLoader());
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  const deletePurchase = async (data) => {
    try {
      let config = {
        ...(await getToken()),
      };
      const response = await axios.post(deletePurchaseReport(), data, config);
      console.log(response);
      if (response) {
        tableRef.current.reload();
        notification.success({ message: response.data.message });
        return;
      } else {
        notification.error({ message: response.data.message });
      }
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  const filterPurchases = () => {
    setShowFilter(true);
    if (
      !searchVendor &&
      !searchInvoiceNo &&
      !searchPurchaseDate &&
      !searchState
    ) {
      // setCustomerData([])

      notification.error({ message: "please enter name" });

      setShowFilter(false);
      return;
    }
    const filteredPurchases = customerData.filter(
      (purchase) =>
        purchase.customerId?.name
          .toLowerCase()
          .includes(searchVendor.toLowerCase()) &&
        purchase.invoiceNumber.includes(searchInvoiceNo) &&
        purchase.purchaseDate
          .toLowerCase()
          .includes(searchPurchaseDate.toLowerCase()) &&
        purchase.customerId?.state
          .toLowerCase()
          .includes(searchState.toLowerCase())
    );
    console.log(filteredPurchases, "filteredPurchases");
    setCustomerData(filteredPurchases);

    if (tableRef.current) {
      tableRef.current.reload();
    }
  };

  const clearPurchaseFilters = async () => {
    setSearchVendor("");
    setSearchInvoiceNo("");
    setSearchPurchaseDate("");
    setSearchState("");
    setShowFilter(false);
    setCustomerData(filteredData);

    if (tableRef.current) {
      tableRef.current.reload();
    }
  };

  useEffect(() => {
    apiRequest();
  }, []);

  useEffect(() => {
    fetchProductReport();
    fetchcustomerdata();
  }, [purchaseId]);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Vendor",
      dataIndex: "customerId",
      key: "customerId",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },
    {
      title: "Date of Receiving product",
      dataIndex: "purchaseDate",
      key: "purchaseDate",
      render: (text) => <span>{moment(text).format("DD-MMM-YYYY")}</span>,
    },
    {
      title: "State",
      dataIndex: "customerId",
      key: "customerId",
      render: (text) => <span>{text?.state}</span>,
    },
    {
      title: "Total Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => <span>Rs. {text}</span>,
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (text) => <span>Rs. {text}</span>,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text) => <span>Rs. {text}</span>,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            <Tooltip title={"View"}>
              <a
                className={"empty_btn"}
                onClick={() => {
                  setIsModalOpen(true);
                  setPurchaseId(record._id);
                }}
              >
                <i className={"fa fa-eye far"}></i>
              </a>
            </Tooltip>

            {userType == "company" ? (
              <Tooltip title={"Delete"}>
                <Popconfirm
                  title={"Are your sure, you want to delete purchase?"}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    deletePurchase(record);
                  }}
                >
                  <img src={"./close.svg"} />
                </Popconfirm>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
  ];

  let loadColumn = () => {
    let columnList = [];
    _.each(columns, (item) => {
      if (!item.hidden) {
        columnList.push(item);
      }
    });
    return columnList;
  };

  return (
    <PageHeader
      title={"Purchase"}
      extraLink={
        userType == "branch"
          ? []
          : [
              {
                name: "Add Purchase",
                link: "/add-purchase",
              },
            ]
      }
    >
      <div className="card">
        <div className="card-body">
          <div className="mb-3 row">
            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchVendor" className="form-label" />

              <input
                type="text"
                className="form-control search-filters"
                id="searchVendor"
                placeholder="Search by Vendor"
                value={searchVendor}
                onChange={(e) => setSearchVendor(e.target.value)}
              />
            </div>
            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchInvoiceNo" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchInvoiceNo"
                placeholder="Search by Invoice No."
                value={searchInvoiceNo}
                onChange={(e) => setSearchInvoiceNo(e.target.value)}
              />
            </div>
            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchState" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchState"
                placeholder="Search by State"
                value={searchState}
                onChange={(e) => setSearchState(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-3 search-buttons">
            <div className="search-wrap pe-2">
              <button className="btn btn-info" onClick={filterPurchases}>
                Search
              </button>
            </div>
            <div className="search-wrap">
              <button
                className="btn btn-secondary"
                onClick={clearPurchaseFilters}
              >
                Clear
              </button>
            </div>
          </div>
          {showFilter ? (
            <Table
              dataSource={customerData}
              columns={columns}
              ref={tableRef}
              key={`table-${showFilter ? "filtered" : "original"}`}
              position={'top'}
            />
          ) : (
            <Table
              apiRequest={apiRequest}
              columns={loadColumn()}
              pagination={{ ...DefaultTablePagination() }}
              ref={tableRef}
              position={'top'}
            />
          )}
        </div>
      </div>

      {isModalOpen ? (
        <ProductReportList
          visible={isModalOpen}
          Report={Report}
          onClose={() => {
            setIsModalOpen(false);
            setReport([]);
            setPurchaseId("");
          }}
          customerDetails={customerDetails}
        />
      ) : null}
    </PageHeader>
  );
}

export default Purchase;

