import React, { useEffect, useState } from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { States, gstTypeList } from "../../components/_utils/appUtils";
import {
  singleProductFxn,
  updateProductFxn,
  updateServiceFxn,
} from "./actions";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { types, UnitCodeArr, gstRate } from "../../setting";
import { FetchBrand, getCategory } from "./actions";
const EditService = (props) => {
  const { getFieldValue, setFieldsValue } = props.form;
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);

  const fetchBrand = (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await FetchBrand({ ...params });
        resolve(data);
        setBrands(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const fetchCategory = (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await getCategory({ ...params });
        resolve(data);
        setCategory(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };
  useEffect(() => {
    props.form.setFieldsValue({
      type: "Product",
    });
    props.form.setFieldsValue({
      gst: 18,
    });

    fetchBrand();
  }, []);

  useEffect(() => {
    fetchCategory();
  }, []);

  const [productId, setProductId] = useState("");
  useEffect(() => {
    checkProductId();
  }, []);

  const checkProductId = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      getSingleProduct(id);
      setProductId(id);
    }
  };
  const getSingleProduct = async (id) => {
    let { data, success } = await dispatch(singleProductFxn(id));
    console.log(data, "valdata");
    if (success) {
      setTimeout(() => {
        props.form.setFieldsValue({
          type: data.type,
          description: data.description,
          mrp: data.mrp,
          name: data.name,
          price: data.price,
          hsnCode: data.hsnCode,
          code: data.code,
          gst: data.gst,
          unitCode: data.unitCode,
        });
      }, 300);
    }
  };

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      if (!err) {
        if (!valData.type) {
          notification.warning({ message: "choose Type" });
          return;
        }
        if (!valData.name) {
          notification.warning({ message: "Enter Name" });
          return;
        }

        if (!valData.code) {
          notification.warning({ message: "Enter Code" });
          return;
        }
        if (!valData.hsnCode) {
          notification.warning({ message: "Enter Hsn Code" });
          return;
        }

        valData._id = productId;

        let x = await dispatch(updateServiceFxn(valData));
        if (x && x.success) {
          console.log(x, "valdata");
        }
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "type",
        label: "Type *",
        span: "col-md-6",
        placeholder: "Type",
        type: "select",
        options: types,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            type: x,
          });
        },
        disabled: true,
      },

      {
        key: "name",
        label: "Name *",
        span: "col-md-6",
        placeholder: "Name",
      },
      {
        key: "brandId",
        label: "Select Brand",
        span: "col-md-6",
        placeholder: "Brands",
        type: "select",
        options: brands,
        showSearch: true,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.brand_Name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            brandId: x,
          });
        },
        hidden: getFieldValue("type") === "Service",
      },

      {
        key: "code",
        label: "Code *",
        type: "number",
        span: "col-md-3",
        placeholder: "Product Code",
      },
      {
        key: "hsnCode",
        label: "HSN Code *",
        type: "number",
        span: "col-md-3",
        placeholder: "HSN Code",
      },
      {
        key: "price",
        label: "Price *",
        type: "number",
        span: "col-md-3",
        placeholder: "Price",
        hidden: getFieldValue("type") === "Product",
      },
      {
        key: "unitCode",
        label: "Unit *",
        span: "col-md-3",
        placeholder: "Unit Code",
        type: "select",
        options: UnitCodeArr,
        showSearch: true,
        keyAccessor: (x) => x.code,
        valueAccessor: (x) => `${x.name} (${x.code})`,
        onChange: (x) => {
          props.form.setFieldsValue({
            unitCode: x,
          });
        },
        hidden: getFieldValue("type") === "Service",
      },

      {
        key: "price",
        label: "Price *",
        span: "col-md-3",
        placeholder: "Price",

        // required: true,
      },

      {
        key: "gst",
        label: "GST Rate *",
        span: "col-md-3",
        placeholder: "GST Rate",
        type: "select",
        options: gstRate,
        showSearch: true,
        keyAccessor: (x) => x.code,
        valueAccessor: (x) => `${x.number}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            gst: x,
          });
        },
        // required: true,
      },

      {
        key: "description",
        label: "Description",
        span: "col-md-12",
        placeholder: "Description",
        type: "textarea",
      },
    ],
  };

  return (
    <>
      <PageHeader title={"Edit Service"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary me-2">
                Submit
              </button>
              <HyperLink
                className=" ms-4 btn btn-danger"
                link={"/product-list"}
              >
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(EditService);
