import { apiUrl } from "../../../setting";

export const addDebitNoteUrl = () => {
    return `${apiUrl}/routes/debitNote/addDebitNote`;
};

export const editSaleUrl = () => {
    return `${apiUrl}/routes/sale/editSale`;
};

export const getCreditNoteUrl = () => {
    return `${apiUrl}/routes/creditNote/getAllCreditNote`;
};
export const getDebitNoteUrl = () => {
    return `${apiUrl}/routes/debitNote/getAllDebitNote`;
};
export const getUpdateStatusUrl = () => {
    return `${apiUrl}/routes/debitNote/updateDebitNoteStatus`;
};

export const invoiceUpdateUrl = () => {
    return `${apiUrl}/routes/sale/updateInvoice`;
};

export const getbatchRecordByProductIdUrl = () => {
    return `${apiUrl}/routes/sale/getBatchRecordByProductId`;
}
export const PrintSaleUrl = (id) => {
    return apiUrl + "/routes/sale/printSaleRecord/" + id;
};

export const getBatchForCreditNote = () => {
    return `${apiUrl}/routes/sale/getBatchForCreditNote`;
}
export const getCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/customerList`;
};

export const getStockUrl = () => {
    return `${apiUrl}/routes/product/getStock`;
};

// export const getProductUrl = () => {
//     return `${apiUrl}/routes/product/getCompanyStock`;
// };


export const getProductUrl = () => {
    return `${apiUrl}/routes/product/getProductData`;
};

export const addStockRequestUrl = () => {
    return `${apiUrl}/routes/stock/stockRequest`;
};

export const getPurchaseUrl = () => {
    return `${apiUrl}/routes/stock/getAllStockRequest`;
};


export const getGroupCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/getCustomerDataByGroupId`;
};

export const fetchRequestedProductReport = () => {
    return `${apiUrl}/routes/stock/getStockRequestReport`;
};

export const approveRequestURl = (saleId,requestId) => {
    return `${apiUrl}/routes/stock/getStockRecord/${saleId}/${requestId}`;
};

export const stockReport = (requestId) => {
    return apiUrl + "/routes/stock/getStockRecord/" + requestId;
};

export const rejectStockRequest = (requestId) => {
    return `${apiUrl}/routes/stock/rejectStockRequest/${requestId}`;
};
export const getCompanyDataUrl = () => {
    return `${apiUrl}/routes/customer/companyList`;
};

export const editDebitUrl = () => {
    return `${apiUrl}/routes/debitNote/editDebitNote`;
};
