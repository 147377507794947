import { customAxios as axios, getToken } from "../../../request";
import {getProductReportUrl} from "../api";


export const fetchProductStockFxn = async (valData) => {
    let config = {
        params: { ...valData },
        ...(await getToken()),
    };
    const { data } = await axios.get(getProductReportUrl(), config);
    return data;
};