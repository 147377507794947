import React, {useEffect, useRef, useState} from "react";
import { Table} from "../../components/Elements/appUtils";
import {fetchCompany} from "./actions/index";
import PageHeader from "../../components/Elements/pageHeader";
import {useNavigate} from "react-router-dom";
import { set } from "lodash";
function CompanyData() {
    let tableRef = useRef();
    const navigate = useNavigate();
    const [companyData , setCompanyData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [showFilter, setShowFilter] = useState(false)
    const [searchName, setSearchName] = useState("")
    const [searchEmail, setSearchEmail] = useState("")
    const [searchMobile, setSearchMobile] = useState("")
    const [searchCompanyName, setSearchCompanyName] = useState("")
    const [searchAddress, setSearchAddress] = useState("")
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                const data = await fetchCompany({...params});
                setCompanyData(data.data)
                setFilteredData(data.data)
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    useEffect(() => {
        apiRequest();
    }, []);

    const columns = [
        {
            title: "Name",
            dataIndex: "companyName",
            key: "companyName",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
        },
        {
            title: "Company Name",
            dataIndex: "companyName",
            key: "companyName",
        },

        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            render: (item, record) => {
                return (
                    <>
                        {item}
                     
                        {record.state ? <span>, {record.state}</span> : ""}
                        {record.pincode ? <span> ({record.pincode})</span> : ""}
                    </>
                )
            }
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 190,
            render: (val, record) => {
              return (
                  <>
                      <button className={'btn'} onClick={() => {
                          navigate(`/edit-company?_id=${record._id}`)
                      }}>
                          <img src={"./edit.svg"}/>
      
                      </button>
      
      
                  </>
              );
            }
        }
    ];

    const filterCompany = () => {
        setShowFilter(true)
        if(!searchName && !searchEmail && !searchMobile && !searchCompanyName && !searchAddress){
           setCompanyData([])
        }
        const filteredCompany = companyData?.filter((item) => {
            item.companyName.toLowerCase().includes(searchName.toLowerCase()) &&
            item.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
            item.mobile.toLowerCase().includes(searchMobile.toLowerCase()) &&
            item.companyName.toLowerCase().includes(searchCompanyName.toLowerCase()) &&
            item.address.toLowerCase().includes(searchAddress.toLowerCase())
        
        })
        setCompanyData(filteredCompany)
    }

    const clearCompanyFilters = () => {
        setSearchName("")
        setSearchEmail("")
        setSearchMobile("")
        setSearchCompanyName("")
        setSearchAddress("")
        setShowFilter(false)
        setCompanyData(filteredData)
        if (tableRef.current.reload) {
            tableRef.current.reload()
        }
    }
    return (
        <>
            <PageHeader title={'Company'} extraLink={[{
                name: "Add Company",
                link: "/add-company"
            }]}>
                <div className="card">
                    <div className="card-body">
                    <div className="mb-3 row">
                    <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
                    <label htmlFor="searchName" className="form-label" />
                    <input
                      type="text"
                      className="form-control search-filters"
                      id="searchName"
                      placeholder="Search by Name"
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                    />
                  </div>
                    <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
                    
                      <label htmlFor="searchCompanyName" className="form-label" />
                      <input
                        type="text"
                        className="form-control search-filters"
                        id="searchCompanyName"
                        placeholder="Search by CompanyName"
                        value={searchCompanyName}
                        onChange={(e) => setSearchCompanyName(e.target.value)}
                      />
                    </div>
        
                    <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
                      <label htmlFor="searchEmail" className="form-label" />
                      <input
                        type="text"
                        className="form-control search-filters"
                        id="searchEmail"
                        placeholder="Search by Email"
                        value={searchEmail}
                        onChange={(e) => setSearchEmail(e.target.value)}
                      />
                    </div>
        
                    <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
                      <label htmlFor="searchMobile" className="form-label" />
                      <input
                        type="text"
                        className="form-control search-filters"
                        id="searchMobile"
                        placeholder="Search by Mobile"
                        value={searchMobile}
                        onChange={(e) => setSearchMobile(e.target.value)}
                      />
                    </div>
                    <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
                    <label htmlFor="searchAddress" className="form-label" />
                    <input
                      type="text"
                      className="form-control search-filters"
                      id="searchAddress"
                      placeholder="Search by Address"
                      value={searchAddress}
                      onChange={(e) => setSearchAddress(e.target.value)}
                    />
                  </div>
                  </div>
                
                  <div className="mb-3 search-buttons">
                    <div className="search-wrap pe-2">
                      <button className="btn btn-info" onClick={filterCompany}>
                        Search
                      </button>
                    </div>
                    <div className="search-wrap">
                      <button className="btn btn-secondary" onClick={clearCompanyFilters}>
                        Clear
                      </button>
                    </div>
                  </div>
        
                  {showFilter ? (
                    <Table
                      dataSource={companyData}
                      columns={columns}
                      ref={tableRef}
                      key={`table-${showFilter ? 'filtered' : 'original'}`}
                    />
                  ) : (
                    <Table
                      apiRequest={apiRequest}
                      columns={columns}
                      ref={tableRef}
                    />
                  )}
                    </div>
                </div>
            </PageHeader>
        </>
    );
}

export default CompanyData;
