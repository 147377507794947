import React, {useEffect, useState} from "react";
import {
    Form,
    HyperLink,
    notification,

} from "../../components/Elements/appUtils";
import {gender, getCityByState, getStateByCountry} from "../../components/_utils/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {gstTypeList, Countries} from "../../components/_utils/appUtils";
import {groupNameObj, leadSourceOption} from "../../components/_utils/_utils"
import {useNavigate} from "react-router-dom";
import {singleCustomerFxn, updateCustomerFxn} from "./actions";
import {fetchGroup} from "../customerGroup/actions"
import moment from "moment";

const EditCustomer = (props) => {
    const {getFieldValue, setFieldsValue} = props.form;
    let dispatch = useDispatch();
    const [branchId, setBranchId] = useState("");
    const [stateList, setStateList] = useState([]);
    const [groups, setGroups] = useState([]);
    const [shippingStateList, setShippingStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [shippingCityList, setShippingCityList] = useState([]);
    const [copyBillingAddress, setCopyBillingAddress] = useState(false);
    useEffect(() => {
        checkBranchId();
    }, []);


    useEffect(() => {
        props.form.setFieldsValue({
            customerGroupName: groupNameObj.sundryDebtors,
            gstType: "Unregistered",
            country: "India",
            shippingCountry: "India",
        });
        let resp = getStateByCountry("India");
        setStateList(resp);
        setShippingStateList(resp);
    }, []);
    const checkBranchId = async () => {
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("_id");
        if (id) {
            getSingleBranch(id);
            setBranchId(id);
        }
    };
    const getSingleBranch = async (id) => {
        let {data, success} = await dispatch(singleCustomerFxn(id));
        console.log(data, "valdata")
        if (success) {
            setTimeout(() => {
                props.form.setFieldsValue({
                    name: data.name,
                    mobile: data.mobile,
                    password: data.password,
                    address: data.address,
                    email: data.email,
                    state: data.state,
                    city: data.city,
                    pincode: data.pincode,
                    gstType: data.gstType,
                    royalty: data.royalty,
                    country: data.country,
                    discount: data.discount,
                    shippingAddress: data.shippingAddress,
                    shippingCity: data.shippingCity,
                    shippingPincode: data.shippingPincode,
                    shippingState: data.shippingState,
                    gender: data.gender,
                    age: data.age,
                    sourceMedium: data.sourceMedium,
                    dateOfBirth: moment(data.dateOfBirth),
                });


            }, 300);
            setTimeout(() => {
                if (data.gstType == "Registered") {
                    setFieldsValue({gstIn: data.gstIn})
                }
            }, 400);

        }
    };

    // const events = {
    //   fetchGroups: async () => {
    //     let params = {
    //       type: "CLIENT",
    //     };
    //     const data = await fetchGroup({ ...params });
    //     setGroups(data.data);
    //   },
    //   getState: (countryName) => {
    //     let resp = getStateByCountry(countryName);
    //     setStateList(resp);
    //   },
    //   getShippingState: (countryName) => {
    //     let resp = getStateByCountry(countryName);
    //     setShippingStateList(resp);
    //   },
    // };

    useEffect(() => {
        events.fetchGroups();
    }, []);

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {
            if (!err) {

                valData._id = branchId

                let x = await dispatch(updateCustomerFxn(valData));
                if (x && x.success) {
                    console.log(x, "valdata")
                }
            } else {
                if (err.name) {
                    notification.warning({
                        message: `Please enter Name`,
                    });
                    return;
                }

                if (err.email) {
                    notification.warning({
                        message: `Please enter Email`,
                    });
                    return;
                }

                if (err.mobile) {
                    notification.warning({
                        message: `Please enter Mobile No`,
                    });
                    return;
                }

                if (err.state) {
                    notification.warning({
                        message: `Please enter State`,
                    });
                    return;
                }

                if (err.city) {
                    notification.warning({
                        message: `Please enter City`,
                    });
                    return;
                }

                if (err.pincode) {
                    notification.warning({
                        message: `Please enter Pin Code`,
                    });
                    return;
                }

                if (err.address) {
                    notification.warning({
                        message: `Please enter Address`,
                    });
                    return;
                }

                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    const events = {
        fetchGroups: async () => {
            let params = {
                type: "CLIENT",
            };
            const data = await fetchGroup({...params});
            setGroups(data.data);
        },
        getState: (countryName) => {
            let resp = getStateByCountry(countryName);
            setStateList(resp);
        },
        getCity: (stateName) => {
            let resp = getCityByState(stateName);
            setCityList(resp);
        },
        getShippingState: (countryName) => {
            let resp = getStateByCountry(countryName);
            setShippingStateList(resp);
        },
        getShippingCity: (stateName) => {
            let resp = getCityByState(stateName);
            setShippingCityList(resp);
        },
    };

    useEffect(() => {
        events.fetchGroups();
    }, []);

    let inputTypes = {
        fields: [
            {
                key: "name",
                label: "Name *",
                span: "col-md-6",
                placeholder: "Name",
            },
            {
                key: "mobile",
                label: "Mobile No",
                span: "col-md-6",
                placeholder: "Mobile No",
            },
            {
                key: "email",
                label: "Email",
                type: "email",
                span: "col-md-6",
                placeholder: "Email",
            },
            {
                key: "gender",
                label: "Gender",
                span: "col-md-3",
                type: "select",
                options: gender,
                showSearch: true,
                keyAccessor: (x) => x.value,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        gender: x,
                    });
                },
            },
            {
                key: "dateOfBirth",
                label: "Date of Birth",
                showStar: true,
                placeholder: "Date of Birth",
                type: "date",
                span: "col-md-3",
                onChange: (x) => {
                    const dob = new Date(x);
                    const today = new Date();

                    if (dob > today) {
                        notification.warning({
                            message: "Please Select Valid Date Of Birth",
                        });
                    } else {
                        const age = Math.floor(
                            (today - dob) / (365.25 * 24 * 60 * 60 * 1000)
                        );

                        props.form.setFieldsValue({
                            age: age,
                            dateOfBirth: x,
                        });
                    }
                },
            },
            {
                key: "age",
                label: "Age(In Years)",
                span: "col-md-3",
                placeholder: "Enter your Age",
            },
            {
                key: "sourceMedium",
                label: "Source Medium",
                span: "col-md-3",
                placeholder: "Please Choose",
                type: "select",
                options: leadSourceOption,
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        sourceMedium: x,
                    });
                },
            },
            {
                key: "gstType",
                label: "GST Type *",
                span: "col-md-6",
                placeholder: "GST Type",
                type: "select",
                options: gstTypeList,
                showSearch: true,
                keyAccessor: (x) => x.value,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    console.log(x);
                    props.form.setFieldsValue({
                        gstType: x,
                    });
                },
            },
            {
                key: "gstIn",
                label: "GSTIN",
                span: "col-md-6",
                placeholder: "GSTIN",
                hidden: getFieldValue("gstType") !== "Registered",
                required: getFieldValue("gstType") === "Registered",
            },
        ],
        billingAddress: [
            {
                key: "country",
                label: "Country *",
                span: "col-md-3",
                placeholder: "Country",
                type: "select",
                options: Countries,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        country: x,
                    });
                    events.getState(x);
                },
            },
            {
                key: "state",
                label: "State  *",
                span: "col-md-3",
                placeholder: "State",
                type: "select",
                options: stateList,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        state: x,
                    });
                    events.getCity(x);
                },
            },
            {
                key: "city",
                label: "City  *",
                span: "col-md-3",
                placeholder: "City",
                type: "select",
                options: cityList,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        city: x,
                    });
                },
                hidden: getFieldValue("country") != "India",
            },

            {
                key: "pincode",
                label: "Pincode *",
                span: "col-md-3",
                placeholder: "Pincode",
            },
            {
                key: "city",
                label: "City *",
                span: "col-md-3",
                placeholder: "City",
                hidden: getFieldValue("country") == "India",
            },
            {
                key: "address",
                label: "Address *",
                span: "col-md-12",
                placeholder: "Address",
                type: "textarea",
            },
        ],
        shippingAddress: [
            {
                key: "shippingCountry",
                label: "Shipping Country *",
                span: "col-md-3",
                placeholder: "Shipping Country",
                type: "select",
                options: Countries,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        shippingCountry: x,
                    });
                    events.getShippingState(x);
                },
            },
            {
                key: "shippingState",
                label: "Shipping State *",
                span: "col-md-3",
                placeholder: "Shipping State",
                type: "select",
                options: shippingStateList,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        shippingState: x,
                    });
                    events.getShippingCity(x);
                },
            },
            {
                key: "shippingCity",
                label: "Shipping City  *",
                span: "col-md-3",
                placeholder: "Shipping City",
                type: "select",
                options: shippingCityList,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        shippingCity: x,
                    });
                },
                hidden: getFieldValue("shippingCountry") != "India",
            },

            {
                key: "shippingPincode",
                label: "Shipping Pincode *",
                span: "col-md-3",
                placeholder: "Shipping Pincode",
            },
            {
                key: "shippingCity",
                label: "Shipping City *",
                span: "col-md-3",
                placeholder: "Shipping City",
                hidden: getFieldValue("shippingCountry") == "India",
            },
            {
                key: "shippingAddress",
                label: "Shipping Address *",
                span: "col-md-12",
                placeholder: "Shipping Address",
                type: "textarea",
            },
        ],
    };
    const handleCheckboxChange = (e) => {
        setCopyBillingAddress(e.target.checked); // Step 3: Handle checkbox state change
        if (e.target.checked) {
            // If the checkbox is checked, copy the billing address to the shipping address
            props.form.setFieldsValue({
                shippingCountry: getFieldValue("country"),
                shippingState: getFieldValue("state"),
                shippingCity: getFieldValue("city"),
                shippingPincode: getFieldValue("pincode"),
                shippingAddress: getFieldValue("address"),
            });
        } else {
            props.form.setFieldsValue({
                shippingCountry: "",
                shippingState: "",
                shippingCity: "",
                shippingPincode: "",
                shippingAddress: "",
            });

        }
    };
    return (
        <>
            {/* <PageHeader title={"Edit Branch"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary me-2">
                Submit
              </button>
              <HyperLink
                className=" ms-4 btn btn-danger"
                link={"/company-list"}
              >
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
        
      </PageHeader> */}
            <PageHeader title={"Edit Customer"}>
                <div className="card">
                    <div className="card-body">
                        <Form onSubmit={handleSubmit}>
                            <div className="form-elements-wrapper">
                                <div className={"row"}>
                                    {inputTypes.fields.map((item, key) => {
                                        return !item.hidden ? (
                                            <div
                                                className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                                key={key}
                                            >
                                                <GetEachFormFields {...props.form} item={item}/>
                                            </div>
                                        ) : null;
                                    })}
                                </div>
                                <div className={"custom_card"}>
                                    <h5 className={"custom_header"}>Billing Address</h5>
                                    <div className={"custom_body"}>
                                        <div className={"row"}>
                                            {inputTypes.billingAddress.map((item, key) => {
                                                return (
                                                    <div className={`pb-3 ${item.span}`} key={key}>
                                                        <GetEachFormFields {...props.form} item={item}/>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className={"custom_card"}>
                                    <h5 className={"custom_header"}>Shipping Address</h5>
                                    <div className={"custom_body"}>
                                        <div className={"row"}>
                                            <div className="col-md-12 mt-2 mb-3">
                                                <label>
                                                    <input
                                                        className="m-2"
                                                        type="checkbox"
                                                        checked={copyBillingAddress}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    Same as Billing Address
                                                </label>
                                            </div>

                                            {inputTypes.shippingAddress.map((item, key) => {
                                                return (
                                                    <div className={`pb-3 ${item.span}`} key={key}>
                                                        <GetEachFormFields {...props.form} item={item}/>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button type="submit" className="btn btn-gradient-info me-2">
                                Submit
                            </button>
                            <HyperLink className=" ms-4 btn btn-danger" link={"/customers"}>
                                Cancel
                            </HyperLink>
                        </Form>
                    </div>
                </div>
            </PageHeader>
        </>
    );
};

export default Form.create()(EditCustomer);
