import React, {useState} from "react";
import {useDispatch} from "react-redux";
import axios from "axios";
import {Navigate, useNavigate} from "react-router-dom";
import notification from "../../components/Elements/Notification";
import {apiUrl} from "../../setting";

function PasswordReset() {
    // const navigate = useNavigate();
    //
    // let dispatch = useDispatch();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");


    const setPassword = async (e) => {
        e.preventDefault()
        try {
            if (newPassword !== confirmPassword) {
                notification.error({
                    message: "Both passwords should be same",
                });
            } else {
                const params = new URLSearchParams(window.location.search);
                console.log(params.get("resetToken"))
                const response = await axios.post(`${apiUrl}/routes/user/password-reset/?resetToken=${params.get("resetToken")}&userId=${params.get("userId")}`, {
                    password: newPassword

                });
                if (response.data.error)
                    notification.error({message: response.data.message})
                else {
                    notification.success({message: "Password has been reset"})
                    setNewPassword("");
                    setConfirmPassword("");
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 1000);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <>
            {/*<h4>Create Password</h4>*/}
            {<h6 className="font-weight-light">Enter Password</h6>}

            <form className="pt-3">
                <div className="form-group">
                    <input type="password" className="form-control form-control-lg" id="exampleInputEmail1"
                           onChange={(e) => {
                               setNewPassword(e.target.value)
                           }}
                           value={newPassword}
                           placeholder="new password" required/>
                    <input type="password" className="form-control form-control-lg mt-2" id="exampleInputEmail1"
                           onChange={(e) => {
                               setConfirmPassword(e.target.value)
                           }}
                           value={confirmPassword}
                           placeholder="confirm password" required/>
                </div>

                <div className="mt-3">
                    <button className=" btn-gradient-primary p-2 " style={{fontSize: "small"}}
                            onClick={setPassword}>Set Password
                    </button>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check">
                    </div>
                    <a className="auth-link text-black" onClick={() => {
                        window.location.href = "/login"
                    }}>Back to login</a>
                </div>
                {/*   <div className="text-center mt-4 font-weight-light"> Dot have an account? <a href="register.html"
                                                                                         className="text-primary">Create</a>
          </div>*/}
            </form>

        </>
    )
}

export default PasswordReset;
