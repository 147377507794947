import React from "react";
import {
    Form,
    HyperLink,
    notification,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {updatePasswordFxn} from "./actions";
import {getItemLocalstorage} from "../../components/_utils/_utils";

const ChangePassword = (props) => {
    let dispatch = useDispatch();
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            console.log(valData, "w1");
            if (!err) {
                if (valData.newPassword !== valData.confirmPassword) {
                    notification.warning({
                        message: `New password should be equal to confirm password`,
                    });
                    return;
                }
                const data = getItemLocalstorage("user");
                const response = await dispatch(updatePasswordFxn({...valData, data}));
                if (response.success) {
                    form.setFieldsValue({
                        oldPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                    });
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 1000);
                }
            } else {

                if (err.oldPassword) {
                    notification.warning({
                        message: `Please enter old password`,
                    });
                    return;
                }
                if (err.newPassword) {
                    notification.warning({
                        message: `Please enter new password`,
                    });
                    return;
                }
                if (err.confirmPassword) {
                    notification.warning({
                        message: `Please enter confirm password`,
                    });
                    return;
                }

                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };


    let inputTypes = {
        fields: [{
            key: "oldPassword",
            label: "Old Password",
            span: "col-md-6",
            placeholder: "Old Password",
            required: true,
            type: 'password'
        },
            {
                key: "newPassword",
                label: "New Password",
                span: "col-md-6",
                placeholder: "New Password",
                required: true,
                type: 'password'
            }
            ,
            {
                key: "confirmPassword",
                label: "Confirm Password",
                span: "col-md-6",
                placeholder: "Confirm Password",
                required: true,
                type: 'password'
            }],
    };


    return (


        <>
            <PageHeader title={"Change password"}>
                <div className="card">
                    <div className="card-body">
                        <Form onSubmit={handleSubmit}>
                            <div className="form-elements-wrapper">
                                <div className={"row"}>
                                    {inputTypes.fields.map((item, key) => {
                                        return !item.hidden ? (
                                            <div
                                                className={`pb-3 ${
                                                    item.span ? item.span : "col-md-6"
                                                }`}
                                                key={key}
                                            >
                                                <GetEachFormFields {...props.form} item={item}/>
                                            </div>
                                        ) : null;
                                    })}
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="btn btn-gradient-primary "
                            >
                                Submit
                            </button>
                            <HyperLink
                                className=" ms-4 btn btn-danger"
                                link={"/company-list"}
                            >
                                Cancel
                            </HyperLink>
                        </Form>
                    </div>
                </div>
            </PageHeader>
        </>


    );
};

export default Form.create()(ChangePassword);
