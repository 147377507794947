import React, { useState, useRef } from "react";
import { getToken } from "../../../request";
import axios from "axios";
import { InputBox, Form, notification, Table } from "../../../components/Elements/appUtils";
import { useNavigate } from "react-router-dom";
import { fetchTakenDetails } from "./action";
import { addTakenUrl } from "./api";

import PageHeader from "../../../components/Elements/pageHeader";

function AddTaken(props) {

  const navigate = useNavigate();
  
 let {onClose}=props;
 console.log(onClose, "onClose");
  let tableRef = useRef();
  const [formData, setFormData] = useState({
    whentotake: "",
  });
  let [takenId, setTakenId] = useState("");

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleS = async (e) => {
    e.preventDefault();
    formData.takenId = takenId;
 
    const response = await axios.post(addTakenUrl(), formData, getToken());
    if (response.data.success) {
     
      notification.success({
        message: response.data.message || "Success",
      });
      setFormData({ whentotake: "" });
      tableRef.current.reload();
    
    } else {
      console.log(response.data.message,"brand data");
      notification.error({message:response.data.message});
    }
  };

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      const data = await fetchTakenDetails({...params});
      console.log(data, "sddsfds")
      resolve(data)
    });
  };


  const columns = [
    {
      title: "When To Take",
      dataIndex: "whentotake",
      key: "whentotake",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 190,
      render: (val, record) => {
        return (
            <>
                <a onClick={() => {
                  navigate(`/edit-addtaken?_id=${record._id}`)
                }}>
                  <img src={"./edit.svg"}/>

                </a> 
              

            </>
        );
      }
  }
  
  ];

  // useEffect(() => {
  //   // Fetch data when the component mounts
  //   fetchBrand();
  // }, []);

  return (
    <PageHeader>
      <Form>
        <div class="card">
          <div class="card-body">
            <div class="form-elements-wrapper">
              <div class="row">
                <div class="col-lg-6">
                  <InputBox title={"When To Take"}>
                    <input
                      type="text"
                      className={"form-control"}
                      placeholder="Enter Taken Name"
                      value={formData.whentotake}
                      onChange={(e) => handleChange(e, "whentotake")}
                      name="whentotake"
                      required
                    />
                  </InputBox>
                </div>
              </div>
            </div>
            <button onClick={handleS} className="btn btn-success">
              SUBMIT DETAILS
            </button>
          </div>
        </div>
      </Form>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
            </div>
          </div>
        </div>
      </div>
    </PageHeader>
  );
}

export default AddTaken;
