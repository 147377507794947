import React, { useEffect, useRef, useState } from "react";
import { Table, notification } from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { fetchUsers } from "./actions";
import { useNavigate } from "react-router-dom";
const UserList = (props) => {
  const [userData, setUserData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [searchName, setSearchName] = useState("")
  const [searchEmail, setSearchEmail] = useState("")
  const [searchMobile, setSearchMobile] = useState("")
  let tableRef = useRef();
  const navigate = useNavigate()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      const data = await fetchUsers({ ...params });
      setUserData(data.data);
      setFilteredData(data.data);
      resolve(data);
    });
  };

  useEffect(() => {
    apiRequest();
}, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Number",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 190,
      render: (val, record) => {
        return (
            <>
                <a onClick={() => {
                  navigate(`/edit-user?_id=${record._id}`)
                }}>
                  <img src={"./edit.svg"}/>

                </a>


            </>
        );
      }
  }
  ];

  const filterUser = () => {
    setShowFilter(true)
  
  if (!searchName && !searchEmail && !searchMobile) {
    // setUserData([])
    notification.error({ message: "please enter name" });
    setShowFilter(false)
    return
  } else {
   const filteredUser = userData.filter((user) =>
   user.name.toLowerCase().includes(searchName.toLowerCase()) &&
   user.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
   user.mobile.toLowerCase().includes(searchMobile.toLowerCase())
  );
  console.log(filteredUser, "filteredUser");
  setUserData(filteredUser);
  }
  };
  
  
  const clearUserFilters = () => {
    setSearchName("");
    setSearchEmail("");
    setSearchMobile("");
    setShowFilter(false)
    setUserData(filteredData);
    if(tableRef.current.reload) {
      tableRef.current.reload()
    }
  } 
  return (
    <PageHeader
      title={"User"}
      extraLink={[
        {
          name: "Add User",
          link: "/add-user",
        },
      ]}
    >
      <div className="card">
        <div className="card-body">
        <div className="mb-3 row">
        <div className="col-md-2 mb-2 pe-0" style={{paddingLeft : '7px'}}>
          <label htmlFor="searchName" className="form-label" />
         
          <input
            type="text"
            className="form-control search-filters"
            id="searchName"
            placeholder="Search by Name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </div>
        
        <div className="col-md-2 mb-2 pe-0" style={{paddingLeft : '7px'}}>
        <label htmlFor="searchEmail" className="form-label"/>
        <input
          type="text"
          className="form-control search-filters"
          id="searchEmail"
          placeholder="Search by Email"
          value={searchEmail}
          onChange={(e) => setSearchEmail(e.target.value)}
        />
      </div>
      <div className="col-md-2 mb-2 pe-0" style={{paddingLeft : '7px'}}>
      <label htmlFor="searchMobile" className="form-label"/>
      <input
        type="text"
        className="form-control search-filters"
        id="searchMobile"
        placeholder="Search by Mobile"
        value={searchMobile}
        onChange={(e) => setSearchMobile(e.target.value)}
      />
    </div>
        </div>
         
        <div className="mb-3 search-buttons">
        <div className="search-wrap pe-2">
        <button className="btn btn-info" onClick={filterUser}>
          Search
        </button>
      </div>
      <div className="search-wrap">
        <button className="btn btn-secondary" onClick={clearUserFilters}>
          Clear
        </button>
      </div>
        </div>
        {showFilter ? (
          <Table
          dataSource={userData}
          columns={columns}
          ref={tableRef}
          key={`table-${showFilter ? 'filtered' : 'original'}`}
        />
        
        ) : (
          <Table
          apiRequest={apiRequest}
          columns={columns}
          ref={tableRef}
       
        />
        )}
        </div>
      </div>
    </PageHeader>
  );
};

export default UserList;
