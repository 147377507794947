import { apiUrl } from "../../../setting";
// import { getItemLocalstorage } from "../../../components/_utils/_utils";


// const companyId = getItemLocalstorage("user")["companyId"];


export const getBrandDataUrl = () => {
    return `${apiUrl}/routes/brand/getBrandDataByCompanyId`;
};


export const addBrandUrl = () => {
    return `${apiUrl}/routes/brand/createBrand`;
};
export const singleBrandUrl = (id) => {
    return apiUrl + "/routes/brand/getSingleBrand/" + id;
};
export const updateBrandUrl = () => {
    return `${apiUrl}/routes/brand/updateBrand`;
  };
