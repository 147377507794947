import React, {useEffect, useState} from "react";
import {
    Form,
    HyperLink,
    notification,
} from "../../../components/Elements/appUtils";
import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../../../components/_utils/formUtils";
import {
    ExpenseTypes,
    States,
} from "../../../components/_utils/appUtils";
import {addCustomerFxn} from "../actions";
import _ from "lodash";

const AddClientComponent = (props) => {
    let dispatch = useDispatch();
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.name) {
                    notification.warning({message: "Please Fill Name"});
                    return;
                }
                if (!valData.customerGroupName) {
                    notification.warning({message: "Please Select Group"});
                    return;
                }
                valData.type = "customer";
                valData.expense = true;
                let x = await dispatch(addCustomerFxn(valData));
                if (x && x.success) {
                    props.form.setFieldsValue({
                        name: "",
                        customerGroupName: "",
                        details: "",
                    });
                }
            }
        });
    };

    let inputTypes = {
        fields: [
            {
                key: "name",
                label: "Name*",
                span: "col-md-6",
                placeholder: "Name",
            },
            {
                key: "customerGroupName",
                label: "Select Group*",
                span: "col-md-6",
                placeholder: "Select Group",
                type: "select",
                options: ExpenseTypes,
                showSearch: true,
                keyAccessor: (x) => x.type,
                valueAccessor: (x) => `${x.type}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        customerGroupName: x,
                    });
                },
            },
            {
                key: "details",
                label: "Details",
                span: "col-md-12",
                type: "textarea",
                placeholder: "Details..",
            },
        ],
    };

    return (
        <Form onSubmit={handleSubmit}>
            <div className="form-elements-wrapper">
                <div className={"row"}>
                    {inputTypes.fields.map((item, key) => {
                        return !item.hidden ? (
                            <div
                                className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                key={key}
                            >
                                <GetEachFormFields {...props.form} item={item}/>
                            </div>
                        ) : null;
                    })}
                </div>
            </div>

            <button type="submit" className="btn btn-gradient-info me-2">
                Submit
            </button>
            <HyperLink className=" ms-4 btn btn-danger" link={"/banks"}>
                Cancel
            </HyperLink>
        </Form>
    );
};

export default Form.create()(AddClientComponent);
