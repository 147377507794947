import { apiUrl } from "../../../setting";
// import { getItemLocalstorage } from "../../../components/_utils/_utils";


// const companyId = getItemLocalstorage("user")["companyId"];


export const getGroupDataUrl = () => {
    return `${apiUrl}/routes/group/getGroupDataByCompanyId`;
};

export const getGroupDataByCustomerIdUrl = () => {
    return `${apiUrl}/routes/customer/getGroupByCustomerId`;
};



export const getGroupDataByGroupIdUrl = () => {
    return `${apiUrl}/routes/group/getGroupDataByGroupId`;
};

    
export const addGroupUrl = () => {
    return `${apiUrl}/routes/group/createGroup`;
};
