import React, { useEffect, useState } from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../components/Elements/appUtils";
import moment from "moment";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { States, gstTypeList } from "../../components/_utils/appUtils";

import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { singleEmployeeFxn , updateEmployeeFxn} from "./action";

const EditEmployee = (props) => {
  const { getFieldValue, setFieldsValue } = props.form;
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [employeeId, setemployeeId] = useState("");
  const [branchTypes, setBranchType] = useState("");
  useEffect(() => {
    checkemployeeId();
  }, []);

  const checkemployeeId = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      getSingleBranch(id);
      setemployeeId(id);
    }
  };
  const getSingleBranch = async (id) => {
    let { data, success } = await dispatch(singleEmployeeFxn(id));
    console.log(data, "valdata");
    if (success) {
      setTimeout(() => {
        props.form.setFieldsValue({
          name: data.name,

          email: data.email,
          mobile: data.mobile,
          salary: data.salary,
          emergencyNumber: data.emergencyNumber,
          address: data.address,
          designation: data.designation,
          state: data.state,
          dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth) : "",
          joiningDate: data.joiningDate ? moment(data.joiningDate) : ""
        });

        setBranchType(data.type);
      }, 300);
      setTimeout(() => {
        if (data.gstType == "Registered") {
          setFieldsValue({ gstIn: data.gstIn });
        }
      }, 400);
    }
  };

      const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {
            if (!err) {

                valData._id = employeeId
         
                let x = await dispatch(updateEmployeeFxn(valData));
                if (x && x.success) {
                    console.log(x, "valdata")
                    navigate('/employee')
                }
            } else {
                if (err.name) {
                    notification.warning({
                        message: `Please enter Name`,
                    });
                    return;
                }

                if (err.email) {
                    notification.warning({
                        message: `Please enter Email`,
                    });
                    return;
                }

                if (err.mobile) {
                    notification.warning({
                        message: `Please enter Mobile No`,
                    });
                    return;
                }

            

                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name",
        span: "col-md-6",
        placeholder: "Name",
          required: true,
      },
      {
        key: "mobile",
        label: "Mobile No",
        span: "col-md-6",
        placeholder: "Mobile No",
        required: true,
      },
      {
        key: "email",
        label: "Email",
        type: "email",
        span: "col-md-6",
        placeholder: "Email",
      },
      {
        key: "dateOfBirth",
        label: "Date Of Birth",
        showStar: true,
        placeholder: "Date",
        type: "date",
        span: "col-md-6",
        onChange: (x) => {
          props.form.setFieldsValue({
            dateOfBirth: x,
          });
        },
      },

      {
        key: "address",
        label: "Address",
        type: "text",
        span: "col-md-6",
        placeholder: "Address",
      },
      {
        key: "joiningDate",
        label: "Joining Date",
        showStar: true,
        placeholder: "Date",
        type: "date",
        span: "col-md-6",
        onChange: (x) => {
          props.form.setFieldsValue({
            joiningDate: x,
          });
        },
      },
      {
        key: "designation",
        label: "Designation",
        type: "text",
        span: "col-md-6",
        placeholder: "Designation",
      },
      {
        key: "salary",
        label: "Salary",
        type: "Number",
        span: "col-md-6",
        placeholder: "Salary",
      },
      {
        key: "emergencyNumber",
        label: "Emergency Contact Number",
        type: "Number",
        span: "col-md-6",
        placeholder: "Emergency Contact Number",
      },
    ],
  };

  return (
    <>
      <PageHeader title={"Edit Employee"}>
        <div className="card">
          <div className="card-body">
            <Form  onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary me-2">
                Submit
              </button>
              <HyperLink
                className=" ms-4 btn btn-danger"
                link={"/company-list"}
              >
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(EditEmployee);
