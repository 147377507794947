import { apiUrl } from "../../../setting";

export const getSymptomDataUrl = () => {
    return `${apiUrl}/routes/symptom/getSymptomDataByCompanyId`;
};


export const addSymptomUrl = () => {
    return `${apiUrl}/routes/symptom/createSymptom`;
};
export const singleSymptomUrl = (id) => {
    return apiUrl + "/routes/symptom/getSingleSymptom/" + id;
};
export const updateSymptomUrl = () => {
    return `${apiUrl}/routes/symptom/updateSymptom`;
  };
  export const deleteSymptomUrl = (id) => {
    return apiUrl + "/routes/symptom/deleteSingleSymptom/" + id;
  };
  

