import { apiUrl } from "../../../setting";

export const getCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/customerList`;
};
export const getUserDataUrl = () => {
    return `${apiUrl}/routes/customer/userList`;
};

export const getBank = () => {
    return `${apiUrl}/routes/customer/getBank`;
};

export const getStockUrl = () => {
    return `${apiUrl}/routes/product/getStock`;
};

export const addSaleUrl = () => {
    return `${apiUrl}/routes/sale/saleProduct`;
};
export const generateSaleUrl = () => {
    return `${apiUrl}/routes/sale/saleGenerate`;
};


export const editSaleUrl = () => {
    return `${apiUrl}/routes/sale/editSale`;
};

export const getSaleUrl = () => {
    return `${apiUrl}/routes/sale/getAllSale`;
};
export const getSaleCsvUrl = () => {
    return `${apiUrl}/routes/sale/getAllSaleCsv`;
};

export const invoiceUpdateUrl = () => {
    return `${apiUrl}/routes/sale/updateInvoice`;
};

export const getGroupCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/getCustomerDataByGroupId`;
};

export const getbatchRecordByProductIdUrl = () => {
    return `${apiUrl}/routes/sale/getBatchRecordByProductId`;
}
export const PrintSaleUrl = (id) => {
    return apiUrl + "/routes/sale/printSaleRecord/" + id;
};

export const getSingleUser = () => {
    return `${apiUrl}/routes/user/getSingleUser2`;
};
export const GetBranchInfo = () => {
    return apiUrl + "/routes/sale/branchInfo" ;
};