import React, { useEffect, useState } from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../../components/Elements/appUtils";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../../components/_utils/formUtils";
import {
  States,
  gstTypeList,
  Countries,
  getStateByCountry,
  getCityByState,
} from "../../../components/_utils/appUtils";
import { addCustomerFxn } from "../actions";
import _ from "lodash";
import { fetchGroup } from "../../customerGroup/actions";
import { useNavigate } from "react-router-dom";
import { groupNameObj } from "../../../components/_utils/_utils";

const AddCustomerComponent = (props) => {
  let { onClose = null, groupType,customerNames } = props;

  console.log(customerNames, "sssssssss");
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [shippingCityList, setShippingCityList] = useState([]);
  const [shippingStateList, setShippingStateList] = useState([]);
  const [copyBillingAddress, setCopyBillingAddress] = useState(false);
  const { getFieldValue } = props.form;
  let dispatch = useDispatch();
  console.log(onClose, "onCLose");
  useEffect(() => {
    props.form.setFieldsValue({
      customerGroupName: groupNameObj.sundryDebtors,
      gstType: "Unregistered",
      country: "India",
      shippingCountry: "India",
      name: customerNames ? customerNames : '', 
    });
    let resp = getStateByCountry("India");
    setStateList(resp);
    setShippingStateList(resp);
  }, [customerNames]);

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        let findStateCode = _.find(States, (item) => {
          return item.name === valData.state;
        });
        if (findStateCode) {
          valData.stateCode = findStateCode.stateCode;
        }
        valData.type = "customer";

        if (!valData.name) {
          notification.warning({ message: "Please Enter Name" });
          return;
        }

        if (!valData.gstType) {
          notification.warning({ message: "Please Select GST Type" });
          return;
        }

        if (!valData.country) {
          notification.warning({ message: "Please Select Billing Country" });
          return;
        }
        if (!valData.state) {
          notification.warning({ message: "Please Select Billing State" });
          return;
        }
        if (!valData.city) {
          notification.warning({ message: "Please Select Billing city" });
          return;
        }
        // if (!valData.address) {
        //   notification.warning({ message: "Please Select Billing Address" });
        //   return;
        // }
        if (!valData.shippingCountry) {
          notification.warning({ message: "Please Select Shipping Country" });
          return;
        }
        if (!valData.shippingState) {
          notification.warning({ message: "Please Select Shipping State" });
          return;
        }
        if (!valData.shippingCity) {
          notification.warning({ message: "Please Select Shipping city" });
          return;
        }
        // if (!valData.shippingAddress) {
        //   notification.warning({ message: "Please Select Shipping Address" });
        //   return;
        // }
        if (groupType === undefined) {
          valData.customerGroupName = "SUNDRY DEBTORS";
        } else if (groupType !== null) {
          valData.customerGroupName = groupType;
        }

        valData.balance = 0;

        let x = await dispatch(addCustomerFxn(valData));
        if (x && x.success) {
          if (onClose) {
            onClose(x.addCustomer);
          }
          props.form.setFieldsValue({
            name: "",
            email: "",
            mobile: "",
            gstType: "",
            gstIn: "",
            customerGroupName: "",
            state: "",
            city: "",
            pincode: "",
            address: "",
            shippingState: "",
            shippingCity: "",
            shippingPincode: "",
            shippingAddress: "",
          });
          setCopyBillingAddress(false);
        }
      }
    });
  };

  const events = {
    fetchGroups: async () => {
      let params = {
        type: "CLIENT",
      };
      const data = await fetchGroup({ ...params });
      setGroups(data.data);
    },
    getState: (countryName) => {
      let resp = getStateByCountry(countryName);
      setStateList(resp);
    },
    getCity: (stateName) => {
      let resp = getCityByState(stateName);
      setCityList(resp);
    },
    getShippingState: (countryName) => {
      let resp = getStateByCountry(countryName);
      setShippingStateList(resp);
    },
    getShippingCity: (stateName) => {
      let resp = getCityByState(stateName);
      setShippingCityList(resp);
    },
  };

  useEffect(() => {
    events.fetchGroups();
  }, []);

  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name *",
        span: "col-md-6",
        placeholder: "Name",
      },
      {
        key: "mobile",
        label: "Mobile No",
        span: "col-md-6",
        placeholder: "Mobile No",
      },
      {
        key: "email",
        label: "Email",
        type: "email",
        span: "col-md-6",
        placeholder: "Email",
      },

      {
        key: "gstType",
        label: "GST Type *",
        span: "col-md-6",
        placeholder: "GST Type",
        type: "select",
        options: gstTypeList,
        showSearch: true,
        keyAccessor: (x) => x.value,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          console.log(x);
          props.form.setFieldsValue({
            gstType: x,
          });
        },
      },
      {
        key: "gstIn",
        label: "GSTIN",
        span: "col-md-6",
        placeholder: "GSTIN",
        hidden: getFieldValue("gstType") !== "Registered",
        required: getFieldValue("gstType") === "Registered",
      },
    ],
    billingAddress: [
      {
        key: "country",
        label: "Country *",
        span: "col-md-3",
        placeholder: "Country",
        type: "select",
        options: Countries,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            country: x,
            state: "",
          });
          events.getState(x);
        },
      },
      {
        key: "state",
        label: "State  *",
        span: "col-md-3",
        placeholder: "State",
        type: "select",
        options: stateList,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            state: x,
            city: "",
          });
          events.getCity(x);
        },
      },
      {
        key: "city",
        label: "City  *",
        span: "col-md-3",
        placeholder: "City",
        type: "select",
        options: cityList,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            city: x,
          });
        },
        hidden: getFieldValue("country") != "India",
      },
      {
        key: "city",
        label: "City *",
        span: "col-md-3",
        placeholder: "City",
        hidden: getFieldValue("country") == "India",
      },
      {
        key: "pincode",
        label: "Pincode",
        span: "col-md-3",
        placeholder: "Pincode",
      },
      {
        key: "address",
        label: "Address",
        span: "col-md-12",
        placeholder: "Address",
        type: "textarea",
      },
    ],
    shippingAddress: [
      {
        key: "shippingCountry",
        label: "Shipping Country *",
        span: "col-md-3",
        placeholder: "Shipping Country",
        type: "select",
        options: Countries,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            shippingCountry: x,
            shippingState: "",
          });
          events.getShippingState(x);
        },
      },
      {
        key: "shippingState",
        label: "Shipping State *",
        span: "col-md-3",
        placeholder: "Shipping State",
        type: "select",
        options: shippingStateList,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            shippingState: x,
            shippingCity: "",
          });
          events.getShippingCity(x);
        },
      },
      {
        key: "shippingCity",
        label: "Shipping City  *",
        span: "col-md-3",
        placeholder: "Shipping City",
        type: "select",
        options: shippingCityList,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            shippingCity: x,
          });
        },
        hidden: getFieldValue("shippingCountry") != "India",
      },

      {
        key: "shippingPincode",
        label: "Shipping Pincode",
        span: "col-md-3",
        placeholder: "Shipping Pincode",
      },
      {
        key: "shippingCity",
        label: "Shipping City *",
        span: "col-md-3",
        placeholder: "Shipping City",
        hidden: getFieldValue("shippingCountry") == "India",
      },
      {
        key: "shippingAddress",
        label: "Shipping Address",
        span: "col-md-12",
        placeholder: "Shipping Address",
        type: "textarea",
      },
    ],
  };

  const handleCheckboxChange = (e) => {
    setCopyBillingAddress(e.target.checked); // Step 3: Handle checkbox state change
    if (e.target.checked) {
      // If the checkbox is checked, copy the billing address to the shipping address
      props.form.setFieldsValue({
        shippingCountry: getFieldValue("country"),
        shippingState: getFieldValue("state"),
        shippingCity: getFieldValue("city"),
        shippingPincode: getFieldValue("pincode"),
        shippingAddress: getFieldValue("address"),
      });
    } else {
      props.form.setFieldsValue({
        shippingCountry: "",
        shippingState: "",
        shippingCity: "",
        shippingPincode: "",
        shippingAddress: "",
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-elements-wrapper">
        <div className={"row"}>
          {inputTypes.fields.map((item, key) => {
            return !item.hidden ? (
              <div
                className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                key={key}
              >
                <GetEachFormFields {...props.form} item={item} />
              </div>
            ) : null;
          })}
        </div>
        <div className={"custom_card"}>
          <h5 className={"custom_header"}>Billing Address</h5>
          <div className={"custom_body"}>
            <div className={"row"}>
              {inputTypes.billingAddress.map((item, key) => {
                return (
                  <div className={`pb-3 ${item.span}`} key={key}>
                    <GetEachFormFields {...props.form} item={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={"custom_card"}>
          <h5 className={"custom_header"}>Shipping Address</h5>
          <div className={"custom_body"}>
            <div className={"row"}>
              <div className="col-md-12 mt-2 mb-3">
                <label>
                  <input
                    className="m-2"
                    type="checkbox"
                    checked={copyBillingAddress}
                    onChange={handleCheckboxChange}
                  />
                  Same as Billing Address
                </label>
              </div>

              {inputTypes.shippingAddress.map((item, key) => {
                return (
                  <div className={`pb-3 ${item.span}`} key={key}>
                    <GetEachFormFields {...props.form} item={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <button type="submit" className="btn btn-gradient-info me-2">
        Submit
      </button>
      <HyperLink className=" ms-4 btn btn-danger" link={"/customers"}>
        Cancel
      </HyperLink>
    </Form>
  );
};

export default Form.create()(AddCustomerComponent);
