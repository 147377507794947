import React, {Suspense} from 'react';
import Header from "./header";
import Footer from "./footer";
import SideBar from "./asidebar";
import {Navigate, Outlet} from "react-router-dom";

// const Scripts = React.lazy(() => import('./scripts.js'))

const isLoggedIn = localStorage.getItem("token") ? true : false;

export default function Layout(props) {
    return isLoggedIn ? (
        <>
            <div className="container-scroller">
                <Header {...props}/>
                <div className="container-fluid page-body-wrapper">

                    <SideBar/>
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <Outlet/>
                        </div>
                        <Footer/>

                    </div>
                </div>
            </div>

        </>) : (
        <Navigate replace to="/login"/>
    )
}
