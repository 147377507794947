import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  HyperLink,
  InputBox,
  fixed2Digit,
  notification,
  Popconfirm
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import moment from "moment";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import {
  States,
  PaymentTypes,
  patientType,
  gender,
  PatientCategory,
  ReferralCode,
  visitType,
} from "../../components/_utils/appUtils";
import { getToken } from "../../request";
import { getVisitUrl } from "./api";
import {
  addPatientFxn,
  fetchBank,
  fetchCustomer,
  updatePatientFxn,
  singleCustomerFxn,
  singlePatientFxn
} from "./actions";
import { generateSlipUrl } from "./api";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { fetchDoctors } from "../doctor/actions";
import { getGroupDataByCustomerIdUrl } from "../customerGroup/api";
import axios from "axios";
import ItemListComponent from "./components/ItemListComponent";
// import { invoiceUpdateFxn } from "../../sales/actions";
import { groupNameObj } from "../../components/_utils/_utils";
import ChooseSymptom from "../symptom";
import ChoosePatient from "./chooseCustomer/choosePatient";
import { fetchSymptom } from "./actions";
const EditPatient = (props) => {
  const [formData, setFormData] = useState([
    { bankId: "", paymentType: "", amount: "", transactionDetails: "" },
  ]);
  const [showDeleteButtons, setShowDeleteButtons] = useState([false]);

  const handleInputChange = (index, field, value) => {
    const newData = [...formData];
    newData[index][field] = value;
    setFormData(newData);
    setShowDeleteButtons((prevButtons) =>
      prevButtons.map((btn, idx) => (idx === index ? true : btn))
    );
  };

  const handleDelete = (index) => {
    const newPayments = [...formData];
    newPayments.splice(index, 1);
    setFormData(newPayments);
    setShowDeleteButtons((prevButtons) =>
      prevButtons.filter((_, idx) => idx !== index)
    );
  };

  const handleAddField = () => {
    const sumAmount = formData.reduce(
      (sum, item) => sum + parseFloat(item.amount) || 0,
      0
    );
    if (sumAmount > getFieldValue("paidAmount")) {
      notification.warning({
        message: "Amount Cannot Be Greater Than Recieving Amount",
      });
      return;
    }
    if (sumAmount == getFieldValue("paidAmount")) {
      // notification.warning({
      //   message: "Amount Cannot Be Greater Than Recieving Amount",
      // });
      return;
    }

    const lastData = formData[formData.length - 1];
    if (!lastData.bankId) {
      notification.warning({ message: "Please Choose Bank" });
      return;
    }
    if (!lastData.paymentType) {
      notification.warning({ message: "Please Choose Payment Type" });
      return;
    }
    if (!lastData.amount) {
      notification.warning({ message: "Please Enter Amount" });
      return;
    }

    setFormData([
      ...formData,
      { bankId: "", paymentType: "", amount: "", transactionDetails: "" },
    ]);

    setShowDeleteButtons([...showDeleteButtons, true]);
  };

  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [typeOfPatient, setTypeOfPatient] = useState("New Patient");
  const [typeOfVisit, setTypeOfVisit] = useState();
  const { getFieldValue } = props.form;
  const paidToUserId = getFieldValue("paidToUserId");
  const [groupName, setGroupName] = useState(null);
  const [banks, setBanks] = useState([]);
  const [symptomId, setSymptomId] = useState([]);
  const [symptomIdVal, setSymptomIdVal] = useState('');
  const [dates, setDates] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [type, setType] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [ProductReport, setProductReport] = useState([]);
  const [patientId, setPatientId] = useState("");
  const updateProductReport = (newProductReport) => {
    setProductReport(newProductReport);
  };

  const findprodct = () => {
    if (ProductReport && ProductReport?.length > 0) {
      const productTypes = ProductReport.map((item) => item.productType);
      const isSlipProduct = productTypes.includes("Slip");
      const isMembershipProduct = productTypes.includes("Membership");

      if (isSlipProduct) {
        const now = moment();
        const startDate = moment(now);
        const expiryDate = moment(now).add(15, "days");
        setStartDate(startDate);
        setExpireDate(expiryDate);
        setType("Slip");
      }
      if (isMembershipProduct) {
        const now = moment();
        const startDate = moment(now);
        const expiryDate = moment(now).add(6, "months");
        setStartDate(startDate);
        setExpireDate(expiryDate);
        setType("Membership");
      }
    }
  };

  let stateOfSupply = "1";
  let companyState = "1";

  // const [invoice, setInvoice] = useState(null);
  let [customerId, setCustomerId] = useState("");
  let [slipRecord, setSlipRecord] = useState([]);


  let netAmount = 0;
  let quantity = 0;
  let balance = 0;
  const calNetAmount = () => {
    ProductReport.forEach((y) => {
      let paidAmount = getFieldValue("paidAmount");
      paidAmount = paidAmount ? fixed2Digit(paidAmount) : 0;
      netAmount += Number(y.netAmount);
      quantity += Number(y.quantity);
      balance = paidAmount ? netAmount - paidAmount : netAmount;

      if (balance < 0) {
        balance = "Not valid Paid Amount";

        props.form.setFieldsValue({
          totalAmount: netAmount ? fixed2Digit(netAmount) : 0,
          quantity: quantity,
          balance: 0,
        });
      } else {
        props.form.setFieldsValue({
          totalAmount: netAmount.toFixed(2),
          quantity: quantity,
          balance: balance ? balance.toFixed(2) : 0,
        });
      }
    });
  };

  useEffect(() => {
    calNetAmount();
  }, [ProductReport, getFieldValue("paidAmount")]);

  // useEffect(() => {
  //   props.form.setFieldsValue({
  //     paidAmount: 0,
  //     invoiceNumber: invoice,
  //   });
  //   updatedInvoiceNumber();
  // }, [invoice]);

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      const data = await fetchDoctors({ ...params });
      setDoctor(data.data);
    });
  };

  const fetchBanks = async () => {
    const { data } = await fetchBank({
      type: "bank",
      results: 100,
      count: 100,
    });
    setBanks(data);
  };
  const fetchSymptoms = async () => {
    const { data } = await fetchSymptom();
    setSymptomId(data);
    setSymptomIdVal(data._id)
  };

  const fetchGroupName = async () => {
    try {
      const response = await axios.get(getGroupDataByCustomerIdUrl(), {
        params: { customerId: paidToUserId },
      });
      setGroupName(response.data.data.name);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };
  const fetchVisitModule = async () => {
    try {
      const response = await axios.get(getVisitUrl(), {
        params: { patientId: customerId },
      });
      setSlipRecord(response.data);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    apiRequest();
    fetchBanks();
    fetchSymptoms();
    fetchGroupName();
    findprodct();
  }, [getFieldValue("slipType"), ProductReport]);

  useEffect(() => {
    const apiRequest = (params = {}) => {
      return new Promise(async (resolve) => {
        params._id = customerId;
        const data = await fetchCustomer({ ...params });
        const Patientdata = data.data[0];
        props.form.setFieldsValue({
          name: Patientdata.name,
          email: Patientdata.email,
          age: Patientdata.age,
          city: Patientdata.city,
          mobile: Patientdata.mobile,
          address: Patientdata.address,
          state: Patientdata.state,
          pincode: Patientdata.pincode,
          gender: Patientdata.gender,

        });
        
        setProductReport()
      });
    };
    if (customerId) {
      apiRequest();
      fetchVisitModule();
    }
  }, [customerId]);


  useEffect(() => {
    checkPatientId();
  }, []);

  let id;

  const checkPatientId = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    id = searchParams.get("_id");
    if (id) {
         getSinglePatient(id);
        setPatientId(id);
    }
  };
  const getSinglePatient = async (id) => {
    let { data, success } = await dispatch(singlePatientFxn(id));
    console.log(data, "maindata")
    if (success) {
      setTimeout(() => {
        props.form.setFieldsValue({
            name: data.patient.name,
            patientVisit:data.patient.patientVisit,
            email:data.patient.email,
            dateOfBirth: data.patient.dateOfBirth ? moment(data.patient.dateOfBirth) : "",
            mobile:data.patient.mobile,
            doctorId:data.patient.doctorId,
            state:data.patient.state,
            city:data.patient.city,
            pincode:data.patient.pincode,
            gender:data.patient.gender,
            age:data.patient.age,
            patientCategory:data.patient.patientCategory,
            address:data.patient.address,
          

        });
      
        setProductReport(data.productReports)
        setFormData(data.sales?.paidToUser)
        props.form.setFieldsValue({
        
          paidAmount:data.sales?.paidAmount,
        });
      
      }, 300);
      
      
    }
  };
  console.log(formData, "paidTouser")
  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        let findStateCode = _.find(States, (item) => {
          return item.name === valData.state;
        });
        if (findStateCode) {
          valData.stateCode = findStateCode.stateCode;
        }

        if (!typeOfPatient) {
          notification.warning({ message: "Please Select Patient Type" });
          return;
        }
        if (!valData.name) {
          notification.warning({ message: "Please Enter Name" });
          return;
        }
        if (!valData.mobile) {
          notification.warning({ message: "Please Select Number" });
          return;
        }

        if (!valData.doctorId) {
          notification.warning({ message: "Please Select Doctor" });
          return;
        }

        if (!valData.state) {
          notification.warning({ message: "Please Select State" });
          return;
        }
        if (!valData.city) {
          notification.warning({ message: "Please Enter City" });
          return;
        }

        if (!valData.gender) {
          notification.warning({ message: "Please Select Gender" });
          return;
        }

        if (!valData.age) {
          notification.warning({ message: "Please Enter Age" });
          return;
        }

        if (!valData.address) {
          notification.warning({ message: "Please Fill Address" });
          return;
        }

        // if (valData.invoiceNumber) {
        //   updatedInvoiceNumber();
        //   valData.invoiceNumber = invoice;
        // }
        if (
          valData.patientVisit === 'Free' && 
          slipRecord.some(record => record.visitCount < 2 && record.type === "Slip")
        ) {
          notification.warning({
            message: 'Cannot Add Free Slip when u have a slip',
          });
          return; 
        }
        if (
          valData.patientVisit === 'Free' && 
          slipRecord.some(record => record.visitCount && record.type === "Membership")
        ) {
          notification.warning({
            message: 'Cannot Add Free Slip when u have a Membership',
          });
          return; 
        }
      
        valData.customerGroupName = groupNameObj.sundryDebtors;
        valData.type = "patient";

        valData.membershipType = "paid";
        if (valData.patientVisit !== 'Free' && ProductReport?.length === 0) {
          notification.warning({
            message: "Please Add Products",
          });
          return;
        }

        valData.productReports = ProductReport;

        valData.salesDate = moment();
        // valData.symptomId = symptomIdVal;
        valData.patientType = typeOfPatient;
        valData._id = patientId;
        valData.startDate = startDate;
        valData.expiryDate = expireDate;
        valData.paidToUser = formData;
        
        console.log(valData,"abcdef");
        const sumFormDataAmount = formData.reduce(
          (sum, item) => sum + parseFloat(item.amount) || 0,
          0
        );


        const totalAmount = valData.totalAmount;
        const paidAmount = valData.paidAmount;

        if (parseFloat(paidAmount) > parseFloat(totalAmount)) {
          notification.error({
            message: "paid amount cannot be Greater than total amount",
          });
          return null;
        }

        if (parseFloat(valData.paidAmount) < sumFormDataAmount) {
          notification.error({
            message: "Paid amount cannot be Greater than total amount",
          });
          return;
        } else if (parseFloat(valData.paidAmount) > sumFormDataAmount) {
          notification.error({
            message: "Paid amount cannot be less than total amount",
          });
          return;
        }

        let x = await dispatch(updatePatientFxn(valData));
        // navigate("/patient");

        props.form.setFieldsValue({
          name: "",
          doctorId: "",
          email: "",
          mobile: "",
          state: "",
          city: "",
          pincode: "",
          address: "",
          gender: "",
          age: "",
        });
        setProductReport([]);
      }
    });
  };

  const generateSlip = async () => {
    try {
      let config = {
        ...(await getToken()),
      };
      const response = await axios.post(
        generateSlipUrl(),
        { customerId: customerId },
        config
      );
      if (!response.data.error) {
        window.location.reload();
        notification.success({ message: response.data.message });
        return;
      } else {
        notification.error({ message: response.data.message });
      }

      //
    } catch (error) {
      console.error("Error generating slip:", error);
    }
  };

  let inputTypes = {
    upperFields: [
      {
        key: "name",
        label: "Patient Name*",
        span: "col-md-3",
        placeholder: "Type here..",
      },
      {
        key: "patientVisit",
        label: "Patient Visit",
        span: "col-md-3",
        type: "select",
        options: visitType,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            patientVisit: x,
          });
        },
       
      },
      {
        key: "email",
        label: "Email",
        span: "col-md-3",
        placeholder: "Type here.. ",
      },
      {
        key: "dateOfBirth",
        label: "Date of Birth",
        showStar: true,
        placeholder: "Date of Birth",
        type: "date",
        span: "col-md-3",
        onChange: (x) => {
          props.form.setFieldsValue({
            dateOfBirth: x,
          });
        },
      },
      {
        key: "mobile",
        type: "number",
        label: "Mobile No *",
        span: "col-md-3",
        placeholder: "Mobile No",
        disabled: typeOfPatient == "Old Patient",
      },
      {
        key: "doctorId",
        label: "Select Doctor *",
        span: "col-md-3",
        placeholder: "select",
        type: "select",
        options: doctor,
        showSearch: true,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            doctorId: x,
          });
        },
      },
      {
        key: "state",
        label: "State *",
        span: "col-md-3",
        placeholder: "State",
        type: "select",
        options: States,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            state: x,
          });
        },
        disabled: getFieldValue("state"),
      },
      {
        key: "city",
        label: "City *",
        span: "col-md-3",
        placeholder: "City",
      },
      {
        key: "pincode",
        label: "Pincode",
        span: "col-md-3",
        placeholder: "Pincode",
      },
      {
        key: "gender",
        label: "Gender*",
        span: "col-md-3",
        type: "select",
        options: gender,
        showSearch: true,
        keyAccessor: (x) => x.value,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            gender: x,
          });
        },
      },
      {
        key: "age",
        label: "Age*",
        span: "col-md-3",
        type: "number",
        placeholder: "Enter your Age",
      },
      // {
      //   key: "invoiceNumber",
      //   label: "Invoice Number",
      //   span: "col-md-3",
      //   placeholder: "Invoice Number",
      //   disabled: true,
      // },
      {
        key: "patientCategory",
        label: "Patient Category",
        span: "col-md-3",
        type: "select",
        options: PatientCategory,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            PatientCategory: x,
          });
        },
      },
      // {
      //   key: "referralCode",
      //   label: "Referral",
      //   span: "col-md-3",
      //   type: "select",
      //   options: ReferralCode,
      //   showSearch: true,
      //   keyAccessor: (x) => x.referralCode,
      //   valueAccessor: (x) => `${x.referralCode}`,
      //   onChange: (x) => {
      //     props.form.setFieldsValue({
      //       referralCode: x,
      //     });
      //   },
      // },
      {
        key: "address",
        label: "Address *",
        span: "col-md-6",
        type: "textarea",
        placeholder: "Address",
      },
    ],
    lowerFields: [
      {
        key: "totalAmount",
        label: "Total Amount *",
        span: "col-md-3",
        placeholder: "Total Amount",
        onChange: (x) => {
          props.form.setFieldsValue({
            totalAmount: netAmount,
          });
        },
        disabled: true,
      },
      {
        key: "paidAmount",
        type: "number",
        label: "Received Amount",
        span: "col-md-3",
        placeholder: "Received Amount",
      },
      {
        key: "balance",
        label: "Balance *",
        span: "col-md-3",
        placeholder: "Balance",
        onChange: (x) => {
          props.form.setFieldsValue({
            balance: balance,
          });
        },
        disabled: true,
      },
    ],
  };
  useEffect(() => {
    props.form.setFieldsValue({
      patientType: "New Patient",
    });
    props.form.setFieldsValue({
      patientVisit: "Paid",
    });
  }, []);


  return (
    <>
      <PageHeader title={"Edit Patient"}>
        <div className="card">
          <div className="card-body">
      <div
        className={"row justify-content-start align-items-center gap-5 mb-3"}
      >
        <InputBox title={"Patient Type*"} className={"col-3"}>
          <select
            style={{ height: "45px" }}
            className="form-control patient-type"
            value={typeOfPatient}
            onChange={(e) => setTypeOfPatient(e.target.value)}
            required
          >
            <option value="">Select Type</option>
            {patientType.map((stock) => (
              <option key={stock.name} value={stock.name}>
                {stock.name}
              </option>
            ))}
          </select>
        </InputBox>

        {typeOfPatient == "Old Patient" && (
          <>
            <div className={"col-3 "} style={{ width: "286px" }}>
              <ChoosePatient
                type={"patient"}
                className="form-control patient-type"
                callback={(clientData) => {
                  if (clientData && clientData._id) {
                    setCustomerId(clientData._id);
                  }
                }}
              />
            </div>
          
            <InputBox className={"col-4 methodss"}>
              <label className="previous-record">Previous Visit Record</label>
              <table class="table table-bordered previous-visit-record">
                <thead>
                  <tr>
                  <th scope="col">Type</th>
                    <th scope="col">Doctor Name</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Expiry Date</th>
                    <th scope="col">Count</th>
                  </tr>
                </thead>
                <tbody>
                  {slipRecord?.map((item, index) => {
                    return (
                      <>
                        <tr>
                        <td     className={"empty_btn m-3 " }>{item?.type ? item.type.charAt(0) : ''}</td>
                          <td>{item?.doctorId?.name}</td>
                          <td>
                            {" "}
                            {moment(item.startDate).format("DD/MM/YYYY") ? moment(item.startDate).format("DD/MM/YYYY") : ''}
                            {}
                          </td>
                          <td>
                            {" "}
                            {moment(item.expiryDate).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            {item.visitCount}
                            {item.type === "Slip" && item.visitCount < 2 ? (
                              <>
                                <Popconfirm
                    title={"Are your sure, you want to generate visit?"}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                      generateSlip();
                    }}>
                     <button  className=" btn btn-info btn-sm m-2">Create Visit</button>
                </Popconfirm>
                              </>
                              // <button
                              //   onClick={generateSlip}
                              //   className=" btn btn-info btn-sm m-2"
                              // >
                              //    Create Visit
                              // </button>
                              
                            ) : item.type === "Membership" ? (
                              <>
                                      <Popconfirm
                    title={"Are your sure, you want to generate visit?"}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                      generateSlip();
                    }}>
                     <button  className=" btn btn-info btn-sm m-2">Create Visit</button>
                </Popconfirm>
                              </>
                              // <button
                              //   onClick={generateSlip}
                              //   className={"btn btn-info btn-sm m-2"}
                              // >
                              //    Create Visit
                              // </button>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              {/* {slipRecord?.map((item, index) => {
                return (
                  <>
                    <label className="previous-record">
                      Previous Visit Record
                    </label>
                    <div className="input-box-content ">
                      <label>Doctor Name:</label>
                      <div className="column">{item?.doctorId?.name}</div>
                      {item && item.date ? (
                        <>
                          <label>Start Date:</label>
                          <div className="column">
                            {moment(item.startDate).format("DD/MM/YYYY")}
                          </div>
                          <label>Expiry Date:</label>
                          <div className="column">
                            {moment(item.expiryDate).format("DD/MM/YYYY")}
                          </div>
                          <div className="column">{item.visitCount}</div>
                          <div className="column">
                            {item.type === "Slip" && item.visitCount < 2 ? (
                              <button onClick={generateSlip}>+</button>
                            ) : item.type === "Membership" ? (
                              <button onClick={generateSlip}>+</button>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="column">-</div>
                          <div className="column">-</div>
                        </>
                      )}
                    </div>
                  </>
                );
              })} */}
            </InputBox>
          </>
        )}
      </div>

      <Form onSubmit={handleSubmit}>
        <div className="form-elements-wrapper">
          <div className={"row"}>
            {inputTypes.upperFields.map((item, key) => {
              return !item.hidden ? (
                <div
                  className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                  key={key}
                >
                  <GetEachFormFields {...props.form} item={item} />
                </div>
              ) : null;
            })}
            <div className={"col-md-3 mb-3"}>
              <ChooseSymptom
                // type="customer"
                symptomId={symptomId}
                callback={(clientData) => {
                  if (clientData && clientData._id) {
                    setSymptomId(clientData._id);
                  }
                }}
              />
            </div>
          </div>

          {/* <div className={"row"}>
            <div className={"col-md-12 mt-4"}>
              <ItemListComponent
                ProductReport={ProductReport}
                // customerId={customerId}
                updateProductReport={updateProductReport}
                statesAreEqual={true}
              />
            </div>
          </div> */}

          {getFieldValue("patientVisit") !== "Free" && (
            <div className={"col-md-12 mt-4"}>
              <ItemListComponent
                ProductReport={ProductReport}
                updateProductReport={updateProductReport}
                statesAreEqual={true}
                companyState={companyState}
                stateOfSupply={stateOfSupply}
              />
            </div>
          )}
        </div>
        {getFieldValue("patientVisit") !== "Free" && (
          <div className={"row"}>
            {inputTypes.lowerFields.map((item, key) => {
              return !item.hidden ? (
                <div
                  className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                  key={key}
                >
                  <GetEachFormFields {...props.form} item={item} />
                </div>
              ) : null;
            })}
          </div>
        )}

        {getFieldValue("paidAmount") ? (
          <Card title={"Payments"} size={"small"}>
            <div className="d-flex align-items-end">
              <div>
                {formData.map((data, index) => (
                  <div key={index} className={"item-flex mt-2"}>
                    <InputBox title={"Banks"}>
                      <select
                        style={{ height: "45px" }}
                        className="form-control"
                        value={data.bank}
                        onChange={(e) =>
                          handleInputChange(index, "bankId", e.target.value)
                        }
                        required="true"
                      >
                        <option value="">Select Bank</option>
                        {banks.map((bank) => (
                          <option key={bank._id} value={bank._id}>
                            {bank.name}
                          </option>
                        ))}
                      </select>
                    </InputBox>

                    <InputBox title={"Payment Type"}>
                      <select
                        style={{
                          height: "45px",
                        }}
                      
                        className="form-control"
                        value={data.paymentType}
                        
                        required
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "paymentType",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select Payment Type</option>
                        {PaymentTypes.map((PaymentTypes) => (
                          <option>{PaymentTypes.name}</option>
                        ))}
                      </select>
                    </InputBox>

                    <InputBox title={"Amount"}>
                      <input
                        style={{
                          height: "45px",
                        }}
                        className={"form-control"}
                        type="number"
                        value={data.amount}
                        onChange={(e) =>
                          handleInputChange(index, "amount", e.target.value)
                        }
                        placeholder="Amount"
                      />
                    </InputBox>

                    <InputBox title={"Transaction Details"}>
                      <input
                        style={{
                          height: "45px",
                        }}
                        className={"form-control"}
                        value={data.transactionDetails}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "transactionDetails",
                            e.target.value
                          )
                        }
                        placeholder="Transaction Details"
                      />
                    </InputBox>
                    <InputBox className="deleteBtn">
                      <div key={index} className={"item-flex mt-3"}>
                        {index > 0 &&
                          showDeleteButtons[index] && ( // Condition added to not show delete button for zero index
                            <a
                              className="empty_btn"
                              onClick={() => handleDelete(index)}
                            >
                              <i className="fa fa-trash-alt"></i>
                            </a>
                          )}
                      </div>
                    </InputBox>
                  </div>
                ))}
              </div>
              <div>
                <InputBox className={"pb-2"}>
                  <a className="addBtn  btn-link" onClick={handleAddField}>
                    Add More
                  </a>
                </InputBox>
              </div>
            </div>
          </Card>
        ) : null}

        {getFieldValue("slipType") && (
          <div className="border border-dark p-3 mb-4">
            <div className="form-elements-wrapper mt-4">
              <div className="row">
                <div className="col-md-3">
                  <strong>Start Date: </strong>{" "}
                  {dates.startDate && dates.startDate.format("DD/MM/YYYY")}
                </div>
                <div className="col-md-3">
                  <strong>Expiry Date: </strong>{" "}
                  {dates.expiryDate && dates.expiryDate.format("DD/MM/YYYY")}
                </div>
                <div className="col-md-3">
                  <strong>Validity: </strong> {dates.validity}
                </div>
              </div>
            </div>
          </div>
        )}
        <button type="submit" className="btn btn-gradient-info me-2">
          Submit
        </button>
        <HyperLink className=" ms-4 btn btn-danger" link={"/patient"}>
          Cancel
        </HyperLink>
      </Form>
      </div>
      </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(EditPatient);
