import React from "react";
import PageHeader from "../../components/Elements/pageHeader";
import AddCustomerComponent from "./components/addCustomComponent";

const AddCustomer = (props) => {
    return (
        <PageHeader title={'Add Customer'}>
            <div className="card">
                <div className="card-body">
                    <AddCustomerComponent/>
                </div>
            </div>
        </PageHeader>
    );
}

export default AddCustomer;
