import React from "react";
import PageHeader from "../../components/Elements/pageHeader";
import AddPatientComponent from "./components/addPatient";

const AddPatient = (props) => {
    return (
        <PageHeader title={'Add Patient'}>
            <div className="card">
                <div className="card-body">
                    <AddPatientComponent/>
                </div>
            </div>
        </PageHeader>
    );
}

export default AddPatient;
