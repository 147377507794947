import React, { useEffect, useState } from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../components/Elements/appUtils";
import { PaymentTypes } from "../../components/_utils/appUtils";
import moment from "moment";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { fetchBank } from "../customer/actions";
import { fetchExpenseCategory } from "./actions";
import _ from "lodash";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { singleExpenseFxn, updateExpenseFxn } from "./actions";
import { fetchAllEmp } from "../employee/action";
import { getGroupDataByCustomerIdUrl } from "../customerGroup/api";
const EditExpense = (props) => {
  
  const [groupName, setGroupName] = useState(null);
  let [customerId, setCustomerId] = useState("");
  const { getFieldValue, setFieldsValue } = props.form;
  const [category, setCategory] = useState([]);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [expenseId, setExpenseId] = useState("");
  const [banks, setBanks] = useState([]);

  const paidToUserId = getFieldValue("paidToUserId");

  useEffect(() => {
    checkExpenseId();
  }, []);

  const checkExpenseId = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      getSingleBranch(id);
      setExpenseId(id);
    }
  };
  const getSingleBranch = async (id) => {
    let { data, success } = await dispatch(singleExpenseFxn(id));
    console.log(data, "valdata");
    if (success) {
      setTimeout(() => {
        props.form.setFieldsValue({
          category: data.category,
          amount: data.amount,
          mobile: data.mobile,
          paymentType: data.paymentType,
          detail: data.detail,
          date: data.date ? moment(data.date) : "",
          employee: data.employee
        });
      }, 300);
    }
  };
  const [allList, setAllList] = useState([]);
  
    const apiRequest1 = (params) => {
      return new Promise(async (resolve) => {
        const data = await fetchAllEmp({...params});
          setAllList(data.data)
          console.log(data.data, "alllis")
         
         
        resolve(data)
      });
    };


  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      if (!err) {
        valData._id = expenseId;

        let x = await dispatch(updateExpenseFxn(valData));
        if (x && x.success) {
          console.log(x, "valdata");
          navigate("/xxpense");
        }
      } else {
        if (err.name) {
          notification.warning({
            message: `Please enter Name`,
          });
          return;
        }

        if (err.email) {
          notification.warning({
            message: `Please enter Email`,
          });
          return;
        }

        if (err.mobile) {
          notification.warning({
            message: `Please enter Mobile No`,
          });
          return;
        }

        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };
  const fetchBanks = async () => {
    const { data } = await fetchBank({
      type: "bank",
      results: 100,
      count: 100,
    });
    setBanks(data);
  };
  const fetchGroupName = async () => {
    try {
      const response = await axios.get(getGroupDataByCustomerIdUrl(), {
        params: { customerId: paidToUserId },
      });
      setGroupName(response.data.data.name);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    props.form.setFieldsValue({
      paidAmount: 0,
      paymentType: "None",
     
    });
 
    fetchBanks();
  }, [customerId]);

  useEffect(() => {
    fetchGroupName();
  }, [paidToUserId]);
let inputTypes = {
  fields: [
   
    {
      key: "category",
      label: "Expense Category *",
      span: "col-md-6",
      placeholder: "Category",
      type: "select",
      options: category,
      showSearch: true,
      keyAccessor: (x) => x._id,
      valueAccessor: (x) => `${x.category}`,
      onChange: (x) => {
        props.form.setFieldsValue({
          category: x,
        });
      },
    },

    {
      key: "date",
      label: "Expense Date",
      showStar: true,
      placeholder: "Date",
      type: "date",
      span: "col-md-6",
      onChange: (x) => {
        props.form.setFieldsValue({
          date: x,
        });
      },
    },
    {
      key: "employee",
      label: "Employee",
      span: "col-md-6",
      placeholder: "Select Employee",
      type: "select",
      options: allList,
      showSearch: true,
      keyAccessor: (x) => x._id,
      valueAccessor: (x) => `${x.name}`,
      onChange: (x) => {
        props.form.setFieldsValue({
          employee: x,
        });
      },
    },
    {
      key: "netAmount",
      label: "Paid Amount",
      showStar: true,
      placeholder: "Amount",
      type: "number",
      span: "col-md-6",
     
    },
    {
      key: "paidToUserId",
      label: "Select Bank *",
      span: "col-md-6",
      placeholder: "Bank",
      type: "select",
      options: banks,
      showSearch: true,
      keyAccessor: (x) => x._id,
      valueAccessor: (x) => `${x.name}`,
      onChange: (x) => {
        props.form.setFieldsValue({
          paidToUserId: x,
        });
      },
      hidden: getFieldValue("paidAmount") == 0,
    },
    {
      key: "paymentType",
      label: "Payment type",
      span: "col-md-6",
      type: "select",
      placeholder: "None",
      options: PaymentTypes,
      showSearch: true,
      keyAccessor: (x) => x.name,
      valueAccessor: (x) => `${x.name}`,
      onChange: (x) => {
        props.form.setFieldsValue({
          paymentType: x,
        });
      },
      required: getFieldValue("paidToUserId"),
      hidden: groupName === "CASH IN HAND",
    },
    {
      key: "paymentDetails",
      label: "Payment Details (Optional)",
      span: "col-md-6",
      placeholder: "Payment Details",
    
    },
    
  ],
};

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      const data = await fetchExpenseCategory({ ...params });

      setCategory(data.data);
      resolve(data);
    });
  };
  useEffect(() => {
    apiRequest();
    apiRequest1()
  }, []);
  return (
    <>
      <PageHeader title={"Edit Expense"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary me-2">
                Submit
              </button>
              <HyperLink
                className=" ms-4 btn btn-danger"
                link={"/company-list"}
              >
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(EditExpense);
