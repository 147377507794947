import _ from "lodash";
import TableMain from "../../../components/Elements/Table";
import { useEffect } from "react";
import { calculation } from "../../../components/Elements/appUtils";
const ServiceModalComponent = (props) => {
    let {
        stockServiceAll,
        setStockServiceAll,
        serviceState,
        setServiceState
      } = props;

      const reCalculateAmount = () => {
        calculation({ ...serviceState }).then((resp) => {
          setServiceState({
            ...serviceState,
            ...resp,
          });
        });
      };
      useEffect(() => {
        reCalculateAmount();
      }, [
        serviceState.serviceId,
        serviceState.name,
      
  
      ]);
      const handleDeleteService = (index) => {
        reCalculateAmount();
        const updatedServiceReport = [...stockServiceAll];
        updatedServiceReport.splice(index, 1);
        setStockServiceAll(updatedServiceReport);
      };
    const column = [
        {
          title: "Sr. No",
          dataIndex: "serialNo",
          key: "serialNo",
          render: (text, record, index) => index + 1,
        },
        {
          title: "Service Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Action",
          key: "delete",
          render: (text, record, index) => (
            <a className="nav-link" onClick={() => handleDeleteService(index)}>
              <i className="mdi mdi-delete"></i>
            </a>
          ),
        },
       
      ];
      //
      // const getcolumsvalue = () => {
      //     let columrecord = [];
      //     _.each(column, (item) => {
      //
      //       if (!item.show) {
      //
      //         columrecord.push(item);
      //
      //       }
      //     });
      //
      //
      //     return columrecord;
      //   };
    return(
        <>
          <div key={stockServiceAll.length} className="mt-4 mb-4">
                <h4 className="m-2">Service Details</h4>
                <TableMain
                  dataSource={stockServiceAll}
                  columns={column}
                />
              </div>
        </>
    )
}
export default ServiceModalComponent;