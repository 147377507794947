import React from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import {
  IndianStates,
  States,
  gstTypeList,
} from "../../components/_utils/appUtils";
import { addCompanyFxn } from "./actions";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const CompanyForm = (props) => {
  const { getFieldValue } = props.form;
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        if (!valData.companyName) {
          notification.warning({ message: "please Enter Company Name" });
          return;
        }
      // e  if (!valData.name) {
      //     notification.warning({ message: "please Enter contact person Name" });
      //     rturn;
      //   }
        if (!valData.email) {
          notification.warning({ message: "please Enter Email" });
          return;
        }
        if (!valData.password) {
          notification.warning({ message: "please Enter password" });
          return;
        }
        if (!valData.mobile) {
          notification.warning({ message: "please Enter mobile" });
          return;
        }
        if (!valData.state) {
          notification.warning({ message: "please select State" });
          return;
        }
        let findStateCode = _.find(IndianStates, (item) => {
          return item.name === valData.state;
        });
        if (findStateCode) {
          valData.stateCode = findStateCode.stateCode;
        }
        if (!valData.city) {
          notification.warning({ message: "please select city" });
          return;
        }
        if (!valData.pincode) {
          notification.warning({ message: "please select pincode" });
          return;
        }
        if (!valData.gstType) {
          notification.warning({ message: "please select Gst Type" });
          return;
        }

        let x = await dispatch(addCompanyFxn(valData));
        if (x && x.success) {
          setTimeout(() => {
            navigate("/company-list");
          }, 1000);
          props.form.setFieldsValue({
            companyName: "",
            name: "",
            email: "",
            password: "",
            mobile: "",
            state: "",
            city: "",
            pincode: "",
            document: "",
            gstType: "",
            gstIn: "",
            address: "",
          });
        }
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "companyName",
        label: "Company Name*",
        span: "col-md-6",
        placeholder: "Company Name",
      },
      // {
      //   key: "name",
      //   label: "Contact Person Name*",
      //   span: "col-md-6",
      //   placeholder: "Contact Person Name",
      // },
      {
        key: "email",
        label: "Email*",
        type: "email",
        span: "col-md-6",
        placeholder: "Email",
      },
      {
        key: "password",
        label: "Password*",
        type: "password",
        span: "col-md-6",
        placeholder: "Password",
        minLength: 6,
      },
      {
        key: "mobile",
        label: "Mobile No*",
        span: "col-md-6",
        placeholder: "Mobile No",
      },
      {
        key: "state",
        label: "State*",
        span: "col-md-6",
        placeholder: "State",
        type: "select",
        options: IndianStates,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            state: x,
          });
        },
      },
      {
        key: "city",
        label: "City*",
        span: "col-md-3",
        placeholder: "City",
      },
      {
        key: "pincode",
        label: "Pincode*",
        span: "col-md-3",
        placeholder: "Pincode",
      },

      {
        key: "gstType",
        label: "GST Type*",
        span: "col-md-6",
        placeholder: "GST Type",
        type: "select",
        options: gstTypeList,
        showSearch: true,
        keyAccessor: (x) => x.value,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            gstType: x,
          });
        },
      },
      {
        key: "gstIn",
        label: "GSTIN",
        span: "col-md-6",
        placeholder: "GSTIN",
        hidden: getFieldValue("gstType") !== "Registered",
        required: getFieldValue("gstType") === "Registered",
      },
      {
        key: "address",
        label: "Address",
        span: "col-md-12",
        placeholder: "Address",
        type: "textarea",
      },
    ],
  };

  return (
    <>
      <PageHeader title={"Company Registration"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary me-2">
                Submit
              </button>
              <HyperLink
                className=" ms-4 btn btn-danger"
                link={"/company-list"}
              >
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(CompanyForm);
