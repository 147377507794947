import React, {useEffect, useRef, useState} from "react";
import {Popconfirm, Table, Tooltip, notification} from "../../components/Elements/appUtils";
import {fetchService, getServiceforReceptionFxn} from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import _, {set} from "lodash";
import {getItemLocalstorage} from "../../components/_utils/_utils";
import {useNavigate} from "react-router-dom";
import {DefaultTablePagination} from "../../components/Elements/appUtils";
import axios from "axios";
import { deleteServiceUrl } from "./api";

function Service() {
    const [searchServiceName, setSearchServiceName] = useState("");
    const [searchServiceType, setSearchServiceType] = useState("");
    const [searchHsnCode, setSearchHsnCode] = useState("");
    const [searchCode, setSearchCode] = useState("");
    const [searchDescription, setSearchDescription] = useState("");
    const [serviceData, setServiceData] = useState([]);
    const [filteredServices, setFilteredServices] = useState([]);
    const [showFilter, setShowFilter] = useState(false)
    const navigate = useNavigate()
    const userType = getItemLocalstorage("user")["userType"];
    let showViewButton;
    if (userType === "doctor") {
        showViewButton = false;
    } else {
        showViewButton = true;
    }

    let tableRef = useRef();

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                const response = await getServiceforReceptionFxn({...params});
                setServiceData(response.data);
                setFilteredServices(response.data);
                resolve(response);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const deleteService = async (id) => {
      
        try {
          const response = await axios.delete(deleteServiceUrl(id));
          console.log(response, "response");
          if (response.data.error) {
            notification.error({
              message: response.data.message,
            });
          } else {
            tableRef.current.reload();
            notification.success({
              message: response.data.message,
            });
          }
        } catch (error) {
          console.error("Error getting the data:", error);
        }
      };
    console.log(serviceData, "serviceData")
    useEffect(() => {
        apiRequest()
    }, [])

    const filterServices = async () => {
        setShowFilter(true)
        if (!searchServiceName && !searchServiceType && !searchHsnCode && !searchCode && !searchDescription) {
            notification.error({message: "please enter name"});
            setShowFilter(false)
            return
        } else {
            const filteredServices = serviceData.filter((service) =>
                service.name.toLowerCase().includes(searchServiceName.toLowerCase()) &&
                service.type.toLowerCase().includes(searchServiceType.toLowerCase()) &&
                service.hsnCode.toLowerCase().includes(searchHsnCode.toLowerCase()) &&
                service.code.toLowerCase().includes(searchCode.toLowerCase()) &&
                service.description.toLowerCase().includes(searchDescription.toLowerCase())
            );
            setServiceData(filteredServices)
            console.log(filteredServices, "filteredServices")

        }
    };

    const clearServiceFilters = async () => {
        setSearchServiceName("");
        setSearchServiceType("");
        setSearchHsnCode("");
        setSearchCode("");
        setSearchDescription("");
        setShowFilter(false);
        setServiceData(filteredServices)
        if (tableRef.current.reload) {
            tableRef.current.reload()
        }
    };


    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",

        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",

        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code",

        },
        {
            title: "HSN Code",
            dataIndex: "hsnCode",
            key: "hsnCode",


        },
        {
            title: "Gst Rate",
            dataIndex: "gst",
            key: "gst",
        },

        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Actions",
            dataIndex: "description",
            key: "description",
            hide: !showViewButton,
            render: (text, record) => (
                <>

                    <Tooltip title={'Edit Product'}>
                        <a onClick={() => {
                            navigate(`/edit-service?_id=${record._id}`)
                        }}>
                            <img src={"./edit.svg"}/>

                        </a>
                    </Tooltip>
                    <Tooltip title={"Delete"} className="ml-4">
                <Popconfirm
                  title={"Are your sure, you want to delete Service?"}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    deleteService(record._id);
                  }}
                >
                  <img
                    className="ms-2"
                    style={{ height: "30px", width: "30px" }}
                    src={"./close.svg"}
                  />
                </Popconfirm>
              </Tooltip>
                </>

            ),
        },
    ];

    const getColumns = () => {
        let columnArr = [];
        _.each(columns, (item) => {
            if (!item.hide) {
                columnArr.push(item);
            }
        });
        return columnArr;
    };

    return (
        <PageHeader
            title={"Services"}
            extraLink={
                userType === "company"
                    ? [
                        {
                            name: "Add Product",
                            link: "/add-product",
                        },
                    ]
                    : []
            }
        >
            <div className="card">
                <div className="card-body">
                    <div className=" row">
                        <div className="col-md-2 mb-3 pe-0" style={{paddingLeft: '7px'}}>
                            <label htmlFor="searchServiceName" className="form-label"/>

                            <input
                                type="text"
                                className="form-control search-filters"
                                id="searchServiceName"
                                placeholder="Search by Service Name"
                                value={searchServiceName}
                                onChange={(e) => setSearchServiceName(e.target.value)}
                            />
                        </div>
                        <div className="col-md-2 mb-3 pe-0" style={{paddingLeft: '7px'}}>
                            <label htmlFor="searchServiceType" className="form-label"/>
                            <input
                                type="text"
                                className="form-control search-filters"
                                id="searchServiceType"
                                placeholder="Search by Service Type"
                                value={searchServiceType}
                                onChange={(e) => setSearchServiceType(e.target.value)}
                            />
                        </div>
                        <div className="col-md-2 mb-3 pe-0" style={{paddingLeft: '7px'}}>
                            <label htmlFor="searchCode" className="form-label"/>
                            <input
                                type="text"
                                className="form-control search-filters"
                                id="searchCode"
                                placeholder="Search by Code"
                                value={searchCode}
                                onChange={(e) => setSearchCode(e.target.value)}
                            />
                        </div>
                        <div className="col-md-2 mb-3 pe-0" style={{paddingLeft: '7px'}}>
                            <label htmlFor="searchHsnCode" className="form-label"/>
                            <input
                                type="text"
                                className="form-control search-filters"
                                id="searchHsnCode"
                                placeholder="Search by HSN Code"
                                value={searchHsnCode}
                                onChange={(e) => setSearchHsnCode(e.target.value)}
                            />
                        </div>
                        <div className="col-md-2 mb-3 pe-0" style={{paddingLeft: '7px'}}>
                            <label htmlFor="searchDescription" className="form-label"/>
                            <input
                                type="text"
                                className="form-control search-filters"
                                id="searchDescription"
                                placeholder="Search by Description"
                                value={searchDescription}
                                onChange={(e) => setSearchDescription(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="mb-2 search-buttons">
                        <div className="search-wrap pe-2">
                            <button className="btn btn-info" onClick={filterServices}>
                                Search
                            </button>
                        </div>
                        <div className="search-wrap">
                            <button className="btn btn-secondary" onClick={clearServiceFilters}>
                                Clear
                            </button>
                        </div>
                    </div>
                    {showFilter ? (
                        <Table
                            dataSource={serviceData}
                            columns={getColumns()}
                            ref={tableRef}
                            key={`table-${showFilter ? 'filtered' : 'original'}`}
                        />
                    ) : (
                        <Table
                            apiRequest={apiRequest}
                            columns={getColumns()}
                            ref={tableRef}
                            pagination={DefaultTablePagination()}

                        />
                    )}
                </div>
            </div>
        </PageHeader>
    );
}

export default Service;
