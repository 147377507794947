import React, { useEffect, useRef, useState } from "react";
import { Modal, Table } from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { useNavigate } from "react-router-dom";
import { fetchPrecription } from "./actions";
import { getItemLocalstorage } from "../../components/_utils/_utils";

function PrescriptionList() {
  const userType = getItemLocalstorage("user")["userType"];
  let tableRef = useRef();
  const navigate = useNavigate();
  const [precripedMedicines, setPrescribedMedicine] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log(precripedMedicines, "thisis precribed medicine");

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await fetchPrecription({ ...params });
        console.log(data, "this is data");
        resolve(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const ConvertSale = (id) => {
    setTimeout(() => {
      navigate(`/add-sales?requestedMedId=${id}`);
    }, 1000);
  };

  useEffect(() => {
    apiRequest();
  }, []);

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
    },
    {
      title: "Visit Number",
      dataIndex: "visitNumber",
      key: "visitNumber",
    },
    {
      title: "Next Follow up Date",
      dataIndex: "nextday",
      key: "nextday",
    },
    {
      title: "Remarks",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 190,
      render: (val, record) => {
        return (
          <>
            {userType == "reception" ? (
              <button
              onClick={() => {
                setPrescribedMedicine(record.products);
                setIsModalOpen(true);
              }}
              className="btn btn-info btn-xs m-2"
              >
                View 
              </button>
            ) : null}

            {userType == "reception" ? (
              <button
              className="btn btn-success btn-xs"
                onClick={() => {
                  // setRequestId(record._id);
                  ConvertSale(record._id);
                }}
              >
                convert
              </button>
            ) : null}
          </>
        );
      },
    },
  ];
  return (
    <>
      <PageHeader title={"Precription"}>
        <div className="card">
          <div className="card-body">
            <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
          </div>
        </div>
        {isModalOpen && (
          <Modal
            title={"Medicines"}
            width={"50%"}
            visible={isModalOpen == true}
            onClose={() => setIsModalOpen(false)}
          >
            <table class="table table-bordered mt-4">
              <thead>
                <td>Sr no.</td>
                <td>Product Name</td>
                <td>Dosage</td>
                <td>Frequency/Routine</td>
                <td>Remarks</td>
                <td>When to Taken</td>
              </thead>
              <tbody>
                {precripedMedicines.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.productName}</td>
                    <td>{item.dosage}</td>
                    <td>{item.frequency}</td>
                    <td>{item.remarks}</td>
                    <td>{item.whentotake}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal>
        )}
      </PageHeader>
    </>
  );
}

export default PrescriptionList;
