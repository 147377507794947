import React, {useState, useRef} from "react";
import {customAxios as axios} from "../../request";
import {
    Table,
    notification,
    Tooltip,
    Popconfirm,
} from "../../components/Elements/appUtils";
import {deleteExpenseUrl} from "./api";
import moment from "moment";
import {fetchAllExpense} from "./actions";
import PageHeader from "../../components/Elements/pageHeader";

function JournalEntryList() {
    let tableRef = useRef();
    const [allList, setAllList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    // const [searchName, setSearchName] = useState("");
    // const [searchCategory, setSearchCategory] = useState("");
    const [searchDate, setSearchDate] = useState("")
    let [dates, setDates] = useState({
        fromDate: "",
        toDate: "",
    });
    const apiRequest = (params = {}) => {
        return new Promise(async (resolve) => {

            if (dates.fromDate && dates.toDate) {
                const fromDateStartOfDay = new Date(dates.fromDate);
                fromDateStartOfDay.setHours(0, 0, 0, 0);

                const toDateEndOfDay = new Date(dates.toDate);
                toDateEndOfDay.setHours(23, 59, 59, 999);

                params.date = {
                    $gte: new Date(fromDateStartOfDay),
                    $lte: new Date(toDateEndOfDay),
                };
            }
            const data = await fetchAllExpense({...params});
            setAllList(data.data)
            setFilteredData(data.data)
            resolve(data.data)
        });
    };

    const deleteExpense = async (id) => {

        try {
            const response = await axios.delete(deleteExpenseUrl(id));
            console.log(response, "response");
            if (response.data.error) {
                notification.error({
                    message: response.data.message,
                });
            } else {
                tableRef.current.reload();
                notification.success({
                    message: response.data.message,
                });
            }
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };


    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Invoice No.",
            dataIndex: "invoiceNumber",
            key: "invoiceNumber",
        },
        {
            title: "From Client",
            dataIndex: "client",
            key: "client",
            render: (text, record) => (
                <span>{record?.fromClient?.name} </span>
            ),
        }, {
            title: "To Client",
            dataIndex: "client",
            key: "client",
            render: (text, record) => (
                <span>{record?.toClient?.name} </span>
            ),
        },
        {
            title: "Amount",
            dataIndex: "netAmount",
            key: "netAmount",
            render: (text, record) => (
                <span>Rs. {text} </span>
            ),
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text, record) => (
                <span>{moment(record.date).format("DD/MM/YYYY")} </span>
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 190,
            render: (val, record) => {
                return (
                    <>
                        {/* <a onClick={() => {
                    navigate(`/edit-expense?_id=${record._id}`)
                  }}>
                    <img src={"./edit.svg"}/>
  
                  </a> */}
                        <Tooltip title={"Delete"} className="ml-4">
                            <Popconfirm
                                title={"Are your sure, you want to delete Expense?"}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    deleteExpense(record._id);
                                }}
                            >
                                <img src={"./close.svg"}/>
                            </Popconfirm>
                        </Tooltip>

                    </>
                );
            }
        }
    ];

    const filterExpense = () => {
        tableRef.current.reload()
        // setShowFilter(true)
        // if (!searchName && !searchCategory && !searchDate) {
        //     //  setAllList([])
        //
        //     notification.error({message: "please enter name"});
        //     setShowFilter(false)
        //     return
        // } else {
        //     const filteredExpense = allList.filter((item) => {
        //         item.name?.toLowerCase().includes(searchName.toLowerCase()) &&
        //         item.category?.category.toLowerCase().includes(searchCategory.toLowerCase()) &&
        //         (typeof item.date === 'string' && item.date.includes(searchDate))
        //     })
        //     setAllList(filteredExpense)
        // }
    }

    const clearFilterExpense = () => {
        // setSearchName("")
        // setSearchCategory("")
        setSearchDate("")
        setShowFilter(false)
        setAllList(filteredData)
        if (tableRef.current.reload) {
            tableRef.current.reload()
        }
    }

    return (
        <PageHeader title={'Journal Entry'} extraLink={[{
            name: "Add Journal Entry",
            link: "/add-journal-entry"
        }]}>

            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="mb-3 row">
                                <div className="col-md-2">
                                    <label htmlFor="searchCategory" className="form-label"/>
                                    <input
                                        type="date"
                                        className="form-control "
                                        name="name"
                                        value={dates.fromDate}
                                        onChange={(e) =>
                                            setDates({...dates, fromDate: e.target.value})
                                        }
                                    />
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="searchCategory" className="form-label"/>
                                    <input
                                        type="date"
                                        className="form-control "
                                        name="name"
                                        value={dates.toDate}
                                        onChange={(e) => setDates({...dates, toDate: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 search-buttons">
                                <div className="search-wrap pe-2">
                                    <button className="btn btn-info" onClick={filterExpense}>
                                        Search
                                    </button>
                                </div>
                                <div className="search-wrap">
                                    <button className="btn btn-secondary" onClick={clearFilterExpense}>
                                        Clear
                                    </button>
                                </div>
                            </div>

                            {showFilter ? (
                                <Table
                                    dataSource={allList}
                                    columns={columns}
                                    ref={tableRef}
                                    key={`table-${showFilter ? 'filtered' : 'original'}`}
                                />

                            ) : (
                                <Table
                                    apiRequest={apiRequest}
                                    columns={columns}
                                    ref={tableRef}

                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </PageHeader>
    );
}

export default JournalEntryList;
