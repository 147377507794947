import axios from "axios";

export let customAxios = axios.create({});

export const getToken = () => {
    return {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
    };
};
customAxios.interceptors.response.use(function (response) {
    if (response.status == 200) {
    }
    return response
}, function (error) {
    handleErr(error)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
})

export const handleErr = (err) => {
    try {
        if (err.response.status === 401) {
            localStorage.clear()
            window.location.reload()
        }
    } catch (e) {
    }
}
