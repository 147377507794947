import {
    AddDoctorUrl,
    singleUserUrl,
    getCompanyDataUrl,
    getCustomerDataUrl,
    getDoctorsUrl,
    getPrescriptionURL,
    getVisitsUrl,
    updateUserUrl,
    getSlipUrl,
    testUploadUrl
} from "../api";
import {customAxios as axios, getToken} from "../../../request";
import {hideLoader, showLoader} from "../../../actions/loader";
import {notification} from "../../../components/Elements/appUtils";
import {addFollowUrl} from "../../lead/api";

export const fetchCompany = async (valData) => {
    let config = {
        params: {...valData}
    }
    const {data} = await axios.get(getCompanyDataUrl(), config);
    return data;
};

export const fetchPrecription = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()

    }
    const {data} = await axios.get(getPrescriptionURL(), config);
    return data;
};

export const fetchCustomer = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getCustomerDataUrl(), config);
    return data;
};

export const fetchDoctors = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getDoctorsUrl(), config);
    return data;
};

export const fetchVisits = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getVisitsUrl(), config);
    return data;
};
// export const fetchIdPrescription = async (valData) => {
//     let config = {
//         params: {...valData},
//         ...await getToken()
//     }
//     const {data} = await axios.get(findPrescriptionIdUrl(), config);
//     return data;
// };

export const addDoctorFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(AddDoctorUrl(), valData, getToken());
    console.log(data,"this is data in doctor function");
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};
export const singleUserFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    
    let { data } = await axios.get(singleUserUrl(id));
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    }
  
    return data;
  };
  export const updateUserFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateUserUrl(), valData ,getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };
  export const fetchslip = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getSlipUrl(), config);
    return data;
};
// export const singlePrescriptionFxn = (id) => async (dispatch) => {
//     dispatch(showLoader());
    
//     let { data } = await axios.get(singlePrescriptionUrl(id));
//     console.log(data);
//     dispatch(hideLoader());
//     if (data.error) {
//       notification.error({
//         message: data.message || "Error",
//       });
//     }
//     return data;
//   };

export const testUploadFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(testUploadUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

