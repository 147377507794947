import {addBrandUrl, getBrandDataUrl,singleBrandUrl,updateBrandUrl} from "../api";
import {customAxios as axios, getToken} from "../../../request";
import { showLoader, hideLoader } from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
export const fetchBrand = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getBrandDataUrl(), config);
    return data;
};
export const addBrandFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addBrandUrl(), valData, getToken());
    dispatch(hideLoader())
    if(!data.error){
        notification.success({message: data.message})
    }
    else {
            notification.error({message: data.message || "Error"})
        }
    
    return data;
   
};
export const singleBrandFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    
    let { data } = await axios.get(singleBrandUrl(id));
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    }
  
    return data;
  };

  export const updateBrandFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateBrandUrl(), valData);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };
