import React, { useState, useRef, useEffect } from "react";
import { customAxios as axios, getToken } from "../../request";
import {
  InputBox,
  Form,
  Table,
  notification,
  Tooltip,
  Popconfirm,
} from "../../components/Elements/appUtils";
import { checkInUrl, checkOutUrl } from "./api";
import moment from "moment";
import { fetchAllEmp } from "./action";
import PageHeader from "../../components/Elements/pageHeader";
import { useNavigate } from "react-router-dom";
import { deleteEmployeeUrl } from "./api";
import { DefaultTablePagination} from "../../components/Elements/appUtils";
import _ from "lodash";
function EmployeeList() {
  let tableRef = useRef();
    const [allList, setAllList] = useState([]);
    const [ids, setIds] = useState('');
  const navigate = useNavigate()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      const data = await fetchAllEmp({...params});
  
      console.log(data.data, "checkdta")
      const employeeData = data.data.filter(item => item.type == 'employee');
  
      setAllList(employeeData);
  
      console.log(employeeData, "Filtered Employee Data");
  
      resolve({data:employeeData});
    });
  };

  const deleteEmployee = async (id) => {
    console.log(id, "ppridyct");
    try {
      const response = await axios.delete(deleteEmployeeUrl(id));
      console.log(response, "response");
      if (response.data.error) {
        notification.error({
          message: response.data.message,
        });
      } else {
        tableRef.current.reload();
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };
  const handleViewEmployee = (recordId) => {
    navigate('/emp-profile', { state: { employeeId: recordId } });
  };

   const checkIn = async(e, employeeId) => {
    e.preventDefault();
    try {
      const response = await axios.post(checkInUrl(), {employeeId:employeeId}, getToken());

      if (response.data.error) {
        notification.error({
          message: response.data.message,
        });
      } else {
       
        notification.success({
          message: response.data.message,
        });
        // window.location.reload();
      }
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  }
  const checkOut = async(e,employeeId) => {
    e.preventDefault();
    try {
      const response = await axios.post(checkOutUrl(), {employeeId:employeeId}, getToken());
     
      if (response.data.error) {
        notification.error({
          message: response.data.message,
        });
      } else {
      
        notification.success({
          message: response.data.message,
        });
        // window.location.reload();
      }
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  }
  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    
    },
    {
      title: "Date Of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (text, record) => (
          <span>{moment(record.dateOfBirth).format("DD/MM/YYYY")} </span>
        ),
    },
    {
        title: "Mobile",
        dataIndex: "mobile",
        key: "mobile",
      
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
      
      },
      {
        title: "Designation",
        dataIndex: "designation",
        key: "designation",
      
      },
    {
        title: "Joining Date",
        dataIndex: "joiningDate",
        key: "joiningDate",
        render: (text, record) => (
            <span>{moment(record.joiningDate).format("DD/MM/YYYY")} </span>
          ),
      },
      {
        title: "Salary",
        dataIndex: "salary",
        key: "salary",
      },
      {
        title: "Emergency Number",
        dataIndex: "emergencyNumber",
        key: "emergencyNumber",
      
      },
      {
        title: "Action",
        dataIndex: "action",
        width: 190,
        render: (val, record) => {
          return (
              <>
               
                 <a className={"empty_btn "}
             

             onClick={() => handleViewEmployee(record._id)}>
                      <i className={"fa fa-eye far"}></i>
                  </a>
            
           
         
                  <a onClick={() => {
                    navigate(`/edit-employee?_id=${record._id}`)
                  }}>
                    <img src={"./edit.svg"}/>
  
                  </a>
                  <Tooltip title={"Delete"} className="ml-4">
                <Popconfirm
                  title={"Are your sure, you want to delete Employee?"}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    deleteEmployee(record._id);
                  }}
                >
                  <img src={"./close.svg"} />
                </Popconfirm>
              </Tooltip>
           
            
              <a className={"empty_btn m-1"}
               onClick={(e) => {checkIn(e,record._id)}}
             

           >
                      <img src={"./check-in (1).png"} className="checkIn"/>
                  </a>
                  <a className={"empty_btn m-1"}
             onClick={(e) => {checkOut(e,record._id)}}

             >
                        <img src={"./check-out (1).png"} className="checkIn"/>
                    </a>
              </>
          );
        }
    }
  ];

  let loadColumn = () => {
    let columnList = []
    _.each(columns, (item) => {
        if (!item.hidden) {
            columnList.push(item)
        }
    })
    return columnList
}

  return (
    <PageHeader title={'Employee'} extraLink={[{
        name: "Add Employee",
        link: "/add-employee"
    }]}>
     
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <Table apiRequest={apiRequest}     columns={loadColumn()}
            pagination={DefaultTablePagination()} ref={tableRef} />
            </div>
          </div>
        </div>
      </div>
    </PageHeader>
  );
}

export default EmployeeList;
