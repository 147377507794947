import { apiUrl } from "../../../setting";

export const getCompanyDataUrl = () => {
  return `${apiUrl}/routes/company/getCompanyData`;
};

export const getCustomerDataUrl = () => {
  return `${apiUrl}/routes/customer/customerList`;
};

export const addBranchUrl = () => {
  return `${apiUrl}/routes/customer/addCustomer`;
};
export const singleBranchUrl = (BranchId) => {
  return apiUrl + "/routes/customer/getSingleBranch/" + BranchId;
};
export const updateBranchUrl = () => {
  return `${apiUrl}/routes/customer/updateBranch`;
};
