import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  InputBox,
  Card,
  Modal,
  notification,
  calculation,
} from "../components/Elements/appUtils";
import { getToken } from "../request";
import TableMain from "../components/Elements/Table";
import { getStockUrl , getbatchRecordByProductIdUrl} from "./sales/api";
import { fetchRequestedProductReport } from "./stockRequest/api";
import _ from "lodash";
import async from "async";

import { getItemLocalstorage } from "../components/_utils/_utils";
import { Table } from "../components/Elements/appUtils";
import { arraySorter } from "../components/_utils/appUtils";
import { generateSaleProduct } from "../components/_utils/appUtils";
let initState = {
  productId: "",
  productName: "",
  quantity: 1,
  price: "",
  salePrice: "",
  inclusivePrice: "",
  pUnit: "",
  hsn: "",
  subTotal: "",
  discount: "",
  discountAmount: "",
  expiryDate: "",
  manufacturingDate: "",
  batchNo: "",
  batchId: "",
  taxableAmount: "",
  igstAmount: "",
  cgstAmount: "",
  sgstAmount: "",
  igstTax: "",
  gst: "",
  uom: "",
  totalAmount: "",
  gstAmount: "",
  netAmount: "",
  stateOfSupply: "",
  customerId: "",
  reportType: "Inclusive",
  transactionType: "",
  BatchQunatity: "",
};

const ReportMappingComponent = ({
  item,
  index,
  selectProductSubmit,
  ProductReport,
}) => {
  const [enteredQuantity, setEnteredQuantity] = useState("");

  let show;

  if (
    ProductReport.find((x) => {
      return x.productId == item.productId;
    })
  ) {
    show = false;
  } else {
    show = true;
  }

  return (
    <>
      <tr key={item.id}>
        <td>{index + 1}</td>
        <td>{item.productName}</td>
        <td>{item.hsn}</td>
        <td>{item.uom}</td>
        <td>{item.quantity}</td>
        <td>
          <input
            className={"form-control"}
            type="number"
            value={enteredQuantity || item.quantity}
            onChange={(e) => setEnteredQuantity(e.target.value)}
          ></input>
        </td>
        <td>
          {show == true ? (
            <a
              onClick={() => {
                selectProductSubmit(
                  { target: { value: item.productId } },
                  "productId",
                  enteredQuantity || item.quantity
                );
              }}
              className="btn btn-info btn-xs"
            >
              Select batch
            </a>
          ) : (
            <p>Added To Cart</p>
          )}
        </td>
      </tr>
    </>
  );
};

const MedDataMapingComponent = ({
  item,
  index,
  selectProductSubmit,
  ProductReport,
}) => {
  const [enteredQuantity, setEnteredQuantity] = useState("");

  let show;

  if (
    ProductReport.find((x) => {
      return x.productId == item.productId;
    })
  ) {
    show = false;
  } else {
    show = true;
  }

  return (
    <>
      <tr key={item.id}>
        <td>{index + 1}</td>
        <td>{item.productName}</td>
        <td>{item.dosage}</td>
        <td>{item.frequency}</td>
        <td>{item.remarks}</td>
        <td>{item.whentotake}</td>
        <td>
          <input
            className={"form-control"}
            type="number"
            value={enteredQuantity || item.quantity}
            onChange={(e) => setEnteredQuantity(e.target.value)}
          ></input>
        </td>
        <td>
          {show == true ? (
            <a
              onClick={() => {
                selectProductSubmit(
                  { target: { value: item.productId } },
                  "productId",
                  enteredQuantity || item.quantity
                );
              }}
              className="btn btn-info btn-xs"
            >
              Select batch
            </a>
          ) : (
            <p>Added To Cart</p>
          )}
        </td>
      </tr>
    </>
  );
};

const GenerateSaleItemComponent = (props) => {
  let {
    visitData,
    ProductReport,
    updateProductReport,
    statesAreEqual,
    stateOfSupply,
    companyState,
    requestId,
    visitId,
    setIsModal2Open,
    isModal2Open,
    setRequestedMedModal,
    requestedMedModal

  } = props;
  const [stock, setStock] = useState([]);
  const userType = getItemLocalstorage("user")["userType"];
  let [Report, setReport] = useState([]);
  let [medData, setMedData] = useState([]);
  let [k, setk] = useState("");
  let [Batch, setBatch] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visibleBatchModal, setVisibleBatchModal] = useState(false);
  let [productState, setProductState] = useState(initState);
  let [productList, setProductList] = useState([]);


  useEffect(() => {
    console.log(ProductReport);
    setProductList(ProductReport);
  }, [ProductReport]);


  const [availableQty, setAvailableQty] = useState('')
  const handleBatchSelect = (Batch) => {
    // setSelectedBatch(Batch);
    let obj = {
      batchId: Batch._id,
      batchNo: Batch.batchNo,
      price: Batch.salePrice ? Batch.salePrice : 0,
      manufacturingDate: Batch.manufacturingDate,
      expiryDate: Batch.expiryDate,
      BatchQunatity: Batch.quantity,
      salePrice: Batch.salePrice,
    };
    setAvailableQty(Batch.quantity)
    setIsModalOpen(false);
    setProductState((prevState) => {
      return {
        ...prevState,
        ...obj,
      };
    });
  };

  console.log(Batch, "Batch");

  const chooseProductFromBatch = async (batchData) => {
    let obj = {
      batchQuantity: batchData.quantity,
      batchId: batchData._id,
      batchNo: batchData.batchNo,
      price: batchData.salePrice ? batchData.salePrice : 0,
      manufacturingDate: batchData.manufacturingDate,
      expiryDate: batchData.expiryDate,
      salePrice: batchData.salePrice,
      reportType: productState.reportType,
    };
    setProductState({ ...obj });

    if (!productState.productId) {
      notification.warning({ message: "Please Enter Product" });
      return;
    }
    if (!productState.quantity) {
      notification.warning({ message: "Please Enter Quantity" });
      return;
    }
    if (obj.batchQuantity < productState.quantity) {
      setVisibleBatchModal(false);
      notification.warning({
        message: "Entered Quantity Cannot Be Greater Than choose Batch",
      });

      return;
    }
    let newProduct = {
      ...productState,
      serialNo: ProductReport?.length + 1,
      ...obj,
    };
    let resp = await calculation(newProduct, stateOfSupply, companyState);

    const productReportData = [...ProductReport, { ...newProduct, ...resp }];

    if (
      ProductReport.find((x) => {
        return (
          x.batchNo == batchData.batchNo &&
          x.productId == batchData.productId &&
          x.expiryDate == batchData.expiryDate &&
          x.manufacturingDate == batchData.manufacturingDate
        );
      })
    ) {
      notification.warning({
        message:
          "Product Already Exist With Same Batch, Mfg. Date, and Exp. Date",
      });
      setVisibleBatchModal(false);
      return;
    }

    updateProductReport(productReportData);
    setVisibleBatchModal(false);
    setProductState(initState);
  };

  useEffect(() => {
    reCalculateAmount().then(() => {
      setk(new Date().toString());
    });
  }, [
    productState.productId,
    productState.reportType,
    productState.price,
    productState.quantity,
    productState.discount,
    productState.gst,
  ]);

  console.log(visibleBatchModal, "visibleBatchModal");
  const reCalculateAmount = () => {
    return new Promise((resolve) => {
      calculation({ ...productState }, stateOfSupply, companyState).then(
        (resp) => {
          setProductState({
            ...productState,
            ...resp,
          });
        }
      );
      return resolve("done");
    });
  };

  const StateChangesRecalculate = () => {
    // Assuming productState is an array
    let cloneData = _.clone(ProductReport);
    let newArr = [];
    _.each(cloneData, async (item) => {
      let resp = await calculation(item, stateOfSupply, companyState);
      newArr.push(resp);
    });
    // setProductList(newArr);
    updateProductReport(newArr);
  };

  
 
 
  const onSubmit = () => {
    if (!productState.productId) {
      notification.warning({ message: "Please Enter Product" });
      return;
    }
    if (!productState.price) {
      notification.warning({ message: "Please Enter Price" });
      return;
    }
    if (!productState.quantity) {
      notification.warning({ message: "Please Enter Quantity" });
      return;
    }
    if (productState.discount > 100) {
      notification.warning({
        message: "Please Enter A Valid Discount Percentage",
      });
      return;
    }
   
  

    const updatedProductReport = [
      ...ProductReport,
      { ...productState, serialNo: ProductReport?.length + 1 },
    ];
    updateProductReport(updatedProductReport);

    setProductState(initState);
  };

  const handleChange1 = (e, fieldName) => {
    const { value } = e.target;
    let obj = {};
    if (fieldName == "productId") {
      const selectedProduct = generateSaleProduct.find(
        (prod) => prod._id === value
      );

      obj = {
        productName: selectedProduct.name,
        pUnit: selectedProduct.unitCode,
        hsn: selectedProduct.hsnCode,
        gst: selectedProduct.gst,
        uom: selectedProduct.unitCode,
       
      };
      setIsModalOpen(true);
    }
    setProductState({
      ...productState,
      [fieldName]: value,
      stateOfSupply: stateOfSupply,
      ...obj,
    });
  };

  const _selectProductSubmit = (e, fieldName, batchQuantity) => {
    if (!batchQuantity) {
      notification.warning({ message: "Please Enter Quantity" });
      return;
    }
    setVisibleBatchModal(true);
    const { value } = e.target;
    let obj = {};
    if (fieldName == "productId") {
      const findedProduct = stock.find((prod) => prod.productId._id === value);

      obj = {
     
        productName: findedProduct.name,
        pUnit: findedProduct.unitCode,
        hsn: findedProduct.hsnCode,
        gst: findedProduct.gst,
        uom: findedProduct.unitCode,
      };
    }

    setProductState({
      ...productState,
      [fieldName]: value,
      stateOfSupply: stateOfSupply,
      ...obj,
    });

    // setk(new Date().toString());
  };

  let Cgst = {
    title: "CGST",
    dataIndex: "cgstAmount",
    key: "cgstAmount",
    render: (text, record) => (
      <span>
        {record.gst / 2}% (Rs.{text}){" "}
      </span>
    ),
  };

  let Sgst = {
    title: "SGST",
    dataIndex: "sgstAmount",
    key: "sgstAmount",
    render: (text, record) => (
      <span>
        {record.gst / 2}% (Rs.{text}){" "}
      </span>
    ),
  };

  let Igst = {
    title: "IGST",
    dataIndex: "igstTax",
    key: "igstTax",
    render: (text, record) => (
      <span>
        {text}% (Rs.{record.igstAmount}){" "}
      </span>
    ),
  };

  let NetAmount = {
    title: "Net Amount",
    dataIndex: "netAmount",
    key: "netAmount",
    render: (text) => `Rs. ${text}`,
  };

  let action = {
    title: "Action",
    key: "delete",
    render: (text, record, index) => {
      return (
        <>
          <a className="empty_btn" onClick={() => handleDelete(index)}>
            <i className="fa fa-trash-alt"></i>
          </a>
        </>
      );
    },
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Type",
      dataIndex: "reportType",
      key: "reportType",
    },
    {
      title: "HSN",
      dataIndex: "hsn",
      key: "hsn",
    },
  
    {
      title: "Qty.",
      dataIndex: "quantity",
      key: "quantity",
    },
 
    {
      title: "Rate",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => `Rs.${text}`,
    },
    {
      title: "Dis(%)",
      dataIndex: `discount`,
      key: "discount",
      render: (text) => <span>{text}%</span>,
    },
    {
      title: "Dis Amt.",
      dataIndex: `discountAmount`,
      key: "discountAmount",
      render: (text) => <span>Rs.{text}</span>,
    },
    {
      title: "Taxable Amount",
      dataIndex: "taxableAmount",
      key: "taxableAmount",
      render: (text) => `Rs. ${text}`,
    },
  ];

  const handleDelete = (index) => {
    const updatedProductReport = [...ProductReport];
    updatedProductReport.splice(index, 1);
    updateProductReport(updatedProductReport);
  };

  const getColumns = () => {
    if (stateOfSupply == companyState) {
      columns.push(Cgst, Sgst, NetAmount, action);
    } else {
      columns.push(Igst, NetAmount, action);
    }
    return columns;
  };

  useEffect(() => {
    StateChangesRecalculate();
  }, [stateOfSupply, statesAreEqual, k]);

  const fetchRequestedProductReport1 = async () => {
    try {
      let config = {
        params: { requestId },
        ...(await getToken()),
      };
      const response = await axios.get(fetchRequestedProductReport(), config);
      setReport(response.data.data);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  // const fetchPrescribedMedicine = (params) => {
  //   return new Promise(async (resolve) => {
  //     try {
  //       params.requestedMedId = requestedMedId;
  //       const data = await fetchPrecription({ ...params });
  //       setMedData(data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   });
  // };

  useEffect(() => {
    if (requestId) {
      fetchRequestedProductReport1();
    }
  }, [requestId]);


  return (
    <>
      <Card title={"Select Items"} size={"small"}>
        <div className={"item-flex"}>
          <InputBox title={"Product"} className={"flex2"}>
            <select
              style={{ height: "45px" }}
              className="form-control"
            //   value={productState.productId}
              onChange={(e) => handleChange1(e, "productId")}
              required
            >
              <option value="">Select Product</option>
              {generateSaleProduct.map((stock) => (
                <option key={stock._id} value={stock._id}>
                  {stock.name}
                </option>
              ))}
            </select>
          </InputBox>
          <InputBox title={"Type"}>
            <select
              disabled
              style={{ height: "45px", color: "black" }}
              className="form-control"
              value={productState.reportType}
              onChange={(e) => handleChange1(e, "reportType")}
              required
            >
              <option>Select Tax Type</option>
              <option>Inclusive</option>
              <option>Exclusive</option>
            </select>
          </InputBox>
          <InputBox title={"Sale Price"}>
            <input
              className={"form-control"}
              type="number"
              placeholder="Sale Price"
              value={productState.price}
              onChange={(e) => handleChange1(e, "price")}
            />
          </InputBox>
          <InputBox >
        <p className="mb-1">{`Qty: ${availableQty}`} </p>
            <input
              className={"form-control"}
              type="number"
              value={productState.quantity}
              placeholder="Quantity"
              onChange={(e) => handleChange1(e, "quantity")}
            />
          </InputBox>
          <InputBox title={"Dis(%)"}>
            <input
              className={"form-control"}
              type="number"
              value={productState.discount}
              placeholder="Discount"
              onChange={(e) => handleChange1(e, "discount")}
              max="100"
            />
          </InputBox>
          <InputBox title={"GST(%)"}>
            <input
              style={{ height: "45px", color: "black" }}
              disabled
              className={"form-control"}
              type="text"
              value={productState.gst}
              placeholder="GST"
              onChange={(e) => handleChange1(e, "gst")}
            />
          </InputBox>

         

          <InputBox className={"flex_none"}>
            <a
              className="addBtn fs-5 btn-link"
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <i className={`mdi mdi-library-plus menu-icon`}></i>
            </a>
          </InputBox>
        </div>
      </Card>
      <div key={ProductReport?.length} className="mt-4 mb-4">
        <div className="d-flex">
          <h4 className="m-2">Items List</h4>
          {requestId && userType == "company" ? (
            <a
              className="btn btn-link m-2"
              onClick={() => {
                setIsModal2Open(true);
              }}
            >
              Requested Products
            </a>
          ) : null}
          {visitData && userType == "reception" ? (
            <a
              className="btn btn-link m-2"
              onClick={() => {
                setRequestedMedModal(true);
              }}
            >
              Requested Med
            </a>
          ) : null}
        </div>
        <div key={productList}>
          <Table
            dataSource={productList}
            columns={getColumns()}
            key={JSON.stringify(productList)}
          />
        </div>
      </div>
      {/* {isModalOpen && (
        <Modal
          visible={isModalOpen}
          // closable={false}
          title={"Choose Batch"}
          onClose={() => setIsModalOpen(false)}
        >
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <td>Batch Number</td>
                  <td>Mfg Date</td>
                  <td>Exp Date</td>
                  <td>Quantity</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {Batch.map((item) => (
                  <tr key={item.id}>
                    <td>{item.batchNo}</td>
                    <td>{item.manufacturingDateString}</td>
                    <td>{item.expiryDateString}</td>
                    <td>{item.quantity}</td>
                    <td>
                      <button
                        className="btn btn-info btn-xs"
                        onClick={() => handleBatchSelect(item)}
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      )} */}

      {isModal2Open && (
        <Modal
          title={"Requested Products List"}
          width={"50%"}
          visible={isModal2Open == true}
          onClose={() => setIsModal2Open(false)}
        >
          <table className="table table-bordered">
            <thead>
              <tr>
                <td>Sr no.</td>
                <td>Product Name</td>
                <td>HSN</td>
                <td>UOM.</td>
                <td>Requested Qty.</td>
                <td>Qty.</td>
              </tr>
            </thead>
            {Report.map((item, index) => (
              <ReportMappingComponent
                ProductReport={ProductReport}
                item={item}
                index={index}
                selectProductSubmit={_selectProductSubmit}
              />
            ))}
          </table>
        </Modal>
      )}

      {requestedMedModal && (
        <Modal
          title={"Requested med List"}
          width={"50%"}
          visible={requestedMedModal == true}
          onClose={() => setRequestedMedModal(false)}
        >
          <table className="table table-bordered">
            <thead>
              <tr>
                <td>Sr no.</td>
                <td>Product Name</td>
                <td>Dosage</td>
                <td>Frequency/Routine</td>
                <td>Remarks</td>
                <td>When to Taken</td>
                <td>Qty.</td>
              </tr>
            </thead>
            {visitData && visitData?.length ? (
              <tbody>
                {visitData.map((item, index) => (
                  <MedDataMapingComponent
                    item={item}
                    index={index}
                    selectProductSubmit={_selectProductSubmit}
                    ProductReport={ProductReport}
                  />
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="6">No products available</td>
                </tr>
              </tbody>
            )}
          </table>
        </Modal>
      )}

  
    </>
  );
};
export default GenerateSaleItemComponent;
