import {apiUrl} from "../../../setting";

export const getCompanyDataUrl = () => {
    return `${apiUrl}/routes/company/getCompanyData`;
};

export const getCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/customerList`;
};

export const addReceptionUrl = () => {
    return `${apiUrl}/routes/user/createUser`;
};

export const getReceptionUrl = () => {
    return `${apiUrl}/routes/user/getReception`;
};

export const getVisitsUrl = () => {
    return `${apiUrl}/routes/visit/getVisits`;
};
export const singleUserUrl = (id) => {
    return apiUrl + "/routes/user/getSingleUser/" + id;
};
export const updateUserUrl = () => {
    return `${apiUrl}/routes/user/updateUser`;
  };