import React from "react";
import PageHeader from "../../components/Elements/pageHeader";
import Image from "../../circle.svg";
import {menuData} from "../../menu";
import {getItemLocalstorage} from "../../components/_utils/_utils";
import {useNavigate} from "react-router-dom";
import FollowUp from "../lead/followUp";
import FollowUpList from "../lead/followUpList";
import Visits from "../doctor/visits";
import DayBook from "../daybook/dayBook";

const Dashboard = () => {
    const navigate = useNavigate();
    const checkUserType = getItemLocalstorage("user")["userType"];

    const accessibleItems = menuData.reduce((acc, curr) => {
        if (
            curr.authority.includes(checkUserType) &&
            !curr.dontShowOnMenu &&
            curr.name !== "Dashboard" &&
            (!curr.subMenu || curr.subMenu.length === 0)
        ) {
            acc.push({name: curr.name, path: curr.path, icon: curr.icon});
        }
        return acc;
    }, []);

    console.log(accessibleItems, "accessibleItems");
    const handleItemClick = (path) => {
        navigate(path);
    };
    return (
        <>
            {/*<div class="container-fluid page-body-wrapper">*/}
            {/*    <div class="main-panel dashboard">*/}
                    {/*<div class="content-wrapper dashboard">*/}
                        {/*<div class="row">*/}
                            {/*{accessibleItems.map((item) => (*/}
                            {/*    <div*/}
                            {/*        class="card design col-sm-2  mx-3 my-3 p-3"*/}
                            {/*        onClick={() => handleItemClick(item.path)}*/}
                            {/*    >*/}
                            {/*        <div class="row align-items-center">*/}
                            {/*            <div class="col-sm-4">*/}
                            {/*                <i*/}
                            {/*                    className={`mdi mdi-${item.icon}`}*/}
                            {/*                    style={{*/}
                            {/*                        color: "#198ae3",*/}
                            {/*                        borderRadius: "50%",*/}
                            {/*                        padding: "5px",*/}
                            {/*                        backgroundColor: "#d6e6f3d9",*/}
                            {/*                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",*/}

                            {/*                    }}*/}
                            {/*                />*/}
                            {/*            </div>*/}
                            {/*            <div class="col-sm-8">*/}
                            {/*                <span style={{fontWeight: "600"}}>{item.name}</span>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*))}*/}
                        {/*</div>*/}
                        <div className="row">
                            <div className="col-sm-7">
                                <Visits/>
                            </div>
                            <div className="col-sm-5">
                                <FollowUpList/>
                            </div>
                            <div className="col-sm-12" style={{marginTop:'20px'}}>
                                <DayBook/>
                            </div>

                        {/*</div>*/}
                    </div>
                {/*</div>*/}
            {/*</div>*/}
        </>
    );
};
export default Dashboard;
