import React, { useEffect, useState } from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../components/Elements/appUtils";
import axios from "axios";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import {
  IndianStates,
  States,
  gstTypeList,
} from "../../components/_utils/appUtils";
import { branchType } from "../../components/_utils/appUtils";
import { addCompanyFxn, updateProfileFxn } from "./actions";
import _ from "lodash";
import { updateCompanyFxn } from "./actions";
import { useNavigate } from "react-router-dom";
import { singleProfileUrl, updateProfileUrl } from "./api";
import { getToken } from "../../request";
import { getItemLocalstorage } from "../../components/_utils/_utils";
const EditProfile = (props) => {
  const userType = getItemLocalstorage("user")["userType"];
  const { getFieldValue, setFieldsValue } = props.form;
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [companyId, setCompanyId] = useState("");

  useEffect(() => {
    getSingleCompany();
  }, []);

  const getSingleCompany = async () => {
    let config = {
      ...(await getToken()),
    };
    const data = await axios.get(singleProfileUrl(), config);
    // let { data, success } = await dispatch(singleProfileUrl(), config);
    console.log(data.data.data, "ddtdtdtdtt");
    if (data.data.success) {
      setTimeout(() => {
        props.form.setFieldsValue({
          name: data.data.data.name,
          companyName: data.data.data.companyName,
          mobile: data.data.data.mobile,
          contactName: data.data.data.contactName,
          address: data.data.data.address,
          email: data.data.data.email,
          state: data.data.data.state,
          city: data.data.data.city,
          pincode: data.data.data.pincode,
          gstType: data.data.data.gstType,
          prefix: data.data.data.prefix,
          discount: data.data.data.discount,
          typeOfBranch: data.data.data.type,
          royalty: data.data.data.royalty,
        });
        setTimeout(() => {
          if (data.data.data.gstType == "Registered") {
            setFieldsValue({ gstIn: data.data.data.gstIn });
          }
        }, 400);
      }, 300);
    }
  };

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      console.log(valData, "w1");
      if (!err) {
        let config = {
          ...(await getToken()),
        };
        let findStateCode = _.find(States, (item) => {
          return item.name === valData.state;
        });
        if (findStateCode) {
          valData.stateCode = findStateCode.stateCode;
        }
        let x = await dispatch(updateProfileFxn(valData));
        if (x && x.success) {
          console.log(x, "valdata");
        }
      } else {
        if (err.name) {
          notification.warning({
            message: `Please enter Name`,
          });
          return;
        }

        if (err.email) {
          notification.warning({
            message: `Please enter Email`,
          });
          return;
        }

        if (err.mobile) {
          notification.warning({
            message: `Please enter Mobile No`,
          });
          return;
        }

        if (err.state) {
          notification.warning({
            message: `Please enter State`,
          });
          return;
        }

        if (err.city) {
          notification.warning({
            message: `Please enter City`,
          });
          return;
        }

        if (err.pincode) {
          notification.warning({
            message: `Please enter Pin Code`,
          });
          return;
        }

        if (err.address) {
          notification.warning({
            message: `Please enter Address`,
          });
          return;
        }

        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "companyName",
        label: "Company Name",
        span: "col-md-6",
        placeholder: "Company Name",
      },
      // {
      //   key: "name",
      //   label: "Contact Person Name",
      //   span: "col-md-6",
      //   placeholder: "Contact Person Name",
      // },
      {
        key: "email",
        label: "Email",
        type: "email",
        span: "col-md-6",
        placeholder: "Email",
      },
      // {
      //   key: "password",
      //   label: "Password",
      //   type: "password",
      //   span: "col-md-6",
      //   placeholder: "Password",
      //   minLength: 6,
      // },
      {
        key: "mobile",
        label: "Mobile No",
        span: "col-md-6",
        placeholder: "Mobile No",
      },

      {
        key: "state",
        label: "State",
        span: "col-md-6",
        placeholder: "State",
        type: "select",
        options: IndianStates,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            state: x,
          });
        },
      },
      {
        key: "city",
        label: "City",
        span: "col-md-3",
        placeholder: "City",
      },
      {
        key: "pincode",
        label: "Pincode",
        span: "col-md-3",
        placeholder: "Pincode",
      },
      {
        key: "prefix",
        label: "Prefix",
        span: "col-md-6",
        placeholder: "Prefix",
      },
      {
        key: "gstType",
        label: "GST Type",
        span: "col-md-6",
        placeholder: "GST Type",
        type: "select",
        options: gstTypeList,
        showSearch: true,
        keyAccessor: (x) => x.value,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            gstType: x,
          });
        },
      },
      {
        key: "gstIn",
        label: "GSTIN",
        span: "col-md-6",
        placeholder: "GSTIN",
        hidden: getFieldValue("gstType") !== "Registered",
        required: getFieldValue("gstType") === "Registered",
      },
      {
        key: "address",
        label: "Address",
        span: "col-md-12",
        placeholder: "Address",
        type: "textarea",
      },
    ],
  };

  let inputTypesBranch = {
    fields: [

      {
        key: "typeOfBranch",
        label: "Type of Branch",
        span: "col-md-6",
        type: "select",
        disabled:"true",
        options: branchType,
        keyAccessor: (x) => x.key,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            typeOfBranch: x,
          });
        },
      },
      {
        key: "name",
        label: "Branch Name",
        span: "col-md-6",
        placeholder: "Branch Name",
        required: true,
      },
      // {
      //   key: "contactName",
      //   label: "Contact Person Name",
      //   span: "col-md-6",
      //   placeholder: "Contact Person Name",
      //   required: true,
      // },
      {
        key: "email",
        label: "Email",
        type: "email",
        span: "col-md-6",
        placeholder: "Email",
        required: true,
      },
      {
        key: "prefix",
        label: "Prefix",
        span: "col-md-6",
        placeholder: "Prefix",
      },
      {
        key: "mobile",
        label: "Mobile No",
        span: "col-md-6",
        placeholder: "Mobile No",
        required: true,
      },
      {
        key: "state",
        label: "State",
        span: "col-md-6",
        placeholder: "State",
        type: "select",
        options: States,
        showSearch: true,
        keyAccessor: (x) => x.stateCode,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            state: x,
          });
        },
      },

      {
        key: "city",
        label: "City",
        span: "col-md-3",
        placeholder: "City",
        required: true,
      },
      {
        key: "pincode",
        label: "Pincode",
        span: "col-md-3",
        placeholder: "Pincode",
        required: true,
      },
      {
        key: "discount",
        label: "Default Discount",
        span: "col-md-3",
        placeholder: "Default Discount",
        required: true,
      },
      {
        key: "royalty",
        label: "Royalty %",
        span: "col-md-3",
        placeholder: "Royalty %",
        required: true,
      },

      {
        key: "gstType",
        label: "GST Type",
        span: "col-md-6",
        placeholder: "GST Type",
        type: "select",
        options: gstTypeList,
        showSearch: true,
        keyAccessor: (x) => x.value,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          console.log(x);
          props.form.setFieldsValue({
            gstType: x,
          });
        },
      },
      {
        key: "gstIn",
        label: "GSTIN",
        span: "col-md-6",
        placeholder: "GSTIN",
        hidden: getFieldValue("gstType") !== "Registered",
        required: getFieldValue("gstType") === "Registered",
      },
      {
        key: "address",
        label: "Address",
        span: "col-md-12",
        placeholder: "Address",
        required: false,
        type: "textarea",
      },
    ],
  };

  return (
    <>
      {userType === "company" ? (
        <>
          <PageHeader title={"Edit Profile"}>
            <div className="card">
              <div className="card-body">
                <Form onSubmit={handleSubmit}>
                  <div className="form-elements-wrapper">
                    <div className={"row"}>
                      {inputTypes.fields.map((item, key) => {
                        return !item.hidden ? (
                          <div
                            className={`pb-3 ${
                              item.span ? item.span : "col-md-6"
                            }`}
                            key={key}
                          >
                            <GetEachFormFields {...props.form} item={item} />
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-gradient-primary me-2"
                  >
                    Submit
                  </button>
                  <HyperLink
                    className=" ms-4 btn btn-danger"
                    link={"/company-list"}
                  >
                    Cancel
                  </HyperLink>
                </Form>
              </div>
            </div>
          </PageHeader>
        </>
      ) : userType === "branch" || userType === "headBranch" ? (
        <>
          <PageHeader title={"Edit Profile"}>
            <div className="card">
              <div className="card-body">
                <Form onSubmit={handleSubmit}>
                  <div className="form-elements-wrapper">
                    <div className={"row"}>
                      {inputTypesBranch.fields.map((item, key) => {
                        return !item.hidden ? (
                          <div
                            className={`pb-3 ${
                              item.span ? item.span : "col-md-6"
                            }`}
                            key={key}
                          >
                            <GetEachFormFields {...props.form} item={item} />
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-gradient-primary me-2"
                  >
                    Submit
                  </button>
                  <HyperLink
                    className=" ms-4 btn btn-danger"
                    link={"/company-list"}
                  >
                    Cancel
                  </HyperLink>
                </Form>
              </div>
            </div>
          </PageHeader>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Form.create()(EditProfile);
