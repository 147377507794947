import React, {useState, useRef, useEffect} from "react";
import {useDispatch} from "react-redux";
import {
    Form,
    notification,
    HyperLink,
} from "../../components/Elements/appUtils";
import {useNavigate} from "react-router-dom";
import {addExpenseFxn} from "./actions/index";
import {fetchBank, fetchClientsForJournalEntry} from "../customer/actions";
import PageHeader from "../../components/Elements/pageHeader";
import {GetEachFormFields} from "../../components/_utils/formUtils";

function AddJournalEntry(props) {
    const {getFieldValue} = props.form;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [clientData, setClientData] = useState([]);


    const getClient = async (params = {}) => {
        params.results = 50;
        params.count = 50;
        // params.page = 1
        const {data} = await fetchBank({...params, regExFilters: ["name",]});
        if(data && data.length){
            setClientData(data);
        }else{
            setClientData([]);
        }
    };

    useEffect(() => {
        props.form.setFieldsValue({
            netAmount: 0,
            paymentType: "None",
        });
    }, []);


    useEffect(() => {
        // getClient();
    }, [getFieldValue("category")]);

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.fromClient) {
                    notification.warning({message: "Please Select From Client"});
                    return;
                }

                if (!valData.toClient) {
                    notification.warning({message: "Please Select To Client"});
                    return;
                }
                if (!valData.netAmount) {
                    notification.warning({message: "Please Enter Amount"});
                    return;
                }
                if (isNaN(valData.netAmount)) {
                    notification.warning({message: "Please Enter Valid Paid Amount"});
                    return;
                }
                if (!valData.date) {
                    notification.warning({message: "Please Select Date"});
                    return;
                }
                let x = await dispatch(addExpenseFxn(valData));
                if (x && x.success) {
                    navigate("/journal-entry");
                    props.form.setFieldsValue({
                        category: "",
                        date: "",
                    });
                }
            }
        });
    };

    let inputTypes = {
        fields: [
            {
                key: "fromClient",
                label: "From Client (Debit)*",
                span: "col-md-6",
                placeholder: "Select From Client",
                type: "select",
                options: clientData,
                onSearch: (value) => {
                    getClient({name: value})
                },
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        fromClient: x,
                    });
                },
            },
            {
                key: "toClient",
                label: "To Client (Credit)* ",
                span: "col-md-6",
                placeholder: "Select To Client",
                type: "select",
                onSearch: (value) => {
                    getClient({name: value})
                },

                options: clientData,
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        toClient: x,
                    });
                },
            },
            {
                key: "netAmount",
                label: "Amount*",
                placeholder: "Amount",
                span: "col-md-6",
            },
            {
                key: "date",
                label: "Date*",
                showStar: true,
                placeholder: "Date",
                type: "date",
                span: "col-md-6",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        date: x,
                    });
                },
            },
            {
                key: "paymentDetails",
                label: "Payment Details (Optional)",
                span: "col-md-12",
                placeholder: "Payment Details",
            },
        ],
    };


    return (
        <PageHeader title={"Journal Entry"}>
            <Form onSubmit={handleSubmit}>
                <div className="card">
                    <div className="card-body">
                        <div className="form-elements-wrapper">
                            <div className={"row"}>
                                {inputTypes.fields.map((item, key) => {
                                    return !item.hidden ? (
                                        <div
                                            className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    ) : null;
                                })}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-gradient-info me-2">
                            Submit
                        </button>
                        <HyperLink className=" ms-4 btn btn-danger" link={"/journal-entry"}>
                            Cancel
                        </HyperLink>
                    </div>
                </div>
            </Form>
        </PageHeader>
    );
}

export default Form.create()(AddJournalEntry);
