import React, { useEffect, useRef, useState } from "react";
import { Table } from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { fetchDoctors } from "./actions";
import { useNavigate } from "react-router-dom";
import {notification} from "../../components/Elements/appUtils";
const DoctorList = (props) => {
  let tableRef = useRef();
  const [doctorData, setDoctorData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [searchName, setSearchName] = useState("")
  const [searchEmail, setSearchEmail] = useState("")
  const [searchMobile, setSearchMobile] = useState("")

const navigate = useNavigate()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      const data = await fetchDoctors({ ...params });
      setDoctorData(data.data);
      setFilteredData(data.data);
      resolve(data);
      console.log(data, "this is doctors data");
    });
  };

  useEffect(() => {
    apiRequest();
}, []);

const filterDoctor = () => {
  setShowFilter(true)
  console.log(doctorData, "ddtattc")

if (!searchName && !searchEmail && !searchMobile) {
  // setDoctorData([])
  notification.error({ message: "please enter name" });
  setShowFilter(false)
  return
} else {
 const filteredDoctor = doctorData.filter((doctor) =>
 doctor.name.toLowerCase().includes(searchName.toLowerCase()) &&
 doctor.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
 doctor.mobile.toLowerCase().includes(searchMobile.toLowerCase())
);
console.log(filteredDoctor, "filteredDoctor");
setDoctorData(filteredDoctor);
}
};


const clearDoctorFilters = () => {
  setSearchName("");
  setSearchEmail("");
  setSearchMobile("");
  setShowFilter(false)
  setDoctorData(filteredData);
  if(tableRef.current.reload) {
    tableRef.current.reload()
  }
} 
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Number",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 190,
      render: (val, record) => {
        return (
            <>
                <a onClick={() => {
                  navigate(`/edit-doctor?_id=${record._id}`)
                }}>
                  <img src={"./edit.svg"}/>

                </a>


            </>
        );
      }
  }
  ];

  return (
    <PageHeader
      title={"Doctors"}
      extraLink={[
        {
          name: "Add Doctor",
          link: "/add-doctor",
        },
      ]}
    >
      <div className="card">
        <div className="card-body">

        <div className="mb-3 row">
            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
              <label htmlFor="searchName" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchName"
                placeholder="Search by Name"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </div>

            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
              <label htmlFor="searchEmail" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchEmail"
                placeholder="Search by Email"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
              />
            </div>

            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
              <label htmlFor="searchMobile" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchMobile"
                placeholder="Search by Mobile"
                value={searchMobile}
                onChange={(e) => setSearchMobile(e.target.value)}
              />
            </div>
          </div>

          <div className="mb-3 search-buttons">
            <div className="search-wrap pe-2">
              <button className="btn btn-info" onClick={filterDoctor}>
                Search
              </button>
            </div>
            <div className="search-wrap">
              <button className="btn btn-secondary" onClick={clearDoctorFilters}>
                Clear
              </button>
            </div>
          </div>
        {showFilter ? (
          <Table
          dataSource={doctorData}
          columns={columns}
          ref={tableRef}
          key={`table-${showFilter ? 'filtered' : 'original'}`}
        />
        
        ) : (
          <Table
          apiRequest={apiRequest}
          columns={columns}
          ref={tableRef}
       
        />
        )}
        </div>
      </div>
    </PageHeader>
  );
};

export default DoctorList;
