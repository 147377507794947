import { addLeadUrl, getAllLeadUrl, addFollowUrl,addFollowListUrl,singleLeadUrl,updateLeadUrl } from "../api";
import {customAxios as axios, getToken} from "../../../request";
import notification from "../../../components/Elements/Notification";
import {hideLoader, showLoader} from "../../../actions/loader";



export const addLeadFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addLeadUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};
export const fetchAllLeadEmp = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getAllLeadUrl(), config);
    return data;
};
export const AddFollowUpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(addFollowUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};

export const FollowUpListFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let config = {
        params: {...valData},
        ...(await getToken()),
    };
    let {data} = await axios.get(addFollowListUrl(), config);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};
export const singleLeadFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    
    let { data } = await axios.get(singleLeadUrl(id));
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    }
  
    return data;
  };
  export const updateLeadFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateLeadUrl(), valData ,getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };

