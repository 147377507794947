import React, { useEffect, useState } from "react";
import {Table, Tooltip, Modal, DefaultTablePagination} from "../../components/Elements/appUtils";
import { fetchProductStockFxn} from "./actions";
import moment from "moment";
import PageHeader from "../../components/Elements/pageHeader";

function DailyStockProductReport() {
    const [selectedReport, setSelectedReport] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                const res = await fetchProductStockFxn(params);
                resolve(res);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };


    const handleView = (record) => {
        setSelectedReport(record);
        setIsModalOpen(true);
    };

    const columns = [
        {
            title: "Date",
            dataIndex: "dailyUpdateDate",
            key: "dailyUpdateDate",
            render: (text) => <span>{moment(text).format("DD-MMM-YYYY")}</span>,
            width: 150,
        },
        {
            title: "Number of Products",
            dataIndex: "products",
            key: "products",
            render: (products) => <span>{products.length}</span>,
            width: 150,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            width: 100,
            render: (_, record) => (
                <Tooltip title="View">
                    <a className={"empty_btn"}
                       onClick={() => handleView(record)}> <i className={"fa fa-eye far"}/></a>
                </Tooltip>
            ),
        },
    ];

    const productColumns = [
        {
            title: "Product Name",
            dataIndex: "productName",
            key: "productName",
            width: 200,
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            width: 100,
        },
    ];

    return (
        <PageHeader
        title={<div style={{ display: 'flex', alignItems: 'center' }}>
            <div>Daily Stock Report List</div>
        </div>}>
            <div className="card">
                <div className="card-body">
                    <Table
                        columns={columns}
                        apiRequest={apiRequest}
                        position={"top"}
                        pagination={{...DefaultTablePagination(), defaultPageSize: 50}}
                        extraProps={{scroll: {x: 100},}}
                    />

                </div>
            </div>

            <Modal
                title="Product Details"
                visible={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                footer={null}
                width={800}
            >
                {selectedReport && (
                    <Table
                        columns={productColumns}
                        dataSource={selectedReport.products}
                        rowKey="_id"
                        pagination={false}
                        scroll={{ y: 400 }}
                    />
                )}
            </Modal>
        </PageHeader>
    );
}

export default DailyStockProductReport;
