import {
  addCustomerUrl,
  addPatientUrl,
  deleteCustomerUrl,
  singlePatientUrl,
  generateSlipUrl,
  getBankDataUrl,
  getCustomerDataUrl,
  updateBankUrl,
  singleBankUrl, getClientDataUrl, getJournalEntryClients, editPatientUrl,
} from "../api";
import { customAxios as axios, getToken } from "../../../request";
import notification from "../../../components/Elements/Notification";
import { hideLoader, showLoader } from "../../../actions/loader";
import {
  getSymptomDataUrl,
  singleCustomerUrl,
  updateCustomerUrl,
  updatePatientUrl,
} from "../api";
import {updateCompanyUrl} from "../../company/api";

export const fetchCustomer = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  const { data } = await axios.get(getCustomerDataUrl(), config);
  return data;
};

export const fetchBank = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  const { data } = await axios.get(getBankDataUrl(), config);
  return data;
};
export const fetchExpenseClient = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  const { data } = await axios.get(getClientDataUrl(), config);
  return data;
};

export const addCustomerFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  const { data } = await axios.post(addCustomerUrl(), valData, getToken());
  dispatch(hideLoader());
  if (!data.error) {
    notification.success({ message: data.message || "Success" });
  } else {
    notification.error({ message: data.message || "Error" });
  }
  return data;
};

export const addPatientFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  const { data } = await axios.post(addPatientUrl(), valData, getToken());
  dispatch(hideLoader());
  if (!data.error) {
    notification.success({ message: data.message });
  } else {
    notification.error({ message: data.message || "Error" });
  }
  return data;
};

export const generateslipFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  const { data } = await axios.post(generateSlipUrl(), valData, getToken());
  dispatch(hideLoader());
  if (!data.error) {
    notification.success({ message: data.message || "Success" });
  } else {
    notification.error({ message: data.message || "Error" });
  }
  return data;
};
export const fetchSymptom = async (valData) => {
  let config = {
    ...(await getToken()),
  };
  const { data } = await axios.get(getSymptomDataUrl(), config);
  return data;
};

export const singleCustomerFxn = (id) => async (dispatch) => {
  dispatch(showLoader());

  let { data } = await axios.get(singleCustomerUrl(id));
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};
export const singlePatientFxn = (id) => async (dispatch) => {
  dispatch(showLoader());

  let { data } = await axios.get(singlePatientUrl(id));
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};
export const updateCustomerFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(updateCustomerUrl(), valData);
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const singleBankFxn = (id) => async (dispatch) => {
  dispatch(showLoader());

  let { data } = await axios.get(singleBankUrl(id));
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};
export const updateBankFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(updateBankUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const updatePatientFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let { data } = await axios.post(updatePatientUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const fetchClientsForJournalEntry = async (valData) => {
  let config = {
    params: { ...valData },
    ...(await getToken()),
  };
  const { data } = await axios.get(getJournalEntryClients(), config);
  return data;
};

export const updatePatientsFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let config = {
    ...(await getToken()),
  };
  let {data} = await axios.post(editPatientUrl(), valData, config);
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};