import React, {useEffect, useState} from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { IndianStates, States, gstTypeList } from "../../components/_utils/appUtils";
import { addCompanyFxn } from "./actions";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { singleCompanyFxn , updateCompanyFxn} from "./actions";
const EditCompany = (props) => {
  const { getFieldValue,setFieldsValue } = props.form;
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [companyId, setCompanyId] = useState("");
  useEffect(() => {
    checkCompanyId();
  },[]);

  const checkCompanyId = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      getSingleCompany(id);
      setCompanyId(id)
    }
  };
  const getSingleCompany = async (id) => {
    let { data, success } = await dispatch(singleCompanyFxn(id));
    console.log(data, "ddtdtdtdtt");
    if (success) {
      setTimeout(() => {
        props.form.setFieldsValue({
          name: data.name,
          companyName: data.companyName,
          mobile: data.mobile,
          address: data.address,
          email: data.email,
          state: data.state,
          city: data.city,
          pincode: data.pincode,
          gstType: data.gstType,
        
        });
        setTimeout(() => {
          if(data.gstType == "Registered"){
            setFieldsValue({gstIn: data.gstIn})
          }
        }, 400);
     
      }, 300);
    }
  };

  const handleSubmit = (e) => {
    const {form} = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
        if (!err) {
            
          
            valData._id = companyId
         
            let x = await dispatch(updateCompanyFxn(valData));
            if (x && x.success) {
                console.log(x, "valdata")
            }
        } else {
            if (err.name) {
                notification.warning({
                    message: `Please enter Name`,
                });
                return;
            }

            if (err.email) {
                notification.warning({
                    message: `Please enter Email`,
                });
                return;
            }

            if (err.mobile) {
                notification.warning({
                    message: `Please enter Mobile No`,
                });
                return;
            }

            if (err.state) {
                notification.warning({
                    message: `Please enter State`,
                });
                return;
            }

            if (err.city) {
                notification.warning({
                    message: `Please enter City`,
                });
                return;
            }

            if (err.pincode) {
                notification.warning({
                    message: `Please enter Pin Code`,
                });
                return;
            }

            if (err.address) {
                notification.warning({
                    message: `Please enter Address`,
                });
                return;
            }
            
            notification.warning({
                message: "Fill All Required Fields",
            });
        }
    });
};

  let inputTypes = {
    fields: [
      {
        key: "companyName",
        label: "Company Name",
        span: "col-md-6",
        placeholder: "Company Name",
      },
      {
        key: "name",
        label: "Contact Person Name",
        span: "col-md-6",
        placeholder: "Contact Person Name",
      },
      {
        key: "email",
        label: "Email",
        type: "email",
        span: "col-md-6",
        placeholder: "Email",
      },
     
      {
        key: "mobile",
        label: "Mobile No",
        span: "col-md-6",
        placeholder: "Mobile No",
      },
      
      {
        key: "state",
        label: "State",
        span: "col-md-6",
        placeholder: "State",
        type: "select",
        options: IndianStates,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            state: x,
          });
        },
      },
      {
        key: "city",
        label: "City",
        span: "col-md-3",
        placeholder: "City",
      },
      {
        key: "pincode",
        label: "Pincode",
        span: "col-md-3",
        placeholder: "Pincode",
      },

      {
        key: "gstType",
        label: "GST Type",
        span: "col-md-6",
        placeholder: "GST Type",
        type: "select",
        options: gstTypeList,
        showSearch: true,
        keyAccessor: (x) => x.value,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            gstType: x,
          });
       
        },
        
      },
      {
        key: "gstIn",
        label: "GSTIN",
        span: "col-md-6",
        placeholder: "GSTIN",
         hidden: getFieldValue("gstType") !== "Registered",
         required: getFieldValue("gstType") === "Registered",
      },
      {
        key: "address",
        label: "Address",
        span: "col-md-12",
        placeholder: "Address",
        type: "textarea",
      },
    ],
  };

  return (
    <>
      <PageHeader title={"Edit Company"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary me-2">
                Submit
              </button>
              <HyperLink
                className=" ms-4 btn btn-danger"
                link={"/company-list"}
              >
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(EditCompany);
