import React from "react";

const Card = (props) => {
    let {
        title,
        children,
        className = "",
        lg,
        sm,
        size = "normal",
        padding = true
    } = props;
    let titleStyle = {fontSize: 14};
    if (lg) {
        titleStyle = {fontSize: 18};
    }
    if (sm) {
        titleStyle = {fontSize: 12};
    }
    // card-h-100
    return (
        <div className="card">
            {title ? <div className="card-header">
                {title}
            </div> : null}
            <div className={`card-body ${size}`} style={!padding ? {padding: 0} : null}>
                {children}
            </div>
        </div>
    );
};
export default Card;
