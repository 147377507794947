import { apiUrl } from "../../../setting";


export const addTransferSaleUrl = () => {
    return `${apiUrl}/routes/transfer/transferProductSale`;
};

export const addTransferPurchaseUrl = () => {
    return `${apiUrl}/routes/transfer/transferProductPurchase`;
};

export const getCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/customerList`;
};

export const getBank = () => {
    return `${apiUrl}/routes/customer/getBank`;
};

export const getStockUrl = () => {
    return `${apiUrl}/routes/product/getStock`;
};

export const getTransferUrl = () => {
    return `${apiUrl}/routes/transfer/getAllTransfer`;
};

export const invoiceUpdateUrl = () => {
    return `${apiUrl}/routes/sale/updateInvoice`;
};

export const getGroupCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/getCustomerDataByGroupId`;
};

export const getbatchRecordByProductIdUrl = () => {
    return `${apiUrl}/routes/sale/getBatchRecordByProductId`;
}
export const PrintSaleUrl = (id) => {
    return apiUrl + "/routes/sale/printSaleRecord/" + id;
};
export const deleteTransferReport = () => {
    return `${apiUrl}/routes/transfer/deleteTransfer`;
};
export const getSaleByHeadBranch = () => {
    return `${apiUrl}/routes/sale/findAllSaleRoi`;
};

export const getSaleByBranch = () => {
    return `${apiUrl}/routes/sale/findRoiByBranch`;
};
export const generateSale = () => {
    return `${apiUrl}/routes/sale/saleGenerate`;
};
export const approveRequestROIURl = (requestId) => {
   
    return `${apiUrl}/routes/stock/approvedROI/${requestId}`;
};
export const findROIServiceUrl = () => {
    return `${apiUrl}/routes/product/getOnlyROI`;
};
