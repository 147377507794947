import React from 'react';
import {Navigate} from "react-router-dom";
import PrescriptionDetail from '../../pages/doctor/prescriptionDetail';

const isLoggedIn = localStorage.getItem("token") ? true : false;

export default function PrintLayout(props) {
    return isLoggedIn ? (
        <>
            <div className="container-scroller">
                <div className="container-fluid print-sale">
                    <PrescriptionDetail/>
                </div>
            </div>

        </>) : (
        <Navigate replace to="/login"/>
    )
}
