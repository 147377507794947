import { Api_Url } from "./api";

export const apiUrl = Api_Url;

export const UnitCodeArr = [
  { code: "BAG", name: "BAGS" },
  { code: "BAL", name: "BALE" },
  { code: "BDL", name: "BUNDLES" },
  { code: "BKL", name: "BUCKLES" },
  { code: "BOU", name: "BILLIONS OF UNITS" },
  { code: "BOX", name: "BOX" },
  { code: "BTL", name: "BOTTLES" },
  { code: "BUN", name: "BUNCHES" },
  { code: "CAN", name: "CANS" },
  { code: "CBM", name: "CUBIC METER" },
  { code: "CCM", name: "CUBIC CENTIMETER" },
  { code: "CMS", name: "CENTIMETER" },
  { code: "CTN", name: "CARTONS" },
  { code: "DOZ", name: "DOZEN" },
  { code: "DRM", name: "DRUM" },
  { code: "GGR", name: "GREAT GROSS" },
  { code: "GMS", name: "GRAMS" },
  { code: "GRS", name: "GROSS" },
  { code: "GYD", name: "GROSS YARDS" },
  { code: "KGS", name: "KILOGRAMS" },
  { code: "KLR", name: "KILOLITRE" },
  { code: "KME", name: "KILOMETRE" },
  { code: "MLT", name: "MILLILITRE" },
  { code: "MTR", name: "METERS" },
  { code: "MTS", name: "METRIC TON" },
  { code: "NOS", name: "NUMBERS" },
  { code: "PAC", name: "PACKS" },
  { code: "PCS", name: "PIECES" },
  { code: "PRS", name: "PAIRS" },
  { code: "QTL", name: "QUINTAL" },
  { code: "ROL", name: "ROLLS" },
  { code: "SET", name: "SETS" },
  { code: "SQF", name: "SQUARE FEET" },
  { code: "SQM", name: "SQUARE METERS" },
  { code: "SQY", name: "SQUARE YARDS" },
  { code: "TBS", name: "TABLETS" },
  { code: "TGM", name: "TEN GRAMS" },
  { code: "THD", name: "THOUSANDS" },
  { code: "TON", name: "TONNES" },
  { code: "TUB", name: "TUBES" },
  { code: "UGS", name: "US GALLONS" },
  { code: "UNT", name: "UNITS" },
  { code: "YDS", name: "YARDS" },
  { code: "OTH", name: "OTHERS" },
];

export const gstRate = [
  { code: "0", number: "0" },
  { code: "5", number: "5" },
  { code: "12", number: "12" },
  { code: "18", number: "18" },
  { code: "28", number: "28" },
];
export const type = [{ name: "Inclusive" }, { name: "Exclusive" }];

export const types = [
  { name: "Product" },
  { name: "Service" },
  { name: "Slip" },
  { name: "Membership" },
];
