import { apiUrl } from "../../../setting";
// import { getItemLocalstorage } from "../../../components/_utils/_utils";


// const companyId = getItemLocalstorage("user")["companyId"];





export const addExpenseCategoryUrl = () => {
    return `${apiUrl}/routes/expense/createCategory`;
};
export const addExpenseUrl = () => {
    return `${apiUrl}/routes/expense/addExpense`;
};
export const getExpenseCategoryDataUrl = () => {
    return `${apiUrl}/routes/expense/getExpenseCategoryDataByCompanyId`;
};
export const getExpenseAllDataUrl = () => {
    return `${apiUrl}/routes/expense/getExpenseDetailDataByCompanyId`;
};
export const deleteExpenseUrl = (id) => {
    return apiUrl + "/routes/expense/deleteExpense/" + id;
  };
  export const singleExpenseUrl = (expenseId) => {
    return apiUrl + "/routes/expense/getSingleExpense/" + expenseId;
};
export const updateExpenseUrl = () => {
    return `${apiUrl}/routes/expense/updateExpense`;
  };
  export const singleExpenseCategoryUrl = (id) => {
    return apiUrl + "/routes/expense/getSingleExpenseCategory/" + id;
};
export const updateExpenseCategoryUrl = () => {
    return `${apiUrl}/routes/expense/updateExpenseCategory`;
  };
