import React, { useEffect, useState } from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../../components/Elements/appUtils";

import PageHeader from "../../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../../components/_utils/formUtils";
import { States, gstTypeList } from "../../../components/_utils/appUtils";

import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { singleFrequencyFxn, updateFrequencyFxn } from "./action";


const EditFrequency = (props) => {
    let {onClose} = props;
  const { getFieldValue,setFieldsValue } = props.form;
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [frequencyId, setFrequencyId] = useState("");
  useEffect(() => {
    checkFrequencyId();
  }, []);

  let id;

  const checkFrequencyId = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    id = searchParams.get("_id");
    if (id) {
        getSingleFrequency(id);
        setFrequencyId(id);
    }
  };
  const getSingleFrequency = async (id) => {
    let { data, success } = await dispatch(singleFrequencyFxn(id));
    console.log(data, "valdata")
    if (success) {
      setTimeout(() => {
        props.form.setFieldsValue({
            frequency: data.frequency,
        });
       
         
     
      }, 300);
      
      
    }
  };
 

    const handleSubmit = (e) => {
      const {form} = props;
      e.preventDefault();

      form.validateFields(async (err, valData) => {
          if (!err) {

              valData._id = frequencyId

              let x = await dispatch(updateFrequencyFxn(valData));
              if (x && x.success) {
                  console.log(x, "valdata")
                  navigate('/add-frequency')
              }
          } else {
              if (err.name) {
                  notification.warning({
                      message: `Please enter Frequency`,
                  });
                  return;
              }

            

              notification.warning({
                  message: "Fill All Required Fields",
              });
          }
      });
  };

  let inputTypes = {
    fields: [
        {
            title: "Frequency",
            dataIndex: "frequency",
            key: "frequency",
          },
     
     
    ],
  };

  return (
    <>
      <PageHeader title={"Edit Frequency"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary me-2">
                Submit
              </button>
              <HyperLink
                className=" ms-4 btn btn-danger"
                link={"/add-frequency"}
              >
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(EditFrequency);
