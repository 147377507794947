import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Modal,
  notification,
  InputBox,
  Form,
  Table,
  Button,
} from "../../../components/Elements/appUtils";
import { useCollapse } from "react-collapsed";
import { fetchOnlyServiceFxn } from "../../products/actions";
import moment from "moment";
import ServiceModal from "./serviceModal";
import { fetchTakenDetails } from "./action";
import { fetchFrequencyDetails } from "./action";
import { findPrescriptionByVisitNumberUrl } from "./api";
import _ from "lodash";
import { getToken } from "../../../request";
import axios from "axios";
import { getStockUrl } from "../../products/api";
import TableMain from "../../../components/Elements/Table";
import { createPrescreptionUrl, getVisitAllRecordsUrl } from "./api";
import { useDispatch } from "react-redux";
import { addPrescreptionFxn, fetchVisitAllDetailsFxn } from "./action";
import { calculation, Tabs } from "../../../components/Elements/appUtils";
import FollowupVisitModalComponent from "./followupvistiModal";
import { findPrescriptionIdUrl } from "../../doctor/api";
import { dosage } from "../../../components/_utils/_utils";
import {apiUrl} from "../../../setting";
let { TabPane } = Tabs;
let initState = {
  productId: "",
  productName: "",
  dosage: "",
  whentotake: "",
  routine: "",
  duration: "",
  remarks: "",
};
const CustomerDetailsModal = (props) => {
  let tableRef = useRef();
  let {
    statesAreEqual,
    customerId,
    visible,
    onClose,
    customerRecord,
    visitId,
  } = props;
  console.log(customerRecord, "customerRecord");
  const [stock, setStock] = useState([]);

  const [takenDetails, SetTakenDetails] = useState([]);
  const [frequencyDetails, SetFrequencyDetails] = useState([]);
  const [medicineDetails, setMedicineDetails] = useState([]);
  const [testUploadData, setTestUploadData] = useState([]);
  const [stockAll, setStockAll] = useState([]);
  const [serviceStock, setServiceStock] = useState([]);
  const [allPrescription, setAllPrescription] = useState([]);
  const [visitIds, setVisitIds] = useState("");
  let { patient } = props;

  let [items, setItems] = useState([]);
  let initState = {
    productId: "",
    productName: "",
    dosage: "",
    whentotake: "",
    duration: "",
    frequency:
      frequencyDetails?.length > 0 ? frequencyDetails[0].frequency : "",
    remarks: "",
  };
  let [state, setState] = useState(initState);
  console.log(initState, "initState");
  let events = {
    _updateState: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
    },
    addItem: () => {
      // if (!state.productId) {
      //   notification.warning({ message: "Choose product" });
      //   return;
      // }
      if (!state.dosage) {
        notification.warning({ message: "Choose dosage" });
        return;
      }
      if (!state.whentotake) {
        notification.warning({ message: "Choose when to takes" });
        return;
      }
      if (!state.duration) {
        notification.warning({ message: "Enter duration" });
        return;
      }

      let findItem = _.find(items, (item) => {
        return item.productId == state.productId;
      });
      if (findItem) {
        notification.warning({ message: "Product already added." });
        return;
      } else {
        let itemClone = _.clone(items);
        itemClone.push(state);
        setItems(itemClone);
        setState(initState);
      }
    },
  };

  console.log(medicineDetails, "allPrescription");
  const fetchStock = async () => {
    try {
      const response = await axios.get(getStockUrl(), getToken());
      const filteredStock = response.data.data.filter(
        (item) => item.productId.type === "Product"
      );
      setStock(filteredStock);
      console.log(filteredStock, "dsfdsfdsf");
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };
  useEffect(() => {
    if (visible) {
      fetchStock();
      apiRequest();
      fetchTaken();
      fetchFrequency();
      apiRequest2();
      prescriptionByVisitNumber();
    }
  }, [visible]);

  const apiRequest = async (params) => {
    try {
      const data = await fetchOnlyServiceFxn({ ...params });
      console.log(data, "edfdfd");
      setServiceStock(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpansion = async (index, id) => {
    setExpandedIndex(expandedIndex === index ? null : index);
    const response = await axios.get(findPrescriptionIdUrl(id), getToken());
    setMedicineDetails(response.data.visitDoc);
    if (response.data.visitDoc && response.data.visitDoc.testFilePath && response.data.visitDoc.testFilePath.length !== 0){
      setTestUploadData(response.data.visitDoc.testFilePath);
    }else{
      setTestUploadData([])
    }
  };
  let [productState, setProductState] = useState(initState);

  const prescriptionByVisitNumber = () => {
    return new Promise(async (resolve) => {
      try {
        console.log(visitIds, "idddddddddddddddddd");
        const response = await axios.get(
          findPrescriptionByVisitNumberUrl(visitIds),
          getToken()
        );
        console.log(response.data.datas, "idss   ");
        resolve(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  console.log(productState, "productState");
  const getColumns = () => {
    let columnArr = [];
    _.each(columns, (item) => {
      if (!item.show) {
        columnArr.push(item);
      }
    });

    return columnArr;
  };

  const handleDelete = (index) => {
    console.log(index, "index");
    reCalculateAmount();
    const updatedProductReport = [...stockAll];
    updatedProductReport.splice(index, 1);
    setStockAll(updatedProductReport);
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Dosage",
      dataIndex: "dosage",
      key: "dosage",
    },
    {
      title: "When to take",
      dataIndex: "whentotake",
      key: "whentotake",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Frequency.",
      dataIndex: "frequency",
      key: "frequency",
    },

    {
      title: "Action",
      key: "delete",
      render: (text, record, index) => (
        <a className="nav-link" onClick={() => handleDelete(index)}>
          <i className="mdi mdi-delete"></i>
        </a>
      ),
    },
  ];

  const columns2 = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Test Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "File Name",
      dataIndex: "filePath",
      key: "filePath",
      render: (text, record) => {
        return (
            <a href={`${apiUrl}${text}`} target={'_blank'}>{`${record.name}`}</a>
        )
      },
    },
    {
      title: "Upload Date",
      dataIndex: "date",
      key: "date",
      render: (text) => <span>{moment(text).format("DD-MMM-YY")}</span>,
    },
  ];


  const fetchTaken = (params) => {
    return new Promise(async (resolve) => {
      const data = await fetchTakenDetails({ ...params });
      console.log(data.data, "dsdsds");
      SetTakenDetails(data.data);
    });
  };

  const fetchFrequency = (params) => {
    return new Promise(async (resolve) => {
      const data = await fetchFrequencyDetails({ ...params });
      console.log(data.data, "dsdsds");
      SetFrequencyDetails(data.data);
    });
  };

  const reCalculateAmount = () => {
    calculation({ ...productState, statesAreEqual }).then((resp) => {
      setProductState({
        ...productState,
        ...resp,
      });
    });
  };
  useEffect(() => {
    reCalculateAmount();
  }, [
    productState.productId,
    productState.dosage,
    productState.duration,
    productState.routine,
    productState.remarks,
  ]);
  console.log(allPrescription, "allPrescription");
  const apiRequest2 = async () => {
    return new Promise(async (resolve) => {
      try {
        let params = {
          visitId: visitId,
          patientId: customerId,
        };

        const data = await fetchVisitAllDetailsFxn({ ...params });
        console.log(data, "visitRecords");
        setAllPrescription(data);

        resolve(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  return (
    <>
      <Modal
        title={"Visit Details"}
        visible={visible}
        onClose={onClose}
        width={"95%"}
      >
        <div>
          <Form>
            <div className={"row"}>
              <div className={"col-md-3"}>
                <Card title={"Patient Details"} padding={0}>
                  <table className={"table table-bordered mt-1"}>
                    <tr>
                      <td>Name</td>
                      <td>{customerRecord.name}</td>
                    </tr>
                    <tr>
                      <td>Mobile</td>
                      <td>{customerRecord.mobile}</td>
                    </tr>

                    <tr>
                      <td>State</td>
                      <td>{customerRecord.state}</td>
                    </tr>
                    <tr>
                      <td>City</td>
                      <td>{customerRecord.city}</td>
                    </tr>
                  </table>
                </Card>
              </div>
              <div className={"col-md-9"}>
                <Tabs defaultActiveKey="1" onChange={() => {}}>
                  <TabPane tab={"Visit Details"} key="1">
                    {allPrescription?.visitRecords?.map((item, index) => (
                      <div key={index}>
                        <div
                          className="faq-question"
                          style={{
                            border: "1px solid #ccc",
                            padding: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div className="row">
                              <div
                                onClick={() => toggleExpansion(index, item._id)}
                                style={{ cursor: "pointer" }}
                              >
                                <h4 style={{ margin: 0 }}>
                                  Visit Number: {item.visitNumber}
                                  &nbsp;&nbsp;&nbsp;&nbsp;Date:{" "}
                                  {moment(item.date).format("DD/MM/YYYY")}
                                </h4>
                              </div>
                            </div>
                            <div
                              onClick={() => toggleExpansion(index)}
                              style={{ cursor: "pointer" }}
                            >
                              {expandedIndex === index ? "▲" : "▼"}
                            </div>
                          </div>
                        </div>
                        {expandedIndex === index && (
                          <>
                            <div className="row patient-details mb-1" style={{border: "0.5px solid lightgrey"}}>
                              <div className="col-sm-5 mt-1 p-0">
                                <div className="row sizerow">
                                  <div className="col-sm-5 p-0">
                                    <p className="size">
                                      <b>Complaint Box</b>
                                    </p>
                                  </div>
                                  <div className="col-sm-7 size">
                                    <p className="size">
                                     
                                      :&nbsp;&nbsp;{medicineDetails?.complaintBox}
                                    </p>
                                  </div>
                                </div>
                                <div className="row sizerow">
                                  <div className="col-sm-5 p-0">
                                    <p className="size">
                                      <b>Family History</b>
                                    </p>
                                  </div>
                                  <div className="col-sm-7">
                                    <p className="size">
                                    :&nbsp;&nbsp;{medicineDetails?.familyHistory}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-7 mt-1">
                                <div className="row sizerow">
                                  <div className="col-sm-3">
                                    <p className="size">
                                      <b>Other Diseases</b>
                                    </p>
                                  </div>
                                  <div className="col-sm-9">
                                    <p className="size">
                                    :&nbsp;&nbsp;{medicineDetails?.otherDisease}
                                    </p>
                                  </div>
                                </div>
                                <div className="row sizerow">
                                  <div className="col-sm-3">
                                    <p className="size">
                                      <b>Past History</b>
                                    </p>
                                  </div>
                                  <div className="col-sm-9">
                                    <p className="size">
                                    :&nbsp;&nbsp;{medicineDetails?.pastHistory}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="faq-answer">
                              <p className="mb-0 p-0">Medicines Advised</p>
                              <table class="table table-bordered mt-2">
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Sr No.</b>
                                    </th>
                                    <th>
                                      <b>Drug Name</b>
                                    </th>
                                    <th>
                                      <b>Dosage</b>
                                    </th>
                                    <th>
                                      <b>When to taken</b>
                                    </th>
                                    <th>
                                      <b>Frequency</b>
                                    </th>
                                    <th>
                                      <b>Duration</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {medicineDetails?.products?.length ? (
                                    medicineDetails?.products?.map(
                                      (item, index) => (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>{item.productName}</td>
                                          <td>{item.dosage}</td>
                                          <td>{item.whentotake}</td>
                                          <td>{item.frequency}</td>
                                          <td>{item.duration}</td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="9"
                                        style={{ textAlign: "center" }}
                                      >
                                        No records found
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                              <div className="next-step mt-1 mb-2">
                                <p className=" mt-1">
                                  <b>Services</b>
                                </p>
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>
                                        <b>Service</b>
                                      </th>
                                      <th>
                                        <b>Service Name</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {medicineDetails?.services?.length ? (
                                      medicineDetails.services?.map(
                                        (item, index) => (
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>{item.name}</td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan="9"
                                          style={{ textAlign: "center" }}
                                        >
                                          No records found
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                <div className="mt-4 mb-4">
                                  <h4 className="m-2">Tests</h4>
                                  <TableMain
                                      key={testUploadData.length}
                                      dataSource={testUploadData}
                                      columns={columns2}
                                      ref={tableRef}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default Form.create()(CustomerDetailsModal);
