
import { addLeadUrl, getUsersUrl, addPurchaseUserUrl,addFollowListUrl, singleUserUrl, updateUserUrl} from "../api";
import {customAxios as axios, getToken} from "../../../request";
import notification from "../../../components/Elements/Notification";
import {hideLoader, showLoader} from "../../../actions/loader";
export const addTypeUserFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addPurchaseUserUrl(), valData, getToken());
   
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};
export const fetchUsers = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getUsersUrl(), config);
    return data;
};
export const singleUserFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    
    let { data } = await axios.get(singleUserUrl(id));
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    }
  
    return data;
  };
  export const updateUserFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateUserUrl(), valData ,getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };