import React, { useEffect, useRef, useState } from "react";
import { Table } from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { fetchVisits } from "./actions";
import moment from "moment";
import { PrescriptionDetailUrl } from "./api";
import { getToken } from "../../request";
import axios from "axios";

const PrescriptionDetail = (props) => {
  const [allDetail, setAllDetail] = useState([]);
  let id;
  const apiRequest = () => {
    return new Promise(async (resolve) => {
      try {
        let searchParams = new URLSearchParams(window.location.search);
        id = searchParams.get("visitId");

        const response = await axios.get(PrescriptionDetailUrl(id), getToken());
        console.log(response, "ERrrrrrrrrrrr");
        setAllDetail(response.data.datas);
        resolve(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  useEffect(() => {
    apiRequest();
    setTimeout(() => {
      window.print();
    }, 1000);
  }, [id]);

  return (
    <>
      <div>
        {allDetail?.map((i, index) => {
          return (
            <>
              <div class="text-end"></div>
              <div className="prescription-slip">
                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                  Prescription Slip
                </p>
                <div className="row patient-details mb-1">
                  <div className="col-sm-5 mt-1 p-0">
                    <div className="row sizerow">
                      <div className="col-sm-5 p-0">
                        <p className="size">
                          <b>UHID</b>
                        </p>
                      </div>
                      <div className="col-sm-7 size">
                        <p className="size">:&nbsp;&nbsp;{i.patientId.uhid}</p>
                      </div>
                    </div>
                    <div className="row sizerow">
                      <div className="col-sm-5 p-0">
                        <p className="size">
                          <b>Patient Name</b>
                        </p>
                      </div>
                      <div className="col-sm-7">
                        <p className="size">:&nbsp;&nbsp;{i.patientId.name}</p>
                      </div>
                    </div>
                    <div className="row sizerow">
                      <div className="col-sm-5 p-0">
                        <p className="size">
                          <b>Age</b>
                        </p>
                      </div>
                      <div className="col-sm-7">
                        <p className="size">
                          :&nbsp;&nbsp;
                          {i.patientId.age ? `${i.patientId.age} Years` : ""}
                        </p>
                      </div>
                    </div>
                    <div className="row sizerow">
                      <div className="col-sm-5 p-0">
                        <p className="size">
                          <b>Doctor Name</b>
                        </p>
                      </div>
                      <div className="col-sm-7">
                        {" "}
                        <p className="size">:&nbsp;&nbsp;{i.doctorId?.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-7 mt-1">
                    <div className="row sizerow">
                      <div className="col-sm-3">
                        <p className="size">
                          <b>Mobile No</b>
                        </p>
                      </div>
                      <div className="col-sm-9">
                        <p className="size">
                          : &nbsp;&nbsp; {i.patientId.mobile}
                        </p>
                      </div>
                    </div>
                    <div className="row sizerow">
                      <div className="col-sm-3">
                        <p className="size">
                          <b>Address</b>
                        </p>
                      </div>
                      <div className="col-sm-9">
                        <p className="size">
                          : &nbsp;&nbsp;{" "}
                          {i.patientId.address && (
                            <span>{i.patientId.address} , </span>
                          )}
                          {i.patientId.state && (
                            <span>{i.patientId.state} , </span>
                          )}
                          {i.patientId.city}
                        </p>
                      </div>
                    </div>
                    <div className="row sizerow">
                      <div className="col-sm-3">
                        <p className="size">
                          <b>Date/Time</b>
                        </p>
                      </div>
                      <div className="col-sm-9">
                        <p className="size">
                          :&nbsp;&nbsp;{" "}
                          {moment(i.visitId.date).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="row sizerow">
                      <div className="col-sm-3">
                        <p className="size">
                          <b>Visit Number</b>
                        </p>
                      </div>
                      <div className="col-sm-9">
                        <p className="size">
                          : &nbsp;&nbsp; {i.visitId.visitNumber}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row patient-details mb-1">
                  <div className="col-sm-5 mt-1 p-0">
                    <div className="row sizerow">
                      <div className="col-sm-5 p-0">
                        <p className="size">
                          <b>Complaint Box</b>
                        </p>
                      </div>
                      <div className="col-sm-7 size">
                        <p className="size">
                          {i.complaints && (
                            <div className="next-step no-bottom-margin">
                              <p
                                className="advice-paragraph"
                                style={{ fontSize: "11px" }}
                              >
                                :&nbsp;
                                {i.complaints.slice(0, 1) +
                                  " , " +
                                  i.complaints.slice(1)}
                              </p>
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="row sizerow">
                      <div className="col-sm-5 p-0">
                        <p className="size">
                          <b>Family History</b>
                        </p>
                      </div>
                      <div className="col-sm-7">
                        <p className="size">:&nbsp;&nbsp;{i.familyHistory}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-7 mt-1">
                    <div className="row sizerow">
                      <div className="col-sm-3">
                        <p className="size">
                          <b>Other Diseases</b>
                        </p>
                      </div>
                      <div className="col-sm-9">
                        <p className="size">: &nbsp;&nbsp; {i.otherDisease}</p>
                      </div>
                    </div>
                    <div className="row sizerow">
                      <div className="col-sm-3">
                        <p className="size">
                          <b>Past History</b>
                        </p>
                      </div>
                      <div className="col-sm-9">
                        <p className="size">: &nbsp;&nbsp; {i.pastHistory}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {i.test && (
                  <div className="next-step mt-1 no-bottom-margin">
                    <p className="advice" style={{ fontSize: "12px" }}>
                      <b>Investigation:</b>
                    </p>
                    <p
                      className="advice-paragraph"
                      style={{ fontSize: "11px" }}
                    >
                      {i.test.slice(0, 1) + " , " + i.test.slice(1)},{" "}
                      {i.otherTest}
                    </p>
                  </div>
                )}
                {i.diagnosis && (
                  <div className="next-step mt-1 no-bottom-margin">
                    <p className="advice" style={{ fontSize: "12px" }}>
                      <b>Diagnosis:</b>
                    </p>
                    <p
                      className="advice-paragraph"
                      style={{ fontSize: "11px" }}
                    >
                      {i.diagnosis.slice(0, 1) + " , " + i.diagnosis.slice(1)}
                    </p>
                  </div>
                )}
                <p className="title mt-1 " style={{ fontSize: "12px" }}>
                  <b>Medicines Advised</b>
                </p>
                <table class="table table-bordered">
                  <thead>
                    <tr className="border-top" style={{ fontSize: "12px" }}>
                      <th>
                        <b>Sr No.</b>
                      </th>
                      <th
                        className="table-heading"
                        style={{ fontSize: "12px" }}
                      >
                        <b>Drug Name</b>
                      </th>
                      <th
                        className="table-heading"
                        style={{ fontSize: "12px" }}
                      >
                        <b>Dosage</b>
                      </th>
                      <th
                        className="table-heading"
                        style={{ fontSize: "12px" }}
                      >
                        <b>When to Taken</b>
                      </th>
                      <th
                        className="table-heading"
                        style={{ fontSize: "12px" }}
                      >
                        <b>Frequency</b>
                      </th>
                      <th
                        className="table-heading"
                        style={{ fontSize: "12px" }}
                      >
                        <b>Duration</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {i?.products?.map((item, i) => {
                      return (
                        <>
                          <tr className="table-height">
                            <td>{i + 1}</td>
                            <td style={{ fontSize: "11px" }}>
                              {item.productName}
                            </td>
                            <td style={{ fontSize: "11px" }}>{item.dosage}</td>
                            <td style={{ fontSize: "11px" }}>
                              {item.whentotake}
                            </td>
                            <td style={{ fontSize: "11px" }}>
                              {item.frequency}
                            </td>
                            <td style={{ fontSize: "11px" }}>
                              {item.duration}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {i.services && i.services.length > 0 && (
                  <div className="next-step mt-1">
                    <p className="title mt-1" style={{ fontSize: "12px" }}>
                      <b>Services</b>
                    </p>
                    <table className="table table-bordered">
                      <thead>
                        <tr className="border-top">
                          <th style={{ fontSize: "12px" }}>
                            <b>Service</b>
                          </th>
                          <th
                            className="table-heading"
                            style={{ fontSize: "12px" }}
                          >
                            <b>Service Name</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {i.services.map((item, index) => (
                          <tr className="table-height" key={index}>
                            <td style={{ fontSize: "11px" }}>{index + 1}</td>
                            <td style={{ fontSize: "11px" }}>{item.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {i.advice && (
                  <div className="next-step mt-1 no-bottom-margin">
                    <p className="advice" style={{ fontSize: "12px" }}>
                      <b>Advice/Observation:</b>
                    </p>
                    <p
                      className="advice-paragraph"
                      style={{ fontSize: "11px" }}
                    >
                      {i.advice}
                    </p>
                  </div>
                )}
                <div className="next-step mt-2">
                  <p className="title " style={{ fontSize: "12px" }}>
                    <b>Followup Visit</b>
                  </p>
                  <table class="table table-bordered">
                    <thead>
                      <tr className="border-top">
                        <th style={{ fontSize: "12px" }}>
                          <b>Days</b>
                        </th>
                        <th
                          className="table-heading"
                          style={{ fontSize: "12px" }}
                        >
                          <b>Next Visit</b>
                        </th>
                        <th
                          className="table-heading"
                          style={{ fontSize: "12px" }}
                        >
                          <b>Week Day</b>
                        </th>
                        <th
                          className="table-heading"
                          style={{ fontSize: "12px" }}
                        >
                          <b>Remark</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-height">
                        <td style={{ fontSize: "11px" }}>{i.days}</td>
                        <td style={{ fontSize: "11px" }}>{i.nextday}</td>
                        <td style={{ fontSize: "11px" }}>{i.weekday}</td>
                        <td style={{ fontSize: "11px" }}>{i.remark}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="footer-section mt-4">
                <div className="row">
                  <div className="col-sm-6">
                    <div
                    style={{
                      borderBottom: "1px solid #000",
                      marginTop: "6%",
                      width: "160px",
                    }}
                    />
                    <br />
                    <b>{i.doctorId?.name}</b><br/>
                    <b>{i.doctorId?.education}</b>
                  </div>
                  <div className="col-sm-6"></div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default PrescriptionDetail;
