import {getBrandDataUrl, getSymptomDataUrl,updateSymptomUrl, singleSymptomUrl, deleteSymptomUrl} from "../api";
import {customAxios as axios, getToken} from "../../../request";
import { hideLoader, showLoader } from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";

export const fetchSymptom = async (valData) => {
    let config = {
        ...await getToken()
    }
    const {data} = await axios.get(getSymptomDataUrl(), config);
    return data;
};
export const singleSymptomFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    
    let { data } = await axios.get(singleSymptomUrl(id));
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    }
  
    return data;
  };

  export const updateSymptomFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateSymptomUrl(), valData);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };
  export const deleteSymptomFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.delete(deleteSymptomUrl(id));
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };
