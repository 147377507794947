import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  notification,
  HyperLink,
  fixed2Digit,
  InputBox,
  Card,
  Table, Tooltip, Popconfirm,
} from "../../components/Elements/appUtils";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import {
  PaymentTypes,
} from "../../components/_utils/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { addPaymentInFxn, fetchPaymentIn } from "./action";
import { fetchCustomer } from "../doctor/actions";
import ChooseCustomer from "../customer/chooseCustomer";
import { fetchBank } from "../customer/actions";
import moment from "moment";
import {getToken} from "../../request";
import axios from "axios";
import {deletePaymentIn} from "./api";

function PaymentIn(props) {
  let tableRef = useRef();
  const [formData, setFormData] = useState([
    { bankId: "", paymentType: "", amount: "", transactionDetails: "" },
  ]);
  const [showDeleteButtons, setShowDeleteButtons] = useState([false]);

  const handleInputChange = (index, field, value) => {
    const newData = [...formData];
    newData[index][field] = value;
    setFormData(newData);
    setShowDeleteButtons((prevButtons) =>
      prevButtons?.map((btn, idx) => (idx === index ? true : btn))
    );
  };

  const handleDelete = (index) => {
    const newPayments = [...formData];
    newPayments.splice(index, 1);
    setFormData(newPayments);
    setShowDeleteButtons((prevButtons) =>
      prevButtons?.filter((_, idx) => idx !== index)
    );
  };

  const { getFieldValue } = props.form;
  let dispatch = useDispatch();
  let [customerId, setCustomerId] = useState("");
  let [clientId, setClientId] = useState("");
  const [banks, setBanks] = useState([]);
  const [ProductReport, setProductReport] = useState([]);
  const [customerState, setCustomerState] = useState("");
  const [customerName, setCustomerName] = useState("");

  useEffect(() => {
    const apiRequest123 = (params = {}) => {
      return new Promise(async (resolve) => {
        params._id = clientId;
        const data = await fetchCustomer({ ...params });
        console.log(data, "checkdata");
        setCustomerName(data.data[0].name);
        setCustomerState(data.data[0].state);
      });
    };
    if (clientId) {
      apiRequest123();
      setCustomerId(clientId);
    }
  }, [clientId]);

  let netAmount = 0;
  let quantity = 0;
  let balance = 0;

  const calNetAmount = () => {
    if (ProductReport.length == 0) {
      props.form.setFieldsValue({
        totalAmount: 0,
        balance: 0,
        quantity: 0,

        remainingAmount: 0,
      });
    }
    ProductReport.forEach((y) => {
      let paidAmount = getFieldValue("paidAmount");
      paidAmount = paidAmount ? fixed2Digit(paidAmount) : 0;
      netAmount += Number(y.netAmount);
      quantity += Number(y.quantity);
      balance = paidAmount ? netAmount - paidAmount : netAmount;

      if (balance < 0) {
        balance = "Not valid Paid Amount";

        props.form.setFieldsValue({
          totalAmount: netAmount ? fixed2Digit(netAmount) : 0,
          quantity: quantity,
          balance: 0,
        });
      } else {
        props.form.setFieldsValue({
          totalAmount: netAmount.toFixed(2),
          quantity: quantity,
          balance: balance ? balance.toFixed(2) : 0,
        });
      }
    });
  };

  const fetchBanks = async () => {
    const { data } = await fetchBank({
      type: "bank",
      results: 100,
      count: 100,
    });
    setBanks(data);
  };

  useEffect(() => {
    props.form.setFieldsValue({
      paidAmount: 0,
      paymentType: "None",
      salesDate: moment(),
      creditNoteBalance: 0,
      remainingAmount: 0,
    });

    fetchBanks();
  }, []);

  useEffect(() => {
    if (customerState) {
      props.form.setFieldsValue({
        stateOfSupply: customerState,
      });
    }
  }, [customerState]);

  console.log(customerState, "customerState");
  useEffect(() => {
    calNetAmount();
  }, [ProductReport, getFieldValue("paidAmount")]);

  const handleSubmit = async (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        valData.customerId = customerId;
        valData.paidToUser = formData;

        if (!valData.customerId) {
          notification.warning({ message: "Please Choose Client" });
          return;
        }
            
        if (isNaN(valData.paidAmount)) {
          notification.warning({
            message: "Please Enter Paid Amount in Numbers",
          });
          return;
        }
        const paidAmount = valData.paidAmount;
        if (paidAmount < 0) {
          notification.error({
            message: "Paid Amount cannot be negative",
          });
          return;
        }
        if (!valData.paidAmount) {
          notification.warning({ message: "Please Add Amount" });
          return;
        }
        if (!valData.date) {
          notification.warning({ message: "Please Choose Date" });
          return;
        }
        const lastData = formData[formData.length - 1];
        if (!lastData.bankId) {
          notification.warning({ message: "Please Choose Bank" });
          return;
        }
        if (!lastData.paymentType) {
          notification.warning({ message: "Please Choose Payment Type" });
          return;
        }
        if (!lastData.amount) {
          notification.warning({ message: "Please Enter Amount" });
          return;
        }
        const sumFormDataAmount = formData.reduce(
          (sum, item) => sum + parseFloat(item.amount) || 0,
          0
        );

        if (parseFloat(valData.paidAmount) < sumFormDataAmount) {
          notification.error({
            message: "Paid amount cannot be Greater than total amount",
          });
          return;
        } else if (parseFloat(valData.paidAmount) > sumFormDataAmount) {
          notification.error({
            message: "Paid amount cannot be less than total amount",
          });
          return;
        }

        let x = await dispatch(addPaymentInFxn(valData));

        if (x && x.success) {
          notification.success({ message: x.message });
          setProductReport([]);
          props.form.setFieldsValue({
            paidAmount: "",
            balance: "",
            customerId: "",
            paymentType: "",
            paymentDetails: "",
          });
          setCustomerId("");
          setCustomerName("")
          setCustomerState("")
          tableRef.current.reload();
        } else {
          notification.error({ message: x.message });
        }
      }
    });
  };

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await fetchPaymentIn({ ...params });
        resolve(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const deletePaymentInFxn = async (data) => {
    try {
      let config = {
        ...(await getToken()),
      };
      const response = await axios.post(deletePaymentIn(), data, config);
      console.log(response);
      if (response) {
        tableRef.current.reload();
        notification.success({ message: response.data.message });
        return;
      } else {
        notification.error({ message: response.data.message });
      }
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  const columns = [
    {
      title: "Client Name",
      dataIndex: "customerId",
      key: "customerId",
      render: (text, record) => <>{record?.customerId?.name}</>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => <>{text ? (moment(text).format("l")) : null}</>,
      // render: (text, record) => <>{text ? (moment(text).format("l")) : (moment(record.createdAt).format("l"))}</>,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
            <>
              <Tooltip title={"Delete"}>
                <Popconfirm
                    title={"Are your sure, you want to delete Payment In?"}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                      deletePaymentInFxn(record);
                    }}
                >
                  <img src={"./close.svg"}/>
                </Popconfirm>
              </Tooltip>
            </>
        );
      },
    },
  ];

  let inputTypes = {
    lowerFields: [
      {
        key: "paidAmount",
        type: "text",
        label: "Amount",
        span: "col-md-3",
        placeholder: "Amount",
      },
      {
        key: "date",
        label: "Date*",
        type: "date",
        span: "col-md-3",
        onChange: (x) => {
          props.form.setFieldsValue({
            date: x,
          });
        },
      },
    ],
  };
  const groupType = "SUNDRY DEBTORS";

  return (
    <PageHeader title={"Payment In (Receive Amount From Client)"}>
      <div className="card">
        <div className="card-body">
          <div className={"row"}>
            {clientId ? (
              <div className={"row"}>
                <div className={"col-md-3 mb-3"}>
                  <InputBox title={"Client"}>
                    <input
                      style={{ height: "45px", color: "black" }}
                      disabled
                      className={"form-control"}
                      type="text"
                      value={customerName}
                    />
                  </InputBox>
                </div>
              </div>
            ) : (
              <div className={"col-md-3 mb-3"}>
                <ChooseCustomer
                  groupType={groupType}
                  customerId={customerId}
                  callback={(clientData) => {
                    if (clientData && clientData._id) {
                      setCustomerId(clientData._id);
                      setCustomerState(clientData.state);
                    }
                  }}
                  label={'Choose Client (Debit)'}
                />
              </div>
            )}
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="form-elements-wrapper">
              <div className={"row"}>
                {inputTypes.lowerFields.map((item, key) => {
                  return !item.hidden ? (
                    <div
                      className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                      key={key}
                    >
                      <GetEachFormFields {...props.form} item={item} />
                    </div>
                  ) : null;
                })}
              </div>

              {getFieldValue("paidAmount") > 0 ? (
                <Card title={"Payments (Credit)"} size={"small"}>
                  <div className="d-flex align-items-end">
                    <div>
                      {formData?.map((data, index) => (
                        <div key={index} className={"item-flex mt-2"}>
                          <InputBox title={"Banks"}>
                            <select
                              style={{ height: "45px" }}
                              className="form-control"
                              value={data.bank}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "bankId",
                                  e.target.value
                                )
                              }
                              required="true"
                            >
                              <option value="">Select Bank</option>
                              {banks?.map((bank) => (
                                <option key={bank._id} value={bank._id}>
                                  {bank.name}
                                </option>
                              ))}
                            </select>
                          </InputBox>

                          <InputBox title={"Payment Type"}>
                            <select
                              style={{
                                height: "45px",
                              }}
                              className="form-control"
                              value={data.paymentType}
                              required
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "paymentType",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Payment Type</option>
                              {PaymentTypes?.map((PaymentTypes) => (
                                <option>{PaymentTypes.name}</option>
                              ))}
                            </select>
                          </InputBox>

                          <InputBox title={"Amount"}>
                            <input
                              style={{
                                height: "45px",
                              }}
                              className={"form-control"}
                              type="text"
                              value={data.amount}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "amount",
                                  e.target.value
                                )
                              }
                              placeholder="Amount"
                            />
                          </InputBox>

                          <InputBox title={"Transaction Details"}>
                            <input
                              style={{
                                height: "45px",
                              }}
                              className={"form-control"}
                              value={data.transactionDetails}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "transactionDetails",
                                  e.target.value
                                )
                              }
                              placeholder="Transaction Details"
                            />
                          </InputBox>
                          <InputBox className="deleteBtn">
                            <div key={index} className={"item-flex mt-3"}>
                              {index > 0 &&
                                showDeleteButtons[index] && ( // Condition added to not show delete button for zero index
                                  <a
                                    className="empty_btn"
                                    onClick={() => handleDelete(index)}
                                  >
                                    <i className="fa fa-trash-alt"/>
                                  </a>
                                )}
                            </div>
                          </InputBox>
                        </div>
                      ))}
                    </div>
                    {/*<div>*/}
                    {/*  <InputBox className={"pb-2"}>*/}
                    {/*    <a*/}
                    {/*      className="addBtn  btn-link"*/}
                    {/*      onClick={handleAddField}*/}
                    {/*    >*/}
                    {/*      Add More*/}
                    {/*    </a>*/}
                    {/*  </InputBox>*/}
                    {/*</div>*/}
                  </div>
                </Card>
              ) : null}
            </div>

            <button type="submit" className="btn btn-gradient-info me-2">
              Submit
            </button>
            <HyperLink className=" ms-4 btn btn-danger" link={"/sale"}>
              Cancel
            </HyperLink>
          </Form>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
            </div>
          </div>
        </div>
      </div>
    </PageHeader>
  );
}

export default Form.create()(PaymentIn);
