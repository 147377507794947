import React, { useRef, useState, useEffect } from "react";
import {
  Table,
  notification,
  Tooltip,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { fetchCustomer } from "./actions";
import { useNavigate } from "react-router-dom";
import { tab } from "@testing-library/user-event/dist/tab";
import { getTransaction } from "../customer/api";
import TransactionComp from "../../components/transactionComp";
import axios from "axios";
import { getToken } from "../../request";
const BranchList = (props) => {
  let tableRef = useRef();
  const navigate = useNavigate();
  const [branchData, setBranchData] = useState([]);
  const [filteredBranchData, setFilteredBranchData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchMobile, setSearchMobile] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [report, setReport] = useState([]);
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.type = ["branch", "headBranch"];
      const data = await fetchCustomer({ ...params });
      setBranchData(data.data);
      setFilteredBranchData(data.data);
      resolve(data);
    });
  };

  const filterBranch = () => {
    setShowFilter(true);

    if (!searchName && !searchEmail && !searchMobile) {
      // setBranchData([]);
      notification.error({ message: "please enter name" });
      setShowFilter(false);
      return;
    } else {
      const filteredBranch = branchData.filter(
        (branch) =>
          branch.name.toLowerCase().includes(searchName.toLowerCase()) &&
          branch.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
          branch.mobile.toLowerCase().includes(searchMobile.toLowerCase())
      );
      setBranchData(filteredBranch);
    }
  };

  const clearBranchFilters = () => {
    setSearchName("");
    setSearchEmail("");
    setSearchMobile("");
    setShowFilter(false);
    setBranchData(filteredBranchData);
    if (tableRef.current.reload) {
      tableRef.current.reload();
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <span>{text.toUpperCase()}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Number",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 190,
      render: (val, record) => {
        return (
          <>
            <a
              onClick={() => {
                navigate(`/edit-branch?_id=${record._id}`);
              }}
            >
              <img src={"./edit.svg"} alt="Edit" />
            </a>
            <Tooltip title={"View"}>
              <a
                className={"empty_btn m-2"}
                onClick={() => {
                  setIsModalOpen(true);
                  setCustomerId(record._id);
                }}
              >
                <i className={"fa fa-eye far"}></i>
              </a>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const fetchTransaction = async (dispach) => {
    try {
      let config = {
        params: { customerId },
        ...(await getToken()),
      };

      const response = await axios.get(getTransaction(customerId), config);
      console.log(customerId, "responseresponseresponse");
      setReport(response.data.data);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    fetchTransaction();
  }, [customerId]);

  useEffect(() => {
    if (isModalOpen) {
      fetchTransaction();
    }
  }, [isModalOpen]);
  return (
    <PageHeader
      title={"Branch"}
      extraLink={[
        {
          name: "Add Branch",
          link: "/add-branch",
        },
      ]}
    >
      <div className="card">
        <div className="card-body">
          <div className="mb-3 row">
            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchName" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchName"
                placeholder="Search by Name"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </div>

            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchEmail" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchEmail"
                placeholder="Search by Email"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
              />
            </div>

            <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: "7px" }}>
              <label htmlFor="searchMobile" className="form-label" />
              <input
                type="text"
                className="form-control search-filters"
                id="searchMobile"
                placeholder="Search by Mobile"
                value={searchMobile}
                onChange={(e) => setSearchMobile(e.target.value)}
              />
            </div>
          </div>

          <div className="mb-3 search-buttons">
            <div className="search-wrap pe-2">
              <button className="btn btn-info" onClick={filterBranch}>
                Search
              </button>
            </div>
            <div className="search-wrap">
              <button
                className="btn btn-secondary"
                onClick={clearBranchFilters}
              >
                Clear
              </button>
            </div>
          </div>

          {showFilter ? (
            <Table
              dataSource={branchData}
              columns={columns}
              ref={tableRef}
              key={`table-${showFilter ? "filtered" : "original"}`}
            />
          ) : (
            <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
          )}
        </div>
      </div>
      {isModalOpen ? (
        <TransactionComp
          visible={isModalOpen}
          Report={report}
          onClose={() => {
            setIsModalOpen(false);
            //  setReport([]);
          }}
        />
      ) : null}
    </PageHeader>
  );
};

export default BranchList;
