import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  displayDate,
  notification,
} from "../../components/Elements/appUtils";

import { GetEachFormFields } from "../../components/_utils/formUtils";
import { addEmployeeFxn } from "../employee/action";
import { AddFollowUpFxn } from "./action";
// import { AddFollowUpFxn, FollowUpList } from "../action";

const AddFollowup = (props) => {

  const [date, setDate] = useState("");

  const [selectedAudio, setSelectedAudio] = useState(null);
  let { visible, onClose, onSubmit, lead } = props;


  const { getFieldDecorator } = props.form;
  let dispatch = useDispatch();
  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        valData.leadId = lead._id;
        valData.date = date
        if (!valData.notes) {
          notification.warning({ message: "Please enter note." });
          return;
        }
        if (!date) {
          notification.warning({ message: "Please choose date." });
          return;
        }
    
        const formData = new FormData();
        formData.append("audioPath", selectedAudio); 
  
        Object.keys(valData).forEach((key) => {
          formData.append(key, valData[key]); 
        });
  
        let x = await dispatch(AddFollowUpFxn(formData));
        if (!x.error) {
          props.form.setFieldsValue({
            date: "",

            notes: "",
          });
          setDate("");
          onSubmit();
        }
      } else {
        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "notes",
        label: " FollowUp Notes",
        // required: true,
        placeholder: "FollowUp Notes",
        type: "textarea",
      },
    ],
  };

  const handleAudioChange = (event) => {
    const file = event.target.files[0];
    setSelectedAudio(file);
  };
  return (
    <>
      <Drawer
        placement="right"
        onClose={onClose}
        visible={visible}
        width="45%"
        rightContent={`Total Follow ups:`}
        className={"follow_ups"}
        title={"Add Follow Up"}
      >
        <div className="container">
          <Form onSubmit={handleSubmit} enctype="multipart/form-data">
            <div className="row">
              <div className="col-lg-12 col-12 m-2">
                {inputTypes.fields.map((item, key) => {
                  return (
                    <div
                      className={`pb-3 ${
                        item.span == 6 ? "col-md-6" : "col-md-12"
                      }`}
                      key={key}
                    >
                      <GetEachFormFields {...props.form} item={item} />
                    </div>
                  );
                })}
              </div>
              <div className="col-lg-12 col-12 m-2">
                <div className="form-group">
                  <label>Upload Audio</label>
                  <input
                  type="file"
                  name="audioPath"
                  accept="audio/*"
                  className="form-control"
                  onChange={handleAudioChange}
                />
               
                </div>
              </div>
              <div className="col-lg-12 col-12 m-2">
                <div className="row">
                  <div className="col-lg-4 col-12">
                    <div className="form-group">
                      <label className="form-control">Next Followup</label>
                    </div>
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="form-group">
                      <DatePicker
                        className="form-control"
                        placeholder="DD/MM/YYYY"
                        value={date}
                        onChange={(value) => {
                          setDate(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="or ">
              <button
                type={"primary"}
                htmlType="submit"
                className="btn btn-info btn-sm m-2"
              >
                Add FollowsUp
              </button>
            
            
            </div>
          </Form>
        </div>
      </Drawer>
    </>
  );
};

export default Form.create()(AddFollowup);
