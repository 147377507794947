import React from "react";
import AddCustomerComponent from "./addCustomComponent";
import { Modal } from "../../../components/Elements/appUtils";

const AddCustomerDrawer = (props) => {
  let { visible, onClose,groupType ,customerNames} = props;
  return (
    <Modal
      width={"75%"}
      visible={visible}
      onClose={onClose}
      title={"Add Customer"}
    >
      <AddCustomerComponent onClose={onClose} groupType={groupType} customerNames={customerNames}/>
    </Modal>
  );
};
export default AddCustomerDrawer;
