import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Form,
  notification,
  HyperLink,
} from "../../components/Elements/appUtils";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { States } from "../../components/_utils/appUtils";
import { getCustomerDataUrl } from "./api";
import { getToken } from "../../request";
import PageHeader from "../../components/Elements/pageHeader";
import _ from "lodash";
import ItemListComponent from "./ItemListComponent";
import { addStockRequestFxn } from "./actions";
import { getItemLocalstorage } from "../../components/_utils/_utils";
import { useNavigate } from "react-router-dom";

function Stock(props) {
  const branchId = getItemLocalstorage("user")["branchId"];
  const { getFieldValue } = props.form;
  const [customer, setCustomer] = useState([]);
  const [statesAreEqual, setStatesAreEqual] = useState(false);
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const [ProductReport, setProductReport] = useState([]);

  const updateProductReport = (newProductReport) => {
    setProductReport(newProductReport);
  };

  let netAmount = 0;
  let quantity = 0;
  let balance = 0;

  const calNetAmount = () => {
    ProductReport.forEach((y) => {
      netAmount += Number(y.netAmount);
      quantity += Number(y.quantity);
      balance = netAmount - Number(getFieldValue("paidAmount"));

      if (balance < 0) {
        balance = "Not valid Paid Amount";

        props.form.setFieldsValue({
          totalAmount: netAmount.toFixed(2),
          quantity: quantity,
          balance: balance,
        });
      } else {
        props.form.setFieldsValue({
          totalAmount: netAmount.toFixed(2),
          quantity: quantity,
          balance: balance.toFixed(2) || 0,
        });
      }
    });
  };

  const fetchCustomer1 = async () => {
    try {
      let config = {
        params: { _id: branchId },
        ...(await getToken()),
      };
      const response = await axios.get(getCustomerDataUrl(), config);
      setCustomer(response.data.data[0]);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    fetchCustomer1();
  }, []);

  useEffect(() => {
    calNetAmount();
  }, [ProductReport, getFieldValue("paidAmount")]);

  const handleSubmit = async (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      if (!err) {
        let findStateCode = _.find(States, (item) => {
          return item.name === valData.state;
        });
        if (findStateCode) {
          valData.stateCode = findStateCode.stateCode;
        }
        valData.StockRequestReports = ProductReport;
        valData.status = "pending";

        console.log(valData,"this is stock request");

        let x = await dispatch(addStockRequestFxn(valData));

        if (x && x.success) {
          notification.success({ message: x.message });
          setProductReport([]);
          props.form.setFieldsValue({});
          setTimeout(() => {
            navigate("/stockRequest");
          }, 1000);
        } else {
          notification.error({ message: x.message });
        }
        props.form.setFieldsValue({
          name: "",
          document: "",
        });
      }
    });
  };

  let inputTypes = {
    lowerFields: [
      {
        key: "details",
        label: "Details",
        span: "col-md-12",
        placeholder: "Payment Details",
      },
    ],
  };

  return (
    <PageHeader title={"Stock Request"}>
      <div className="card">
        <div className="card-body">
          <Form onSubmit={handleSubmit}>
            <div className="form-elements-wrapper">
              <div className={"row"}>
                <div className={"col-md-12 mt-4"}>
                  <ItemListComponent
                    ProductReport={ProductReport}
                    updateProductReport={updateProductReport}
                    statesAreEqual={statesAreEqual}
                  />
                </div>
              </div>
              <div className={"row mt-4"}>
                {inputTypes.lowerFields.map((item, key) => {
                  return !item.hidden ? (
                    <div
                      className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                      key={key}
                    >
                      <GetEachFormFields {...props.form} item={item} />
                    </div>
                  ) : null;
                })}
              </div>
            </div>

            <button type="submit" className="btn btn-info me-2">
              Submit
            </button>
            <HyperLink className=" ms-4 btn btn-danger" link={"/stockRequest"}>
              Cancel
            </HyperLink>
          </Form>
        </div>
      </div>
    </PageHeader>
  );
}

export default Form.create()(Stock);