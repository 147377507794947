import {apiUrl} from "../../../setting";



export const addEmployeeUrl = () => {
    return `${apiUrl}/routes/employee/addEmployee`;
};
export const getAllEmpUrl = () => {
    return `${apiUrl}/routes/employee/getEmployeeList`;
};
export const singleEmployeeUrl = (empId) => {
    return apiUrl + "/routes/employee/getSingleEmployee/" + empId;
};
export const updateEmployeeUrl = () => {
    return `${apiUrl}/routes/employee/updateEmployee`;
  };
  export const deleteEmployeeUrl = (id) => {
    return apiUrl + "/routes/employee/deleteEmployee/" + id;
  };

  export const singleExpenseEmployeeUrl = (employeeId) => {
    return apiUrl + "/routes/expense/SingleExpenseOfEmp/" + employeeId;
};
  export const checkInUrl = () => {
    return `${apiUrl}/routes/dailyreport/checkIn`;
};
export const checkOutUrl = () => {
  return `${apiUrl}/routes/dailyreport/checkOut`;
};

export const dailyReportUrl = (employeeId) => {
  return apiUrl + "/routes/dailyreport/findDailyReport/" + employeeId;
};
export const addSalaryUrl = () => {
  return `${apiUrl}/routes/salary/createSalary`;
};