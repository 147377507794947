import React, { useState, useEffect } from "react";
import {
  Form,
  notification,
  HyperLink,
} from "../../components/Elements/appUtils";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { States } from "../../components/_utils/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import _ from "lodash";
import ItemListComponent from "./ItemListComponent";
import {
  addTransferPurchaseFxn,
  addTransferSaleFxn,
  fetchCustomer,
  invoiceUpdateFxn,
} from "./actions";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function AddTransfer(props) {
  const { getFieldValue } = props.form;
  const [ProductReport, setProductReport] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const updateProductReport = (newProductReport) => {
    setProductReport(newProductReport);
  };
  const navigate = useNavigate();
  let dispatch = useDispatch();

  const [invoice, setInvoice] = useState(null);
  const updatedInvoiceNumber = async () => {
    const UpdInvoiceNo = await invoiceUpdateFxn();
    setInvoice(UpdInvoiceNo);
  };

  useEffect(() => {
    props.form.setFieldsValue({
      paidAmount: 0,
      paymentType: "None",
      invoiceNumber: invoice,
      transferDate: moment(),
    });
    updatedInvoiceNumber();
  }, [invoice]);

  let quantity = 0;

  const calNetAmount = () => {
    ProductReport.forEach((y) => {
      quantity += Number(y.quantity);
    });
  };
  useEffect(() => {
    calNetAmount();
  }, [ProductReport]);

  const apiRequest = (params = {}) => {
    return new Promise(async (resolve) => {
      params.results = 1000
      params.type = ["branch", "headBranch"];
      const data = await fetchCustomer({ ...params });
      setCustomerList(data.data);
    });
  };

  const handleSubmit = async (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      if (!err) {
        let findStateCode = _.find(States, (item) => {
          return item.name === valData.state;
        });
        if (findStateCode) {
          valData.stateCode = findStateCode.stateCode;
        }
        valData.productReports = ProductReport;
        if (ProductReport.length == 0) {
          notification.error({
            message: "Please Add Products",
          });
          return;
        }

        valData.quantity = quantity;

        if (!valData.customerId) {
          notification.warning({ message: "Please choose Client" });
          return;
        }

        let x = await dispatch(addTransferSaleFxn(valData));
        console.log("this is transaction record", x);
        const transferId = x.transferId;
        valData.transferId = transferId;
        let y = await dispatch(addTransferPurchaseFxn(valData));
        if (x && x.success) {
          notification.success({ message: x.message });
          setProductReport([]);
          props.form.setFieldsValue({});
          setTimeout(() => {
            navigate("/transfer");
          }, 1000);
        } else {
          notification.error({ message: x.message });
        }
        props.form.setFieldsValue({
          customerId: "",
          stateOfSupply: "",
          invoiceNumber: "",
          purchaseDate: "",
          totalAmount: "",
          paidAmount: "",
          balance: "",
          paymentType: "",
          paymentDetails: "",
        });
      }
    });
  };

  useEffect(() => {
    apiRequest();
  }, []);

  let inputTypes = {
    upperFields: [
      {
        key: "customerId",
        label: "Select  Branch",
        span: "col-md-3",
        placeholder: "Choose Branch",
        type: "select",
        options: customerList,
        showSearch: true,
        keyAccessor: (x) => x._id,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            customerId: x,
          });
        },
      },
      {
        key: "transferDate",
        label: "Date of Transfer",
        type: "date",
        span: "col-md-3",
        onChange: (x) => {
          props.form.setFieldsValue({
            transferDate: x,
          });
        },
      },
    ],
  };

  return (
    <PageHeader title={"Add Transfer"}>
      <div className="card">
        <div className="card-body">
          <div className={"row"}>
            <div className={"col-md-3 mb-3"}></div>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="form-elements-wrapper">
              <div className={"row"}>
                {inputTypes.upperFields.map((item, key) => {
                  return !item.hidden ? (
                    <div
                      className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                      key={key}
                    >
                      <GetEachFormFields {...props.form} item={item} />
                    </div>
                  ) : null;
                })}
              </div>
              <div className={"row"}>
                <div className={"col-md-12 mt-4"}>
                  <ItemListComponent
                    ProductReport={ProductReport}
                    customerId={getFieldValue("customerId")}
                    updateProductReport={updateProductReport}
                  />
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-gradient-info me-2">
              Submit
            </button>
            <HyperLink className=" ms-4 btn btn-danger" link={"/sale"}>
              Cancel
            </HyperLink>
          </Form>
        </div>
      </div>
    </PageHeader>
  );
}

export default Form.create()(AddTransfer);
