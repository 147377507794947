import React, { useRef, useState , useEffect} from "react";
import { Table, Tooltip } from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { fetchBank } from "./actions";
import { useNavigate } from "react-router-dom";
import {notification} from "../../components/Elements/appUtils";
import { getTransaction } from "./api";
import axios from "axios";
import { getToken } from "../../request";
import TransactionComp from "../../components/transactionComp";
const BankList = (props) => {
  const [bankData, setBankData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchDetails, setSearchDetails] = useState("");
  const [searchBankName, setSearchBankName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [report, setReport] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let tableRef = useRef();
  const navigate = useNavigate()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.type='bank'
      const data = await fetchBank({ ...params });
      setBankData(data.data);
      setFilteredData(data.data);
      resolve(data);
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 190,
      render: (val, record) => {
        return (
            <>
                <a onClick={() => {
                  navigate(`/edit-bank?_id=${record._id}`)
                }}>
                  <img src={"./edit.svg"}/>

                </a> 
              
                <Tooltip title={"View"}>
              <a
                className={"empty_btn"}
                onClick={() => {
                  setIsModalOpen(true);
                  setCustomerId(record._id);
                }}
              >
                <i className={"fa fa-eye far"}></i>
              </a>
            </Tooltip>
            </>
        );
      }
  }
  ];
  const filterBank = () => {
    setShowFilter(true);

    if (!searchName && !searchDetails && !searchBankName) {
      // setBankData([]);
      notification.error({ message: "please enter name" });
      setShowFilter(false)
      return
    } else {
      const filteredBank = bankData.filter((bank) =>
      (bank.name?.toLowerCase().includes(searchName.toLowerCase()) || searchName === '') &&
      (bank.details?.toLowerCase().includes(searchDetails.toLowerCase()) || searchDetails === '') &&
      (bank.bankName?.toLowerCase().includes(searchBankName.toLowerCase()) || searchBankName === '') 
      );
      setBankData(filteredBank);
    }
  };

  const clearBankFilters = () => {
    setSearchName("");
    setSearchDetails("");
    setSearchBankName("");
    setShowFilter(false);
    setBankData(filteredData);
  };


  
  const fetchTransaction = async (dispach) => {
    try {
      let config = {
        params: { customerId },
        ...(await getToken()),
      };

      const response = await axios.get(getTransaction(customerId), config);
      setReport(response.data.data);
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    fetchTransaction();
  }, [customerId]);

  useEffect(() => {
    if (isModalOpen) {
      fetchTransaction();
    }
  }, [isModalOpen]);
  return (
    <PageHeader
      title={"Banks"}
      extraLink={[
        {
          name: "Add Bank",
          link: "/add-bank",
        },
      ]}
    >
      <div className="card">
        <div className="card-body">
        <div className="mb-3 row">
        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
          <label htmlFor="searchName" className="form-label" />
          <input
            type="text"
            className="form-control search-filters"
            id="searchName"
            placeholder="Search by Name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </div>

        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
          <label htmlFor="searchDetails" className="form-label" />
          <input
            type="text"
            className="form-control search-filters"
            id="searchDetails"
            placeholder="Search by Details"
            value={searchDetails}
            onChange={(e) => setSearchDetails(e.target.value)}
          />
        </div>

        <div className="col-md-2 mb-2 pe-0" style={{ paddingLeft: '7px' }}>
          <label htmlFor="searchBankName" className="form-label" />
          <input
            type="text"
            className="form-control search-filters"
            id="searchBankName"
            placeholder="Search by BankName"
            value={searchBankName}
            onChange={(e) => setSearchBankName(e.target.value)}
          />
        </div>
      </div>

      <div className="mb-3 search-buttons">
        <div className="search-wrap pe-2">
          <button className="btn btn-info" onClick={filterBank}>
            Search
          </button>
        </div>
        <div className="search-wrap">
          <button className="btn btn-secondary" onClick={clearBankFilters}>
            Clear
          </button>
        </div>
      </div>
      {showFilter ? (
        <Table
          dataSource={bankData}
          columns={columns}
          ref={tableRef}
          key={`table-${showFilter ? 'filtered' : 'original'}`}
        />
      ) : (
        <Table
          apiRequest={apiRequest}
          columns={columns}
          ref={tableRef}
        />
      )}
        </div>
      </div>
      {isModalOpen ? (
        <TransactionComp
          visible={isModalOpen}
          Report={report}
        
          onClose={() => {
            setIsModalOpen(false);
              setReport([]);
              
          }}
        />
      ) : null}
    </PageHeader>
  );
};

export default BankList;
