
import React, {useEffect, useRef, useState} from "react";
import {Table} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import {fetchVisits} from "./actions";
import moment from "moment";
import {PrintCreditNoteUrl, PrintSaleUrl} from "./api";
import {GetBranchInfo} from "../sales/api"
import image2 from "../../components/assets/images/logo.png";
import {getToken} from "../../request";
import axios, {formToJSON} from "axios";
import {States} from "../../components/_utils/appUtils";
import {getItemLocalstorage} from "../../components/_utils/_utils";
const groupByGST = (data) => {
    return data.reduce((acc, item) => {
        if (!acc[item.gst]) {
            acc[item.gst] = {
                totalAmount: 0,
                totalDiscount: 0,
                totalIGST: 0,
                totalCGST: 0,
                totalSGST: 0,
                totalGST: 0,
            };
        }
        acc[item.gst].totalAmount += item.totalAmount;
        acc[item.gst].totalDiscount += item.discountAmount;
        acc[item.gst].totalIGST += item.igstAmount;
        acc[item.gst].totalCGST += item.cgstAmount;
        acc[item.gst].totalSGST += item.sgstAmount;
        acc[item.gst].totalGST += item.gstAmount;
        return acc;
    }, {});
};
const ProductTable = ({ data, saleRecord }) => {
    const groupedData = groupByGST(data);
    const gstClasses = [5, 12, 18, 28];
    const grandTotals = {
        totalAmount: 0,
        totalDiscount: 0,
        totalIGST: 0,
        totalCGST: 0,
        totalSGST: 0,
        totalGST: 0,
    };
    gstClasses.forEach((gstKey) => {
        const totals = groupedData[gstKey] || {
            totalAmount: 0,
            totalDiscount: 0,
            totalIGST: 0,
            totalCGST: 0,
            totalSGST: 0,
            totalGST: 0,
        };

        grandTotals.totalAmount += totals.totalAmount;
        grandTotals.totalDiscount += totals.totalDiscount;
        grandTotals.totalIGST += totals.totalIGST;
        grandTotals.totalCGST += totals.totalCGST;
        grandTotals.totalSGST += totals.totalSGST;
        grandTotals.totalGST += totals.totalGST;
    });
    const hasIGST = !(saleRecord.cgstAmount !== 0 && saleRecord.sgstAmount !== 0);

    return (
        <div className="container" style={{ marginLeft: '0px', paddingLeft: '0px', paddingRight: '0px' }}>
            <div className="row font-weight-bold border-bottom border-left border-top" style={{ fontSize: '0.7rem', marginLeft: '1px', marginRight: 0 , borderColor: '#D3D3D3'}}>
                <div className="col p-1">GST Class</div>
                <div className="col p-1">Total</div>
                <div className="col p-1">Disc</div>
                {hasIGST && <div className="col p-1">IGST</div>}
                {!hasIGST && <div className="col p-1">CGST</div>}
                {!hasIGST && <div className="col p-1">SGST</div>}
                <div className="col p-1">Total</div>
            </div>
            {gstClasses.map(gstKey => (
                <div key={gstKey} className="row border-bottom border-left" style={{ fontSize: '0.7rem', marginLeft: '1px', marginRight: 0 }}>
                    <div className="col p-1">{gstKey} %</div>
                    <div className="col p-1">{(groupedData[gstKey]?.totalAmount || 0).toFixed(2)}</div>
                    <div className="col p-1">{(groupedData[gstKey]?.totalDiscount || 0).toFixed(2)}</div>
                    {hasIGST && <div className="col p-1">{(groupedData[gstKey]?.totalIGST || 0).toFixed(2)}</div>}
                    {!hasIGST && <div className="col p-1">{(groupedData[gstKey]?.totalCGST || 0).toFixed(2)}</div>}
                    {!hasIGST && <div className="col p-1">{(groupedData[gstKey]?.totalSGST || 0).toFixed(2)}</div>}
                    <div className="col p-1">{(groupedData[gstKey]?.totalGST || 0).toFixed(2)}</div>
                </div>
            ))}
            <div className="row font-weight-bold border-bottom border-left" style={{ fontSize: '0.7rem', marginLeft: '1px', marginRight: 0 }}>
                <div className="col p-1">Total</div>
                <div className="col p-1">{grandTotals.totalAmount.toFixed(2)}</div>
                <div className="col p-1">{grandTotals.totalDiscount.toFixed(2)}</div>
                {hasIGST && <div className="col p-1">{grandTotals.totalIGST.toFixed(2)}</div>}
                {!hasIGST && <div className="col p-1">{grandTotals.totalCGST.toFixed(2)}</div>}
                {!hasIGST && <div className="col p-1">{grandTotals.totalSGST.toFixed(2)}</div>}
                <div className="col p-1">{grandTotals.totalGST.toFixed(2)}</div>
            </div>
        </div>
    );
};

const Check = (props) => {
    const user = getItemLocalstorage('user')

    const [allDetail, setAllDetail] = useState([]);
    const [saleRecord, setSaleRecord] = useState("");
    const [branchDetail, setBranchDetail] = useState({});
    const [invoiceNumber, setInvoiceNumber] = useState();
    let id;
    const tableRef = useRef(null);
    const apiRequest = () => {
        return new Promise(async (resolve) => {
            try {
                let searchParams = new URLSearchParams(window.location.search);
                id = searchParams.get("_id");
                const branchInfo = await axios.get(GetBranchInfo(), getToken());
                setBranchDetail({...branchInfo.data.customer})
                const response = await axios.get(PrintCreditNoteUrl(id), getToken());
                console.log(response,'res1')
                setAllDetail(response.data.datas);
                setInvoiceNumber(response.data.sale);
                setSaleRecord(response.data.sale);


                resolve(response);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const getStateNameFromCode = () => {
        let foundState = {name:""}
        if(saleRecord.stateOfSupply!=-1){
            foundState = States.find(
                (state) => state.stateCode == saleRecord.stateOfSupply
            );}
        else
            foundState.name = saleRecord.otherState;


        return foundState ? foundState.name : "";
    };



    function convertNumberToWords(num) {
        const units = [
            "",
            "One",
            "Two",
            "Three",
            "Four",
            "Five",
            "Six",
            "Seven",
            "Eight",
            "Nine",
        ];
        const teens = [
            "Eleven",
            "Twelve",
            "Thirteen",
            "Fourteen",
            "Fifteen",
            "Sixteen",
            "Seventeen",
            "Eighteen",
            "Nineteen",
        ];
        const tens = [
            "",
            "Ten",
            "Twenty",
            "Thirty",
            "Forty",
            "Fifty",
            "Sixty",
            "Seventy",
            "Eighty",
            "Ninety",
        ];

        if (num === 0) return "Zero";

        let words = "";

        if (num >= 1000 && num < 10000) {
            words += `${units[Math.floor(num / 1000)]} Thousand `;
            num %= 1000;
        }

        if (num >= 100 && num < 1000) {
            words += `${units[Math.floor(num / 100)]} Hundred `;
            num %= 100;
        }

        if (num >= 20 && num < 100) {
            words += `${tens[Math.floor(num / 10)]} `;
            num %= 10;
        }

        if (num > 10 && num < 20) {
            words += `${teens[(num % 10) - 1]} `;
            num = 0;
        }

        if (num > 0 && num < 10) {
            words += `${units[num]} `;
        }

        return words.trim();
    }

    useEffect(()=>{
        const printData =  async() => {
            await apiRequest();

            setTimeout(() => {
                const tableElement = tableRef.current;
                const rows = tableElement.querySelectorAll('tr');
                let totalHeight = 0;

                rows.forEach((row) => {
                    totalHeight += row.offsetHeight;
                    if (totalHeight >= 850) {
                        row.classList.add('page-break');
                        totalHeight = 0;
                    }
                });

                window.print();
                // window.close()
            }, 1000);
        }

        printData();
    }, [id]);
    const getAddress = () => {
        const { address, city,state, pincode } = branchDetail;
        let fullAddress = '';

        const capitalize = (str) => {
            if (!str) return '';
            return str.split(' ')
                .map((word, index) =>  word.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                .join(' ');
        };

        if (address) fullAddress += address;
        if (city) fullAddress += ` , ${city}`;
        if (state) fullAddress += ` , ${state}`;
        if (pincode) fullAddress += ` , ${pincode}`;
        fullAddress = capitalize(fullAddress);
        return fullAddress.trim() || '_';
    };
    const email = localStorage.getItem('user');

    return (
        <>
            {saleRecord ? (
                <div className="bd-example-row">
                    <div className="col-auto p-0 m-0" style={{position:"fixed" ,fontSize:"13px", left:"3px",top:"3px", zIndex:22}}>
                        {branchDetail &&
                        branchDetail.gstIn ? (
                            `GSTIN : ${branchDetail.gstIn}`) : null
                        }                    </div>
                    <div className="col-auto p-0 align-content-start"   style={{position:"fixed" , right:"3px",top:"3px", zIndex:22}} >
                        <div className="row justify-content-end p-0">
                            <img
                                src={image2}
                                alt="logo"
                                style={{ width: 'auto', height: 55 }}
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3" style={{position:"fixed",width:'80%', top:"15px", left:"50%", transform:"translateX(-50%)" }}>

                        <div className="col-sm-10 align-items-end">
                            <div className="row p-0 justify-content-center">
                                <h4 className="col-10 mb-0"  style={{wordWrap: "break-word" ,   textAlign: "center" }}>
                                    {branchDetail.companyName ? branchDetail.companyName.toUpperCase()  : branchDetail?.name?.toUpperCase() || '_'}
                                </h4>
                            </div>
                            <div className="row mt-0 p-0 justify-content-center">
                                <p className="col-auto" style={{wordWrap: "break-word" , fontSize:"16px",  textAlign: "center" }}>
                                    {branchDetail&&getAddress()}
                                </p>
                            </div>
                        </div>


                    </div>
                    <hr
                        style={{
                            height: '2px',
                            backgroundColor: 'black',
                            position: 'fixed',
                            top:"100px",
                            width: '100vw',
                            left: 0,
                        }}
                    />

                    <div className="row " style={{ minHeight: 800, position:"relative ",zIndex:"23", top:"140px" ,paddingBottom:"50px", marginBottom:"200px"  }}>
                        <div className="col-12" style={{paddingLeft:'5px', paddingRight:'5px'}}>
                            <div className="row patient-details">
                                <div className="col-sm-4 mt-1 p-0">
                                    <div className="row sizerow">
                                        <div className="col-sm-5 p-0">
                                            <p className="size"  style={{display:'flex' , justifyContent:'space-between'}}>
                                                <b>Name</b>
                                                <span>:</span>
                                            </p>
                                        </div>
                                        <div className="col-sm-7 size">
                                            <p className="size">{saleRecord.customerId?.name}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row sizerow">
                                        <div className="col-sm-5 p-0">
                                            <p className="size" style={{display:'flex' , justifyContent:'space-between'}}>
                                                <b>Mobile</b>
                                                <span>:</span>
                                            </p>
                                        </div>
                                        <div className="col-sm-7">
                                            <p className="size">{saleRecord.customerId?.mobile}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row sizerow">
                                        <div className="col-sm-5 p-0">
                                            <p className="size" style={{display:'flex' , justifyContent:'space-between'}}>
                                                <b>GstIn</b>
                                                <span>:</span>
                                            </p>
                                        </div>
                                        <div className="col-sm-7">
                                            {" "}
                                            {saleRecord.customerId?.gstIn && (
                                                <p className="size">{saleRecord.customerId.gstIn}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8 mt-1">
                                    <div className="row sizerow">
                                        <div className="col-sm-4">
                                            <p className="size" style={{display:'flex' , justifyContent:'space-between'}}>
                                                <b>Invoice</b>
                                                <span>:</span>
                                            </p>

                                        </div>
                                        <div className="col-sm-8">
                                            <p className="size">
                                                {saleRecord.invoiceNumber}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row sizerow">
                                        <div className="col-sm-4">
                                            <p className="size" style={{display:'flex' , justifyContent:'space-between'}}>
                                                <b>Invoice Date</b>
                                                <span>:</span>
                                            </p>

                                        </div>
                                        <div className="col-sm-8">
                                            <p className="size">
                                                {moment(saleRecord.creditNoteDate).format("DD/MM/YYYY")}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row sizerow">
                                        <div className="col-sm-4">
                                            <p className="size" style={{display:'flex' , justifyContent:'space-between'}}>
                                                <b>Place of supply</b>
                                                <span>:</span>
                                            </p>
                                        </div>
                                        <div className="col-sm-8">
                                            <p className="size"> {getStateNameFromCode(saleRecord.stateOfSupply)}

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row sizerow" style={{paddingLeft:'0px' , fontSize:"x-small" ,marginTop:0}}>
                                    <div className="col-sm-2 p-0" style={{width:'101.7px'}}>
                                        <p className="size" style={{display:'flex' , justifyContent:'space-between' , width:'101.7px'}}>
                                            <b>Address</b>
                                            <span>:</span>
                                        </p>
                                    </div>
                                    <div className="col-sm-9 p-0" style={{marginLeft:'3px' ,marginTop:'-1px'}}>
                                        <p className="size" style={{paddingLeft:'17px'}}>
                                            {saleRecord.customerId?.address ?? ""}
                                            {saleRecord.customerId?.state &&
                                            saleRecord.customerId?.address
                                                ? ", "
                                                : ""}
                                            &nbsp;{saleRecord.customerId?.state ?? ""}
                                            {saleRecord.customerId?.city &&
                                            (saleRecord.customerId?.state ||
                                                saleRecord.customerId?.address)
                                                ? ", "
                                                : ""}
                                            &nbsp;{saleRecord.customerId?.city ?? ""}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <table  ref={tableRef} id="tableS" className="table table-bordered mt-3 " style={{ marginBottom:"150px", width:"100%", border:"0", cellspacing:"0" ,cellpadding:"0", borderLeft:"#ebedf2 !important" , borderTop:"#ebedf2 !important"}}>
                                        <tbody>
                                        <tr style={{fontSize: "10px"}}>
                                            <th  style={{fontSize: "11px", width:"24px" ,textAlign:'center' }} className={'leftStyle'}>
                                                <b>Sr</b>
                                            </th>
                                            <th className="table-heading leftStyle" style={{fontSize: "10px", width:"270px",marginRight:0 ,textAlign:'start' }}>
                                                <b>Product</b>
                                            </th>
                                            <th className="table-heading leftStyle" style={{fontSize: "10px",width:"52px" ,textAlign:'end' }}>
                                                <b>HSN</b>
                                            </th>
                                            <th className="table-heading leftStyle" style={{fontSize: "10px" ,textAlign:'end' , width:'43px' }}>
                                                <b>Exp.</b>
                                            </th>
                                            <th className="table-heading leftStyle" style={{fontSize: "10px", width:"65px" ,textAlign:'end' }}>
                                                <b>Batch</b>
                                            </th>
                                            <th className="table-heading leftStyle" style={{fontSize: "10px",width:"30px" ,textAlign:'end' }}>
                                                <b>Qty</b>
                                            </th>
                                            <th className="table-heading leftStyle" style={{fontSize: "10px",width:"40px",whiteSpace:"break-spaces" ,textAlign:'end' }}>
                                                <b>pkg</b>
                                            </th>

                                            <th className="table-heading leftStyle" style={{fontSize: "10px" ,textAlign:'end' , width:'63px' }}>
                                                <b>MRP ₹ </b>
                                            </th>
                                            <th className="table-heading leftStyle" style={{fontSize: "10px", width:"35px" ,textAlign:'end' }}>
                                                <b>Dis %</b>
                                            </th>
                                            <th className="table-heading leftStyle" style={{fontSize: "10px",whiteSpace:"break-spaces" ,textAlign:'end' , width:'63px'}}>
                                                <b>Tax Amt ₹ </b>
                                            </th>

                                            {saleRecord.cgstAmount !== 0 && saleRecord.sgstAmount !== 0 ? (
                                                <>
                                                    <th className="table-heading leftStyle" style={{fontSize: "10px" ,textAlign:'end' , width:'63px'}}>
                                                        <b> CGST ₹ </b>
                                                    </th>
                                                    <th className="table-heading leftStyle" style={{fontSize: "10px" ,textAlign:'end', width:'63px' }}>
                                                        <b>SGST ₹ </b>
                                                    </th>
                                                </>
                                            ) : saleRecord.cgstAmount === 0 ||
                                            saleRecord.sgstAmount === 0 ? (
                                                <th className="table-heading leftStyle" colSpan={'2'} style={{fontSize: "10px" ,textAlign:'end' , width:'63px' }}>
                                                    <b>IGST</b>
                                                </th>
                                            ) : null}

                                            <th
                                                className="table-heading leftStyle"
                                                style={{fontSize: "10px", width: "63px", whiteSpace:"break-spaces",textAlign:'end' }}
                                            >
                                                <b>Net Amt ₹ </b>
                                            </th>

                                        </tr>



                                        {allDetail.map((i, index) => (
                                            <tr key={index} >
                                                <td style={{fontSize: "10px", width:"30px" , textAlign:"center" }} className="leftStyle">{index + 1}</td>
                                                <td style={{fontSize: "10px", minWidth:"70px",maxWidth:"70px", whiteSpace:"break-spaces" , wordBreak:"break-all",padding:"0 !important"}} className="leftStyle">{i.productName}</td>
                                                <td style={{fontSize: "10px", minWidth:"50px",maxWidth:"50px", whiteSpace:"break-spaces"  , wordBreak:"break-all",padding:"0 !important" ,textAlign:'end'}} className="leftStyle">{i.productId?.hsnCode}</td>
                                                <td style={{fontSize: "10px", whiteSpace:"break-spaces" , wordBreak:"break-all",padding:"0 !important" ,textAlign:'end'}} className="leftStyle">{i.expiryDate.split('/')[0] + '/' + i.expiryDate.split('/')[1]?.slice(-2)}</td>
                                                <td style={{fontSize: "10px",  minWidth:"50px",maxWidth:"50px", whiteSpace:"break-spaces" , wordBreak:"break-all",padding:"0 !important" ,textAlign:'end'}} className="leftStyle">{i.batchNo}</td>
                                                <td style={{fontSize: "10px", whiteSpace:"break-spaces" , wordBreak:"break-all",padding:"0 !important" ,textAlign:'end'}} className="leftStyle">{i.quantity}</td>
                                                <td style={{fontSize: "10px",padding:"0 !important" ,textAlign:'end'}} className="leftStyle">{i.productId?.unitCode}</td>
                                                <td style={{fontSize: "10px", whiteSpace:"break-spaces" , wordBreak:"break-all",padding:"0 !important",textAlign:'end'}} className="leftStyle">{ parseFloat(i.price).toFixed(2)}</td>
                                                <td style={{fontSize: "10px", whiteSpace:"break-spaces" , wordBreak:"break-all",padding:"0 !important",textAlign:'end'}} className="leftStyle">{i.discount}</td>
                                                <td style={{fontSize: "10px", whiteSpace:"break-spaces" , wordBreak:"break-all",padding:"0 !important" ,textAlign:'end'}} className="leftStyle"> { parseFloat(i.taxableAmount).toFixed(2)}</td>
                                                {i.igstAmount !== 0 ? (<>{" "}<td style={{fontSize: "10px" ,textAlign:'end'}} colSpan={'2'}  className="leftStyle">{ parseFloat(i.igstAmount).toFixed(2)}</td></>
                                                ) : i.sgstAmount !== 0 && i.cgstAmount !== 0 ? (<>
                                                        <td style={{fontSize: "10px", whiteSpace:"break-spaces" , wordBreak:"break-all",padding:"0 !important" ,textAlign:'end'}} className="leftStyle">{parseFloat(i.cgstAmount).toFixed(2)}</td>
                                                        <td style={{fontSize: "10px", whiteSpace:"break-spaces" , wordBreak:"break-all",padding:"0 !important" ,textAlign:'end'}} className="leftStyle">{ parseFloat(i.sgstAmount).toFixed(2)}</td>
                                                    </>
                                                ) : (
                                                    <td style={{fontSize: "10px" ,textAlign:'end'}} colSpan={'2'}  className="leftStyle">_</td>
                                                )}
                                                <td style={{fontSize: "10px", whiteSpace:"break-spaces" , wordBreak:"break-all",padding:"0 !important" ,textAlign:'end'}} className="leftStyle"> { parseFloat(i.netAmount).toFixed(2)}</td>
                                            </tr>
                                        ))}

                                        <tr colSpan={"13"} style={{height:"20px", border:"none"}}>{null}</tr>
                                        <tr colSpan={"13"} style={{height:"20px", border:"none"}}></tr>
                                        <tr colSpan={"13"} style={{height:"20px", border:"none"}}></tr>

                                        <tr className="customrow" style={{borderStyle:"hidden", width:"100%"}} >
                                            <td colSpan="13"  className={' leftStyle'} id={"forHideBorder"} style={{borderStyle:"hidden !important" , paddingLeft:0 , paddingRight:0}}>
                                                <div className="row customrow" style={{ display: "flex", height: "100%", width:"100%", marginLeft:0 }}>
                                                    <div className="col-6 pt-2 pb-1 pl-0 pr-0" style={{ flex: 1, borderStyle: "hidden", textAlign: "left" ,paddingLeft:0 ,paddingRight:0 , marginRight:'5px', paddingBottom:'4px' }}>
                                                        <div className="row customrow" style={{ borderStyle: "hidden", width: "100%" , marginLeft:'0px' ,borderLeft:"1px solid" ,borderTop:"1px solid" ,borderRight:"1px solid #D3D3D3" ,borderColor: '#D3D3D3' }}>

                                                            <ProductTable data ={allDetail} saleRecord ={saleRecord}/>
                                                        </div>
                                                        <div className="row customrow" style={{ borderStyle: "hidden" ,borderLeft:"1px solid" ,borderBottom:"1px solid", borderRight:"1px solid",
                                                            borderColor: '#D3D3D3' , width: "100%" , marginLeft:'0px'  }}>
                                                            <div className="col-12 " style={{ whiteSpace: "break-spaces"  , fontSize:'11px' , paddingLeft:'5px', paddingTop:'3px' ,paddingBottom:'3px'}}>
                                                                <b>Amount In Words :</b> Rupees {convertNumberToWords(saleRecord.netAmount)} only
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ height: "20px", borderStyle: "hidden" , marginLeft:0 }}></div>{/* Empty row to create space */}

                                                        {/*<div style={{ borderStyle: "hidden", textAlign: "left" }}>*/}
                                                        {/*    <h6 style={{ fontSize: "13px" }}>Payment Details:</h6>*/}
                                                        {/*</div>*/}
                                                        <div className="row customrow" style={{ borderStyle: "hidden", width: "100%" , marginLeft:0  ,borderLeft:"1px solid" ,borderTop:"1px solid"  ,borderBottom:"1px solid  #D3D3D3",borderRight:"1px solid #D3D3D3" ,borderColor: '#D3D3D3'  }}>
                                                            <div className="col-4 pt-2 pb-1 pl-0 pr-0" style={{ fontSize: "11px", borderStyle: "hidden", textAlign: "left" , paddingLeft:6 }}><b>Payment Details:</b></div>
                                                            <div className="col-4 pt-2 pb-1 pl-0 pr-0" style={{ fontSize: "11px", borderStyle: "hidden", textAlign: "left" }}>PAYMODE</div>
                                                            <div className="col-4 pt-2 pb-1 pl-0 pr-0" style={{ fontSize: "11px", borderStyle: "hidden", textAlign: "left" }}>AMOUNT</div>
                                                        </div>
                                                        {saleRecord?.paidToUser?.map((val, innerIndex) => (
                                                            <div key={innerIndex} className="row customrow" style={{ borderStyle: "hidden", width: "100%" , marginLeft:0  ,borderLeft:"1px solid"  ,borderRight:"1px solid #D3D3D3" ,borderColor: '#D3D3D3' }}>
                                                                <div className="col-4 pt-2 pb-1 pl-0 pr-0" style={{ fontSize: "11px", borderStyle: "hidden", textAlign: "left"  }}>{innerIndex+1}</div>
                                                                <div className="col-4 pt-2 pb-1 pl-0 pr-0" style={{ fontSize: "11px", borderStyle: "hidden", textAlign: "left" }}>{val.paymentType}</div>
                                                                <div className="col-4 pt-2 pb-1 pl-0 pr-0" style={{ fontSize: "11px", borderStyle: "hidden", textAlign: "left" }}>₹ {val.amount}</div>
                                                            </div>
                                                        ))}
                                                        <div className="row" style={{ height: "20px", borderStyle: "hidden",borderTop:"1px solid",borderColor: '#D3D3D3' , width:'100%', marginLeft:0  }}></div>{/* Empty row to create space */}


                                                    </div>
                                                    <div className="col-6 pt-2 pb-1 pl-0 pr-0" style={{ flex: 1,  paddingLeft: 0 ,paddingRight:0 , fontSize:'12px' }}>
                                                        <div className="row customrow" style={{ borderStyle: "hidden", width: "100%", borderTop: "1px solid black", borderLeft: "1px solid black", borderRight: "1px solid black" , flexWrap:"nowrap" , marginLeft:'0px',marginRight:'0px' }}>
                                                            <div className="col-5 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left", paddingLeft:"15px " , paddingTop:"15px" , paddingBottom:"0 " , paddingRight:"0 " }}>
                                                                <b>Taxable Amt</b>
                                                            </div>
                                                            <div className="col-1 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left" }}>
                                                                :
                                                            </div>
                                                            <div className="col-6  pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left", paddingLeft:"15px " , paddingTop:"8px" , paddingBottom:"0 " , paddingRight:"0 " }}>
                                                                ₹ {parseFloat(saleRecord.taxableAmount).toFixed(2)}
                                                            </div>
                                                        </div>
                                                        {saleRecord.cgstAmount !== 0 && saleRecord.sgstAmount !== 0 ? (
                                                            <>
                                                                <div className="row customrow" style={{ borderStyle: "hidden", width: "100%", borderLeft: "1px solid black", borderRight: "1px solid black", flexWrap:"nowrap" , marginLeft:'0px',marginRight:'0px'  }}>
                                                                    <div className="col-5 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left" , paddingLeft:"15px " , paddingTop:"15px" , paddingBottom:"0 " , paddingRight:"0 "}}>
                                                                        <b>CGST Total</b>
                                                                    </div>
                                                                    <div className="col-1 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left" }}>
                                                                        :
                                                                    </div>
                                                                    <div className="col-6 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left", paddingLeft:"15px " , paddingTop:"15px" , paddingBottom:"0 " , paddingRight:"0 " }}>
                                                                        ₹ {parseFloat(saleRecord.cgstAmount).toFixed(2)}
                                                                    </div>
                                                                </div>
                                                                <div className="row customrow" style={{ borderStyle: "hidden", width: "100%", borderLeft: "1px solid black", borderRight: "1px solid black", flexWrap:"nowrap" , marginLeft:'0px',marginRight:'0px' }}>
                                                                    <div className="col-5 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left" , paddingLeft:"15px " , paddingTop:"15px" , paddingBottom:"0 " , paddingRight:"0 "}}>
                                                                        <b>SGST Total</b>
                                                                    </div>
                                                                    <div className="col-1 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left" }}>
                                                                        :
                                                                    </div>
                                                                    <div className="col-6 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left", paddingLeft:"15px " , paddingTop:"15px" , paddingBottom:"0 " , paddingRight:"0 " }}>
                                                                        ₹ {parseFloat(saleRecord.sgstAmount).toFixed(2)}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className="row customrow" style={{ borderStyle: "hidden", width: "100%", borderLeft: "1px solid black", borderRight: "1px solid black", flexWrap:"nowrap" , marginLeft:'0px',marginRight:'0px' }}>
                                                                <div className="col-5 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left" , paddingLeft:"15px " , paddingTop:"15px" , paddingBottom:"0 " , paddingRight:"0 "}}>
                                                                    <b>IGST Total</b>
                                                                </div>
                                                                <div className="col-1 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left" }}>
                                                                    :
                                                                </div>
                                                                <div className="col-6 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left", paddingLeft:"15px " , paddingTop:"15px" , paddingBottom:"0 " , paddingRight:"0 " }}>
                                                                    ₹ {parseFloat(saleRecord.igstAmount).toFixed(2)}
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="row customrow" style={{ borderStyle: "hidden", width: "100%", borderLeft: "1px solid black", borderRight: "1px solid black", flexWrap:"nowrap" , marginLeft:'0px',marginRight:'0px' }}>
                                                            <div className="col-5 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left", paddingLeft:"15px " , paddingTop:"15px" , paddingBottom:"0 " , paddingRight:"0 " }}>
                                                                <b> Grand Total</b>
                                                            </div>
                                                            <div className="col-1 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left" }}>
                                                                :
                                                            </div>
                                                            <div className="col-6 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left", paddingLeft:"15px " , paddingTop:"15px" , paddingBottom:"0 " , paddingRight:"0 " }}>
                                                                ₹ {parseFloat(saleRecord.netAmount).toFixed(2)}
                                                            </div>
                                                        </div>
                                                        <div className="row customrow" style={{ borderStyle: "hidden", width: "100%", borderLeft: "1px solid black", borderRight: "1px solid black", flexWrap:"nowrap" , marginLeft:'0px',marginRight:'0px'}}>
                                                            <div className="col-5 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left", paddingLeft:"15px " , paddingTop:"15px" , paddingBottom:"0 " , paddingRight:"0 " }}>
                                                                <b>Paid Amt</b>
                                                            </div>
                                                            <div className="col-1 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left" }}>
                                                                :
                                                            </div>
                                                            <div className="col-6 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left", paddingLeft:"15px " , paddingTop:"15px" , paddingBottom:"0 " , paddingRight:"0 " }}>
                                                                ₹ {saleRecord.paidAmount}
                                                            </div>
                                                        </div>
                                                        <div className="row customrow" style={{ borderStyle: "hidden", width: "100%", borderLeft: "1px solid black", borderRight: "1px solid black", borderBottom: "1px solid black" , flexWrap:"nowrap" , marginLeft:'0px',marginRight:'0px'}}>
                                                            <div className="col-5 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left", paddingLeft:"15px " , paddingTop:"15px" , paddingBottom:"0 " , paddingRight:"0 " }}>
                                                                <b>Balance Amt</b>
                                                            </div>
                                                            <div className="col-1 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left" }}>
                                                                :
                                                            </div>
                                                            <div className="col-6 pt-2 pb-1 pl-0 pr-0" style={{ textAlign: "left", paddingLeft:"15px " , paddingTop:"15px" , paddingBottom:"0 " , paddingRight:"0 " }}>
                                                                ₹ {saleRecord.balance}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="customrow" style={{borderStyle:"hidden", width:"100%"}} >
                                            <td colSpan="13"  className={' leftStyle'} id={"forHideBorder"} style={{borderStyle:"hidden !important" , paddingLeft:0 , paddingRight:0}}>
                                                <div className="row customrow" style={{ display: "flex", height: "100%", width:"100%", marginLeft:0 }}>
                                                    <div className="col-6 pt-2 pb-1 pl-3 pr-0 leftStyle" style={{ flex: 1,   paddingLeft: '8 !important' ,paddingRight:0 , fontSize:'16px',borderLeft:"1px solid" ,borderBottom:"1px solid", borderRight:"1px solid",borderTop:"1px solid",
                                                        borderColor: '#D3D3D3' ,paddingTop:5 }}><div ><i style={{borderBottom:'1px solid '}}>Terms & Conditions</i></div><div style={{fontSize:'10px',marginTop : 5}}>Goods once sold will not be taken back</div><div style={{fontSize:'10px',marginTop : 3}}>Bills not paid due date will attract 24% interest</div> </div>
                                                    <div className="col-2 pt-2 pb-1 pl-0 pr-0 leftStyle" style={{ flex: 1,  paddingLeft: 0 ,paddingTop:5 ,paddingRight:0 , fontSize:'12px', textAlign:'center',borderTop:"1px solid" ,borderBottom:"1px solid", borderRight:"1px solid", borderColor: '#D3D3D3' , }}> Recieve </div>
                                                    <div className="col-4 pt-2 pb-1 pl-0 pr-0 leftStyle" style={{ flex: 1,  paddingLeft: 0 ,paddingTop:5 ,paddingRight:0 , fontSize:'12px', textAlign:'center',borderTop:"1px solid" ,borderBottom:"1px solid", borderRight:"1px solid", borderColor: '#D3D3D3' , }}>For VHCA HERBALS PVT LTD</div>
                                                </div>
                                            </td>
                                        </tr>
                                        {/*<tr className="customrow" style={{borderStyle:"hidden", width:"100%"}} >
                                          <td colSpan="13"  className={' leftStyle'} id={"forHideBorder"} style={{borderStyle:"hidden !important" , paddingLeft:0 , paddingRight:0}}>
                                              <div className="row" style={{ height: "20px", borderStyle: "hidden" ,borderColor: '#D3D3D3' , width:'100%', marginLeft:0 ,paddingTop:43 }}>
                                                  <div className="col-2 pt-2 pb-1 pl-0 pr-0" style={{ borderStyle: "hidden" ,borderTop:'1px solid black',borderColor: '#D3D3D3', marginLeft:0 , paddingLeft:'3px', paddingTop:'5px' }}>
                                                       Automerized Signature
                                                  </div>
                                              </div>
                                          </td>
                                      </tr>*/}

                                        {/*<tr className="customrow" style={{borderStyle:"hidden", width:"100%"}} >*/}
                                        {/*    <td colSpan="13" id={"forHideBorder"} style={{borderStyle:"hidden !important" }}>*/}
                                        {/*        <div className="row customrow" style={{ display: "flex", height: "100%", width:"100%" }}>*/}

                                        {/*            <div className="col-4 pt-2 pb-1 pl-0 pr-0" style={{ flex: 1,  paddingLeft: 3 , textAlign:'center' }}>*/}
                                        {/*          Terms & Conditions*/}
                                        {/*            </div>*/}
                                        {/*            <div className="col-4 pt-2 pb-1 pl-0 pr-0" style={{ flex: 1,  paddingLeft: 3 , textAlign:'center'}}>*/}
                                        {/*                Reciver*/}
                                        {/*            </div>*/}
                                        {/*            <div className="col-4 pt-2 pb-1 pl-0 pr-0" style={{ flex: 1,  paddingLeft: 3 , textAlign:'center'}}>*/}
                                        {/*             for VHCA*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}
                                        </tbody>
                                    </table>
                                </div>
                            </div>




                        </div>
                    </div>

                    <div style={{ width: '100%', position: 'fixed', bottom: "2px", left: 0 }}>
                        <hr />
                        <div className="row pb-2 justify-content-center  pt-1">
                            <div className="col-auto justify-content-start align-items-start p-2">
                                <i className="mdi mdi-cellphone" /> +91 {branchDetail?.mobile}
                            </div>
                            <div className="col-auto align-items-end p-2">
                                <i className="mdi mdi-email-outline" /> {branchDetail?.email}
                            </div>
                            <div className="col-auto p-2">
                                <i className="mdi mdi-link-box-variant" /> vhcahairclinic.com
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                'loading'
            )}
        </>
    );
};

export default Check;

