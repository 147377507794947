import React, { useEffect, useState } from "react";
import {HyperLink} from "../../components/Elements/appUtils";
import { Form } from "../../components/Elements/appUtils";
import notification from "../../components/Elements/Notification";
import { useDispatch } from "react-redux";

import {GetEachFormFields} from "../../components/_utils/formUtils"
import PageHeader from "../../components/Elements/pageHeader";

import { addEmployeeFxn } from "./action";
import _ from "lodash";

import {useNavigate} from "react-router-dom";



const AddEmployee = (props) => {
  let {onClose=null, groupType}= props;

  
  console.log(groupType, "sssssssss")
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [shippingStateList, setShippingStateList] = useState([]);
  const [copyBillingAddress, setCopyBillingAddress] = useState(false);
  const { getFieldValue } = props.form;
  let dispatch = useDispatch();
  console.log(onClose, "onCLose")

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
       
        valData.type = "employee";

        if (!valData.name) {
          notification.warning({ message: "Please Enter Name" });
          return;
        }

        if (!valData.mobile) {
          notification.warning({ message: "Please Enter Number" });
          return;
        }
        if (!valData.designation) {
          notification.warning({ message: "Please Enter Designation" });
          return;
        }     

        let x = await dispatch(addEmployeeFxn(valData));
        if (x && x.success) {
          navigate('/employee')
        //   setTimeout(() => {
        //     navigate("/customers");
        // }, 1000);
          props.form.setFieldsValue({
            name: "",
            email: "",
            mobile: "",
            address: "",
            joiningDate: "",
            salary: ""
          
         
          });
   
        }
      }
    });
  };

  



  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name *",
        span: "col-md-6",
        placeholder: "Name",
      },
      {
        key: "mobile",
        label: "Mobile No *",
        span: "col-md-6",
        placeholder: "Mobile No",
      },
      {
        key: "email",
        label: "Email",
        type: "email",
        span: "col-md-6",
        placeholder: "Email",
      },
      {
        key: "dateOfBirth",
        label: "Date Of Birth",
        showStar: true,
        placeholder: "Date",
        type: "date",
        span: "col-md-6",
        onChange: (x) => {
          props.form.setFieldsValue({
            dateOfBirth: x,
          });
        },
      },

      {
        key: "address",
        label: "Address",
        type: "text",
        span: "col-md-6",
        placeholder: "Address",
      },
      {
        key: "joiningDate",
        label: "Joining Date",
        showStar: true,
        placeholder: "Date",
        type: "date",
        span: "col-md-6",
        onChange: (x) => {
          props.form.setFieldsValue({
            joiningDate: x,
          });
        },
      },
      {
        key: "designation",
        label: "Designation *",
        showStar: true,
        type: "text",
        span: "col-md-6",
      
        placeholder: "Designation",
      },
      {
        key: "salary",
        label: "Salary",
        type: "Number",
        span: "col-md-6",
        placeholder: "Salary",
      },
      {
        key: "emergencyNumber",
        label: "Emergency Contact Number",
        type: "Number",
        span: "col-md-6",
        placeholder: "Emergency Contact Number",
      },
     
    ],
}

 

  return (
    <PageHeader title={'Add Employee'}>
    <Form onSubmit={handleSubmit}>
          <div className="card">
                <div className="card-body">
      <div className="form-elements-wrapper">
        <div className={"row"}>
          {inputTypes.fields.map((item, key) => {
            return !item.hidden ? (
              <div
                className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                key={key}
              >
                <GetEachFormFields {...props.form} item={item} />
              </div>
            ) : null;
          })}
        </div>
   
      </div>

      <button type="submit" className="btn btn-gradient-info me-2">
        Submit
      </button>
      <HyperLink className=" ms-4 btn btn-danger" link={"/customers"}>
        Cancel
      </HyperLink>
      </div>
      </div>
    </Form>
    </PageHeader>
  );
};

export default Form.create()(AddEmployee);
