import React, {useEffect, useState} from "react";
import {
    InputBox,
} from "../../../components/Elements/appUtils";
import _ from "lodash";
import Select from "rc-select";
import {fetchCustomer} from "../actions";
import "../../../components/Elements/Select/index.css";
import "rc-select/assets/index.less";

const {Option} = Select;

const ChoosePatient = (props) => {
    let {
        callback,
        customerId,
        gstType,
        type,
        groupType,
    } = props;

    let [clientId, setClientId] = useState(undefined);
    let [arrData, setArrData] = useState([]);
    useEffect(() => {
        if (customerId) {
            setClientId(customerId)
        } else {
            setClientId(undefined)
        }
    }, [customerId])
    const events = {
        loadDefaultClient: async () => {
            if (customerId) {
                let params = {
                    results: 20,
                    count: 20,
                    _id: customerId,
                    regExFilters: ["name", "companyName", "mobile", "email", "uhid"],
                    select: ["name", "mobile", "uhid", 'balance', 'state'],
                };
                let {data} = await fetchCustomer(params);
                setArrData(data);
            } else {
                setArrData([]);
            }
        },

        fetchClientByNameOrMobile: async (value) => {
            const uhidMatch = value.match(/^(\d{4})$/);
            const isNumeric = /^\d+$/.test(value);
            if (uhidMatch && isNumeric) {
                const uhidValue = uhidMatch[1];
                events.fetchClient(undefined, undefined, uhidValue);
            } else if (isNumeric) {
                events.fetchClient(undefined, value);
            } else {
                events.fetchClient(value);
            }
        },
        fetchClient: async (name, mobile, uhid) => {
            try {
                let params = {
                    results: 20,
                    count: 20,
                    type: type,
                    gstType: gstType,
                    groupType: groupType,
                    regExFilters: ["name", "mobile", "email", "uhid"],
                    select: ["name", "mobile", "uhid", 'balance', 'state'],
                };

                if (name) {

                    params.name = name;
                }

                if (mobile) {

                    params.mobile = mobile;
                }

                if (uhid) {

                    params.uhid = uhid;
                }

                if (name || mobile || uhid) {
                    const {data} = await fetchCustomer(params);
                    setArrData(data);
                } else {
                    setArrData([]);
                }
            } catch (error) {
                console.error("Error fetching client data:", error);
            }
        },


        handleChange: (clientId) => {
            let item = _.find(arrData, (value) => {
                return value._id == clientId;
            });
            setClientId(clientId);
            callback(item);
        },
    };
    useEffect(() => {
        events.loadDefaultClient();
    }, [customerId]);

    return (
        <>
            <InputBox title={"Choose Patient"}>
                <div className={"rowFlex"}>
                    <Select
                        showSearch={true}
                        // onSearch={events.fetchClient}
                        onSearch={(value) => events.fetchClientByNameOrMobile(value)}
                        filterOption={false}
                        autoClearSearchValue={true}
                        allowClear={true}
                        value={clientId}
                        showArrow={true}
                        onChange={events.handleChange}
                        placeholder="Choose Patient"
                        className={`rc-select rc-select1`}
                        choiceTransitionName="rc-select-selection__choice-zoom"
                        style={{width: "100%"}}
                        dropdownRender={(menu) => {
                            return <div key={arrData}>{menu}</div>;
                        }}
                    >
                        {arrData && arrData?.length
                            ? arrData.map((item, index) => {
                                return (
                                    <Option key={item._id} value={item._id}>

                                        {`${item.name} (${item.uhid ? `ID-${item.uhid}, ` : ''}${item.mobile ? `${item.mobile}` : ''})`}
                                    </Option>
                                );
                            })
                            : null}
                    </Select>
                </div>
            </InputBox>
        </>
    );
};
export default ChoosePatient;
