import { addPurchaseUrl, getCustomerDataUrl, getPurchaseUrl,deletePurchaseReport, findRecentProductReportUrl } from "../api";
import {customAxios as axios, getToken} from "../../../request";
import {hideLoader, showLoader} from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";


export const fetchCustomer = async (valData) => {
    let config = {
        params: {...valData}
    }
    const {data} = await axios.get(getCustomerDataUrl(),getToken(),config);
    return data;
};

export const fetchPurchase = async (valData) => {
    let config = {
        params: { ...valData },
        ...(await getToken()),
      };
    const {data} = await axios.get(getPurchaseUrl(),config);
    return data;
};

export const addPurchaseFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addPurchaseUrl(), valData, getToken());
    dispatch(hideLoader())
    console.log(data,"this is add purchase data");
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};

export const findRecentProductReport = async (valData) => {
    let config = {
        params: { ...valData },
        ...(await getToken()),
      };
    const {data} = await axios.get(findRecentProductReportUrl(),config);
    return data;
};

