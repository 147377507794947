const initialState = {
    currentUser: {},
    isLoading: false,
    studentTestIds: [],
    activePackages: [],
    loadExamData: false
}

export default (state = initialState, action) => {
    switch (action && action.type) {
        case 'SET_CURRENT_USER':
            return {
                ...state,
                currentUser: action.user
            }
        case 'LOGOUT':
            return {
                ...state,
                currentUser: {},
                activePackages: []
            }
        case 'SHOW_LOADER': {
            return {
                ...state,
                isLoading: true
            }
        }
        case 'HIDE_LOADER': {
            return {
                ...state,
                isLoading: false
            }
        }
        default:
            return state
    }
}
