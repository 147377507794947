import React, {useEffect, useRef, useState} from "react";
import {Table, DefaultTablePagination} from "../../components/Elements/appUtils";
import {fetchDayBookFxn} from "./action"
import PageHeader from "../../components/Elements/pageHeader";
import moment from "moment";
import SearchCustomer from "../customer/chooseCustomer/searchCustomer";

// Name	Payment Type	Group Name	Gst	Type	Transaction Type	Quantity	Recieved Amount	Net Amount	Balance
function DayBook() {
    const [customerId, setCustomerId] = useState("")
    let [total, setTotal] = useState("")
    let [dates, setDates] = useState({
        fromDate: "",
        toDate: "",
    });

    const [isCurrentMonth, setIsCurrentMonth] = useState(false);

    let tableRef = useRef();

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                if (dates.fromDate && dates.toDate) {
                    const fromDateStartOfDay = new Date(dates.fromDate);
                    fromDateStartOfDay.setHours(0, 0, 0, 0);
                    const toDateEndOfDay = new Date(dates.toDate);
                    toDateEndOfDay.setHours(23, 59, 59, 999);
                    params.date = {
                        $gte: new Date(fromDateStartOfDay),
                        $lte: new Date(toDateEndOfDay),
                    };
                } else {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    const endOfDay = new Date();
                    endOfDay.setHours(23, 59, 59, 999);
                    params.date = {
                        $gte: today,
                        $lte: endOfDay,
                    };
                }

                if (customerId) {
                    params.customerId = customerId;
                }

                const data = await fetchDayBookFxn({...params});
                setTotal(data.total)
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };
    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text) => <span>{moment(text).format("DD-MMM-YY")}</span>,
        },
        {
            title: "Name",
            dataIndex: "customerId",
            key: "customerId",
            render: (text) => (
                <span>
                    {text?.name}
                    {text?.mobile && ` (${text.mobile})`}
                </span>
            )
        },
        // {
        //     title: "Number",
        //     dataIndex: "customerId",
        //     key: "customerId",
        //     width:'90px',
        //     render: (text) => <span>{text?.mobile}</span>,
        // },

        // {
        //     title: "Payment Type",
        //     dataIndex: "paymentType",
        //     key: "paymentType",
        //     render: (text, record, index) => {
        //
        //         if (record.salesId) {
        //             return (
        //                 <>
        //                     {record.salesId?.paidToUser?.map((item) => (
        //                         <div key={item._id}>
        //                             {console.log(item, "paymentTupe")}
        //                             {item.paymentType}
        //                         </div>
        //                     ))}
        //                 </>
        //             );
        //         } else if (record.purchaseId) {
        //
        //             return (
        //                 <div>
        //                     {record.purchaseId.paymentType}
        //                 </div>
        //             );
        //         } else {
        //             return null;
        //         }
        //     }
        //
        //
        // },
        // {
        //     title: "Group Name",
        //     dataIndex: "groupName",
        //     key: "groupName",
        //     render: (text, record, index) => {
        //
        //         if (
        //             record.customerId &&
        //             (record.customerId.customerGroupName === "BANK ACCOUNT" || record.customerId.customerGroupName === "CASH IN HAND")
        //         ) {
        //             return record.customerId.customerGroupName;
        //         } else {
        //             return null;
        //         }
        //     }
        // },
        // {
        //     title: "Gst",
        //     dataIndex: "gstAmount",
        //     key: "gstAmount",
        //     render: (text, record, index) => {
        //
        //         if (record.salesId) {
        //             return (
        //                 <>
        //                     {record.salesId.gstAmount}
        //                 </>
        //             );
        //         } else if (record.purchaseId) {
        //
        //             return (
        //                 <div>
        //                     {record.purchaseId.gstAmount}
        //                 </div>
        //             );
        //         } else {
        //             return null;
        //         }
        //     }
        // },

        {
            title: "Details",
            dataIndex: "type",
            key: "type",
            render: (text, record, index) => {
                return (
                    <>
                        <span>{record?.type}</span>
                        <span>{record && record.transferToId && ` ➡️ ${record.transferToId?.name}`}</span>

                    </>
                )
            }
        },
        // {
        //     title: "Bank Name",
        //     dataIndex: "bankId",
        //     key: "bankId",
        //     render: (transactionType, record) => {
        //         return    <div>
        //      {record.salesId.paidToUser.map((item) => {
        //             return(
        //                 <>
        //                <span>{getBankNameById(item._id, bankDatas)}</span>
        //                 </>
        //             )
        //         })
        //     }
        //        </div>;
        //     }
        // },

        {
            title: "Credit",
            dataIndex: "transactionType",
            key: "credit",
            render: (transactionType, record) => {
                if (transactionType === "Credit") {
                    return <div style={{color: '#03C03C', padding: '5px', fontWeight: 'bold'}}>{record.netAmount}</div>;
                }
                return null;
            },
        },
        {
            title: "Debit",
            dataIndex: "transactionType",
            key: "debit",
            render: (transactionType, record) => {
                if (transactionType === "Debit") {
                    return <div style={{color: 'red', padding: '5px', fontWeight: 'bold'}}>{record.netAmount}</div>;
                }
                return null;
            },
        },
        // {
        //     title: "Recieved Amount",
        //     dataIndex: "paidAmount",
        //     key: "gstAmount",
        //     render: (text, record, index) => {
        //
        //         if (record.salesId) {
        //             return (
        //                 <>
        //                     {record.salesId.paidAmount}
        //                 </>
        //             );
        //         } else if (record.purchaseId) {
        //
        //             return (
        //                 <div>
        //                     {record.purchaseId.paidAmount}
        //                 </div>
        //             );
        //         } else {
        //             return null;
        //         }
        //     }
        // },
        // {
        //     title: "Amount",
        //     dataIndex: "netAmount",
        //     key: "netAmount",
        //     render: (text) => <span>Rs. {text}</span>,
        //
        // },
    ];

    const clearDayBookFilters = () => {
        setIsCurrentMonth(false);
        setCustomerId('')
        setDates({
            fromDate: "",
            toDate: "",
        })
        tableRef.current.reload()
    }

    const Print = () => {
        let printContents = document.getElementById('printable').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload()
    }


    const handleFilter = async () => {
        if (tableRef.current) {
            tableRef.current.reload();
        }
    };

    const handleCheckboxChange = (e) => {
        setIsCurrentMonth(e.target.checked);
        if (e.target.checked) {
            const startOfMonth = new Date();
            startOfMonth.setDate(1);
            const endOfMonth = new Date();
            endOfMonth.setMonth(endOfMonth.getMonth() + 1);
            endOfMonth.setDate(0);

            setDates({
                fromDate: startOfMonth.toISOString().split('T')[0],
                toDate: endOfMonth.toISOString().split('T')[0]
            });
            setTimeout(() => {
                handleFilter()
            }, 300);
        } else {
            setDates({
                fromDate: '',
                toDate: ''
            });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            handleFilter()
        }, 300);

    }, [isCurrentMonth]);

    return (
        <PageHeader
            title={`Day book (total : ${total})`}
        >
            <div className="card">
                <div className="card-body">
                    <div className="mb-3 row">
                        <div className={"col-md-2"} style={{marginTop: "13px"}}>
                            <SearchCustomer
                                type={["customer", "patient"]}
                                customerId={customerId}
                                callback={(clientData) => {
                                    if (clientData && clientData._id) {
                                        setCustomerId(clientData._id);
                                    }
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="searchCategory" className="form-label"/>
                            <input
                                type="date"
                                className="form-control "
                                value={dates.fromDate}
                                onChange={(e) =>
                                    setDates({...dates, fromDate: e.target.value})
                                }
                            />
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="searchCategory" className="form-label"/>
                            <input
                                type="date"
                                className="form-control "
                                value={dates.toDate}
                                onChange={(e) => setDates({...dates, toDate: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="mb-3 search-buttons">
                        <div className="search-wrap pe-2">
                            <button className="btn btn-info" onClick={handleFilter}>
                                Search
                            </button>
                        </div>
                        <div className="search-wrap pe-2">
                            <button className="btn btn-secondary" onClick={() => {
                                clearDayBookFilters()
                            }}>
                                Clear
                            </button>
                        </div>
                        <div className="search-wrap">
                            <button className="btn btn-info" onClick={Print}>
                                Print
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 mt-10">
                        <label htmlFor="searchCategory" className="form-label"/>
                        <input
                            type="checkbox"
                            id="currentMonth"
                            checked={isCurrentMonth}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="currentMonth">Current Month Data</label>
                    </div>
                    <div id='printable'>
                        <Table
                            apiRequest={apiRequest}
                            columns={columns}
                            pagination={DefaultTablePagination({}, 500)}
                            ref={tableRef}
                            position={'top'}
                        />
                    </div>

                </div>
            </div>
        </PageHeader>
    );
}

export default DayBook;
