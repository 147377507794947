    import {getDayBookUrl} from "../api"
  import { customAxios as axios, getToken } from "../../../request";
  import { hideLoader, showLoader } from "../../../actions/loader";
  import notification from "../../../components/Elements/Notification";

  
  export const fetchDayBookFxn = async (valData) => {
    let config = {
      params: { ...valData },
      ...(await getToken()),
    };
    const { data } = await axios.get(getDayBookUrl(), config);
    return data;
  };
  

  