import { addExpenseUrl,getExpenseCategoryDataUrl,getExpenseAllDataUrl,singleExpenseCategoryUrl, updateExpenseCategoryUrl,singleExpenseUrl,updateExpenseUrl } from "../api";

import {customAxios as axios, getToken} from "../../../request";
import { showLoader, hideLoader } from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";

export const fetchExpenseCategory = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getExpenseCategoryDataUrl(), config);
    return data;
};

export const fetchAllExpense = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getExpenseAllDataUrl(), config);
    return data;
};
export const addExpenseFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addExpenseUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};

export const singleExpenseFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    
    let { data } = await axios.get(singleExpenseUrl(id));
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    }
  
    return data;
  };

  export const updateExpenseFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateExpenseUrl(), valData ,getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };
  export const singleExpenseCategoryFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    
    let { data } = await axios.get(singleExpenseCategoryUrl(id));
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    }
  
    return data;
  };
  export const updateExpenseCategoryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateExpenseCategoryUrl(), valData ,getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };