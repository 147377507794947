// import S from "string";

export const RoundOf = (value) => {
  return parseFloat(value.toFixed(2));
};
export const getAvatar = (name) => {
  return name ? name.charAt(0) : null;
};
export const getItemLocalstorage = (key) => {
  const user = localStorage.getItem(key);
  const companydata = JSON.parse(user);
  return companydata;
};

export const fixed2Digit = (value) => {
  // console.log("fixed2Digit");
  if (value) {
    return parseFloat(parseFloat(value).toFixed(2));
  } else {
    return 0;
  }
};

export const leadSourceOption = [
  { option: "Newspaper" },
  { option: "Facebook" },
  { option: "Instagram" },
  { option: "YouTube" },
  { option: "Friends/Relative Reference" },
  { option: "Google" },
  { option: "Other" },
];
export const months = {
  list: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  jsonList: {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  },
};

export const dateFormats = [
  "DD/MM/YYYY",
  "MM/DD/YYYY",
  "DD.MM.YYYY",
  "DD-MM-YYYY",
  "MM-DD-YYYY",
  "YYYY-MM-DD",
  "DD-MMM-YYYY",
];
export const groupNameObj = {
  sundryCreditors: "SUNDRY CREDITORS",
  sundryDebtors: "SUNDRY DEBTORS",
  cashInHand: "CASH IN HAND",
  bankAccount: "BANK ACCOUNT",
};

export const dosage = [
  { option: "10 Spray" },
  { option: "1 Tab/Cap" },
  { option: "2 Tab/Cap" },
  { option: "3 Tab/Cap" },
  { option: "4 Tab/Cap" },
  { option: "1 Drop" },
  { option: "2 Drops" },
  { option: "1 Tea Spoon Full" },
  { option: "2 Tea Spoons Full" },
  { option: "As Per Requirement" },
];
