import React, {useState} from "react";
import {useDispatch} from "react-redux";
import axios from "axios";
import {Navigate, useNavigate} from "react-router-dom";
import notification from "../../components/Elements/Notification";
import {apiUrl} from "../../setting";
import {InputBox} from "../../components/Elements/appUtils";

function ForgotPassword() {
    // const navigate = useNavigate();
    //
    // let dispatch = useDispatch();
    const [email, setUsername] = useState("");



    const forgotPassword = async (e) => {
        e.preventDefault()
        if (!email) {
            notification.warning({
                message: `Please Enter Email`,
            });
            return;
        }
        try {
            const response = await axios.post(`${apiUrl}/routes/user/forgot-password`, {
                email,
            });
            if(response.data.error)
                notification.error({message: "no user found"})
            else
                notification.success({message:"Link successfully sent to email"})


            setUsername('');
            // const {token, success, user} = response.data;
            // if (success) {
            //     localStorage.setItem("token", token);
            //     localStorage.setItem("user", JSON.stringify(user));
            //     dispatch({
            //         type: "SET_CURRENT_USER",
            //         user: user,
            //     });
            //     setTimeout(() => {
            //         window.location.href = "/dashboard";
            //     }, 300);
            // } else {
            //     notification.error({
            //         message: "Invalid email",
            //     });
            // }
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <>
            <h4>Reset Password</h4>
            <form className="pt-3">
                <div className="form-group">
                    <InputBox title={'Email'}>
                        <input type="email" className="form-control form-control-lg" id="exampleInputEmail1"
                               onChange={(e) => {setUsername(e.target.value)}}
                               value={email}
                               placeholder="Enter Email..."/>
                    </InputBox>
                </div>

                <div className="mt-3">
                    <button className="btn btn-block btn-gradient-info btn-lg font-weight-medium auth-form-btn"
                            onClick={forgotPassword}>Send
                    </button>
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="form-check">
                    </div>
                    <a className="auth-link text-black" onClick={()=>{window.location.href = "/login"}}>Back to login</a>
                </div>
                {/*   <div className="text-center mt-4 font-weight-light"> Dot have an account? <a href="register.html"
                                                                                         className="text-primary">Create</a>
          </div>*/}
            </form>

        </>
    )
}

export default ForgotPassword;
