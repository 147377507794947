import React, { useEffect, useState } from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../components/Elements/appUtils";
import { fetchGroup } from "../customerGroup/actions";
import moment from "moment";
import { leadSourceOption } from "../../components/_utils/_utils";
import { branchType } from "../../components/_utils/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { States, gstTypeList } from "../../components/_utils/appUtils";
import { addCompanyFxn } from "../customer/actions";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { singleBankFxn, updateBankFxn } from "../customer/actions";
import { type } from "@testing-library/user-event/dist/type";
const EditBank = (props) => {
  const { getFieldValue, setFieldsValue } = props.form;
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [bankId, setBankId] = useState("");
  const [groups, setGroups] = useState([]);
  useEffect(() => {
    checkBankId();
  }, []);

  const checkBankId = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      getSingleBank(id);
      setBankId(id);
    }
  };
  const getSingleBank = async (id) => {
    let { data, success } = await dispatch(singleBankFxn(id));
    console.log(data, "valdata");
    if (success) {
      setTimeout(() => {
        props.form.setFieldsValue({
          name: data.name,
          customerGroupName: data.customerGroupName,
          bankName: data.bankName,
          ifsc: data.ifsc,
          accountNo: data.accountNo,
          details: data.details,
          address: data.address,
        });
      }, 300);
    }
  };

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      if (!err) {
        valData._id = bankId;
        if (!valData.address && valData.customerGroupName === "BANK ACCOUNT") {
          notification.warning({ message: "Please fill Address" });
          return;
        }
        if (!valData.customerGroupName) {
          notification.warning({ message: "Please Select Group" });
          return;
        }

        let x = await dispatch(updateBankFxn(valData));
        if (x && x.success) {
          console.log(x, "valdata");
        }
      } else {
        if (err.name) {
          notification.warning({
            message: `Please enter Name`,
          });
          return;
        }

        if (err.email) {
          notification.warning({
            message: `Please enter Email`,
          });
          return;
        }

        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };
  useEffect(() => {
    props.form.setFieldsValue({
      customerGroupName: "BANK ACCOUNT",
    });
  }, []);
  const groupName = getFieldValue("customerGroupName");
  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name",
        span: "col-md-6",
        placeholder: "Name",
        required: true,
      },
      {
        key: "customerGroupName",
        label: "Select Group",
        span: "col-md-6",
        placeholder: "Select Group",
        type: "select",
        options: groups,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            customerGroupName: x,
          });
        },
      },
      // {
      //   key: "openingBalance",
      //   label: "Opening Balance",
      //   span: "col-md-6",
      //   placeholder: "Opening Balance",
      //   required: false,
      //   hidden: groupName === "CASH IN HAND",

      // },

      {
        key: "bankName",
        label: "Bank Name",
        span: "col-md-6",
        placeholder: "Bank Name",
        hidden: groupName === "CASH IN HAND",
        required: true,
      },
      {
        key: "accountNo",
        label: "Account No",
        span: "col-md-6",
        placeholder: "Account No",
        required: true,
        hidden: groupName === "CASH IN HAND",
      },

      {
        key: "ifsc",
        label: "IFSC Code",
        span: "col-md-6",
        placeholder: "IFSC Code",
        hidden: groupName === "CASH IN HAND",
        required: true,
      },

      {
        key: "details",
        label: "Details",
        span: "col-md-12",
        type: "textarea",
        placeholder: "Details..",
      },
      {
        key: "address",
        label: "Address",
        span: "col-md-12",
        type: "textarea",
        placeholder: "Bank Address",
        hidden: groupName === "CASH IN HAND",
      },
    ],
  };

  const events = {
    fetchGroups: async () => {
      let params = {
        // results: 100,
        // count: 100,
        type: "BANK",
      };
      const data = await fetchGroup({ ...params });
      setGroups(data.data);
    },

    // getState: (countryName) => {
    //     let resp = getStateByCountry(countryName)
    //     setStateList(resp)
    // },
    // getShippingState: (countryName) => {
    //     let resp = getStateByCountry(countryName)
    //     setShippingStateList(resp)
    // }
  };

  useEffect(() => {
    events.fetchGroups();
  }, []);
  return (
    <>
      <PageHeader title={"Edit Bank"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary me-2">
                Submit
              </button>
              <HyperLink
                className=" ms-4 btn btn-danger"
                link={"/company-list"}
              >
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(EditBank);
