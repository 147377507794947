import { addStockRequestUrl, approveRequestURl, getCustomerDataUrl, getPurchaseUrl,fetchRequestedProductReport } from "../api";
import {customAxios as axios, getToken} from "../../../request";
import {hideLoader, showLoader} from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";


export const fetchCustomer = async (valData) => {
    let config = {
        params: {...valData}
    }
    const {data} = await axios.get(getCustomerDataUrl(),getToken(),config);
    return data;
};

export const fetchRequestedProductReportFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...getToken(),
    }
    console.log(config,"thsi si confic");
    const {data} = await axios.get(fetchRequestedProductReport(),config);
    return data;
};

export const fetchPurchase = async (valData) => {
    let config = {
        params: {...valData}
    }
    const {data} = await axios.get(getPurchaseUrl(),getToken(),config);
    return data;
};

export const approveReqFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...(await getToken()),
    }
    // console.log(config,"thisis config");
    const {data} = await axios.post(approveRequestURl(),config);
    return data;
};

export const addStockRequestFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addStockRequestUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        // notification.success(data.message || "Success")
    } else {
        notification.error(data.message || "Error")
    }
    return data;
};

export const stockRequest = async (valData) => {
    let config = {
        params: {...valData}
    }
    const {data} = await axios.get(getPurchaseUrl(),getToken(),config);
    return data;
};