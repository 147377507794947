import React, {useState, useEffect} from "react";
import {
    Form,
    HyperLink,
    notification,
} from "../../components/Elements/appUtils";
import {gstRate, types, UnitCodeArr} from "../../setting";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import PageHeader from "../../components/Elements/pageHeader";
import {useDispatch} from "react-redux";
import {FetchBrand, addProductFxn, getCategory} from "./actions";
import {States} from "../../components/_utils/appUtils";
import _ from "lodash";
import {dosage, getItemLocalstorage} from "../../components/_utils/_utils";
import {fetchFrequencyDetails, fetchTakenDetails} from "../patients/components/action";

function AddProduct(props) {
    const userType = getItemLocalstorage("user")["userType"];
    const {getFieldValue} = props.form;
    let {onClose = null, onSubmit} = props;
    let dispatch = useDispatch();
    const [brands, setBrands] = useState([]);
    const [category, setCategory] = useState([]);
    const [takenDetails, SetTakenDetails] = useState([]);
    const [frequencyDetails, SetFrequencyDetails] = useState([]);

    const fetchBrand = (params) => {
        return new Promise(async (resolve) => {
            try {
                const data = await FetchBrand({...params});
                resolve(data);
                setBrands(data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const fetchCategory = (params) => {
        return new Promise(async (resolve) => {
            try {
                const data = await getCategory({...params});
                resolve(data);
                setCategory(data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const fetchTaken = (params) => {
        return new Promise(async (resolve) => {
            const data = await fetchTakenDetails({...params});
            SetTakenDetails(data.data);
        });
    };

    const fetchFrequency = (params) => {
        return new Promise(async (resolve) => {
            const data = await fetchFrequencyDetails({...params});
            SetFrequencyDetails(data.data);
        });
    };

    useEffect(() => {
        props.form.setFieldsValue({
            gst: 18,
        });
        fetchCategory();
        fetchBrand();
        fetchFrequency();
        fetchTaken();
    }, []);

    const validTypes = ["Service", "Slip", "Membership"];
    const TypeCheck = validTypes.includes(getFieldValue("type"));

    const handleSubmit = async (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.type) {
                    notification.warning({message: "Choose Type"});
                    return;
                }
                if (!valData.name) {
                    notification.warning({message: "Enter Name"});
                    return;
                }
                if (!valData.brandId && getFieldValue("type") == "Product") {
                    notification.warning({message: "Choose Brand"});
                    return;
                }
                if (!valData.categoryId && getFieldValue("type") == "Product") {
                    notification.warning({message: "Choose Category"});
                    return;
                }
                if (!valData.code) {
                    notification.warning({message: "Enter Code"});
                    return;
                }
                if (!valData.hsnCode) {
                    notification.warning({message: "Enter Hsn Code"});
                    return;
                }
                if (!valData.price && TypeCheck) {
                    notification.warning({message: "Enter Price"});
                    return;
                }
                if (!valData.unitCode && getFieldValue("type") == "Product") {
                    notification.warning({message: "Choose Unit"});
                    return;
                }
                if (!valData.packing && getFieldValue("type") == "Product") {
                    notification.warning({message: "Enter Packing Details"});
                    return;
                }
                if (!valData.gst) {
                    notification.warning({message: "Choose GST"});
                    return;
                }
                let findStateCode = _.find(States, (item) => {
                    return item.name == valData.state;
                });
                if (findStateCode) {
                    valData.stateCode = findStateCode.stateCode;
                }
                let redirect;
                if (valData.type === "Product") {
                    redirect = "product-list";
                } else {
                    redirect = "service-list";
                }

                let x = await dispatch(addProductFxn(valData));
                if (x && x.success) {
                    props.form.setFieldsValue({});
                    if (onClose) {
                        onSubmit()
                        onClose();

                    }
                    props.form.setFieldsValue({
                        name: "",
                        brandId: "",
                        categoryId: "",
                        code: "",
                        hsnCode: "",
                        price: "",
                        unitCode: "",
                        weight: "",
                        packing: "",
                        description: "",
                        type: "",
                        gst: "se"
                    });
                }
            }
        });
    };

    function filterTypesByUserType(userType, types) {
        if (userType === "headBranch") {
            return types.filter((type) => type.name !== "Product");
        } else {
            return types;
        }
    }

    const filteredTypes = filterTypesByUserType(userType, types);

    // Output the filtered types
    console.log(filteredTypes);
    let inputTypes = {
        fields: [
            {
                key: "type",
                label: "Type *",
                span: "col-md-4",
                placeholder: "Type",
                type: "select",
                options: filteredTypes,
                showSearch: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        type: x,
                    });
                },
            },

            {
                key: "name",
                label: "Name *",
                span: "col-md-4",
                placeholder: "Name",
            },
            {
                key: "brandId",
                label: "Select Brand *",
                span: "col-md-4",
                placeholder: "Brands",
                type: "select",
                options: brands,
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.brand_Name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        brandId: x,
                    });
                },
                hidden: TypeCheck,
            },
            {
                key: "categoryId",
                label: "Select Category *",
                span: "col-md-4",
                placeholder: "Categories",
                type: "select",
                options: category,
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.categoryName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        categoryId: x,
                    });
                },
                hidden: TypeCheck,
            },
            {
                key: "code",
                label: "Code *",
                span: "col-md-4",
                placeholder: "Product Code",
            },
            {
                key: "hsnCode",
                label: "HSN Code *",
                type: "text",
                span: "col-md-4",
                placeholder: "HSN Code",
            },
            {
                key: "price",
                label: "Price *",
                type: "number",
                span: "col-md-4",
                placeholder: "Price",
                hidden: getFieldValue("type") === "Product",
            },
            {
                key: "unitCode",
                label: "Unit *",
                span: "col-md-4",
                placeholder: "Unit Code",
                type: "select",
                options: UnitCodeArr,
                showSearch: true,
                keyAccessor: (x) => x.code,
                valueAccessor: (x) => `${x.name} (${x.code})`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        unitCode: x,
                    });
                },
                hidden: TypeCheck,
            },

            {
                key: "weight",
                label: "Weight",
                span: "col-md-4",
                placeholder: "Weight",
                hidden: TypeCheck,
            },

            {
                key: "packing",
                label: "Packing *",
                span: "col-md-4",
                placeholder: "Packing",
                hidden: TypeCheck,
                // required: true,
            },

            {
                key: "gst",
                label: "GST Rate *",
                span: "col-md-4",
                placeholder: "GST Rate",
                type: "select",
                options: gstRate,
                showSearch: true,
                keyAccessor: (x) => x.code,
                valueAccessor: (x) => `${x.number}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        gst: x,
                    });
                },
                // required: true,
            },

            {
                key: "dosage",
                label: "Dosage",
                span: "col-md-4",
                type: "select",
                options: dosage,
                showSearch: true,
                keyAccessor: (x) => x.option,
                valueAccessor: (x) => `${x.option}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        dosage: x,
                    });
                },
            },

            {
                key: "takenDetails",
                label: "When To Take",
                span: "col-md-4",
                type: "select",
                options: takenDetails,
                showSearch: true,
                keyAccessor: (x) => x.whentotake,
                valueAccessor: (x) => `${x.whentotake}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        takenDetails: x,
                    });
                },
            },

            {
                key: "frequencyDetails",
                label: "Routine/Freq",
                span: "col-md-4",
                type: "select",
                options: frequencyDetails,
                showSearch: true,
                keyAccessor: (x) => x.frequency,
                valueAccessor: (x) => `${x.frequency}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        frequencyDetails: x,
                    });
                },
            },

            {
                key: "description",
                label: "Description",
                span: "col-md-8",
                placeholder: "Description",
                type: "textarea",
            },
        ],
    };

    return (
        <PageHeader title={"Add Product"}>
            <div className="card">
                <div className="card-body">
                    <Form>
                        <div className="form-elements-wrapper">
                            <div className={"row"}>
                                {inputTypes.fields.map((item, key) => {
                                    return !item.hidden ? (
                                        <div
                                            className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    ) : null;
                                })}
                            </div>
                        </div>

                        <button type="submit" className="btn btn-gradient-primary" onClick={handleSubmit}>
                            Submit
                        </button>
                        <HyperLink className=" ms-4 btn btn-danger" link={"/product-list"}>
                            Cancel
                        </HyperLink>
                    </Form>
                </div>
            </div>
        </PageHeader>
    );
}

export default Form.create()(AddProduct);
