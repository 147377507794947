import React, { useEffect, useState } from "react";
import {
  Form,
  HyperLink,
  notification,
} from "../../components/Elements/appUtils";
import { branchType } from "../../components/_utils/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "../../components/_utils/formUtils";
import { States, gstTypeList } from "../../components/_utils/appUtils";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { singleBranchFxn, updateBranchFxn } from "./actions";
const EditBranch = (props) => {
  const { getFieldValue, setFieldsValue } = props.form;
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [branchId, setBranchId] = useState("");
  const [branchTypes, setBranchType] = useState("");
  useEffect(() => {
    checkBranchId();
  }, []);

  const checkBranchId = async () => {
    let searchParams = new URLSearchParams(window.location.search);
    let id = searchParams.get("_id");
    if (id) {
      getSingleBranch(id);
      setBranchId(id);
    }
  };
  const getSingleBranch = async (id) => {
    let { data, success } = await dispatch(singleBranchFxn(id));
    if (success) {
      setTimeout(() => {
        props.form.setFieldsValue({
          name: data.name,

          mobile: data.mobile,
          password: data.password,
          address: data.address,
          email: data.email,
          state: data.state,
          city: data.city,
          pincode: data.pincode,
          gstType: data.gstType,
          royalty: data.royalty,
          discount: data.discount,
          typeOfBranch: data.type,
        });

        setBranchType(data.type);
      }, 300);
      setTimeout(() => {
        if (data.gstType == "Registered") {
          setFieldsValue({ gstIn: data.gstIn });
        }
      }, 400);
    }
  };

  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();

    form.validateFields(async (err, valData) => {
      if (!err) {
        let findStateCode = _.find(States, (item) => {
          return item.name === valData.state;
        });
        if (findStateCode) {
          valData.stateCode = findStateCode.stateCode;
        }
        valData._id = branchId;
        valData.type = branchTypes;
        let x = await dispatch(updateBranchFxn(valData));
        if (x && x.success) {
          console.log(x, "valdata");
        }
      } else {
        if (err.name) {
          notification.warning({
            message: `Please enter Name`,
          });
          return;
        }

        if (err.email) {
          notification.warning({
            message: `Please enter Email`,
          });
          return;
        }

        if (err.mobile) {
          notification.warning({
            message: `Please enter Mobile No`,
          });
          return;
        }

        if (err.state) {
          notification.warning({
            message: `Please enter State`,
          });
          return;
        }

        if (err.city) {
          notification.warning({
            message: `Please enter City`,
          });
          return;
        }

        if (err.pincode) {
          notification.warning({
            message: `Please enter Pin Code`,
          });
          return;
        }

        if (err.address) {
          notification.warning({
            message: `Please enter Address`,
          });
          return;
        }

        notification.warning({
          message: "Fill All Required Fields",
        });
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "typeOfBranch",
        label: "Type of Branch",
        span: "col-md-6",
        type: "select",
        options: branchType,
        keyAccessor: (x) => x.key,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            typeOfBranch: x,
          });
        },
      },
      {
        key: "name",
        label: "Branch Name",
        span: "col-md-6",
        placeholder: "Branch Name",
        required: true,
      },

      {
        key: "email",
        label: "Email",
        type: "email",
        span: "col-md-6",
        placeholder: "Email",
        required: true,
      },
      {
        key: "mobile",
        label: "Mobile No",
        span: "col-md-6",
        placeholder: "Mobile No",
        required: true,
      },
      {
        key: "state",
        label: "State",
        span: "col-md-6",
        placeholder: "State",
        type: "select",
        options: States,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            state: x,
          });
        },
      },

      {
        key: "city",
        label: "City",
        span: "col-md-3",
        placeholder: "City",
        required: true,
      },
      {
        key: "pincode",
        label: "Pincode",
        span: "col-md-3",
        placeholder: "Pincode",
        required: true,
      },
      {
        key: "discount",
        label: "Default Discount",
        span: "col-md-3",
        placeholder: "Default Discount",
        required: true,
      },
      {
        key: "royalty",
        label: "Royalty %",
        span: "col-md-3",
        placeholder: "Royalty %",
        required: true,
      },

      {
        key: "gstType",
        label: "GST Type",
        span: "col-md-6",
        placeholder: "GST Type",
        type: "select",
        options: gstTypeList,
        showSearch: true,
        keyAccessor: (x) => x.value,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          console.log(x);
          props.form.setFieldsValue({
            gstType: x,
          });
        },
      },
      {
        key: "gstIn",
        label: "GSTIN",
        span: "col-md-6",
        placeholder: "GSTIN",
        hidden: getFieldValue("gstType") !== "Registered",
        required: getFieldValue("gstType") === "Registered",
      },
      {
        key: "address",
        label: "Address",
        span: "col-md-12",
        placeholder: "Address",
        required: false,
        type: "textarea",
      },
    ],
  };

  return (
    <>
      <PageHeader title={"Edit Branch"}>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              <div className="form-elements-wrapper">
                <div className={"row"}>
                  {inputTypes.fields.map((item, key) => {
                    return !item.hidden ? (
                      <div
                        className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                        key={key}
                      >
                        <GetEachFormFields {...props.form} item={item} />
                      </div>
                    ) : null;
                  })}
                </div>
              </div>

              <button type="submit" className="btn btn-gradient-primary">
                Submit
              </button>
              <HyperLink
                className=" ms-4 btn btn-danger"
                link={"/branch"}
              >
                Cancel
              </HyperLink>
            </Form>
          </div>
        </div>
      </PageHeader>
    </>
  );
};

export default Form.create()(EditBranch);
