import {getGroupDataUrl, addGroupUrl} from "../api";
import {customAxios as axios, getToken} from "../../../request";
import {notification} from "../../../components/Elements/appUtils";
import {showLoader, hideLoader} from "../../../actions/loader";
import { deleteProductUrl } from "../../products/api";


export const fetchGroup = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getGroupDataUrl(), config);
    return data;
};

export const addGroupFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addGroupUrl(), valData, getToken());
    dispatch(hideLoader())
    if (data.success) {
        notification.success({
            message: data.message || "Success",
        });
    } else {
        notification.error({
            message: data.message || "Error",
        });
    }
    return data;
};


