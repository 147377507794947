import React, { useState, useRef } from "react";
import { customAxios as axios, getToken } from "../../request";
import {
  InputBox,
  Form,
  Table,
  notification, Popconfirm, Tooltip,
} from "../../components/Elements/appUtils";
import {addsaleCategoryUrl, deleteSaleCategoryUrl} from "./api";
import { fetchSaleCategory } from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import { useNavigate } from "react-router-dom";
import { yesNo } from "../../components/_utils/appUtils";
import {deleteProductUrl} from "../products/api";
function AddSaleCategory() {
  let tableRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    includeBalance: "",
  });
  const navigate = useNavigate();
  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData, "formadta");

    if (!formData.name) {
      notification.warning({ message: "Enter Name" });
      return;
    }
    if (!formData.includeBalance) {
      notification.warning({ message: "Include Balance?" });
      return;
    }
    const response = await axios.post(
      addsaleCategoryUrl(),
      formData,
      getToken()
    );
    if (response.data.success) {
      notification.success({
        message: response.data.message || "Success",
      });
      setFormData({ name: "", includeBalance: "" });
      tableRef.current.reload();
    } else {
      notification.error({ message: response.data.message });
    }
  };

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await fetchSaleCategory({ ...params });
        resolve(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };
  const deleteSaleCateogry = async (id) => {
    try {
      const response = await axios.delete(deleteSaleCategoryUrl(id));
      if (response.data.error) {
        notification.error({
          message: response.data.message,
        });
      } else {
        tableRef.current.reload();
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Include Balance",
      dataIndex: "includeBalance",
      key: "includeBalance",
      render: (text, record) => (
        <>
          <span>{record.includeBalance ? "Yes" : "No"}</span>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 190,
      render: (val, record) => {
        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              onClick={() => {
                navigate(`/edit-sale-category?_id=${record._id}`);
              }}
            >
              <img src={"./edit.svg"} />
            </a>
            <Tooltip title={"Delete"} className="ml-4">
              <Popconfirm
                  title={"Are your sure, you want to delete Sale Category?"}
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    deleteSaleCateogry(record._id);
                  }}
              >
                <img
                    className="ms-2"
                    style={{ height: "30px", width: "30px" }}
                    src={"./close.svg"}
                />
              </Popconfirm>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <PageHeader title={"Sale Categories"}>
      <Form>
        <div class="card">
          <div class="card-body">
            <div class="form-elements-wrapper">
              <div class="row">
                <div class="col-lg-3">
                  <InputBox title={"Name"}>
                    <input
                      type="text"
                      className={"form-control"}
                      placeholder="Enter Category"
                      value={formData.name}
                      onChange={(e) => handleChange(e, "name")}
                      required
                    />
                  </InputBox>
                </div>
                <div class="col-lg-3">
                  <InputBox title={"Include Balance"} className={"flex2"}>
                    <select
                      style={{ height: "45px" }}
                      className="form-control"
                      value={formData.includeBalance}
                      placeholder="Select"
                      onChange={(e) => handleChange(e, "includeBalance")}
                      required
                    >
                      <option>Select</option>
                      {yesNo.map((item) => (
                        <option key={item.name} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </InputBox>
                </div>
              </div>
            </div>
            <button onClick={handleSubmit} className="btn btn-success">
              SUBMIT
            </button>
          </div>
        </div>
      </Form>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
            </div>
          </div>
        </div>
      </div>
    </PageHeader>
  );
}

export default AddSaleCategory;
