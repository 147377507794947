import React, { useEffect, useRef, useState } from "react";
import {DefaultTablePagination, Modal, Table} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import { fetchslip } from "./actions";
import moment from "moment";

import { useNavigate } from "react-router-dom";


import { getItemLocalstorage } from "../../components/_utils/_utils";
import SearchCustomer from "../customer/chooseCustomer/searchCustomer";

const Slip = (props) => {
  let tableRef = useRef();
  const [visitData, setVisitData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [searchPatientId, setSearchPatientId] = useState("")
  const [searchPatientName, setSearchPatientName] = useState("")
  const [customerId, setCustomerId] = useState("");
  const [date, setDate] = useState("");

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        if (customerId) {
          params.patientId = customerId;
        }
        if (date) {
          params.startDate = moment(date);
        }
        const data = await fetchslip({ ...params });
        setVisitData(data.data)
        setFilteredData(data.data)
        resolve(data);

      } catch (error) {
        console.error("Error fetching data:", error);
        resolve([]);
      }
    });
};

  useEffect(() => {
    apiRequest();
  }, []);

  
  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
  
    {
      title: "Patient Name",
      dataIndex: "patientId",
      key: "patientId",
      render: (item) => {
        return <>{item && item.name ? item.name : ""}</>;
      },
    },
    {
        title: "Doctor Name",
        dataIndex: "doctorId",
        key: "doctorId",
        render: (item) => {
            return <>{item && item.name ? item.name : ""}</>;
          },
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        render: (text, record) => (
          <span>{record.type} </span>
        ),
      },
  
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text, record) => (
        <span>{moment(record.startDate).format("DD/MM/YYYY")} </span>
      ),
    },
    {
        title: "Expire Date",
        dataIndex: "expireDate",
        key: "expireDate",
        render: (text, record) => (
          <span>{moment(record.expiryDate).format("DD/MM/YYYY")} </span>
        ),
      },

  
  ];

  // const filterPatient = () => {
  //   setShowFilter(true)
  //   if(!searchPatientId && !searchPatientName && !searchDate && !searchTime){
  //     setVisitData([])
  //   }
  //   const filteredPatient = visitData.filter((item) => {
  //     item.patientId?.uhid?.toLowerCase().includes(searchPatientId.toLowerCase()) &&
  //     item.patientId?.name?.toLowerCase().includes(searchPatientName.toLowerCase()) &&
  //     item.date?.toLowerCase().includes(searchDate.toLowerCase()) &&
  //     item.time?.toLowerCase().includes(searchTime.toLowerCase())
  //   })
  //   setVisitData(filteredPatient)
  // }

  // const clearPatientFilters = () => {
  //   setSearchPatientId("")
  //   setSearchPatientName("")
  //   setSearchDate("")
  //   setSearchTime("")
  //   setShowFilter(false)
  //   setVisitData(filteredData)
  //   if(tableRef.current.reload) {
  //     tableRef.current.reload()
  //   }
  // }
  const printFxn = () => {
    const printContents = document.getElementById("printable").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  const handleFilter = async () => {
    if (tableRef.current) {
      tableRef.current.reload();
    }
  };
  return (
      <PageHeader title={"Slip"}>
        <div className="card">
          <div className="card-body">
            <div className="mb-3 row">
              <div className={"col-md-2 pe-0"} style={{marginTop: "13px"}}>
                <SearchCustomer
                    type={["patient"]}
                    customerId={customerId}
                    callback={(clientData) => {
                      if (clientData && clientData._id) {
                        setCustomerId(clientData._id);
                      }
                    }}
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="searchCategory" className="form-label"/>
                <input
                    type="date"
                    className="form-control "
                    name="name"
                    value={date}
                    onChange={(e) =>
                        setDate(e.target.value)
                    }
                />
              </div>
              <div className="col-md-2 mb-2 pe-0" style={{paddingLeft: '7px'}}>
                <label htmlFor="searchPatientId" className="form-label"/>
                <input
                    type="text"
                    className="form-control search-filters"
                    id="searchPatientId"
                    placeholder="Search by PatientId"
                    value={searchPatientId}
                    onChange={(e) => setSearchPatientId(e.target.value)}
                />
              </div>
              <div className="col-md-2 mb-2 pe-0" style={{paddingLeft: '7px'}}>

                <label htmlFor="searchPatientName" className="form-label"/>
                <input
                    type="text"
                    className="form-control search-filters"
                    id="searchPatientName"
                    placeholder="Search by PatientName"
                    value={searchPatientName}
                    onChange={(e) => setSearchPatientName(e.target.value)}
                />
              </div>


            </div>

      <div className="mb-3 search-buttons">
        <div className="search-wrap pe-2">
          <button className="btn btn-info" onClick={handleFilter}>
            Search
          </button>
        </div>
        <div className="search-wrap">
          <button className="btn btn-secondary" onClick={handleFilter}>
            Clear
          </button>
        </div>
        <div className="search-wrap">
          <button className="btn btn-success" onClick={printFxn}>
            Print
          </button>
        </div>
      </div>
          <div id="printable">
                <Table
                    apiRequest={apiRequest}
                    columns={columns}
                    ref={tableRef}
                    position={"top"}
                    pagination={{...DefaultTablePagination(), defaultPageSize: 50}}
                    extraProps={{scroll: {x: 100},}}
                />
          </div>
        </div>
      </div>

   
    </PageHeader>
  );
};

export default Slip;
