import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import moment from "moment";
import {
    DatePicker,
    Select2,
    InputBox,
    Card,
    notification,
    Tooltip,
} from "../../components/Elements/appUtils";
import {getStockUrl} from "../products/api";
import {getToken} from "../../request";
import TableMain from "../../components/Elements/Table";
import _ from "lodash";
import {calculation} from "../../components/Elements/appUtils";
import {arraySorter, monthYearPicker} from "../../components/_utils/appUtils";
import AddProductDrawer from "./addProductDrawer";
import {getItemLocalstorage} from "../../components/_utils/_utils";
import {findRecentProductReport} from "./actions";

let {Option} = Select2;
let initState = {
    productId: "",
    productName: "",
    quantity: 1,
    price: "",
    salePrice: "",
    inclusivePrice: "",
    pUnit: "",
    hsn: "",
    subTotal: "",
    discount: "",
    discountAmount: "",
    expiryDate: "",
    manufacturingDate: "",
    batchNo: "",
    taxableAmount: "",
    igstAmount: "",
    cgstAmount: "",
    sgstAmount: "",
    igstTax: "",
    cgstTax: "",
    sgstTax: "",
    gst: "",
    uom: "",
    totalAmount: "",
    gstAmount: "",
    netAmount: "",
    stateOfSupply: "",
    customerId: "",
    transactionType: "",
    reportType: "Exclusive",
};

const ItemListComponent = (props) => {
    let {
        ProductReport,
        updateProductReport,
        customerId,
        productId,
        stateOfSupply,
        companyState,
    } = props;
    const discountCode = getItemLocalstorage("user")["discount"];
    const [stock, setStock] = useState([]);
    let [productState, setProductState] = useState(initState);
    let [productList, setProductList] = useState([]);
    let [k, setk] = useState([]);

    useEffect(() => {
        setProductList(ProductReport);
    }, [ProductReport]);

    useEffect(() => {
        reCalculateAmount().then(() => {
            setk(new Date().toString());
        });
    }, [
        stateOfSupply,
        productState.productId,
        productState.reportType,
        productState.reportType,
        productState.price,
        productState.salePrice,
        productState.quantity,
        productState.discount,
        productState.gst,
    ]);

    const [expDate, setexpDate] = useState();
    const [manfDate, setmanfDate] = useState();

    let [visible, setVisible] = useState(false);
    productState.expiryDate = expDate;
    productState.manufacturingDate = manfDate;

    const fetchStock = async () => {
        try {
            const response = await axios.get(getStockUrl(), getToken());
            const filteredStock = response.data.data.filter(
                (item) => item.productId.type === "Product"
            );

            const sortedArray = arraySorter(filteredStock);

            setStock(sortedArray);
        } catch (error) {
            console.error("Error getting the data:", error);
        }
    };

    useEffect(() => {
        fetchStock();
    }, [productId]);
    useEffect(() => {
        handleDelete();
    }, []);

    const onSubmit = () => {
        if (!productState.productId) {
            notification.warning({message: "Please Choose Product"});
            return;
        }
        if (!productState.price) {
            notification.warning({message: "Please Enter Purchase Price"});
            return;
        }
        if (!productState.salePrice) {
            notification.warning({message: "Please Enter Sale price"});
            return;
        }
        if (!productState.quantity) {
            notification.warning({message: "Please Enter Quantity"});
            return;
        }

        if (!productState.manufacturingDate) {
            notification.warning({message: "Please Enter Manufacturing Date"});
            return;
        }

        if (productState.manufacturingDate) {
            const [month, year] = manfDate.split("/");
            const isValidMonth =
                /^\d{2}$/.test(month) &&
                parseInt(month, 10) >= 1 &&
                parseInt(month, 10) <= 12;
            const isValidYear =
                /^\d{4}$/.test(year) &&
                parseInt(year, 10) >= 2020 &&
                parseInt(year, 10) <= 2030;
            if (isValidMonth && isValidYear) {
                setmanfDate(manfDate);
            } else {
                notification.warning({
                    message: "Please Enter Valid Manufacturing Date",
                });
                return;
            }
        }

        if (!productState.expiryDate) {
            notification.warning({message: "Please Enter Expiry Date"});
            return;
        }

        if (productState.expiryDate) {
            const [month2, year2] = expDate.split("/");
            const isValidMonth2 =
                /^\d{2}$/.test(month2) &&
                parseInt(month2, 10) >= 1 &&
                parseInt(month2, 10) <= 12;
            const isValidYear2 =
                /^\d{4}$/.test(year2) &&
                parseInt(year2, 10) >= 2020 &&
                parseInt(year2, 10) <= 2030;
            if (isValidMonth2 && isValidYear2) {
                setmanfDate(expDate);
            } else {
                notification.warning({message: "Please Enter Valid Expiry Date"});
                return;
            }
        }

        if (!productState.batchNo) {
            notification.warning({message: "Please Enter Batch No"});
            return;
        }
        if (
            ProductReport.find((x) => {
                return (
                    x.batchNo == productState.batchNo &&
                    x.productId == productState.productId &&
                    x.expiryDate == productState.expiryDate &&
                    x.manufacturingDate == productState.manufacturingDate
                );
            })
        ) {
            notification.warning({
                message: "Product already exist with same Batch",
            });
            return;
        }

        productState.batchNo = productState.batchNo.toUpperCase();

        const updatedProductReport = [
            ...ProductReport,
            {...productState, serialNo: ProductReport.length + 1},
        ];
        updateProductReport(updatedProductReport);

        setProductState(initState);
        setmanfDate("");
        setexpDate("");
    };

    const reCalculateAmount = () => {
        return new Promise((resolve) => {
            calculation({...productState}, stateOfSupply, companyState).then(
                (resp) => {
                    setProductState({
                        ...productState,
                        ...resp,
                    });
                }
            );
            return resolve("done");
        });
    };

    const StateChangesRecalculate = () => {
        // Assuming productState is an array
        let cloneData = _.clone(ProductReport);
        let newArr = [];
        _.each(cloneData, async (item) => {
            let resp = await calculation(item, stateOfSupply, companyState);
            newArr.push(resp);
        });

        // setProductList(newArr);
        updateProductReport(newArr);
    };

    useEffect(() => {
        StateChangesRecalculate();
    }, [stateOfSupply, k]);

    const handleChange1 = async (e, fieldName) => {
        if (!props.customerId) {
          notification.warning({ message: "Please Choose Client" });
          setProductState({
            ...productState,
            productId: "",
          });
          return;
        } else {
        const {value} = e.target;

        let obj = {};
        if (fieldName == "productId") {
            const selectedProduct = stock.find(
                (prod) => prod.productId?._id === value
            );

            let recentPurchasePrice;
            let recentSalePrice;

            const {findProductReport} = await findRecentProductReport({productId: selectedProduct.productId._id})
            if (findProductReport && findProductReport._id) {
                recentPurchasePrice = findProductReport.price;
                recentSalePrice = findProductReport.salePrice;
            }
            obj = {
                productName: selectedProduct.productId.name,
                pUnit: selectedProduct.productId.unitCode,
                hsn: selectedProduct.productId.hsnCode,
                gst: selectedProduct.productId.gst,
                uom: selectedProduct.productId.unitCode,
                discount: discountCode || "",
                price: recentPurchasePrice || 0,
                salePrice: recentSalePrice || 0,
            };
        }
        setProductState({
            ...productState,
            [fieldName]: value,
            ...obj,
        });
        }
    };

    let Cgst = {
        title: "CGST",
        dataIndex: "cgstAmount",
        key: "cgstAmount",
        render: (text, record) => (
            <span>
        {record.gst / 2}% (Rs.{text}){" "}
      </span>
        ),
    };

    let Sgst = {
        title: "SGST",
        dataIndex: "sgstAmount",
        key: "sgstAmount",
        render: (text, record) => (
            <span>
        {record.gst / 2}% (Rs.{text}){" "}
      </span>
        ),
    };

    let Igst = {
        title: "IGST",
        dataIndex: "igstTax",
        key: "igstTax",
        render: (text, record) => (
            <span>
        {text}% (Rs.{record.igstAmount}){" "}
      </span>
        ),
    };

    let NetAmount = {
        title: "Net Amount",
        dataIndex: "netAmount",
        key: "netAmount",
        render: (text) => `Rs. ${text}`,
    };

    let action = {
        title: "Action",
        key: "delete",
        render: (text, record, index) => {
            return (
                <>
                    <a className="empty_btn" onClick={() => handleDelete(index)}>
                        <i className="fa fa-trash-alt"></i>
                    </a>
                </>
            );
        },
    };

    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Name",
            dataIndex: "productName",
            key: "productName",
        },
        {
            title: "Type",
            dataIndex: "reportType",
            key: "reportType",
        },
        {
            title: "HSN",
            dataIndex: "hsn",
            key: "hsn",
        },
        {
            title: "Mfg.",
            dataIndex: "manufacturingDate",
            key: "manufacturingDate",
        },
        {
            title: "Exp.",
            dataIndex: "expiryDate",
            key: "expiryDate",
        },
        {
            title: "Batch",
            dataIndex: "batchNo",
            key: "batchNo",
        },
        {
            title: "Purchase Price.",
            dataIndex: "price",
            key: "price",
        },
        {
            title: "Sale Price.",
            dataIndex: "salePrice",
            key: "salePrice",
        },
        {
            title: "Qty.",
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: "UoM",
            dataIndex: "uom",
            key: "uom",
        },
        {
            title: "Rate",
            dataIndex: "price",
            key: "price",
        },
        {
            title: "Total",
            dataIndex: "totalAmount",
            key: "totalAmount",
            render: (text) => `Rs.${text}`,
        },
        {
            title: "Dis(%)",
            dataIndex: `discount`,
            key: "discount",
            render: (text) => <span>{text}%</span>,
        },
        {
            title: "Dis Amt.",
            dataIndex: `discountAmount`,
            key: "discountAmount",
            render: (text) => <span>Rs.{text}</span>,
        },
        {
            title: "Taxable Amount",
            dataIndex: "taxableAmount",
            key: "taxableAmount",
            render: (text) => `Rs. ${text}`,
        },
    ];

    const handleDelete = (index) => {
        reCalculateAmount();
        const updatedProductReport = [...ProductReport];
        updatedProductReport.splice(index, 1);
        updateProductReport(updatedProductReport);
    };

    const getColumns = () => {
        if (stateOfSupply == companyState) {
            columns.push(Cgst, Sgst, NetAmount, action);
        } else {
            columns.push(Igst, NetAmount, action);
        }
        return columns;
    };

    useEffect(() => {
        getColumns();
    }, [customerId, stateOfSupply]);

    return (
        <>
            <form>
                <Card title={"Select Items"} size={"small"}>
                    <div className={"item-flex"}>
                        <InputBox title={"Product"} className={"flex2"}>
                            <div className="d-flex align-items-center">
                                <select
                                    style={{height: "45px", width: "150px"}}
                                    className="form-control flex-grow-1"
                                    value={productState.productId}
                                    onChange={(e) => handleChange1(e, "productId")}
                                    required
                                >
                                    <option value="">Select Product</option>
                                    {stock.map((stock) => (
                                        <option
                                            key={stock.productId._id}
                                            value={stock.productId._id}
                                        >
                                            {stock.productId.name}
                                        </option>
                                    ))}
                                </select>

                                <Tooltip title={"Add Product"}>
                                    <a className={"plus_btn"} onClick={() => setVisible(true)}>
                                        <i className="mdi mdi-plus menu-icon"></i>
                                    </a>
                                </Tooltip>
                            </div>
                        </InputBox>
                        {visible ? (
                            <AddProductDrawer
                                visible={visible}
                                onSubmit={() => fetchStock()}
                                onClose={() => setVisible(false)}
                            />
                        ) : null}

                        <InputBox title={"Type"}>
                            <select
                                style={{height: "45px"}}
                                className="form-control"
                                value={productState.reportType}
                                onChange={(e) => handleChange1(e, "reportType")}
                                required
                            >
                                <option>Select Tax Type</option>
                                <option>Inclusive</option>
                                <option>Exclusive</option>
                            </select>
                        </InputBox>
                        <InputBox title={"Purchase Price"}>
                            <input
                                style={{width: "115px"}}
                                type="number"
                                className={"form-control"}
                                placeholder="Purchase Price"
                                value={productState.price}
                                onChange={(e) => handleChange1(e, "price")}
                                required
                            />
                        </InputBox>
                        <InputBox title={"Sale Price"}>
                            <input
                                className={"form-control"}
                                type="number"
                                placeholder="Sale Price"
                                value={productState.salePrice}
                                onChange={(e) => handleChange1(e, "salePrice")}
                            />
                        </InputBox>
                        <InputBox title={"Qty."}>
                            <input
                                className={"form-control"}
                                type="number"
                                value={productState.quantity}
                                placeholder="Quantity"
                                onChange={(e) => handleChange1(e, "quantity")}
                            />
                        </InputBox>
                        <InputBox title={"Dis(%)"}>
                            <input
                                className={"form-control"}
                                type="text"
                                value={productState.discount}
                                placeholder="Discount"
                                onChange={(e) => handleChange1(e, "discount")}
                            />
                        </InputBox>
                        <InputBox title={"GST(%)"}>
                            <input
                                className={"form-control"}
                                type="text"
                                value={productState.gst}
                                placeholder="GST"
                                onChange={(e) => handleChange1(e, "gst")}
                                disabled
                            />
                        </InputBox>
                        <InputBox title={"Mfg."}>
                            <input
                                type="text"
                                className={"form-control"}
                                placeholder={"MM/YYYY"}
                                value={manfDate}
                                onChange={(e) => {
                                    setmanfDate(monthYearPicker(e.target.value));
                                }}
                                required
                            />
                        </InputBox>
                        <InputBox title={"Exp."}>
                            <input
                                type="text"
                                className={"form-control"}
                                placeholder={"MM/YYYY"}
                                value={expDate}
                                onChange={(e) => {
                                    setexpDate(monthYearPicker(e.target.value));
                                }}
                                required
                            />
                        </InputBox>
                        <InputBox title={"Batch"}>
                            <input
                                className={"form-control"}
                                type="text"
                                value={productState.batchNo}
                                placeholder="Batch no."
                                onChange={(e) => handleChange1(e, "batchNo")}
                            />
                        </InputBox>
                        <InputBox className={"flex_none"}>
                            <a
                                className="addBtn fs-5 btn-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onSubmit();
                                }}
                            >
                                <i className={`mdi mdi-library-plus menu-icon`}></i>
                            </a>
                        </InputBox>
                    </div>
                </Card>
            </form>
            <div key={ProductReport.length} className="mt-4 mb-4">
                <h4 className="m-2">Items List</h4>
                <div key={productList}>
                    <TableMain
                        dataSource={productList}
                        columns={getColumns()}
                        key={JSON.stringify(productList)}
                    />
                </div>
            </div>
        </>
    );
};
export default ItemListComponent;
