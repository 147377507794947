import { apiUrl } from "../../../setting";

export const getCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/customerList`;
};

export const getBank = () => {
    return `${apiUrl}/routes/customer/getBank`;
};

export const getStockUrl = () => {
    return `${apiUrl}/routes/product/getStock`;
};

// export const getProductUrl = () => {
//     return `${apiUrl}/routes/product/getCompanyStock`;
// };


export const getProductUrl = () => {
    return `${apiUrl}/routes/product/getProductData`;
};

export const addStockRequestUrl = () => {
    return `${apiUrl}/routes/stock/stockRequest`;
};

export const getPurchaseUrl = () => {
    return `${apiUrl}/routes/stock/getAllStockRequest`;
};


export const getGroupCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/getCustomerDataByGroupId`;
};

export const fetchRequestedProductReport = () => {
    return `${apiUrl}/routes/stock/getStockRequestReport`;
};

export const approveRequestURl = (saleId,requestId) => {
    return `${apiUrl}/routes/stock/getStockRecord/${saleId}/${requestId}`;
};

export const stockReport = (requestId) => {
    return apiUrl + "/routes/stock/getStockRecord/" + requestId;
};

export const rejectStockRequest = (requestId) => {
    return `${apiUrl}/routes/stock/rejectStockRequest/${requestId}`;
};
